import styled from 'styled-components'
import { maxDesktopWidth, colors, breakpoints } from 'constant'

const { md } = breakpoints

export const CtaBannerWrapper = styled.div`
  width: 100%;
  height: 340px;
  background: ${colors.c14};
  border: 2px solid ${colors.c17};
  box-sizing: border-box;
  backdrop-filter: blur(16px);
  border-radius: 24px;
  max-width: ${maxDesktopWidth};
  display: flex;
  align-items: center;
  padding: 24px;
  justify-content: space-between;

  > div {
    max-width: 490px;
    margin-left: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:first-child {
      > h2 {
        font-weight: bold;
        font-size: 40px;
        line-height: 54px;
        margin-bottom: 20px;

        ${md} {
          font-size: 39px;
        }
      }

      > h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 32px;

        ${md} {
          font-size: 19px;
        }
      }

      > a {
        width: 180px;
      }
    }

    &:last-child {
      height: 100%;
      width: 462px;
      border-radius: 24px;
      background-image: url(${({ image }) => image});
      background-size: cover;
      background-position: ${({ shiftLeft }) => shiftLeft ? 'left center' : 'center'};
    }
  }
`

export const MobileCtaBanner = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.c14};
  border: 2px solid ${colors.c17};
  box-sizing: border-box;
  backdrop-filter: blur(16px);
  border-radius: 24px;
  padding: 16px;

  > div {
    height: 240px;
    width: 100%;
    border-radius: 24px;
    background-image: url(${({ image }) => image});
    background-size: cover;
    background-position: ${({ shiftLeft }) => shiftLeft ? 'left center' : 'center'};
  }

  > h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
    margin-top: 15px;

    ${md} {
      font-size: 31px;
    }
  }

  > h4 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 32px;

    ${md} {
      font-size: 15px;
    }
  }
`
