import {
  Homepage,
  Events,
  NotFound,
  Event,
  Newsletter,
  Articles,
  Article,
  Preferences,
  Episodes,
  ChangePassword,
  About,
  VenueProfile,
  VenueDashboard,
  Signup,
  SignUpThankYou,
  PrefenceThankYou,
  BasicInformation,
  Venue,
  SignupPreferences,
  Optin,
  Login,
  SomethingNew,
  EditProfile,
  VenueEvents,
  VenueSignup,
  VenueGallery,
  CreateEvent,
  NoResult,
  EditEvent,
  NewPartner,
  NewPreferences,
  ArtsOganizations
} from 'scenes'
import signUpThankYou from './scenes/Signup/SignupThankYou'

const routes = [
  {
    path: '/',
    component: Homepage,
    exact: true
  },
  {
    path: '/change-password',
    component: ChangePassword,
    exact: true
  },
  {
    path: '/newsletter',
    component: Newsletter,
    exact: true
  },
  {
    path: '/about',
    component: About,
    exact: true
  },
  {
    path: '/events',
    component: Events,
    exact: true
  },
  {
    path: '/events/:slug',
    component: Event,
    exact: true
  },
  {
    path: '/episodes',
    component: Episodes,
    exact: true
  },
  {
    path: '/articles',
    component: Articles,
    exact: true
  },
  {
    path: '/preferences',
    component: NewPreferences,
    exact: true
  },
  {
    path: '/articles/:slug',
    component: Article,
    exact: true
  },
  {
    path: '/venue/:slug/dashboard/profile',
    component: VenueProfile,
    exact: true
  },
  {
    path: '/venue/:slug/dashboard/edit-profile',
    component: EditProfile,
    exact: true
  },
  {
    path: '/venue/:slug/dashboard/events',
    component: VenueEvents,
    exact: true
  },
  {
    path: '/venue/:slug/dashboard',
    component: VenueEvents,
    exact: true
  },
  {
    path: '/venue/:slug/dashboard/signup',
    component: VenueSignup,
    exact: true
  },
  {
    path: '/venue/:slug/dashboard/gallery',
    component: VenueGallery,
    exact: true
  },
  {
    path: '/venue/:slug/dashboard/create-event',
    component: CreateEvent,
    exact: true
  },
  {
    path: '/venue/:slug/dashboard/edit-event/:slug2',
    component: EditEvent,
    exact: true
  },
  {
    path: '/signup',
    component: Signup,
    exact: true
  },
  {
    path: '/signup/thankyou',
    component: SignUpThankYou,
    exact: true
  },
  {
    path: '/signup/info',
    component: BasicInformation,
    exact: true
  },
  {
    path: '/signup/preferences',
    component: SignupPreferences,
    exact: true
  },
  {
    path: '/signup/preferences/thankyou',
    component: PrefenceThankYou,
    exact: true
  },
  {
    path: '/signup/optin',
    component: Optin,
    exact: true
  },
  {
    path: '/signup/preferences/thankyou',
    component: PrefenceThankYou,
    exact: true
  },
  {
    path: '/venue/:slug',
    component: Venue,
    exact: true
  },
  {
    path: '/login',
    component: Login,
    exact: true
  },
  {
    path: '/somethingnew',
    component: SomethingNew,
    exact: true
  },
  {
    path: '/artorganizations',
    component: ArtsOganizations,
    exact: true
  },
  {
    path: '/noresult',
    component: NoResult,
    exact: true
  },
  {
    path: '/new-partner',
    component: NewPartner,
    exact: true
  },
  {
    path: '/',
    component: NotFound
  }
]

export default routes
