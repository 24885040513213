import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const Wrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SignupButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${colors.c10};
  border: none;
  padding: 10px 20px;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;
`
