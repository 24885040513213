import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { CTA, Copy, MainWrapper, Title, Subtitle } from './styled'

import { Modal } from 'components'
import { useStoreon } from 'storeon/react'

import { Button } from 'components'

const FOMOModal = () => {
  const { showFOMOModal, dispatch } = useStoreon('showFOMOModal')
  const location = useLocation()

  const handleOnClose = () => {
    dispatch('setShowFOMOModal', false)
  }

  useEffect(() => {
    dispatch('setShowFOMOModal', false)
  }, [location])

  const handleDontShow = () => {
    localStorage.setItem('hideFOMOModal', 'true')
    handleOnClose()
  }

  return (
    <Modal open={showFOMOModal} onClose={handleOnClose} closeButton transparent>
      <MainWrapper>
        <Title>FOMO Be Gone!</Title>
        <Subtitle>Thanks for following!</Subtitle>
        <Copy>
          This art organization will now appear on your homepage, making it easy
          to find out about their upcoming performances.
        </Copy>
        <CTA onClick={handleDontShow}>Don't remind me again</CTA>
      </MainWrapper>
    </Modal>
  )
}

export default FOMOModal
