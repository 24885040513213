import React from 'react'
import ImageUploading from 'react-images-uploading'
import {
  ButtonWrapper,
  HeroPlaceholder,
  MainWrapper,
  UploadButton,
  UploadedHero
} from './styled'
import { Icon } from 'components'

const Uploader = () => {
  const [images, setImages] = React.useState([])

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList)
  }

  return (
    <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps
      }) => (
        // write your building UI
        <MainWrapper>
          {images.length ? (
            imageList.map((image, index) => (
              <UploadedHero $imageUrl={image['data_url']} key={index}>
                <ButtonWrapper>
                  <UploadButton
                    onClick={() => onImageUpdate(index)}
                    {...dragProps}
                  >
                    <Icon name="gallery" /> Change
                  </UploadButton>
                </ButtonWrapper>
              </UploadedHero>
            ))
          ) : (
            <HeroPlaceholder>
              <ButtonWrapper>
                <UploadButton onClick={onImageUpload} {...dragProps}>
                  <Icon name="gallery" /> Upload
                </UploadButton>
              </ButtonWrapper>
            </HeroPlaceholder>
          )}
        </MainWrapper>
      )}
    </ImageUploading>
  )
}

export default Uploader
