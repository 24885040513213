import {ArticlesWrapper, ArticleContent, LatestArticle, SectionWrapper, AllCards, AritcleHeader, EpisodeItem, EpisodesWrapper} from './styled'
import React, { useMemo, useState } from 'react'
import {Banner, Button, SearchModal, Select, Loader} from 'components'
import {updateQuery, getQuery, genImgixUrl} from 'utils'
import {useHistory} from 'react-router-dom'
import {useQuery, gql} from '@apollo/client'
import { useStoreon } from 'storeon/react'

const GET_EPISODES = gql`
  query getepisodes($limit: Int!, $where: episodes_bool_exp = {}) {
    episodes(limit: $limit, order_by: {created_at: desc}, where: $where) {
      url
      id
      name
      date
      description
      image_path
      slug
      episode_tags {
        tag {
          name
        }
      }
    }
  }
`

const categories = [
  {label: 'Latest', value: 'latest'},
  {label: 'Music', value: 'music'},
  {label: 'Theater', value: 'theater'},
  {label: 'Dance', value: 'dance'}
]

const Articles = () => {
  const {showSearchModal} = useStoreon('showSearchModal');
  const history = useHistory()
  const {c = '', page = 1} = getQuery(history)

  const {loading: latestEpisodesLoading, data: latestEpisodesData, error: epError} = useQuery(GET_EPISODES,{
    variables: {
      limit: 12,
      where: undefined
    }
  })

  const {loading: musicArticlesLoading, data: musicArticlesData} = useQuery(GET_EPISODES, {
    variables: {
      limit: 12,
      where: {
        episode_tags: {
          tag: {
            name: {
              _eq: 'music'
            }
          }
        }
      }      
    }
  })

  const {loading: theaterArticlesLoading, data: theaterArticlesData, error: theError} = useQuery(GET_EPISODES, {
    variables: {
      limit: 12,
      where: {
        episode_tags: {
          tag: {
            name: {
              _eq: 'theater'
            }
          }
        }
      }
    }
  })

  const {loading: danceArticlesLoading, data: danceArticlesData} = useQuery(GET_EPISODES, {
    variables: {
      limit: 12,
      where: {
        episode_tags: {
          tag: {
            name: {
              _eq: 'dance'
            }
          }
        }
      }
    }
  })

  const {loading: allEpisodesLoading, data: allEpisodesData, error: allError} = useQuery(GET_EPISODES, {
    variables: {
      limit: 16,
      where: {
        episode_tags: {
          tag: {
            name: {
              _eq: c && c !== 'latest' ? c : undefined
            }
          }
        }      
      }      
    }
  })

  const latestEpisodes = latestEpisodesData?.episodes;
  const musicArticles = musicArticlesData?.episodes;
  const theaterArticles = theaterArticlesData?.episodes;
  const danceArticles = danceArticlesData?.episodes;
  const allEpisodes = allEpisodesData?.episodes;

  const loading = useMemo(() => {
    if (latestEpisodesLoading || musicArticlesLoading || theaterArticlesLoading || danceArticlesLoading || allEpisodesLoading) {
      return true;
    } else if (!latestEpisodes || !musicArticles || !theaterArticles || !danceArticles || !allEpisodes) {
      return true;
    }

    return false;
  }, [latestEpisodesLoading, musicArticlesLoading, theaterArticlesLoading, danceArticlesLoading, allEpisodesLoading, latestEpisodes, musicArticles, theaterArticles, danceArticles, allEpisodes]);

  const latestEpisode = latestEpisodes?.[0]
  const {name, url, imagePath} = latestEpisode || {}
  const imageUrl = imagePath ? genImgixUrl(imagePath) : `https://img.youtube.com/vi/${url}/0.jpg`

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return (
        <SearchModal />
      )            
    }
  }, [showSearchModal])

  const bannerContent = (
    <Banner image={imageUrl}>
      <ArticleContent>
        <LatestArticle>
          <h2>{name}</h2>
          <Button href={`https://www.youtube.com/watch?v=${url}`} target='_blank'>
            Watch
          </Button>
        </LatestArticle>
      </ArticleContent>
    </Banner>
  )

  const handleFilter = category => {
    updateQuery({
      history,
      replace: true,
      newParams: {c: category}
    })
  }

  const renderEpisode = (item, idx) => {
    const {name, url, imagePath} = item || {}
    const imageUrl = imagePath ? genImgixUrl(imagePath) : `https://img.youtube.com/vi/${url}/0.jpg`

    return (
      <EpisodeItem key={idx} image={imageUrl} href={`https://www.youtube.com/watch?v=${url}`} target='_blank'>
        <div>
          {name}
        </div>
      </EpisodeItem>
    )
  }

  const renderSection = (item, idx) => {
    const {title, episodes} = item || {}
    
    return (
      <SectionWrapper key={idx}>
        <h2>
          <span>{title}</span>
          <div />
        </h2>
        <EpisodesWrapper>
          <div>
            {episodes?.map(renderEpisode)}
          </div>
        </EpisodesWrapper>
      </SectionWrapper>
    )
  }

  const renderContent = () => {
    if (!c) {
      const usedEpisodes = {}

      const episodesSection = [
        {title: 'Latest', key: 'latest', episodes: latestEpisodes},
        {title: 'Music', key: 'music', episodes: musicArticles},
        {title: 'Theater', key: 'theater', episodes: theaterArticles},
        {title: 'Dance', key: 'dance', episodes: danceArticles}
      ]

      episodesSection.forEach((es, idx) => {
        const esEpisodes = []

        es.episodes.forEach(a => {
          if (esEpisodes.length < 4 && (es.key === 'theater' || !usedEpisodes[a.id])) {
            usedEpisodes[a.id] = true
            esEpisodes.push(a)
          }
        })

        episodesSection[idx].episodes = esEpisodes
      })

      return episodesSection.map(renderSection)
    }

    return (
      <>
        <AllCards>
          {allEpisodes?.map((renderEpisode))}
        </AllCards>
      </>
    )
  }

  if (loading) {
    return (
      <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <p>Loading</p>
        <div style={{marginLeft: '10px'}}>
          <Loader/>
        </div>

      </div>
    )
  }

  return (
    <ArticlesWrapper>
      {bannerContent}
      <AritcleHeader>
        <div />
        <Select
          placeholder='Filter by category'
          options={categories}
          value={c}
          onChange={e => handleFilter(e.value)}
        />
      </AritcleHeader>
      {renderContent()}
      {searchModal}
    </ArticlesWrapper>
  )
}

export default Articles
