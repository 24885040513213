import styled, { css } from 'styled-components'
import { colors } from 'constant'
import { Link } from 'react-router-dom'

const btnStyle = css`
  border: none;
  background: ${colors.buttonBackground};
  color: white;
  font-weight: 600;
  border-radius: 100px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  opacity: 1;
  transition: opacity 300ms ease;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  :disabled {
    opacity: 0.3;
    cursor: default;
  }

  svg {
    height: 24px;
    margin-right: 12px;
  }
`

const disabledCss = css`
  opacity: 0.3;
  cursor: default;
  background: ${colors.c2};
`

const plain = css`
  background: ${colors.c18};
`

export const Wrapper = styled.button`
  ${btnStyle}
  ${({ disabled }) => disabled && disabledCss}
  ${({ $plain }) => $plain && plain}
`

export const LinkBtn = styled(Link)`
  ${btnStyle}
  ${({ disabled }) => disabled && disabledCss}
  ${({ $plain }) => $plain && plain}
`

export const AnchorBtn = styled.a`
  ${btnStyle}
  ${({ disabled }) => disabled && disabledCss}
  ${({ $plain }) => $plain && plain}
`
