import React, { useState } from 'react'
import {
  InputWrapper,
  CoreInput,
  PasswordToggle,
  ErrorMessage,
  MainWrapper
} from './styled'
import { Label, Icon } from 'components'

const TextInput = (props) => {
  const {
    onChange,
    returnValueOnChange = true,
    value = '',
    label,
    placeholder,
    error,
    tooltip,
    onClickOverride,
    icon,
    type,
    ...inputProps
  } = props
  const isPassword = type === 'password'
  const [showPassword, setShowPassword] = useState(false)

  return (
    <MainWrapper>
      <InputWrapper onClick={onClickOverride} $withError={error ? true : false}>
        <CoreInput
          value={value}
          onChange={(e) => {
            if (returnValueOnChange) {
              onChange(e.target.value)
            } else {
              onChange(e)
            }
          }}
          placeholder={placeholder}
          type={!isPassword ? type : showPassword ? 'text' : 'password'}
          {...inputProps}
        />
        {isPassword && (
          <PasswordToggle onClick={() => setShowPassword((prev) => !prev)}>
            <Icon name={showPassword ? 'invisible' : 'visible'} />
          </PasswordToggle>
        )}
      </InputWrapper>
      <ErrorMessage>{error}</ErrorMessage>
    </MainWrapper>
  )
}

export default TextInput
