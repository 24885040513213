import React, { useState, useEffect } from 'react'
import { RegisterWrapper, RegisterForm, Header, Links, Terms } from './styled'
import { Input, Button } from 'components'
import { useHistory } from 'react-router-dom'
import { registerUser } from 'apis'
import { useStoreon } from 'storeon/react'
import { terms, privacy } from 'constant'

const Register = ({ handleGoToLogin }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { user, loading, redirect, isMobile, dispatch } = useStoreon('user', 'loading', 'redirect', 'isMobile')
  const history = useHistory()

  // useEffect(() => {
  //   if (user && !loading) {
  //     let path = '/'

  //     if (redirect) {
  //       const { pathname, search } = redirect
  //       path = `${pathname}${search}`
  //     }

  //     dispatch('setRedirect', null)

  //     history.push(path)
  //   }
  // }, [user, loading, history, redirect, dispatch])

  const onSubmit = async e => {
    e.preventDefault()
    registerUser({ email, password })
    history.push('/preferences')
  }

  return (
    <RegisterWrapper isMobile={isMobile}>
      <Header>Sign up</Header>
      <p>Experiences await. Sign up today!</p>
      <RegisterForm onSubmit={onSubmit}>
        <Input
          label='Email'
          value={email}
          onChange={value => setEmail(value)}
        />
        <Input
          label='Password'
          type='password'
          value={password}
          onChange={value => setPassword(value)}
        />
        <Terms>
          <div>
            <span>by signing up, you agree to our </span>
            <a onClick={() => dispatch('setTextModal', terms)}>Terms of Service&nbsp;</a>
            <span>and&nbsp;</span>
            <a onClick={() => dispatch('setTextModal', privacy)}>Privacy Policy</a>
          </div>
        </Terms>
        <Button
          disabled={!email || !password}
          type='submit'
        >
          Sign up
        </Button>
      </RegisterForm>
      <Links>
        <a onClick={handleGoToLogin}>Already have an account? <span>Login</span></a>
      </Links>
    </RegisterWrapper>
  )
}

export default Register
