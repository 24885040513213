import React, { useState, useEffect } from 'react'

import {
  CTAWrapper,
  FormWrapper,
  InputWrapper,
  MainWrapper,
  TitleWrapper,
  Links,
  ForgotPassword
} from './styled'
import { Button, TextInput, Modal } from 'components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useStoreon } from 'storeon/react'
import toast from 'react-hot-toast'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'
import { requestPasswordReset } from 'apis'

const Login = () => {
  const [hasErrors, setHasErrors] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [resetLoading, setResetLoading] = useState(false)
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const { userData, isMobile, returnURL, dispatch } = useStoreon(
    'userData',
    'isMobile',
    'returnURL'
  )
  const history = useHistory();

  useEffect(() => {
    if (userData) {
      history.push('/')
    }
  }, [userData])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email should be a valid email')
        .required('Email is required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
    }),
    onSubmit: async (values) => {
      // Handle form submission here
      setLoginLoading(true)

      try {
        await firebase.auth().signInWithEmailAndPassword(values.email, values.password)

        history.push(returnURL ?? '/')
      } catch (error) {
        const { code } = error
        console.log('error: ', error)
        toast.error(`Failed to login with error: ${code}`)
      }
    }
  })

  const onResetSubmit = async e => {
    e.preventDefault()
    setResetLoading(true)
    await requestPasswordReset(email)
    setShowPasswordReset(false)
    dispatch('setNotification', { message: 'A password reset link has been sent if this email exists in our system', timer: 8000 })
    setResetLoading(false)
  }

  useEffect(() => {
    // Check if there are errors in formik
    const hasFormErrors = Object.keys(formik.errors).length > 0
    const touch = Object.keys(formik.touched).length > 0

    // Update the state variable

    if (touch || (formik.values.email !== '' && formik.values.password !== '')) {
      setHasErrors(hasFormErrors)
    }
  }, [formik.errors, formik.touched, formik.values.email, formik.values.password])

  return (
    <MainWrapper>
      <FormWrapper>
        <TitleWrapper>
          <h1>Login</h1>
          <span>Welcome back!</span>
        </TitleWrapper>

        {/* <SocialButtonsWrapper>
          <span>Continue with...</span>
          <SocialButtons>
            <Button plain onClick={() => {}}></Button>
            <Button plain onClick={() => {}}></Button>
            <Button plain onClick={() => {}}></Button>
          </SocialButtons>
        </SocialButtonsWrapper> */}

        {/* <DividerWrapper>
          <Divider></Divider>
          <DividerText>or</DividerText>
          <Divider></Divider>
        </DividerWrapper> */}

        <InputWrapper>
          <TextInput
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ''
            }
            placeholder="Email"
            value={formik.values.email}
            returnValueOnChange={false}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            name="email"
          />

          <TextInput
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ''
            }
            placeholder="Password"
            value={formik.values.password}
            returnValueOnChange={false}
            onChange={(e) => formik.handleChange(e)}
            name="password"
            type="password"
            onBlur={formik.handleBlur}
          />
        </InputWrapper>

        <CTAWrapper>
          <Button disabled={hasErrors} type='submit' onClick={() => formik.handleSubmit()}>
            { !loginLoading ?
              `Login`
              : `Logging in...`
            }
          </Button>
        </CTAWrapper>
        <Links>
          <a onClick={() => setShowPasswordReset(true)}><span>Forgot your password?</span></a>
        </Links>
        <Modal
          open={showPasswordReset}
          onClose={() => setShowPasswordReset(false)}
        >
          <ForgotPassword isMobile={isMobile} onSubmit={onResetSubmit}>
            <h1>Forgot your password?</h1>
            <p>Enter your email below and we'll send you a password reset link</p>
            <TextInput
              placeholder='Email'
              value={email}
              onChange={value => setEmail(value)}
            />
            <Button
              loading={resetLoading}
              disabled={!email}
              type='submit'
            >Reset Password</Button>
            <a onClick={() => setShowPasswordReset(false)}>Cancel</a>
          </ForgotPassword>
        </Modal>
      </FormWrapper>
    </MainWrapper>
  )
}

export default Login
