const development = {
  rcaApi: 'https://x9mcjh9zjb.execute-api.us-west-2.amazonaws.com/dev',
  gqlEndpoint: 'https://rca-production.hasura.app/v1/graphql'
}

const test = {
  rcaApi: 'https://x9mcjh9zjb.execute-api.us-west-2.amazonaws.com/dev',
  gqlEndpoint: 'https://rca-production.hasura.app/v1/graphql'
}

const production = {
  rcaApi: 'https://6tf8be28ta.execute-api.us-west-2.amazonaws.com/prod',
  gqlEndpoint: 'https://rca-production.hasura.app/v1/graphql'
}

const configs = {
  development,
  test,
  production
}

const environment = process.env.DEPLOYMENT_ENV || 'development'

export default configs[environment]
