import styled from 'styled-components'
import { colors, breakpoints } from 'constant'

const { md } = breakpoints

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CarouselHeaderRight = styled.div`
  display: flex;

  > div {
    display: flex;

    > button {
      background: none;
      border: none;
      color: ${colors.c3};
      cursor: pointer;

      > svg {
        height: 24px;
        width: 24px;
      }
    }
  }
`

export const CarouselHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  > h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }

  ${md} {
    margin-bottom: 18px;

    > h3 {
      font-size: 18px;
    }
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .carousel-tooltip {
    background: ${colors.c18};
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 400;
    letter-spacing: 0px;
    text-wrap: wrap;
    width: 325px;
    border-radius: 24px;
    border: 1px solid black;
  }
`;

export const IconWrapper = styled.div`
  width: 15px;
  margin-left: 5px;
  margin-bottom: 10px;
`