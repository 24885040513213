import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors, breakpoints } from 'constant'

const { md } = breakpoints

export const AboutWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  color: ${colors.c19};
  font-size: 12px;
  flex: 1;
  line-height: 16px;
  max-height: 100px;
  overflow: hidden;
  position: relative;
  align-items: flex-start !important;

  &:after {
    content: '';
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(17, 17, 18)
    );
    position: absolute;
    height: 20px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const ArticleCardWrapper = styled(Link)`
  min-height: 250px;
  background: ${colors.c1};
  border-radius: 16px;
  padding: 16px;
  display: flex;
  text-decoration: none;
  color: ${colors.mainText};

  ${md} {
    flex-direction: column;
    height: auto;
  }

  ${({ small }) =>
    small &&
    `
    min-height: 0px;
    align-items: center;
  `}
`

export const ArticleImage = styled.div`
  background-image: url('${({ imageUrl }) => imageUrl}');
  border-radius: 16px;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 251px;
  flex-shrink: 0;
  margin-right: 16px;

  ${md} {
    width: 100%;
    height: 210px;
    margin-bottom: 16px;
    margin-right: 0px;
  }

  ${({ small }) =>
    small &&
    `
    width: 130px;
    height: 130px;
  `}
`

export const ArticleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;

  > div {
    display: flex;
    flex-direction: column;

    > h3 {
      font-size: 20px;
      margin-bottom: 8px;
    }

    > div {
      flex: 1;
      font-size: 14px;
      color: ${colors.c19};
      display: flex;
      align-items: center;
    }

    &:first-child {
      flex: 1;
    }
  }

  ${md} {
    > div {
      > h3 {
        font-size: 18px;
      }
    }
  }

  ${({ small }) =>
    small &&
    `
    > div {
      > h3 {
        font-size: 16px;
        margin-bottom: 4px;
      }
    }
  `}
`

export const ArticleDate = styled.div`
  display: flex;
  font-size: 16px;

  > p {
    &:first-child {
      font-size: 12px;
    }
  }

  ${md} {
    margin-top: 16px;
  }
`
