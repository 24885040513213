import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  ContentWrapper,
  PageWrapper,
  ErrorInputRow,
  DateWrapper,
  ErrorText,
  Headers,
  TopBackButton,
  FormWrapper,
  EventTypeWrapper,
  RadioGroup,
  FormSection,
  InputRow,
  InputGroup,
  TagsMultiselectContainer,
  UploaderContainer,
  SubmitWrapper,
  ImagesToRemove,
  ImageDisplay,
  IconWrapper,
  CoverDesignator
} from './styled'
import {
  Button,
  DatePicker,
  Icon,
  RadioButton,
  TextInput,
  MultiSelect,
  Select,
  TextArea,
  Loader
} from 'components'
import {updateEvent} from 'apis'
import Sidebar from '../../Sidebar'
import { preTags } from 'constant'
import Uploader from '../Uploader'
import GalleryUploader from '../GalleryUploader'
import { gql, useQuery } from '@apollo/client'
import {useParams, useHistory} from 'react-router-dom'
import {format} from 'date-fns'
import moment from 'moment'
import { useStoreon } from 'storeon/react'
import { venueAuthUser } from 'utils'

const GET_VENUE_FOR_EVENT_FORM = gql`
  query getVenueForEventForm($where: venues_bool_exp = {}) {
    venues(where: $where, limit: 1) {
      id
      major_city_id
      owner_id
    }
  }
`

const GET_EVENT_TO_EDIT = gql`
  query getEventToEdit($where: events_bool_exp = {}) {
    events(where: $where, limit: 1) {
      name
      id
      about
      scene
      start_date
      end_date
      ticket_link
      min_price
      max_price
      netflix_shows
      cover_image_id
      artists
      target_audience
      event_tags {
        tag_id
      }
      event_images {
        name
        id
      }
    }
  }
`

const sceneTypes = [
  {label: 'Large Theater', value: 0},
  {label: 'Small Theater', value: 1},
  {label: 'Salon-Type Style Performance', value: 2},
  {label: 'At Someones home', value: 3},
  {label: 'Outdoors', value: 4},
  {label: 'Immersive Experience', value: 5},
  {label: 'Bar-Type Setting (not a sit down event)', value: 6},
  {label: 'Dinner Theater', value: 7}
]

const audienceTypes = [
  {label: 'Family Friendly for all ages', value: 0},
  {label: 'No kids under 5 years old', value: 1},
  {label: '21 years old and older only', value: 2}
]

const tagOptions = preTags.flatMap(({ tags }) =>
  tags.map(({ label, value }) => ({ label, value }))
)

const EditEvent = () => {
  const {slug, slug2} = useParams()
  const history = useHistory()
  const { user, userData, loading: userLoading } = useStoreon('user', 'userData', 'userLoading')
  const [selectedOption, setSelectedOption] = useState('option1')
  const [defaultValuesLoading, setDefaultValuesLoading] = useState(true)
  const [defaultGenre, setDefaultGenre] = useState()
  const [dateError, setDateError] = useState(false)
  const [genreError, setGenreError] = useState(false)
  const [sceneError, setSceneError] = useState(false)
  const [audienceError, setAudienceError] = useState(false)
  const [targetAudience, setTargetAudience] = useState()
  const [uploadedImage, setUploadedImage] = useState([])
  const [galleryImages, setGalleryImages] = useState([])
  const [removeImages, setRemoveImages] = useState([])
  const [eventScene, setEventScene] = useState()
  const [selectedEventType, setSelectedEventType] = useState(1)
  const {loading: venueLoading, data: venueData} = useQuery(GET_VENUE_FOR_EVENT_FORM, {
    variables: {
      where: {
        slug: {
          _eq: slug
        }
      }
    }
  })
  const {loading: eventLoading, data: eventData} = useQuery(GET_EVENT_TO_EDIT, {
    variables: {
      where: {
        slug: {
          _eq: slug2
        }
      }
    },
    fetchPolicy: 'network-only'
  })

  const venue = venueData?.venues[0];
  const event = eventData?.events[0];



  const formik = useFormik({
    initialValues: {
      eventName: ``,
      ticketWebsite: '',
      priceMin: '',
      priceMax: '',
      netflix1: '',
      netflix2: '',
      netflix3: '',
      about: '',
      startDate: null,
      endDate: null,
      scene: '',
      tags: [],
      eventDates: null,
      artists: '',
      audience: ''
    },
    validationSchema: Yup.object({
      eventName: Yup.string().required('Event name is required'),
      artists: Yup.string().required('Artists is required'),
      tags: Yup.array().required('Array is required').min(1),
      audience: Yup.string().required('Target audience is required'),
      ticketWebsite: Yup.string().required('Ticket website is required'),
      priceMin: Yup.number().required('Minimum price is required'),
      priceMax: Yup.number().required('Max price is required'),
      // netflix1: Yup.string().test('netflix1-validation', 'At least 2 netflix shows are required', function(value) {
      //   const { netflix1, netflix2, netflix3 } = this.parent; 
      //   if ((!netflix1 && !netflix2) || (!netflix1 && !netflix3) || (!netflix2 && !netflix3)) {
      //     return false
      //   }
      //   return true
      // }),
      // netflix2: Yup.string().test('netflix2-validation', 'At least 2 netflix shows are required', function(value) {
      //   const { netflix1, netflix2, netflix3 } = this.parent; 
      //   if ((!netflix1 && !netflix2) || (!netflix1 && !netflix3) || (!netflix2 && !netflix3)) {
      //     return false
      //   }
      //   return true
      // }),
      // netflix3: Yup.string().test('netflix3-validation', 'At least 2 netflix shows are required', function(value) {
      //   const { netflix1, netflix2, netflix3 } = this.parent; 
      //   if ((!netflix1 && !netflix2) || (!netflix1 && !netflix3) || (!netflix2 && !netflix3)) {
      //     return false
      //   }
      //   return true
      // }),
      about: Yup.string().required('This part is required'),
      startDate: Yup.string().required('Event start date is required'),
      endDate: Yup.string().required('Event end date is required'),
      scene: Yup.string().required('Scene is required')
    }),
    onSubmit: async (values) => {
      // Handle form submission
      const token = await user.getIdToken()
      const netflixShows = [values.netflix1, values.netflix2, values.netflix3].filter(Boolean).join(', ');
      
      await updateEvent({
        id: event.id,
        name: values.eventName,
        about: values.about,
        artists: values.artists,
        audience: values.audience,
        eventType: selectedEventType,
        ticket_link: values.ticketWebsite,
        min_price: values.priceMin,
        max_price: values.priceMax,
        netflix_shows: netflixShows,
        somethingHere: values.eventDescription,
        venue_id: venue.id,
        major_city: venue.major_city_id,
        start_date: format(new Date(values.startDate * 1000), 'P'),
        end_date: format(new Date(values.endDate * 1000), 'P'),
        scene: values.scene,
        tags: values.tags,
        token: token,
        bgImage: uploadedImage && uploadedImage.length > 0 ? uploadedImage[0].file : undefined,
        gallery: galleryImages.map(item => item.file),
        removeImages: removeImages
      })

      history.push(`/venue/${slug}/dashboard/events`)
    }
  })

  useEffect(() => {
    if (event) {
      const selectedSceneType = sceneTypes.find(type => type.label === event.scene);
      const selectedAudienceType = audienceTypes.find(type => type.label === event.target_audience)

      setEventScene(selectedSceneType.value)
      setTargetAudience(selectedAudienceType.value)
 
      formik.setFieldValue('eventName', event?.name)
      formik.setFieldValue('artists', event?.artists)
      formik.setFieldValue('ticketWebsite', event?.ticket_link)
      formik.setFieldValue('priceMin', event?.min_price)
      formik.setFieldValue('priceMax', event?.max_price)
      formik.setFieldValue('about', event?.about)
      formik.setFieldValue('startDate', moment(new Date(event.start_date.replace(/-/g, ', '))).unix())
      formik.setFieldValue('endDate', moment(new Date(event.end_date.replace(/-/g, ', '))).unix())
      formik.setFieldValue('scene', event?.scene)
      formik.setFieldValue('audience', event?.target_audience)
      
      if (event.netflix_shows) {
        const netflixShowsArray = event?.netflix_shows.split(',').map(item => item.trim());

        formik.setFieldValue('netflix1', netflixShowsArray[0])
        formik.setFieldValue('netflix2', netflixShowsArray[1])
        formik.setFieldValue('netflix3', netflixShowsArray[2])        
      }

      if (event.event_tags.length > 0) {
        const selectedTags = tagOptions.filter(tag => event.event_tags.some(eventTag => eventTag.tag_id.toString() === tag.value));

        setDefaultGenre(selectedTags)
        formik.setFieldValue('tags', selectedTags);
      }

      setDefaultValuesLoading(false)
    }

  }, [event])

  const loading = useMemo(() => {
    if (venueLoading || eventLoading || defaultValuesLoading) {
      return true;
    } else if (!venueData || !eventData) {
      return true;
    }

    if (!loading && !userLoading) {
      const authCheck = venueAuthUser(userData, venue?.owner_id);
  
      if (!authCheck) {
        history.push('/')
      }
    }

    return false;
  }, [venueLoading, eventLoading, venueData, eventData, defaultValuesLoading, userLoading]);

  useEffect(() => {
    if (formik.touched.tags && formik.errors.tags) {
      setGenreError(true)
    } else {
      setGenreError(false)
    }
  }, [formik.touched.tags, formik.errors.tags])

  useEffect(() => {
    if (formik.touched.scene && formik.errors.scene) {
      setSceneError(true)
    } else {
      setSceneError(false)
    }
  }, [formik.touched.scene, formik.errors.scene])

  useEffect(() => {
    if (formik.touched.audience && formik.errors.audience) {
      setAudienceError(true)
    } else {
      setAudienceError(false)
    }
  }, [formik.touched.audience, formik.errors.audience])

  useEffect(() => {
    if (formik.touched.eventDates && (formik.errors.startDate || formik.errors.endDate )) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  }, [formik.touched.eventDates, formik.errors.startDate, formik.errors.endDate, formik.values.startDate, formik.values.endDate])

  const handleStartDate = (value) => {
    // setStartDate(value)
    formik.setFieldValue('startDate', value)
  }

  const handleEndDate = (value) => {
    // setEndDate(value)
    formik.setFieldValue('endDate', value)
  }

  const handleImageUpdate = (images) => {
    setUploadedImage(images);
  }

  const handleGalleryUpdate = (image) => {
    setGalleryImages(image)
  }

  const handleChangeScene = (newScene) => {
    setEventScene(newScene.value)
    formik.setFieldValue('scene', newScene.label)
  }

  const handleAudience = (newScene) => {
    setTargetAudience(newScene.value)
    formik.setFieldValue('audience', newScene.label)
  }

  const handleOptionChange = (event) => {
    setSelectedEventType(event.target.value)
  }

  const handleRemoveImages = (name) => {
    if (removeImages.includes(name)) {
      setRemoveImages(removeImages.filter(image => image !== name));
    } else {
      setRemoveImages([...removeImages, name]);
    }
  }

  return (
    <PageWrapper>
      <Sidebar active={0} />
      <ContentWrapper>
        <Headers>
          <TopBackButton to={`/venue/${slug}/dashboard/events`}>
            <Icon name="backIcon" />
          </TopBackButton>
          <h2>Edit Event</h2>
        </Headers>

        <FormWrapper>
          {loading ? (
            <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <p>Loading</p>
              <div style={{marginLeft: '10px'}}>
                <Loader/>
              </div>
            </div>
          ) : ( 
            <>
              <EventTypeWrapper>
                <h2>Is this a live performance, digital performance, or both?</h2>
                <RadioGroup>
                  <RadioButton
                    label="Live performance"
                    value={1}
                    checked={selectedEventType == 1}
                    onChange={handleOptionChange}
                  />
                  <RadioButton
                    label="Digital performance"
                    value={2}
                    checked={selectedEventType == 2}
                    onChange={handleOptionChange}
                  />
                  <RadioButton
                    label="Both"
                    value={3}
                    checked={selectedEventType == 3}
                    onChange={handleOptionChange}
                  />
                </RadioGroup>
              </EventTypeWrapper>

              <FormSection>
                <h2>
                  What is your event called?
                </h2>
                <InputRow $col={1}>
                  <TextInput
                    error={
                      formik.touched.eventName && formik.errors.eventName
                        ? formik.errors.eventName
                        : ''
                    }
                    placeholder="Event Name"
                    value={formik.values.eventName}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="eventName"
                  />
                  {/* <TextInput
                    error={
                      formik.touched.eventDescription &&
                      formik.errors.eventDescription
                        ? formik.errors.eventDescription
                        : ''
                    }
                    placeholder="Description"
                    value={formik.values.eventDescription}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="eventDescription"
                    maxlength={60}
                  /> */}
                </InputRow>
              </FormSection>

              <FormSection>
                <h2>
                  Who are the artists for this performance?
                </h2>

                <InputRow $col={1}>
                  <TextInput
                    error={
                      formik.touched.artists && formik.errors.artists
                        ? formik.errors.artists
                        : ''
                    }
                    placeholder="Artists"
                    value={formik.values.artists}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="artists"
                  />
                </InputRow>
              </FormSection>

              <FormSection>
                <h2>
                  Which genres describe your event the best? Select as many as applicable
                </h2>

                <ErrorInputRow $col={1}>
                  <TagsMultiselectContainer $error={genreError}>
                    <MultiSelect
                      options={tagOptions}
                      defaultValue={defaultGenre}
                      onChange={(e) => formik.setFieldValue('tags', e)}
                      placeholder="Select tags"
                      name='tags'
                    />
                  </TagsMultiselectContainer>
                  {genreError && (
                    <ErrorText>At least one tag is required</ErrorText>
                  )}
                </ErrorInputRow>
              </FormSection>

              <FormSection>
                <h2>
                  Which kind of scene describes your event best?
                </h2>

                <ErrorInputRow $col={1}>
                  <TagsMultiselectContainer $error={sceneError}>
                    <Select
                      placeholder="Select scene"
                      options={sceneTypes}
                      value={eventScene}
                      onChange={(e) => handleChangeScene(e)}
                      name='scene'
                    />
                  </TagsMultiselectContainer>
                  {sceneError && (
                    <ErrorText>At least one tag is required</ErrorText>
                  )}
                </ErrorInputRow>
              </FormSection>

              <FormSection>
                <h2>
                  Who is your target audience?
                </h2>

                <ErrorInputRow $col={1}>
                  <TagsMultiselectContainer $error={audienceError}>
                    <Select
                      placeholder="Select target audience"
                      options={audienceTypes}
                      value={targetAudience}
                      onChange={(e) => handleAudience(e)}
                      name='audience'
                    />
                  </TagsMultiselectContainer>
                  {audienceError && (
                    <ErrorText>Target audience is required</ErrorText>
                  )}
                </ErrorInputRow>
              </FormSection>


              <FormSection>
                <h2>
                  When does your event start showing and when does it end?
                </h2>

                <ErrorInputRow>
                  {/* <TextInput
                    error={
                      formik.touched.startDate && formik.errors.startDate
                        ? formik.errors.startDate
                        : ''
                    }
                    placeholder="Start Date"
                    value={formik.values.startDate}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="startDate"
                  />
                  <TextInput
                    error={
                      formik.touched.eventDescription &&
                      formik.errors.eventDescription
                        ? formik.errors.eventDescription
                        : ''
                    }
                    placeholder="End Date"
                    value={formik.values.endDate}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="endDate"
                    maxlength={60}
                  /> */}
                  <DateWrapper $error={dateError}>
                    <DatePicker
                      startDate={formik.values.startDate}
                      endDate={formik.values.endDate}
                      onStartChange={(value) => handleStartDate(value)}
                      onEndChange={(value) => handleEndDate(value)}
                      name='eventDates'
                      dateText={'Date'}
                    />
                    <Icon name="dropdown" />
                  </DateWrapper>
                  {dateError && (
                    <ErrorText>Start and End dates are required</ErrorText>
                  )}
                </ErrorInputRow>
              </FormSection>

              <FormSection>
                <h2>
                  Where can people buy tickets and what's the price range for the
                  tickets?
                </h2>

                <InputRow $col={2}>
                  <TextInput
                    error={
                      formik.touched.ticketWebsite && formik.errors.ticketWebsite
                        ? formik.errors.ticketWebsite
                        : ''
                    }
                    placeholder="Link for tickets"
                    value={formik.values.ticketWebsite}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="ticketWebsite"
                  />

                  <InputGroup $col={2}>
                    <TextInput
                      error={
                        formik.touched.priceMin && formik.errors.priceMin
                          ? formik.errors.priceMin
                          : ''
                      }
                      placeholder="Min. Price"
                      value={formik.values.priceMin}
                      returnValueOnChange={false}
                      onChange={(e) => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                      name="priceMin"
                    />

                    <TextInput
                      error={
                        formik.touched.priceMax && formik.errors.priceMax
                          ? formik.errors.priceMax
                          : ''
                      }
                      placeholder="Max. Price"
                      value={formik.values.priceMax}
                      returnValueOnChange={false}
                      onChange={(e) => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                      name="priceMax"
                    />
                  </InputGroup>
                </InputRow>
              </FormSection>

              <FormSection>
                <h2>
                  Please 2-3 current Netflix shows or type of brands that correspond
                  with your event
                </h2>

                <InputRow $col={3}>
                  <TextInput
                    // error={
                    //   formik.touched.netflix1 && formik.errors.netflix1
                    //     ? formik.errors.netflix1
                    //     : ''
                    // }
                    placeholder=""
                    value={formik.values.netflix1}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="netflix1"
                  />

                  <TextInput
                    // error={
                    //   formik.touched.netflix2 && formik.errors.netflix2
                    //     ? formik.errors.netflix2
                    //     : ''
                    // }
                    placeholder=""
                    value={formik.values.netflix2}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="netflix2"
                  />

                  <TextInput
                    // error={
                    //   formik.touched.netflix3 && formik.errors.netflix3
                    //     ? formik.errors.netflix3
                    //     : ''
                    // }
                    placeholder=""
                    value={formik.values.netflix3}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="netflix3"
                  />
                </InputRow>
              </FormSection>

              <FormSection>
                <h2>
                  If you were to tell your friends about this event, how would you
                  describe it? (Layman's terms, please! And please do not just copy
                  and paste what is on your website. Break it down for us and give
                  us the "gist" and details that we should know)
                </h2>

                <InputRow $col={1}>
                  <TextArea
                    rows={3}
                    placeholder=""
                    error={
                      formik.touched.about && formik.errors.about
                        ? formik.errors.about
                        : ''
                    }
                    value={formik.values.about}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    name="about"
                  />
                </InputRow>
              </FormSection>

              <FormSection>
                <h2>Upload cover photo</h2>

                <UploaderContainer>
                  <Uploader handleUpdate={handleImageUpdate} uploadedImages={uploadedImage}/>
                </UploaderContainer>
              </FormSection>

              <FormSection>
                <h2>Add images to showcase the event</h2>

                <UploaderContainer>
                  <GalleryUploader handleUpdate={handleGalleryUpdate} uploadedImages={galleryImages}/>
                </UploaderContainer>
              </FormSection>

              {event?.event_images && (
                <FormSection>
                  <h2>Remove Images</h2>

                  <ImagesToRemove>
                      {event?.event_images.map(image => (
                        <ImageDisplay imageUrl={`https://rca-s3.imgix.net/1/${event.id}/${image.name}?height=250&auto=compression`} onClick={() => handleRemoveImages(image.name)}>
                         {removeImages.includes(image.name) && (
                            <IconWrapper>
                              <Icon name='closeCircle'/>
                            </IconWrapper>
                          )}
                          {(image.id === event.cover_image_id) && (
                            <CoverDesignator>
                              Cover
                            </CoverDesignator>
                          )}
                        </ImageDisplay>
                      ))}
                  </ImagesToRemove>
                </FormSection>      
              )}

              <FormSection>
                <SubmitWrapper>
                  <Button type={'submit'} onClick={formik.handleSubmit}>
                    Submit
                  </Button>              
                </SubmitWrapper>
              </FormSection>  
            </>          
          )}
        </FormWrapper>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default EditEvent
