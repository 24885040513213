import React from 'react'
import {
  ArticleCardWrapper,
  ArticleImage,
  ArticleContent,
  ArticleDate,
  AboutWrapper
} from './styled'
import { formatDate } from 'utils'

const ArticleCard = (props) => {
  const { article, small } = props

  if (!article) {
    return null
  }

  const {
    featured_image: featuredImage,
    summary,
    published,
    slug,
    title
  } = article

  return (
    <ArticleCardWrapper to={`/articles/${slug}`} small={small}>
      <ArticleImage imageUrl={featuredImage} small={small} />
      <ArticleContent small={small}>
        <div>
          <h3>{title}</h3>
          <AboutWrapper>
            <p>{summary}</p>
          </AboutWrapper>
        </div>
        {!small && (
          <ArticleDate>
            <p>Published:</p>
            <p>{formatDate(published)}</p>
          </ArticleDate>
        )}
      </ArticleContent>
    </ArticleCardWrapper>
  )
}

export default ArticleCard
