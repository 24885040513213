import styled, { css } from 'styled-components'
import { colors, breakpoints } from 'constant'
import { Popover } from '@headlessui/react'

const { md } = breakpoints

const headerBg = css`
  background: ${colors.c14};
  backdrop-filter: blur(16px);
`

export const LayoutHeaderWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  transform: translateY(0px);
  transition: transform 300ms ease, background 300ms ease;

  ${({ hide }) => hide && 'transform: translateY(-220px);'}
  ${({ hideBg }) => !hideBg && headerBg}
`

export const HeaderContent = styled.div`
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;

  ${md} {
    padding: 16px;
  }
`

export const RcaLogo = styled.div`
  height: 48px;
  width: 64px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');

  ${md} {
    height: 40px;
    width: 48px;
  }
`

export const ContentRight = styled.div`
  display: flex;
  align-items: center;

  > a {
    color: ${colors.c3};
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    margin-right: 32px;
  }

  button {
    background: none;
    border: none;
  }
`

export const SearchButton = styled.button`
  display: block;
  margin-right: 10px;
  color: white;
  cursor: pointer;
  
  > svg {
    width: 24px;
    height: 24px;
  }
`

export const PopoverContainer = styled(Popover)`
  position: relative;
`

export const PopoverButton = styled(Popover.Button)`
  background: none;
  border: none;
  cursor: pointer;

  > svg {
    height: 24px;
    width: 24px;
    color: ${colors.c3};
  }
`

export const PopoverContent = styled(Popover.Panel)`
  position: absolute;
  top: calc(100% + 8px);
  background: ${colors.c18};
  border-radius: 16px;
  right: 0px;
  width: 365px;
  max-width: calc(100vw - 36px);
`

export const PopoverTop = styled.div`
  padding: 20px 20px 24px 20px;
  border-bottom: 1px solid #101010;

  > div {
    &:first-child {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      ${({ loggedIn }) => loggedIn && 'margin-bottom: 0px;'}

      > div {
        &:first-child {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 4px;
        }

        &:last-child {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: ${colors.c19};
        }
      }
    }

    &:last-child {
      display: flex;
      justify-content: space-between;

      > button {
        width: 157px;

        &:first-child {
          background: ${colors.c20};
        }

        &:last-child {
          background: ${colors.c13};
        }
      }

      > a {
        width: 157px;

        &:first-child {
          background: ${colors.c20};
        }

        &:last-child {
          background: ${colors.c13};
        }
      }
    }
  }
`

export const PopoverCloser = styled(Popover.Button)`
  display: flex;
  align-items: center;

  > svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
`

export const PopoverBottom = styled.div`
  display: flex;
  flex-direction: column;

  > a {
    &:not(:last-child) {
      border-bottom: 1px solid #101010;
    }

    > button {
      cursor: pointer;
      text-align: left;
      color: ${colors.c3};
      width: 100%;
      font-size: 16px;
      padding: 20px;
    }
  }
`

export const SearchCenter = styled.div`
  flex: 1;
  padding: 0px 24px;
  /* max-width: 800px; */
`

export const SignupContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > span {
    width: 200px;
  }

  > a {
    background-color: ${colors.c17};
    width: 100px;
  }

  ${md} {
    > span {
      font-size: 14px;
      text-align: right;
      padding-right: 7px;
    }
  }
`
