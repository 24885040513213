import React, {useState, useEffect, useMemo} from 'react'
import {
  BannerCustomContent,
  BannerLeftUpper,
  BannerLeft,
  BannerLeftLower,
  BannerRight,
  BannerRightUpper,
  BannerRightLower,
  ContentWrapper,
  PageWrapper,
  SideBarWrapper,
  TagWrapper,
  TagItem,
  Details,
  DetailsLeft,
  DetailsRight,
  RoundInfo,
  UsefulInfo,
  InfoItem,
  InfoItemEnd,
  AboutWrapper,
  SocialLink,
  SocialLinks
} from './styled'
import { Loader } from 'components'
import { Icon, Banner } from 'components'
import Sidebar from '../Sidebar'
import { genImagePath, venueAuthUser } from 'utils'
import { gql, useLazyQuery } from '@apollo/client'
import {useParams, useHistory} from 'react-router-dom'
import MediaGallery from './MediaGallery'
import { useStoreon } from 'storeon/react'

const GET_VENUE = gql`
  query getVenue($where: venues_bool_exp = {}) {
    venues(where: $where, limit: 1) {
      id
      name
      slug
      about
      address
      state
      zip_code
      city
      website
      email
      phone
      facebook
      instagram
      twitter
      youtube
      owner_id
      venue_tags {
        tag {
          name
        }
      }
      venue_image {
        name
      }
      venue_gallery {
        name
      }
    }
  }
`

const VenueProfile = () => {
  const {slug} = useParams()
  const { userData, loading: userLoading } = useStoreon('userData', 'loading')
  const history = useHistory()
  const [showGallery, setShowGallery] = useState()
  const [getVenue, {loading: venueLoading, data: venueData}] = useLazyQuery(GET_VENUE, {
    variables: {
      where: {
        slug: {
          _eq: slug
        }        
      }
    },
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    getVenue()
  }, [])

  const renderTag = (item, idx) => {
    return (
      <TagItem key={idx}>
        <span>{item}</span>
      </TagItem>
    )
  }

  const venue = venueData?.venues[0]

  const loading = useMemo(() => {
    if (venueLoading) {
      return true
    } else if (!venue) {
      return true
    }

    if (!userLoading) {
      const authCheck = venueAuthUser(userData, venue?.owner_id);
  
      if (!authCheck) {
        history.push('/')
      }
    }

    return false
  }, [venueLoading, venue, userLoading])

  const tags = venue?.venue_tags?.map(t => t.tag.name)

  let allImages = []

  if (venue?.venue_gallery) {
    allImages = venue.venue_gallery.map((i) => {
      const imageUrl = genImagePath({
        ownerType: 4,
        ownerId: venue.id,
        name: i.name
      })

      return {
        isCover: i.id === venue.bg_image_id,
        imageUrl
      }
    })
  }

  const socialLinks = () => {
    const links = [
      {to: venue?.facebook ?? undefined, svg: 'facebook'},
      {to: venue?.instagram ?? undefined, svg: 'instagram'},
      {to: venue?.twitter ?? undefined, svg: 'twitter'},
      {to: venue?.youtube ?? undefined, svg: 'youtube'},
      {to: venue?.email ? `mailto:${venue?.email}` : undefined, svg: 'email'}
    ]

    return <SocialLinks>{links.map(renderSocialLink)}</SocialLinks>
  }

  const renderSocialLink = (item, idx) => {
    const { to, svg } = item
    if (!to) {
      return null
    }

    return (
      <SocialLink key={idx} href={to} target="_blank">
        <Icon name={`${svg}`} />
      </SocialLink>
    )
  }
  const infoData = [
    { data: `${venue?.address}, ${venue?.city}, ${venue?.state}, ${venue?.zip_code}`, svg: 'place' },
    { data: venue?.phone, svg: 'phone' },
    { data: venue?.website, svg: 'external', link: true }
  ]

  const renderInfo = (item, idx) => {
    const { data, svg, link } = item

    if (!data) {
      return null
    }

    return (
      <InfoItem key={idx}>
        <Icon name={svg} />
        {link ? (
          <a href={data} target="_blank" rel="noreferrer">
            {data}
          </a>
        ) : (
          <span>{data}</span>
        )}
      </InfoItem>
    )
  }

  const handleShowGallery = () => {
    setShowGallery(true)
  }

  if (loading) {
    return (
      <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <p>Loading</p>
        <div style={{marginLeft: '10px'}}>
          <Loader/>
        </div>
      </div>
    )
  }

  return (
    <PageWrapper>
      <Sidebar active={1} />
      <ContentWrapper>
        <Banner
          height={600}
          image={`https://rca-s3.imgix.net/4/${venue?.id}/${venue?.venue_image?.name}`}
          backgroundPosition="center top"
        >
          <BannerCustomContent>
            <BannerLeft>
              <BannerLeftUpper>
                <h2>Profile</h2>
              </BannerLeftUpper>
              <BannerLeftLower>
                <h1>{venue.name}</h1>
                <TagWrapper>{tags.map(renderTag)}</TagWrapper>
              </BannerLeftLower>
            </BannerLeft>

            <BannerRight>
              <BannerRightUpper>
                <button onClick={() => history.push(`/venue/${slug}/dashboard/gallery`)}>
                  <Icon name="gallery" />
                  Edit
                </button>
              </BannerRightUpper>
              <BannerRightLower>
                <a href={`/venue/${venue.slug}/dashboard/edit-profile`} rel="noreferrer">
                  Edit Profile
                </a>
                <button onClick={() => handleShowGallery()}>
                  <Icon name="gallery" />
                  Photo Gallery
                </button>
              </BannerRightLower>
            </BannerRight>
          </BannerCustomContent>
        </Banner>

        <Details>
          <DetailsLeft>
            <h2>About</h2>
            <AboutWrapper>
              {venue.about}
            </AboutWrapper>
          </DetailsLeft>
          <DetailsRight>
            <RoundInfo>
              <UsefulInfo>
                <InfoItemEnd>
                  <h3>Useful Info</h3>
                </InfoItemEnd>
                {infoData.map(renderInfo)}
                <InfoItemEnd>{socialLinks()}</InfoItemEnd>
              </UsefulInfo>
            </RoundInfo>
          </DetailsRight>
        </Details>
      </ContentWrapper>

      <MediaGallery
        show={showGallery}
        data={{ gallery:  allImages }}
        handleClose={() => setShowGallery(false)}
      />
    </PageWrapper>
  )
}

export default VenueProfile
