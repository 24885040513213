import styled from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const EventsWrapper = styled.div`
  padding-top: 100px;
  width: 100%;

  ${({ currCity }) => currCity && 'padding-top: 0px;'}
`

export const EventItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 24px;

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;

    > a {
      margin: 8px;
      width: calc((100% / 4) - 16px);

      ${md} {
        width: 100%;
        margin: 12px 8px;
      }
    }
  }
`

export const EventsHeader = styled.div`
  margin-bottom: 24px;

  ${md} {
    padding: 0px 8px;
  }
`

export const BannerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h2 {
    font-size: 40px;
    margin-bottom: 8px;
  }

  p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 28px;
  }

  a {
    margin-left: 8px;
    text-decoration: underline;
  }

  ${md} {
    padding: 0px 16px;
  }
`

export const SponsoredWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > h2 {
    font-size: 35px;

    ${md} {
      padding: 0px 16px;
    }
  }
`

export const SponsoredEvents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ThickRedLine = styled.div`
  width: 282px;
  height: 6px;
  background-color: ${colors.c2};
  margin-top: 5px;
  margin-bottom: 30px;

  ${md} {
    margin-left: 16px;
  }
`

export const SponsoredItems = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 24px;
  width: 100%;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(96, 96, 96, 0.8);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;

    > div {
      flex: 0 0 25%;
    }

    > a {
      margin: 8px;
      width: calc((100% / 4) - 16px);

      ${md} {
        min-width: 85%;
        margin: 12px 8px;
      }
    }
  }
`

export const RcaLogo = styled.div`
  height: 48px;
  width: 64px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`

export const MobileFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
