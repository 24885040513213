import React from 'react'
import config from 'config'
import firebase from 'firebase/app'
import 'firebase/auth'
import request from 'superagent'
import toast from 'react-hot-toast'
import http from 'http'

const { rcaApi } = config
const keepAliveAgent = new http.Agent({ keepAlive: true });

export const registerUser = async (payload) => {
  try {
    const { email, password, lastName, firstName, zipcode } = payload
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
    const { user } = res
    const token = await user.getIdToken()

    const req = await request
      .agent(keepAliveAgent)
      .post(`${rcaApi}/register`)
      .send({ email, lastName, firstName, zipcode })
      .set('Authorization', token)

    return { req, user }
  } catch (e) {
    const { message } = e

    if (message) {
      if (e.code === "auth/email-already-in-use") {
        toast.error((t) => (
          <div>
            <p>This email is already in use.</p>
            <a href='/login' style={{ color: 'gray', textDecoration: 'underline' }}>Click here to Log In</a>
          </div>
        ))
      } else {
        toast.error(`Failed to create account with error: ${message}`)
      }
    }

    return false
  }
}

export const requestPasswordReset = async (email) => {
  try {
    await request.post(`${rcaApi}/reset-password`).send({ email })

    return true
  } catch (e) {
    return false
  }
}

export const changePassword = async (payload) => {
  const { email, password, token } = payload

  await request
    .post(`${rcaApi}/change-password`)
    .send({ email, password, token })

  return true
}

export const getPrefs = async ({ token }) => {
  const { body } = await request
    .agent(keepAliveAgent)
    .get(`${rcaApi}/me/preferences`)
    .set('Authorization', token)

  return body
}

export const updatePrefs = async (payload) => {
  try {
    const { token } = payload

    const req = await request
      .post(`${rcaApi}/me/preferences`)
      .set('Authorization', token)
      .send(payload)

    return req    
  } catch (e) {
    const { message } = e

    if (message) {
      toast.error(`Failed to update preferences with error: ${message}`)
    }

    return false
  }

}

export const logoutUser = () => {
  return firebase.auth().signOut()
}

export const sendClaimVenueEmail = async payload => {
  try {
    const { body } = await request
      .post(`${rcaApi}/claim-venue`)
      .send(payload);

    return body;

  } catch (e) {
    const { message } = e

    if (message) {
      toast.error(`Failed to send email with error: ${message}`)
    }

    return false
  }
}

export const sendInfoEmail = async payload => {
  try {
    const { body } = await request
      .post(`${rcaApi}/infoEmail`)
      .send(payload);

    return body
  } catch (e) {
    const { message } = e

    if (message) {
      toast.error(`Failed to send email with error: ${message}`)
    }

    return false
  }
}
