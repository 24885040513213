import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormWrapper = styled.div`
  width: 384px;
  margin-top: 115px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: 40px;
    font-weight: 700;
  }

  > span {
  }
`

export const ProfilePhotoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;

  > span {
    margin-left: 15px;
  }
`

export const ProfilePhotoPlaceholder = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${colors.c15};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.c3};

  > svg {
    width: 24px;
    height: 24px;
  }
`

export const InputWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`

export const VibeHeader = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;

  > span {
  }
`

export const VibeTagsContainer = styled.div`
  margin-top: 16px;
`

export const CTAWrapper = styled.div`
  margin-top: 32px;
`
