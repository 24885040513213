export default {
  initialState: {
    user: null,
    loading: true,
    userData: null
  },
  actions: [
    {
      name: 'setUserState',
      cb: (_, value) => {
        return value
      }
    }
  ]
}
