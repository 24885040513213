import React, { useMemo, useEffect, useState } from 'react'
import {
  IconWrapper,
  ImageLabel,
  MainImage,
  MainWrapper,
  IconButton,
  ImageWrapper,
  ContentWrapper
} from './styled'
import { Icon } from 'components'
import { genImagePath } from 'utils'
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client'
import { useStoreon } from 'storeon/react'
import { useHistory } from 'react-router-dom'

const FOLLOW_VENUE = gql`
  mutation followVenue($userId: Int!, $venueId: Int!) {
    insert_user_followed_venues(
      objects: { user_id: $userId, venue_id: $venueId }
    ) {
      affected_rows
    }
  }
`

const UNFOLLOW_VENUE = gql`
  mutation unfollowVenue($userId: Int!, $venueId: Int!) {
    delete_user_followed_venues(
      where: { user_id: { _eq: $userId }, venue_id: { _eq: $venueId } }
    ) {
      affected_rows
    }
  }
`

const PreferenceCard = ({
  userId,
  id,
  ownerId,
  ownerType,
  name,
  label,
  actionUrl,
  followed,
  followAllowed,
  onFollow
}) => {
  const imageUrl = genImagePath({
    ownerType: ownerType,
    ownerId: ownerId,
    name: name
  })

  const history = useHistory()

  const [addFollowedVenue] = useMutation(FOLLOW_VENUE)
  const [removeFollowedVenue] = useMutation(UNFOLLOW_VENUE)
  const [following, setIsFollowing] = useState(false)

  const { dispatch } = useStoreon()

  useEffect(() => {
    setIsFollowing(followed)
  }, [followed])

  const handleFollowClick = (e) => {
    e.stopPropagation()
    const hideFOMOModal = localStorage.getItem('hideFOMOModal')

    if (id && userId) {
      // console.log('followingVenue: ', followingVenue)
      if (!following) {
        // console.log('following venue')
        addFollowedVenue({
          variables: {
            userId: userId,
            venueId: id
          }
        })

        if (!hideFOMOModal) {
          dispatch('setShowFOMOModal', true)
        }
        setIsFollowing(true)
        onFollow(true)
      } else {
        // console.log('unfollowing venue')
        removeFollowedVenue({
          variables: {
            userId: userId,
            venueId: id
          }
        })

        setIsFollowing(false)
        onFollow(false)
      }
    } else {
      dispatch('setShowSignupOptin', true)
    }
  }

  const img = name
    ? imageUrl
    : 'https://img.freepik.com/free-photo/view-3d-cinema-theatre-room_23-2151066955.jpg?t=st=1709591379~exp=1709594979~hmac=3c9ebb0f740350440ba08d38f9bedd8b68b417c4b02f00aada5d5d288020f416&w=1800'

  const handleWrapperClick = () => {
    gtag('venue', 'venue_clicked', {
      venue_clicked: 1
    })

    history.push(actionUrl)
  }

  return (
    <MainWrapper onClick={handleWrapperClick}>
      <ImageWrapper>
        <MainImage $imageUrl={img} />
        <ContentWrapper>
          {followAllowed && (
            <IconWrapper>
              <IconButton onClick={handleFollowClick}>
                <Icon name={following ? 'heartIconFilled' : 'heartIcon'} />
              </IconButton>
            </IconWrapper>
          )}
        </ContentWrapper>
      </ImageWrapper>
      <ImageLabel>{label}</ImageLabel>
    </MainWrapper>
  )
}

export default PreferenceCard
