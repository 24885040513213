import React, { useState, useEffect } from 'react'
import {
  CTAWrapper,
  Divider,
  DividerText,
  DividerWrapper,
  FormWrapper,
  InputWrapper,
  MainWrapper,
  SocialButtons,
  SocialButtonsWrapper,
  TitleWrapper,
  Terms,
  NameInputs
} from './styled'
import { Button, TextInput } from 'components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useStoreon } from 'storeon/react'
import { terms, privacy } from 'constant'
import { useHistory } from 'react-router-dom'
import { registerUser } from 'apis'

const SignUp = () => {
  const [submitDisable, setSubmitDisabled] = useState(true)
  const { dispatch, userData } = useStoreon('userData');

  const history = useHistory()

  useEffect(() => {
    if (userData) {
      history.push('/')
    }
  }, [userData])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      zipcode: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email should be a valid email')
        .required('Email is required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      zipcode: Yup.string().required('Zipcode is required')
    }),
    onSubmit: async (values) => {
      // Handle form submission here
      try {
        setSubmitDisabled(true)
        const regRequest = await registerUser(values)
  
        if (regRequest.req && regRequest.req.statusCode === 200) {
          dispatch('setUserState', {user: regRequest.user})
          history.push('/signup/thankyou')
        } else {
          setSubmitDisabled(false);
        }

      } catch (e) {
        console.error('Failed to register user with error: ', e)
        setSubmitDisabled(false);
      }
    }
  })

  useEffect(() => {
    // Check if there are errors in formik
    const hasFormErrors = Object.keys(formik.errors).length > 0
    const touch = Object.keys(formik.touched).length > 0

    if (touch) {
      setSubmitDisabled(hasFormErrors)
    }
  }, [formik.errors, formik.touched])

  return (
    <MainWrapper>
      <FormWrapper>
        <TitleWrapper>
          <h1>Sign up</h1>
          <span>Experiences await. Sign up today!</span>
        </TitleWrapper>

        {/* <SocialButtonsWrapper>
          <span>Continue with...</span>
          <SocialButtons>
            <Button plain onClick={() => {}}></Button>
            <Button plain onClick={() => {}}></Button>
            <Button plain onClick={() => {}}></Button>
          </SocialButtons>
        </SocialButtonsWrapper> */}

        {/* <DividerWrapper>
          <Divider></Divider>
          <DividerText>or</DividerText>
          <Divider></Divider>
        </DividerWrapper> */}

        <InputWrapper>
          <NameInputs>
            <TextInput
              error={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : ''
              }
              placeholder="First name"
              value={formik.values.firstName}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              name="firstName"
            />
            <TextInput
              error={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : ''
              }
              placeholder="Last name"
              value={formik.values.lastName}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              name="lastName"
            />
          </NameInputs>
          <TextInput
            error={
              formik.touched.zipcode && formik.errors.zipcode
                ? formik.errors.zipcode
                : ''
            }
            placeholder="Zipcode"
            value={formik.values.zipcode}
            returnValueOnChange={false}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            name="zipcode"
          />
          <TextInput
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ''
            }
            placeholder="Email Address"
            value={formik.values.email}
            returnValueOnChange={false}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            name="email"
          />
          <TextInput
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ''
            }
            placeholder="Password (8 characters min.)"
            value={formik.values.password}
            returnValueOnChange={false}
            onChange={(e) => formik.handleChange(e)}
            name="password"
            type="password"
            onBlur={formik.handleBlur}
          />
        </InputWrapper>

        <Terms>
          <div>
            <span>by signing up, you agree to our </span>
            <a onClick={() => dispatch('setTextModal', terms)}>Terms &nbsp;</a>
            <span>and&nbsp;</span>
            <a onClick={() => dispatch('setTextModal', privacy)}>
              Privacy Policy
            </a>
          </div>
        </Terms>

        <CTAWrapper>
          <Button type='submit' disabled={submitDisable} onClick={() => formik.handleSubmit()}>
            Sign up
          </Button>
        </CTAWrapper>
      </FormWrapper>
    </MainWrapper>
  )
}

export default SignUp
