import styled from 'styled-components'
import { colors } from 'constant'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  
  > * {
    width: 600px;
    padding: 12px;
    max-width: 100%;
  }
`

export const ChangePasswordWrapper = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;

  > h1 {
    margin-bottom: 16px;
  }

  > p {
    margin-bottom: 16px;
  }

  > div {
    &:first-child {
      height: 120px;
      width: 120px;
      background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    margin-bottom: 16px;
  }
`

export const Error = styled.div`
  margin-bottom: 16px;
  color: ${colors.c2};
  font-weight: 600;
`
