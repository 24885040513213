import React from 'react'

const UserIcon = () => {
  return (
    <svg
      viewBox='0 0 8.4666664 8.4666664'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='layer1' transform='translate(0 -288.533)'>
        <path id='path4871-0' d='m4.2322998 288.66562c-.9818999 0-1.784904.8056-1.784904 1.78749-.00001.98191.8030041 1.7849 1.784904 1.7849.9819 0 1.7874877-.80299 1.7874877-1.7849 0-.98189-.8055978-1.78749-1.7874877-1.78749zm0 .7953c.5529199 0 .9942544.43927.9942544.99219 0 .55293-.4413345.99219-.9942544.99219-.5529299 0-.9921875-.43926-.9921875-.99219 0-.55292.4392576-.99219.9921875-.99219zm-2.7331665 4.01474v-.00053c-.44787.29873.014302.96655.451652.65268.8475999-.59984 3.2358436-.88885 4.5702635-.004.3328101.23452.4904094.48336.4904094.82476v.46354c0 .37331-.2881383.66145-.6614583.66145h-4.2333333c-.3733099 0-.6614583-.28814-.6614583-.66145v-.48008c.008-.5367-.80133997-.5367-.79374998 0v.48008c0 .79932.65588828 1.4552 1.45520828 1.4552h4.2333333c.7993301 0 1.4552083-.65588 1.4552083-1.4552v-.46354c0-.62024-.3491097-1.13683-.8273396-1.47381-1.3057629-.89564-3.9399277-1.02651-5.4787353.0009z' fill='currentColor' />
      </g>
    </svg>
  )
}

export default UserIcon
