const baseColors = {
  c1: '#111112',
  c2: '#C22026',
  c3: '#ffffff',
  c4: '#ababab',
  c5: '#e4e4e4',
  c6: '#000000',
  c7: '#131313',
  c8: '#404040',
  c9: '#6D6A6A',
  c10: '#1b1b1b',
  c11: '#dfdfdf',
  c12: 'rgba(50, 50, 50, 0.7)',
  c13: '#101010',
  c14: 'rgba(0, 0, 0, 0.5)',
  c15: '#303030',
  c16: '#0F0F0F',
  c17: '#1E2022',
  c18: '#292B31',
  c19: 'rgba(255, 255, 255, 0.6)',
  c20: 'linear-gradient(98.1deg, #C22026 -5.05%, #C400CB 101.49%)',
  c21: '#f00',
  c22: 'rgba(0, 0, 0, 0.3)',
  c23: 'rgba(0, 0, 0, 0.8)',
  c24: '#f745c8',
  c25: '#c3195e',
  c26: 'rgba(30, 31, 34, 0.8)',
  c27: '#f55494'
}

const namedColors = {
  mainText: baseColors.c5,
  secondaryText: baseColors.c11,
  cardBackground: baseColors.c1,
  background: baseColors.c6,
  backgroundSecondary: baseColors.c7,
  buttonBackground: baseColors.c20,
  error: baseColors.c21
}

export default {
  ...baseColors,
  ...namedColors
}
