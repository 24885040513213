import React, { useEffect } from 'react'
import { AlertWrapper } from './styled'
import { colors } from 'constant'
import { Icon } from 'components'

const Alert = props => {
  const { message, onClose, color = colors.cardBackground, timer = 4000 } = props

  useEffect(() => {
    const currTimeout = setTimeout(() => {
      onClose()
    }, timer)

    return () => {
      clearTimeout(currTimeout)
    }
  }, [onClose, timer])

  return (
    <AlertWrapper
      color={color}
    >
      <div>{message}</div>
      <div onClick={onClose}>
        <Icon name='closeCircle'/>
      </div>
    </AlertWrapper>
  )
}

export default Alert
