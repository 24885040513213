import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'
import { Link } from 'react-router-dom'

const { md } = breakpoints

export const SideBarWrapper = styled.div`
  flex-grow: 1;
  width: 235px;
  background-color: ${colors.c17};
`

export const SideBarItems = styled.ul`
  list-style: none;
  margin-top: 100px;
  padding-left: 8px;
  padding-right: 8px;
`

const activeItem = css`
  background-color: ${colors.c16};
`

export const SideBarItem = styled.li`
  border-radius: 12px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${({ $active }) => ($active ? activeItem : '')}

  > svg {
    height: 20px;
    width: 20px;
  }
`

export const SideBarLabel = styled(Link)`
  margin-left: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
`
