import styled, { css } from 'styled-components'
import { colors, breakpoints } from 'constant'

const { md, sm } = breakpoints

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const withError = css`
  border-color: ${colors.error};
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 16px;
  border-radius: 8px;
  color: ${colors.mainText};
  position: relative;
  ${({ $withError }) => $withError && withError};
  height: 56px;
  align-items: center;

  > input {
    /* background-color: ${colors.c17} !important; */
    -webkit-text-fill-color: ${colors.mainText};
    background: none;
    ß ${md} {
      height: 24px;
    }
  }

  ${({ $transparent }) =>
    $transparent
      ? css`
          background: none;
        `
      : css`
          background: ${colors.c17};
        `}

  > button {
    width: unset;
    font-weight: normal;
    padding: 0 14px;
    font-size: 16px;
    height: 34px;

    ${md} {
      height: unset;
      padding: unset;
    }
  }
`

export const CoreInput = styled.input`
  background: none;
  border: none;
  padding: 0px 6px;
  color: ${colors.mainText};
  font-size: 16px;
  width: 100%;

  ${md} {
    font-size: 10px;
  }
`

export const ClearButton = styled.button`
  background: transparent;
  border: none;

  > svg {
    height: 13px;
    width: 20px;
    color: white;
  }
`

export const IconWrapper = styled.div`
  > svg {
    height: 20px;
    width: 20px;
    transform: translate(-3px, 2px);
  }
`

export const ErrorMessage = styled.div`
  color: ${colors.error};
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
`
