import React, { useState } from 'react'
import {
  BannerWrapper,
  BannerContentBg,
  BannerContent,
  Left,
  Right,
  TagItem,
  TagWrapper,
  LocationWrapper
} from './styled'
import { Icon, GalleryModal, Button } from 'components'
import { formatDateD } from 'utils'
import { useStoreon } from 'storeon/react'

const Banner = (props) => {
  const { data } = props
  const {
    name,
    event_tags: tags = [],
    start_date: startDate,
    end_date: endDate,
    location,
    min_price: minPrice,
    max_price: maxPrice,
    ticket_link: ticketLink
  } = data.events[0]
  const { isMobile } = useStoreon('isMobile')
  const [showGallery, setShowGallery] = useState(false)
  const coverImage = data.gallery.find((i) => i.isCover)

  const renderTag = (item, idx) => {
    const { name } = item.tag

    return (
      <TagItem key={idx}>
        <span>{name}</span>
      </TagItem>
    )
  }

  let dateString = formatDateD(startDate)

  if (endDate) {
    dateString = `${dateString} - ${formatDateD(endDate)}`
  }

  const isSinglePrice = minPrice === maxPrice

  const priceString = isSinglePrice
    ? `$${minPrice}`
    : `$${minPrice} - $${maxPrice}`

  return (
    <BannerWrapper image={coverImage?.imageUrl} isMobile={isMobile}>
      <BannerContentBg>
        <BannerContent isMobile={isMobile}>
          <Left>
            <h1>{name}</h1>
            <LocationWrapper>
              <Icon name="place" />
              <span>{location}</span>
            </LocationWrapper>
            <h4>{dateString}</h4>
            <TagWrapper>
              {tags.length && tags.slice(0, 3).map(renderTag)}
            </TagWrapper>
            {isMobile && (
              <>
                <button onClick={() => setShowGallery(true)}>
                  <Icon name="gallery" />
                  Photo gallery
                </button>
                <Button href={ticketLink} rel="noreferrer" target="_blank">
                  {`Get Tickets ${priceString}`}
                </Button>
              </>
            )}
          </Left>
          {!isMobile && (
            <Right>
              <button onClick={() => setShowGallery(true)}>
                <Icon name="gallery" />
                Photo gallery
              </button>
            </Right>
          )}
        </BannerContent>
      </BannerContentBg>
      <GalleryModal
        onClose={() => setShowGallery(false)}
        open={showGallery}
        photos={data.gallery}
      />
    </BannerWrapper>
  )
}

export default Banner
