import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { sendClaimVenueEmail, sendInfoEmail } from 'apis'

import { useStoreon } from 'storeon/react'

import {
  BannerImageWrapper,
  MainWrapper,
  BannerTitle,
  BannerSubtitle,
  Overlay,
  TitleContainer,
  BannerGetstartedCta,
  RequestInfoCta,
  Section,
  SectionHeader,
  CultivationImage,
  CultivationCaption,
  CultivationSection,
  Benefits,
  BenefitsGrid,
  BenefitsGridItem,
  CheckIcon,
  ArtAddicts,
  ArtAddictsIcon,
  ArtAddictsItem,
  ArtAddictsItems,
  ArtAddictsLabel,
  FormWrapper,
  FormImageBackground,
  FormContent,
  FormOverlay,
  FormTitle,
  Form,
  InputRow,
  AboutWrapper,
  TextAreaContainer,
  SubmitButton,
  ImagesSection,
  ImagesRow,
  SingleImage,
  FourByFour,
  FourByFourItem,
  Testimonials,
  TestimonialsItem,
  TestimonialBG,
  TestimonialsColumn,
  TestimonialsPic,
  Comment,
  RcaLogo,
  ContentWrapper,
  TextWrapper,
  ButtonWrapper,
  CloseIcon,
  InputModalRow,
  ContentModalWrapper,
  GridContainer,
  Steps,
  StepConnector,
  StepInfo,
  TestimonialsContent
} from './styled'
import { Button, Icon, TextInput, TextArea, Modal, Loader } from 'components'
import { gql, useMutation, useQuery } from '@apollo/client'

const GET_TESTIMONIALS = gql`
  query getTestimonials {
    testimonials {
      image_name
      description
      testimonial_provider
    }
  }
`

const ADD_ROW_TO_CLAIM_VENUE_FORM = gql`
  mutation addRowToClaimVenueForm(
    $orgName: String!
    $contactName: String!
    $address: String!
    $email: String!
    $phone: String!
    $website: String!
    $about: String!
  ) {
    insert_claim_venue_form(
      objects: {
        org_name: $orgName
        contact_name: $contactName
        address: $address
        email: $email
        phone_number: $phone
        website: $website
        about: $about
      }
    ) {
      affected_rows
    }
  }
`

const mainUrl = 'https://red-curtain-addict.imgix.net/partnership/'

const partnerBenefits = [
  'Showcase your mission, season highlights, contact information, follow feature, event reminders, and more',
  "Get featured placements, so you're seen by users who are looking for new things to experience",
  'Feature your upcoming events with videos, images, and links to your website',
  'Be featured in our cross-city guides for both travelers and locals',
  "Ongoing placement in our Arts Picks Newsletters based on individuals' preferences",
  'Tap into our marketing services, including influencer marketing, design services, promotional strategies and more'
]

const artAddicts = [
  {
    icon: '3peopleIcon.svg',
    label: '50/50 Male & Female'
  },
  {
    icon: 'GradCapIcon.svg',
    label: 'College Graduate from 23-55'
  },
  {
    icon: 'FingerPOintIcon.svg',
    label: 'Looking for the next cultural experience to attend'
  },
  {
    icon: 'FileFolderIcon.svg',
    label: 'Making 6-figure income'
  },
  {
    icon: 'NetworkIcon.svg',
    label: 'Looking for meaningful connections and communities'
  }
]

const NewPartner = () => {
  const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/
  const { isMobile } = useStoreon('isMobile')
  const [open, setOpen] = useState(false)
  const [emailOpen, setEmailOpen] = useState(false)
  const [addRowToClaimVenueForm] = useMutation(ADD_ROW_TO_CLAIM_VENUE_FORM)
  const { loading: testimonialsLoading, data: testimonialsData } =
    useQuery(GET_TESTIMONIALS)
  const getStartedJump = () => {
    const getStartedSection = document.getElementById('getstarted')
    getStartedSection.scrollIntoView({ behavior: 'smooth' })
  }

  const sendEmail = async (data) => {
    try {
      await sendClaimVenueEmail(data)
    } catch (e) {
      console.log('Failed to send email with error: ', e)
    }
  }

  const sendModalEmail = async (data) => {
    try {
      await sendInfoEmail(data)
    } catch (e) {
      console.log('Failed to send email with error: ', e)
    }
  }

  const renderPartnerBenefits = () => {
    return partnerBenefits.map((item, idx) => {
      return (
        <BenefitsGridItem key={idx}>
          <CheckIcon>
            <Icon name="check" />
          </CheckIcon>
          <div>{item}</div>
        </BenefitsGridItem>
      )
    })
  }

  const renderArtAddics = () => {
    return artAddicts.map((item, idx) => {
      return (
        <ArtAddictsItem key={idx}>
          <ArtAddictsIcon src={mainUrl + item.icon + '?compression=auto'} />
          <ArtAddictsLabel>{item.label}</ArtAddictsLabel>
        </ArtAddictsItem>
      )
    })
  }

  const renderTestimonials = () => {
    return testimonialsData.testimonials.map((item, idx) => {
      const provider = item.testimonial_provider.split(',');
      const provName = provider[0];
      const provCompany = provider[1];
      return (
        <TestimonialsItem key={idx}>
          <TestimonialBG
            $gray={!isMobile ? idx % 4 === 0 || idx % 4 === 3 : idx % 2 === 0}
          >
            <TestimonialsPic
              $imageUrl={`https://rca-s3.imgix.net/testimonialIcons/${item.image_name}?compression=auto`}
            />
            <Comment>
              <p>{item.description} - <strong>{provName}</strong>, {provCompany}</p>
            </Comment>
          </TestimonialBG>
        </TestimonialsItem>
      )
    })
  }

  const formik = useFormik({
    initialValues: {
      orgName: '',
      email: '',
      address: '',
      phoneNumber: '',
      website: '',
      about: '',
      contactName: ''
    },
    validationSchema: Yup.object({
      orgName: Yup.string().required('Name is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      address: Yup.string().required('Address is required'),
      phoneNumber: Yup.string().required('Phone number is required'),
      website: Yup.string().required('Website is required'),
      about: Yup.string().required(
        'Description of your organization is required'
      ),
      contactName: Yup.string().required('Contact Name is required')
    }),
    onSubmit: async (values) => {
      await addRowToClaimVenueForm({
        variables: {
          orgName: values.orgName,
          address: values.address,
          email: values.email,
          phone: values.phoneNumber,
          website: values.website,
          about: values.about,
          contactName: values.contactName
        }
      })

      await sendEmail({
        orgName: values.orgName,
        address: values.address,
        email: values.email,
        phone: values.phoneNumber,
        website: values.website,
        about: values.about,
        contactName: values.contactName,
        toEmail: 'Info@redcurtainaddict.com'
      })

      setOpen(true)
    }
  })

  const modalFormIk = useFormik({
    initialValues: {
      email: '',
      requestorName: '',
      subject: '',
      content: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      requestorName: Yup.string().required('Requestor name is required'),
      subject: Yup.string().required('Subject is required'),
      content: Yup.string().required('Email content is required')
    }),
    onSubmit: async (values) => {
      await sendModalEmail({
        toEmail: 'Info@redcurtainaddict.com',
        requestorName: values.requestorName,
        email: values.email,
        subject: `${values.requestorName} - ${values.subject}`,
        content: values.content
      })

      setEmailOpen(false)
    }
  })

  const emailModal = () => {
    return (
      <ContentModalWrapper>
        <CloseIcon onClick={() => setEmailOpen(false)}>X</CloseIcon>

        <RcaLogo />
        {/* 
        <TextModalWrapper>
            <p>Give us your burning questions</p>
        </TextModalWrapper> */}
        <InputModalRow>
          <TextInput
            error={
              modalFormIk.touched.requestorName &&
              modalFormIk.errors.requestorName
                ? modalFormIk.errors.requestorName
                : ''
            }
            placeholder="Name"
            value={modalFormIk.values.requestorName}
            returnValueOnChange={false}
            onChange={(e) => modalFormIk.handleChange(e)}
            name="requestorName"
          />
        </InputModalRow>
        <InputModalRow>
          <TextInput
            error={
              modalFormIk.touched.email && modalFormIk.errors.email
                ? modalFormIk.errors.email
                : ''
            }
            placeholder="Email"
            value={modalFormIk.values.email}
            returnValueOnChange={false}
            onChange={(e) => modalFormIk.handleChange(e)}
            name="email"
          />
        </InputModalRow>
        <InputModalRow>
          <TextInput
            error={
              modalFormIk.touched.subject && modalFormIk.errors.subject
                ? modalFormIk.errors.subject
                : ''
            }
            placeholder="Subject"
            value={modalFormIk.values.subject}
            returnValueOnChange={false}
            onChange={(e) => modalFormIk.handleChange(e)}
            name="subject"
          />
        </InputModalRow>
        <TextAreaContainer
          style={{
            'margin-top': '0px',
            'margin-bottom': '20px',
            width: '100%'
          }}
        >
          <TextArea
            rows={7}
            placeholder="Provide your questions here"
            error={
              modalFormIk.touched.content && modalFormIk.errors.content
                ? modalFormIk.errors.content
                : ''
            }
            value={modalFormIk.values.content}
            returnValueOnChange={false}
            onChange={(e) => modalFormIk.handleChange(e)}
            name="content"
          />
        </TextAreaContainer>
        <SubmitButton>
          <Button type="submit" onClick={() => modalFormIk.handleSubmit()}>
            Submit
          </Button>
        </SubmitButton>
      </ContentModalWrapper>
    )
  }

  const thankYouModal = () => {
    return (
      <ContentWrapper>
        <CloseIcon onClick={() => setOpen(false)}>X</CloseIcon>

        <RcaLogo />

        <h1>Thank You!</h1>
        <h4>Welcome to the "Arts Addict" community!</h4>
        <TextWrapper>
          <p>
            Thank you! We are looking forward to connecting with you about your
            upcoming performances. Someone on our team will be in touch soon.
          </p>
        </TextWrapper>
        <ButtonWrapper>
          <Button href="/">See our events</Button>
        </ButtonWrapper>
      </ContentWrapper>
    )
  }

  return (
    <MainWrapper>
      <BannerImageWrapper>
        <Overlay />
        <TitleContainer>
          <BannerTitle>
            Grow your audience and <br /> visibility with Red Curtain Addict!
          </BannerTitle>
          <BannerSubtitle>
            Through our dynamic and easy-to-use platform, we will help your arts
            experiences get discovered and promoted to targeted audiences while
            keeping your upcoming performances top of mind throughout the year.
          </BannerSubtitle>
          <BannerGetstartedCta>
            <Button onClick={() => getStartedJump()}>Get Started</Button>
          </BannerGetstartedCta>

          {!isMobile && (
            <React.Fragment>
              <GridContainer
                style={{
                  'grid-template-columns': 'repeat(11, 1fr)',
                  'grid-template-rows': 'repeat(3, 1fr)'
                }}
              >
                <Steps style={{ 'grid-area': '1 / 2 / 2 / 3' }}>
                  <img src={mainUrl + '%231withdots.svg?compression=auto'} />
                </Steps>
                <StepConnector style={{ 'grid-area': '1 / 3 / 2 / 6' }}>
                  <img src={mainUrl + 'dots-new.svg?compression=auto'} />
                </StepConnector>
                <Steps style={{ 'grid-area': '1 / 6 / 2 / 7' }}>
                  <img src={mainUrl + '%232withdots.svg?compression=auto'} />
                </Steps>
                <StepConnector style={{ 'grid-area': '1 / 7 / 2 / 10' }}>
                  <img src={mainUrl + 'dots-new.svg?compression=auto'} />
                </StepConnector>
                <Steps style={{ 'grid-area': '1 / 10 / 2 / 11' }}>
                  <img src={mainUrl + '%233withdots.svg?compression=auto'} />
                </Steps>
                <StepInfo style={{ 'grid-area': '2 / 1 / 4 / 4' }}>
                  <h1>Get Discovered</h1>
                  <p>
                    Show up at the right time as arts enthusiasts are actively
                    searching for their next arts outing
                  </p>
                </StepInfo>
                <StepInfo style={{ 'grid-area': '2 / 5 / 4 / 8' }}>
                  <h1>Promote Your Events</h1>
                  <p>
                    Feature your upcoming events through our dynamic event
                    listings, customized emails, and more!
                  </p>
                </StepInfo>
                <StepInfo style={{ 'grid-area': '2 / 9 / 4 / 12' }}>
                  <h1>Stay Top of Mind</h1>
                  <p>
                    Engage with audiences multiple times so that when your event
                    comes up, you're on their radar.
                  </p>
                </StepInfo>
              </GridContainer>
            </React.Fragment>
          )}
        </TitleContainer>
      </BannerImageWrapper>

      {isMobile && (
        <React.Fragment>
          <GridContainer
            style={{
              'grid-template-columns': 'repeat(5, 1fr)',
              'grid-template-rows': '1fr 1rem 1fr 1rem 1fr'
            }}
          >
            <Steps style={{ 'grid-area': '1 / 1 / 2 / 2' }}>
              <img src={mainUrl + '%231withdots.svg?compression=auto'} />
            </Steps>
            <StepConnector style={{ 'grid-area': '2 / 1 / 3 / 2' }}>
              <img src={mainUrl + 'dots-new.svg?compression=auto'} />
            </StepConnector>
            <Steps style={{ 'grid-area': '3 / 1 / 4 / 2' }}>
              <img src={mainUrl + '%232withdots.svg?compression=auto'} />
            </Steps>
            <StepConnector style={{ 'grid-area': '4 / 1 / 5 / 2' }}>
              <img src={mainUrl + 'dots-new.svg?compression=auto'} />
            </StepConnector>
            <Steps style={{ 'grid-area': '5 / 1 / 6 / 2' }}>
              <img src={mainUrl + '%233withdots.svg?compression=auto'} />
            </Steps>
            <StepInfo style={{ 'grid-area': '1 / 2 / 2 / 5' }}>
              <h1>Get Discovered</h1>
              <p>
                Show up at the right time as arts enthusiasts are actively
                searching for their next arts outing
              </p>
            </StepInfo>
            <StepInfo style={{ 'grid-area': '3 / 2 / 4 / 5' }}>
              <h1>Promote Your Events</h1>
              <p>
                Feature your upcoming events through our dynamic event listings,
                customized emails, and more!
              </p>
            </StepInfo>
            <StepInfo style={{ 'grid-area': '5 / 2 / 6 / 5' }}>
              <h1>Stay Top of Mind</h1>
              <p>
                Engage with audiences multiple times so that when your event
                comes up, you're on their radar.
              </p>
            </StepInfo>
          </GridContainer>
        </React.Fragment>
      )}

      <Section>
        <SectionHeader>
          <h1>
            How will Red Curtain Addict help your organization <br />
            reach and cultivate new audiences?
          </h1>
        </SectionHeader>
      </Section>

      <CultivationSection>
        <CultivationImage $imageUrl={mainUrl + 'NextGenTech.png'} />

        <CultivationCaption>
          <h1>Next-Gen Tech</h1>
          <p>
            Utilizing Advanced AI, we streamline arts enthusiasts' searches with
            personalized recommendation based on their location and interests to
            elevate their arts experience and boost engagement.
          </p>
        </CultivationCaption>
      </CultivationSection>

      <CultivationSection $reverse={!isMobile}>
        <CultivationImage $imageUrl={mainUrl + 'partnership10.jpg'} />
        <CultivationCaption>
          <h1>Content Marketing</h1>
          <p>
            Our tone and voice is characterized as the unbiased, go-to friend
            with a finger on the pulse of all things arts, offering unassuming
            insights, engaging stories, and special offerings that drives
            interest and actions.
          </p>
        </CultivationCaption>
      </CultivationSection>

      <CultivationSection>
        <CultivationImage $imageUrl={mainUrl + 'partnership6.jpg'} />

        <CultivationCaption>
          <h1>Community Building</h1>
          <p>
            We specialize in fostering vibrant communities of Arts Addicts
            through curated arts experiences (across genre) and social
            connections while partnering with influencial individuals to reach
            and generate interest within their diverse networks.
          </p>
        </CultivationCaption>
      </CultivationSection>

      <Section>
        <Benefits>
          <h1>Partner Benefits</h1>

          <BenefitsGrid>{renderPartnerBenefits()}</BenefitsGrid>
        </Benefits>
      </Section>

      <RequestInfoCta>
        <Button onClick={() => getStartedJump()}>
          Request more information!
        </Button>
      </RequestInfoCta>

      <ImagesSection>
        <ImagesRow>
          <SingleImage
            $imageUrl={mainUrl + 'partnership8.jpg?compression=auto'}
          />
          <FourByFour>
            <FourByFourItem
              $imageUrl={mainUrl + 'partnership9.jpg?compression=auto'}
            />
            <FourByFourItem
              $imageUrl={mainUrl + 'partnership13.jpg?compression=auto'}
            />
            <FourByFourItem
              $imageUrl={mainUrl + 'partnership1smaller.jpg?compression=auto'}
            />
            <FourByFourItem
              $imageUrl={mainUrl + 'partnership4.jpg?compression=auto'}
            />
          </FourByFour>
        </ImagesRow>

        <ImagesRow $reverse={isMobile}>
          <FourByFour>
            <FourByFourItem
              $imageUrl={mainUrl + 'partnership2smaller.jpg?comperssion=auto'}
            />
            <FourByFourItem
              $imageUrl={mainUrl + 'partnership3.jpg?compression=auto'}
            />
            <FourByFourItem
              $imageUrl={mainUrl + 'partnership5.jpg?compression=auto'}
            />
            <FourByFourItem
              $imageUrl={mainUrl + 'partnership11.jpg?compression=auto'}
            />
          </FourByFour>
          <SingleImage
            $imageUrl={mainUrl + 'partnership7.jpg?compression=auto'}
          />
        </ImagesRow>
      </ImagesSection>

      <Section>
        <ArtAddicts>
          <h1>About Our "Arts Addicts"</h1>

          <ArtAddictsItems>{renderArtAddics()}</ArtAddictsItems>
        </ArtAddicts>
      </Section>

      <Section>
        {testimonialsLoading ? (
          <div
            style={{
              height: '100vh',
              margin: 'auto',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <p>Loading</p>
            <div style={{ marginLeft: '10px' }}>
              <Loader />
            </div>
          </div>
        ) : (
          <Testimonials>
            <h1>What people are saying...!</h1>
            <TestimonialsContent>
              <TestimonialsColumn>{renderTestimonials()}</TestimonialsColumn>
            </TestimonialsContent>
          </Testimonials>
        )}
      </Section>

      <a id="getstarted"></a>
      <Form onSubmit={formik.handleSubmit}>
        <FormWrapper>
          <FormImageBackground />
          <FormOverlay />
          <FormContent>
            <FormTitle>
              <h1>Let's Get Started!</h1>
              <p>
                Please complete the following information about yourself and the
                arts organization you represent.
              </p>
            </FormTitle>

            <InputRow>
              <TextInput
                error={
                  formik.touched.orgName && formik.errors.orgName
                    ? formik.errors.orgName
                    : ''
                }
                placeholder="Name of Organization"
                value={formik.values.orgName}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                name="orgName"
              />
              <TextInput
                error={
                  formik.touched.contactName && formik.errors.contactName
                    ? formik.errors.contactName
                    : ''
                }
                placeholder="Contact Name"
                value={formik.values.contactName}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                name="contactName"
              />
            </InputRow>

            <InputRow>
              <TextInput
                error={
                  formik.touched.address && formik.errors.address
                    ? formik.errors.address
                    : ''
                }
                placeholder="Address"
                value={formik.values.address}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                name="address"
              />
              <TextInput
                error={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : ''
                }
                placeholder="Contact Email"
                value={formik.values.email}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                name="email"
              />
            </InputRow>

            <InputRow>
              <TextInput
                error={
                  formik.touched.website && formik.errors.website
                    ? formik.errors.website
                    : ''
                }
                placeholder="Website"
                value={formik.values.website}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                name="website"
              />
              <TextInput
                error={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? formik.errors.phoneNumber
                    : ''
                }
                placeholder="Phone Number"
                value={formik.values.phoneNumber}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                name="phoneNumber"
              />
            </InputRow>

            <AboutWrapper>
              <p style={{ marginBottom: '10px' }}>Tell us more</p>
              <TextAreaContainer>
                <TextArea
                  rows={7}
                  placeholder="Describe your main objectives"
                  error={
                    formik.touched.about && formik.errors.about
                      ? formik.errors.about
                      : ''
                  }
                  value={formik.values.about}
                  returnValueOnChange={false}
                  onChange={(e) => formik.handleChange(e)}
                  name="about"
                />
              </TextAreaContainer>
            </AboutWrapper>

            <SubmitButton>
              <Button>Request More Information</Button>
            </SubmitButton>
          </FormContent>
        </FormWrapper>
      </Form>

      <Modal open={emailOpen} onClose={() => setEmailOpen(false)}>
        {emailModal()}
      </Modal>

      <Modal open={open} onClose={() => setOpen(false)}>
        {thankYouModal()}
      </Modal>
    </MainWrapper>
  )
}

export default NewPartner
