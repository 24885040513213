import React, { useState } from 'react'
import { Modal, TextArea, MultiPick, Button, TextInput } from 'components'
import {
  TitleWrapper,
  LogoWrapper,
  LogoPlaceholder,
  MainWrapper,
  InputRow,
  AboutWrapper,
  TextAreaContainer,
  SocialLinksWrapper,
  SocialLinksInputs,
  VibeTagsContainer,
  VibesWrapper,
  PrimaryButton,
  ButtonContainer,
  RCALogoWrapper,
  TagsSectionWrapper
} from './styled'
import { vibes, preTags } from 'constant'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const BasicInfo = (props) => {
  const { handleNext } = props

  const [tags, setTags] = useState([])
  const [disableNext, setDisableNext] = useState(true)
  const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/

  const formik = useFormik({
    initialValues: {
      orgName: '',
      address: '',
      email: '',
      phoneNumber: '',
      website: '',
      bio: '',
      facebook: '',
      instagram: '',
      youtube: '',
      twitter: ''
    },
    validationSchema: Yup.object({
      orgName: Yup.string().required('Organization name is required'),
      address: Yup.string().required('Address is required'),
      email: Yup.string().email().required('Email is required'),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, 'Invalid phone number')
        .required('Phone number is required'),
      website: Yup.string().url('Invalid URL format'),
      bio: Yup.string().required('Biography is required')
    }),
    onSubmit: (values) => {
      // Handle form submission here
      console.log(values)
    }
  })

  const handleSubmit = () => {
    formik.handleSubmit()

    const hasErrors = Object.keys(formik.errors).length > 0
    console.log(hasErrors)
    if (!hasErrors) {
      handleNext()
    }
  }

  const renderTagSection = (item, idx) => {
    const { category, tags: categoryTags } = item

    return (
      <TagsSectionWrapper key={idx}>
        <p>
          What {category.toLowerCase()} of events best relate to your
          organiation?
        </p>
        <MultiPick
          lighter
          col={4}
          options={categoryTags}
          value={tags}
          onChange={(value) => setTags(value)}
        />
      </TagsSectionWrapper>
    )
  }

  return (
    <div>
      <TitleWrapper>
        <h1>Basic Information</h1>
        <span>
          Let's get started by adding the necessary information about your
          organization.
        </span>
      </TitleWrapper>

      <LogoWrapper>
        <LogoPlaceholder>LOGO</LogoPlaceholder>
        <span>Add Profile Image</span>
      </LogoWrapper>

      <InputRow>
        <TextInput
          error={
            formik.touched.orgName && formik.errors.orgName
              ? formik.errors.orgName
              : ''
          }
          placeholder="Name of Organization"
          value={formik.values.orgName}
          returnValueOnChange={false}
          onChange={(e) => formik.handleChange(e)}
          name="orgName"
          // tooltip="test"
        />

        <TextInput
          error={
            formik.touched.address && formik.errors.address
              ? formik.errors.address
              : ''
          }
          placeholder="Address"
          value={formik.values.address}
          returnValueOnChange={false}
          onChange={(e) => formik.handleChange(e)}
          name="address"
        />
      </InputRow>

      <InputRow>
        <TextInput
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ''
          }
          placeholder="Contact Email"
          value={formik.values.email}
          returnValueOnChange={false}
          onChange={(e) => formik.handleChange(e)}
          name="email"
        />

        <TextInput
          error={
            formik.touched.phoneNumber && formik.errors.phoneNumber
              ? formik.errors.phoneNumber
              : ''
          }
          placeholder="Phone Number"
          value={formik.values.phoneNumber}
          returnValueOnChange={false}
          onChange={(e) => formik.handleChange(e)}
          name="phoneNumber"
        />
      </InputRow>

      <InputRow>
        <TextInput
          error={
            formik.touched.website && formik.errors.website
              ? formik.errors.website
              : ''
          }
          placeholder="Website"
          value={formik.values.website}
          returnValueOnChange={false}
          onChange={(e) => formik.handleChange(e)}
          name="website"
        />
      </InputRow>

      <AboutWrapper>
        <span>Abour your organization</span>
        <TextAreaContainer>
          <TextArea
            rows={7}
            placeholder="Write a bio about your organization"
            error={
              formik.touched.bio && formik.errors.bio ? formik.errors.bio : ''
            }
            value={formik.values.bio}
            returnValueOnChange={false}
            onChange={(e) => formik.handleChange(e)}
            name="bio"
          />
        </TextAreaContainer>
      </AboutWrapper>

      <SocialLinksWrapper>
        <span>Social links (Add atleast 1 social link)</span>

        <SocialLinksInputs>
          <InputRow>
            <TextInput
              error={
                formik.touched.facebook && formik.errors.facebook
                  ? formik.errors.facebook
                  : ''
              }
              placeholder="Facebook"
              value={formik.values.facebook}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="facebook"
            />

            <TextInput
              error={
                formik.touched.instagram && formik.errors.instagram
                  ? formik.errors.instagram
                  : ''
              }
              placeholder="Instagram"
              value={formik.values.instagram}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="instagram"
            />
          </InputRow>
          <InputRow>
            <TextInput
              error={
                formik.touched.youtube && formik.errors.youtube
                  ? formik.errors.youtube
                  : ''
              }
              placeholder="Youtube"
              value={formik.values.youtube}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="youtube"
            />

            <TextInput
              error={
                formik.touched.twitter && formik.errors.twitter
                  ? formik.errors.twitter
                  : ''
              }
              placeholder="Twitter"
              value={formik.values.twitter}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="twitter"
            />
          </InputRow>
        </SocialLinksInputs>
      </SocialLinksWrapper>

      <VibesWrapper>
        {/* <span>Which words best explain the vibe of your organization?</span> */}

        <VibeTagsContainer>
          {preTags
            .filter((item) => item.category !== 'Setting')
            .map(renderTagSection)}
        </VibeTagsContainer>
      </VibesWrapper>

      <ButtonContainer>
        <Button onClick={() => handleSubmit()}>Next</Button>
      </ButtonContainer>
    </div>
  )
}

export default BasicInfo
