import React, { useEffect, useMemo, useState } from 'react'
import { Form, useFormik } from 'formik'
import * as Yup from 'yup'
import {
  ContentWrapper,
  PageWrapper,
  ErrorInputRow,
  DateWrapper,
  ErrorText,
  Headers,
  TopBackButton,
  FormWrapper,
  EventTypeWrapper,
  RadioGroup,
  FormSection,
  InputRow,
  InputGroup,
  TagsMultiselectContainer,
  UploaderContainer,
  SubmitWrapper
} from './styled'
import {
  Button,
  DatePicker,
  Icon,
  RadioButton,
  TextInput,
  MultiSelect,
  Select,
  TextArea
} from 'components'
import {createEvent} from 'apis'
import Sidebar from '../../Sidebar'
import { preTags } from 'constant'
import Uploader from '../Uploader'
import GalleryUploader from '../GalleryUploader'
import { gql, useQuery } from '@apollo/client'
import {useParams, useHistory} from 'react-router-dom'
import { format } from 'date-fns'
import { useStoreon } from 'storeon/react'
import { venueAuthUser } from 'utils'
import { Loader } from 'components'

const GET_VENUE_FOR_EVENT_FORM = gql`
  query getVenueForEventForm($where: venues_bool_exp = {}) {
    venues(where: $where, limit: 1) {
      id
      major_city_id
      owner_id
    }
  }
`
const sceneTypes = [
  {label: 'Large Theater', value: 0},
  {label: 'Small Theater', value: 1},
  {label: 'Salon-Type Style Performance', value: 2},
  {label: 'At Someones home', value: 3},
  {label: 'Outdoors', value: 4},
  {label: 'Immersive Experience', value: 5},
  {label: 'Bar-Type Setting (not a sit down event)', value: 6},
  {label: 'Dinner Theater', value: 7}
]

const audienceTypes = [
  {label: 'Family Friendly for all ages', value: 0},
  {label: 'No kids under 5 years old', value: 1},
  {label: '21 years old and older only', value: 2}
]

const tagOptions = preTags.flatMap(({ tags }) =>
  tags.map(({ label, value }) => ({ label, value }))
)

const CreateEvent = () => {
  const {slug} = useParams()
  const history = useHistory()
  const { user, userData, loading: userLoading } = useStoreon('user', 'userData', 'userLoading')
  const [selectedOption, setSelectedOption] = useState('option1')
  const [dateError, setDateError] = useState(false)
  const [genreError, setGenreError] = useState(false)
  const [sceneError, setSceneError] = useState(false)
  const [audienceError, setAudienceError] = useState(false)
  const [targetAudience, setTargetAudience] = useState()
  const [uploadedImage, setUploadedImage] = useState([])
  const [galleryImages, setGalleryImages] = useState([])
  const [eventScene, setEventScene] = useState()
  const [selectedEventType, setSelectedEventType] = useState(1)
  const {loading: venueLoading, data: venueData} = useQuery(GET_VENUE_FOR_EVENT_FORM, {
    variables: {
      where: {
        slug: {
          _eq: slug
        }
      }
    }
  })
  
  const venue = venueData?.venues[0]

  const formik = useFormik({
    initialValues: {
      eventName: '',
      // eventDescription: '',
      ticketWebsite: '',
      artists: '',
      priceMin: '',
      priceMax: '',
      netflix1: '',
      netflix2: '',
      netflix3: '',
      about: '',
      startDate: null,
      endDate: null,
      scene: '',
      tags: [],
      eventDates: null,
      audience: ''
    },
    validationSchema: Yup.object({
      eventName: Yup.string().required('Event name is required'),
      artists: Yup.string().required('Artists is required'),
      // eventDescription: Yup.string().required('Event description is required'),
      tags: Yup.array().required('Array is required').min(1),
      audience: Yup.string().required('Target audience is required'),
      ticketWebsite: Yup.string().required('Ticket website is required'),
      priceMin: Yup.number().required('Minimum price is required'),
      priceMax: Yup.number().required('Max price is required'),
      // netflix1: Yup.string().test('netflix1-validation', 'At least 2 netflix shows are required', function(value) {
      //   const { netflix1, netflix2, netflix3 } = this.parent; 
      //   if ((!netflix1 && !netflix2) || (!netflix1 && !netflix3) || (!netflix2 && !netflix3)) {
      //     return false
      //   }
      //   return true
      // }),
      // netflix2: Yup.string().test('netflix2-validation', 'At least 2 netflix shows are required', function(value) {
      //   const { netflix1, netflix2, netflix3 } = this.parent; 
      //   if ((!netflix1 && !netflix2) || (!netflix1 && !netflix3) || (!netflix2 && !netflix3)) {
      //     return false
      //   }
      //   return true
      // }),
      // netflix3: Yup.string().test('netflix3-validation', 'At least 2 netflix shows are required', function(value) {
      //   const { netflix1, netflix2, netflix3 } = this.parent; 
      //   if ((!netflix1 && !netflix2) || (!netflix1 && !netflix3) || (!netflix2 && !netflix3)) {
      //     return false
      //   }
      //   return true
      // }),
      about: Yup.string().required('This part is required'),
      startDate: Yup.string().required('Event start date is required'),
      endDate: Yup.string().required('Event end date is required'),
      scene: Yup.string().required('Scene is required')
    }),
    onSubmit: async (values) => {
      // Handle form submission
      const token = await user.getIdToken()
      const netflixShows = [values.netflix1, values.netflix2, values.netflix3].filter(Boolean).join(', ');
      
      await createEvent({
        name: values.eventName,
        about: values.about,
        artists: values.artists,
        audience: values.audience,
        eventType: selectedEventType,
        ticket_link: values.ticketWebsite,
        min_price: values.priceMin,
        max_price: values.priceMax,
        netflix_shows: netflixShows,
        // somethingHere: values.eventDescription,
        venue_id: venue.id,
        major_city: venue.major_city_id,
        start_date: format(new Date(values.startDate * 1000), 'P'),
        end_date: format(new Date(values.endDate * 1000), 'P'),
        scene: values.scene,
        tags: values.tags,
        token: token,
        bgImage: uploadedImage && uploadedImage.length > 0 ? uploadedImage[0].file : undefined,
        gallery: galleryImages.map(item => item.file)
      })

      history.push(`/venue/${slug}/dashboard/events`)
    }
  })

  const loading = useMemo(() => {
    if (venueLoading) {
      return true
    } else if (!venue) {
      return true
    }
  
    if (!loading && !userLoading) {
      const authCheck = venueAuthUser(userData, venue?.owner_id);
  
      if (!authCheck) {
        history.push('/')
      }
    }
  
    return false
  }, [venueLoading, venue, userLoading])

  useEffect(() => {
    if (formik.touched.tags && formik.errors.tags) {
      setGenreError(true)
    } else {
      setGenreError(false)
    }
  },[formik.touched.tags, formik.errors.tags])

  useEffect(() => {
    if (formik.touched.scene && formik.errors.scene) {
      setSceneError(true)
    } else {
      setSceneError(false)
    }
  }, [formik.touched.scene, formik.errors.scene])

  useEffect(() => {
    if (formik.touched.audience && formik.errors.audience) {
      setAudienceError(true)
    } else {
      setAudienceError(false)
    }
  }, [formik.touched.audience, formik.errors.audience])

  useEffect(() => {
    if (formik.touched.eventDates && (formik.errors.startDate || formik.errors.endDate )) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  }, [formik.touched.eventDates, formik.errors.startDate, formik.errors.endDate, formik.values.startDate, formik.values.endDate])

  const handleStartDate = (value) => {
    // setStartDate(value)
    formik.setFieldValue('startDate', value)
  }

  const handleEndDate = (value) => {
    // setEndDate(value)
    formik.setFieldValue('endDate', value)
  }

  const handleImageUpdate = (images) => {
    setUploadedImage(images);
  }

  const handleGalleryUpdate = (image) => {
    setGalleryImages(image)
  }

  const handleChangeScene = (newScene) => {
    setEventScene(newScene.value)
    formik.setFieldValue('scene', newScene.label)
  }

  const handleAudience = (newScene) => {
    setTargetAudience(newScene.value)
    formik.setFieldValue('audience', newScene.label)
  }

  const handleOptionChange = (event) => {
    setSelectedEventType(event.target.value)
  }

  if (loading) {
    return (
      <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <p>Loading</p>
        <div style={{marginLeft: '10px'}}>
          <Loader/>
        </div>
      </div>
    )
  }

  return (
    <PageWrapper>
      <Sidebar active={0} />
      <ContentWrapper>
        <Headers>
          <TopBackButton to={`/venue/${slug}/dashboard/events`}>
            <Icon name="backIcon" />
          </TopBackButton>
          <h2>New event</h2>
        </Headers>

        <FormWrapper>
          <EventTypeWrapper>
            <h2>Is this a live performance, digital performance, or both?</h2>
            <RadioGroup>
              <RadioButton
                label="Live performance"
                value={1}
                checked={selectedEventType == 1}
                onChange={handleOptionChange}
              />
              <RadioButton
                label="Digital performance"
                value={2}
                checked={selectedEventType == 2}
                onChange={handleOptionChange}
              />
              <RadioButton
                label="Both"
                value={3}
                checked={selectedEventType == 3}
                onChange={handleOptionChange}
              />
            </RadioGroup>
          </EventTypeWrapper>
          <FormSection>
            <h2>
              What is your event called and how you describe it in 60 characters
              or less?
            </h2>
            <InputRow $col={1}>
              <TextInput
                error={
                  formik.touched.eventName && formik.errors.eventName
                    ? formik.errors.eventName
                    : ''
                }
                placeholder="Event Name"
                value={formik.values.eventName}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                name="eventName"
              />
              {/* <TextInput
                error={
                  formik.touched.eventDescription &&
                  formik.errors.eventDescription
                    ? formik.errors.eventDescription
                    : ''
                }
                placeholder="Description"
                value={formik.values.eventDescription}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                name="eventDescription"
                maxlength={60}
              /> */}
            </InputRow>
          </FormSection>

          <FormSection>
              <h2>
                Who are the artists for this performance?
              </h2>

              <InputRow $col={1}>
                <TextInput
                  error={
                    formik.touched.artists && formik.errors.artists
                      ? formik.errors.artists
                      : ''
                  }
                  placeholder="Artists"
                  value={formik.values.artists}
                  returnValueOnChange={false}
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={formik.handleBlur}
                  name="artists"
                />
              </InputRow>
          </FormSection>

          <FormSection>
            <h2>
              Which genres describe your event the best? Select as many as applicable
            </h2>

            <ErrorInputRow $col={1}>
              <TagsMultiselectContainer $error={genreError}>
                <MultiSelect
                  options={tagOptions}
                  onChange={(e) => formik.setFieldValue('tags', e)}
                  placeholder="Select tags"
                  name='tags'
                />
              </TagsMultiselectContainer>
              {genreError && (
                <ErrorText>At least one tag is required</ErrorText>
              )}
            </ErrorInputRow>
          </FormSection>

          <FormSection>
            <h2>
              Which kind of scene describes your event best?
            </h2>

            <ErrorInputRow $col={1}>
              <TagsMultiselectContainer $error={sceneError}>
                <Select
                   placeholder="Select scene"
                   options={sceneTypes}
                   value={eventScene}
                   onChange={(e) => handleChangeScene(e)}
                   name='scene'
                />
              </TagsMultiselectContainer>
              {sceneError && (
                <ErrorText>At least one tag is required</ErrorText>
              )}
            </ErrorInputRow>
          </FormSection>

          <FormSection>
            <h2>
              Who is your target audience?
            </h2>

            <ErrorInputRow $col={1}>
              <TagsMultiselectContainer $error={audienceError}>
                <Select
                   placeholder="Select target audience"
                   options={audienceTypes}
                   value={targetAudience}
                   onChange={(e) => handleAudience(e)}
                   name='audience'
                />
              </TagsMultiselectContainer>
              {audienceError && (
                <ErrorText>Target audience is required</ErrorText>
              )}
            </ErrorInputRow>
          </FormSection>

          <FormSection>
            <h2>
              When does your event start showing and when does it end?
            </h2>

            <ErrorInputRow>
              {/* <TextInput
                error={
                  formik.touched.startDate && formik.errors.startDate
                    ? formik.errors.startDate
                    : ''
                }
                placeholder="Start Date"
                value={formik.values.startDate}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                name="startDate"
              />
              <TextInput
                error={
                  formik.touched.eventDescription &&
                  formik.errors.eventDescription
                    ? formik.errors.eventDescription
                    : ''
                }
                placeholder="End Date"
                value={formik.values.endDate}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                name="endDate"
                maxlength={60}
              /> */}
              <DateWrapper $error={dateError}>
                <DatePicker
                  startDate={formik.values.startDate}
                  endDate={formik.values.endDate}
                  onStartChange={(value) => handleStartDate(value)}
                  onEndChange={(value) => handleEndDate(value)}
                  name='eventDates'
                  dateText={'Date'}
                />
                <Icon name="dropdown" />
              </DateWrapper>
              {dateError && (
                <ErrorText>Start and End dates are required</ErrorText>
              )}
            </ErrorInputRow>
          </FormSection>

          <FormSection>
            <h2>
              Where can people buy tickets and what's the price range for the
              tickets?
            </h2>

            <InputRow $col={2}>
              <TextInput
                error={
                  formik.touched.ticketWebsite && formik.errors.ticketWebsite
                    ? formik.errors.ticketWebsite
                    : ''
                }
                placeholder="Link for tickets"
                value={formik.values.ticketWebsite}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                name="ticketWebsite"
              />

              <InputGroup $col={2}>
                <TextInput
                  error={
                    formik.touched.priceMin && formik.errors.priceMin
                      ? formik.errors.priceMin
                      : ''
                  }
                  placeholder="Min. Price"
                  value={formik.values.priceMin}
                  returnValueOnChange={false}
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={formik.handleBlur}
                  name="priceMin"
                />

                <TextInput
                  error={
                    formik.touched.priceMax && formik.errors.priceMax
                      ? formik.errors.priceMax
                      : ''
                  }
                  placeholder="Max. Price"
                  value={formik.values.priceMax}
                  returnValueOnChange={false}
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={formik.handleBlur}
                  name="priceMax"
                />
              </InputGroup>
            </InputRow>
          </FormSection>

          <FormSection>
            <h2>
              Please 2-3 current Netflix shows or type of brands that correspond
              with your event
            </h2>

            <InputRow $col={3}>
              <TextInput
                // error={
                //   formik.touched.netflix1 && formik.errors.netflix1
                //     ? formik.errors.netflix1
                //     : ''
                // }
                placeholder=""
                value={formik.values.netflix1}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                name="netflix1"
              />

              <TextInput
                // error={
                //   formik.touched.netflix2 && formik.errors.netflix2
                //     ? formik.errors.netflix2
                //     : ''
                // }
                placeholder=""
                value={formik.values.netflix2}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                name="netflix2"
              />

              <TextInput
                // error={
                //   formik.touched.netflix3 && formik.errors.netflix3
                //     ? formik.errors.netflix3
                //     : ''
                // }
                placeholder=""
                value={formik.values.netflix3}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                name="netflix3"
              />
            </InputRow>
          </FormSection>

          <FormSection>
            <h2>
              If you were to tell your friends about this event, how would you
              describe it? (Layman's terms, please! And please do not just copy
              and paste what is on your website. Break it down for us and give
              us the "gist" and details that we should know)
            </h2>

            <InputRow $col={1}>
              <TextArea
                rows={3}
                placeholder=""
                error={
                  formik.touched.about && formik.errors.about
                    ? formik.errors.about
                    : ''
                }
                value={formik.values.about}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                name="about"
              />
            </InputRow>
          </FormSection>

          <FormSection>
            <h2>Upload cover photo</h2>

            <UploaderContainer>
              <Uploader handleUpdate={handleImageUpdate} uploadedImages={uploadedImage}/>
            </UploaderContainer>
          </FormSection>

          <FormSection>
            <h2>Add images to showcase the event</h2>

            <UploaderContainer>
              <GalleryUploader handleUpdate={handleGalleryUpdate} uploadedImages={galleryImages}/>
            </UploaderContainer>
          </FormSection>

          <FormSection>
            <SubmitWrapper>
              <Button type={'submit'} onClick={formik.handleSubmit}>
                Submit
              </Button>              
            </SubmitWrapper>
          </FormSection>
        </FormWrapper>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default CreateEvent
