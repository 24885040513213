import React from 'react'

const TicketsIcon = () => {
  return (
    <svg
      viewBox='0 0 512 512'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <g>
          <g>
            <circle cx='361.869' cy='412.123' r='19.975' />
            <circle cx='315.921' cy='154.435' r='19.975' />
            <path d='M491.711,312.246h19.975V192.393h-50.937c-22.029,0-39.951-17.922-39.951-39.951c0-14.948,8.255-28.532,21.544-35.453
              l17.013-8.861L408.489,0L0.782,192.304l0.042,0.089H0.314v119.853H20.29c22.029,0,39.951,17.922,39.951,39.951
              c0,22.029-17.922,39.951-39.951,39.951H0.314V512h511.371V392.147h-19.975c-22.029,0-39.951-17.922-39.951-39.951
              C451.759,330.168,469.681,312.246,491.711,312.246z M272.143,108.484c4.85,9.631,16.505,13.713,26.323,9.095
              c9.83-4.624,14.117-16.229,9.762-26.115l81.134-38.269l18.505,39.335c-16.998,14.961-27.021,36.606-27.021,59.913
              c0,14.548,3.928,28.188,10.75,39.951H94.244L272.143,108.484z M471.734,429.57v42.479h-89.889
              c0-11.032-8.943-19.975-19.975-19.975c-11.032,0-19.975,8.943-19.975,19.975H40.265V429.57
              c34.424-8.892,59.926-40.211,59.926-77.374c0-37.163-25.503-68.483-59.926-77.374v-42.479h301.629
              c0,11.032,8.943,19.975,19.975,19.975c11.032,0,19.975-8.943,19.975-19.975h89.889v42.479
              c-34.424,8.892-59.926,40.211-59.926,77.374C411.808,389.36,437.31,420.678,471.734,429.57z'
            />
            <circle cx='361.869' cy='292.27' r='19.975' />
            <circle cx='361.869' cy='352.197' r='19.975' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TicketsIcon
