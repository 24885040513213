import styled from 'styled-components'
import { colors } from 'constant'

export const PreferencesWrapper = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    margin-bottom: 20px;
  }

  > div {
    width: 100%;
  }
`

export const PreferencesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  max-width: 100%;
  padding: 12px;
  text-align: center;

  > h2 {
    font-family: Open Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
  }

  > p {
    margin-bottom: 24px;
  }

  > div {
    width: 100%;
    margin-bottom: 24px;
  }

  > form {
    width: 100%;
    margin-bottom: 24px;

    > div {
      background: ${colors.c15};
      border-radius: 10px;
    }
  }
`
