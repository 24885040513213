import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const MainWrapper = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 792px;
`

export const RCALogoWrapper = styled.div`
  position: relative;
  left: -239px;
`
