import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { colors } from 'constant'

export const ModalWrapper = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Background = styled.div`
  height: 100%;
  width: 100%;
  background: ${colors.c14};
  position: absolute;
  top: 0px;
  left: 0px;
`

const fullScreenContent = css`
  height: 100%;
  width: 100%;
  border-radius: 0px;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const transparentContent = css`
  box-shadow: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://red-curtain-addict.imgix.net/static/something-new.jpg')
      no-repeat center;
    filter: blur(18px);
    z-index: 0;
    opacity: 0.8;
  }
`

export const ContentWrapper = styled.div`
  z-index: 1;
  background: ${colors.cardBackground};
  padding: 24px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 4px ${colors.c14}, 0 -1px 0 0 ${colors.c14};
  max-height: 80vh;
  overflow: auto;
  position: relative;
  overflow: hidden;

  ${({ transparent }) => transparent && transparentContent}
  ${({ fullScreen }) => fullScreen && fullScreenContent}
`

// export const BlurredBackground = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   background: rgba(255, 255, 255, 0.2);
//   filter: blur(10px);
// `

export const MobileContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: ${colors.cardBackground};
  padding: 24px 16px;
  position: relative;
  overflow: auto;

  ${({ transparent }) => transparent && transparentContent}
`

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;

  > svg {
    height: 16px;
    width: 16px;
    color: ${colors.mainText};
  }
`
