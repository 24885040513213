import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'
import { Popover } from '@headlessui/react'
import { Button } from 'components'

const { lg, md, sm } = breakpoints

export const PopoverContainer = styled(Popover)`
  position: relative;
  width: 100%;
`

export const PopoverButton = styled(Popover.Button)`
  background: none;
  border: none;
  cursor: pointer;
  background-color: ${colors.c18};
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: 24px;
    width: 24px;
    color: ${colors.c3};
  }
`

export const PopoverContent = styled(Popover.Panel)`
  position: absolute;
  z-index: 2;
  top: calc(100% + 8px);
  background: ${colors.c23};
  border-radius: 16px;
  right: 80px;
  width: 180px;
  max-width: calc(100vw - 36px);
  padding: 10px;
  display: flex;
  flex-direction: column;

  > a {
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
  }

  ${md} {
    right: 0px;

    > a {
      font-size: 13px;
    }
  }
`

export const PopoverCloser = styled(Popover.Button)`
  background-color: transparent;
  border: none;
  padding: unset;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 12px;
    color: ${colors.c4};
    justify-content: left;
    height: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    padding-left: 10px;

    &:hover {
      background: ${colors.c16};
      color: ${colors.c3};
    }
  }
`

export const LabelWrapper = styled(Popover.Button)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  cursor: pointer;
  background: transparent;
  box-shadow: none;
  border: none;
  color: ${colors.mainText};
  padding-left: 0;
  padding-right: 0;
  justify-content: center;

  > svg {
    height: 16px;
    width: 16px;
    margin-left: 20px;
  }
  ${md} {
    font-size: 12px;

    > svg {
      height: 14px;
      width: 14px;
      margin-left: 8px;
    }
  }

  ${sm} {
    font-size: 11px;

    > svg {
      height: 12px;
      width: 12px;
      margin-left: 8px;
    }
  }
`
export const MainWrapper = styled.div`
  width: 100%;
  display: flex;

  border-radius: 30px;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      background-color: ${colors.c15};
    `}

  ${md} {
    width: calc(100% / 3);
  }
`
