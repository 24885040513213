import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const MainWrapper = styled.div`
  margin-top: 12px;
`

export const BlankGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;
  transform: translateY(-53px);
`

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;
`

export const BlankGridItem = styled.div`
  display: flex;
  /* width: calc((100% / 4) - 4px); */
  justify-content: center;
  align-items: center;
  border: 2px solid ${colors.c9};
  border-radius: 16px;
  height: 180px;
  width: 180px;
`

export const GalleryGridItem = styled.div`
  display: flex;
  /* width: calc((100% / 4) - 4px); */
  justify-content: center;
  align-items: center;
  border: 2px solid ${colors.c9};
  height: 180px;
  width: 180px;
  background-image: url('${({ $imageUrl }) => $imageUrl}');
  border-radius: 16px;
  background-size: cover;
  background-position: center;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(61px);
  position: relative;
  z-index: 999;
`

export const UploadButton = styled.button`
  display: flex;
  align-items: center;
  color: white;
  background: ${colors.c10};
  border: none;
  height: 50px;
  justify-content: center;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;
  width: 160px;

  > svg {
    margin-right: 8px;
    height: 18px;
    width: 18px;
  }
`

export const AddMoreButon = styled.button`
  display: flex;
  align-items: center;
  color: white;
  background: ${colors.c10};
  border: none;
  height: 50px;
  justify-content: center;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;
  width: 160px;

  > svg {
    margin-right: 8px;
    height: 18px;
    width: 18px;
  }
`

export const AddMoreGrid = styled.div`
  display: flex;
  /* width: calc((100% / 4) - 4px); */
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 16px;
  height: 180px;
  width: 180px;
`

export const Editbutton = styled.button`
  display: flex;
  align-items: center;
  color: white;
  background: ${colors.c12};
  border: none;
  height: 50px;
  justify-content: center;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;
  width: 100px;

  > svg {
    margin-right: 8px;
    height: 18px;
    width: 18px;
  }
`
