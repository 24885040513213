import styled, { css } from 'styled-components'
import { colors } from 'constant'

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${colors.mainText};

  > label {
    font-size: 14px;
    margin-bottom: 4px;
  }

  > button {
    background: none;
    border: none;
    padding: 10px 16px;
    height: 48px;
    color: ${colors.secondaryText};
    font-size: 16px;
    border-radius: 16px;
    background: ${colors.c1};
    text-align: left;
    cursor: pointer;

    &::placeholder {
      color: ${colors.secondaryText};
    }
  }

  > ul {
    position: absolute;
    width: 100%;
    top: calc(100% + 8px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px 0px;
    border-radius: 16px;
    z-index: 1;
    background: ${colors.c6};
    list-style-type: none;
    padding: 0px;
  }
`

const activeCss = css`
  background: ${colors.c14};
  color: ${colors.mainText};
`

export const ListItem = styled.div`
  padding: 12px 12px;
  cursor: pointer;

  &:hover {
    ${activeCss}
  }

  ${({ active }) => active && activeCss}
  ${({ selected }) => selected && 'display: none;'}
`
