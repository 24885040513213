import React, { useState, useMemo, useCallback, useEffect } from 'react'
import {
  EventSearchBarWrapper,
  EventSearchRight,
  LocationWrapper,
  MaxPrice,
  SearchButton,
  LocationLabelWrapper,
  SelectedCityWrapper,
  EventSearchRightWrapper,
  SearchOptinWrapper
} from './styled'
import { useHistory } from 'react-router-dom'
import {
  DatePicker,
  Dropdown,
  Icon,
  Modal,
  TagFilter,
  SearchOptin
} from 'components'
import { getQuery, updateQuery, track, isFirstTimeVisitor } from 'utils'
import { useStoreon } from 'storeon/react'
import { majorCities } from 'constant'

const EventSearchBar = (props) => {
  const { reroute } = props
  const history = useHistory()
  const {
    s = '',
    l = '',
    p = '',
    sd = null,
    ed = null,
    t = '',
    q = ''
  } = getQuery(history)
  const [search, setSearch] = useState({ s, l, p, sd, ed, t, q })
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const { isMobile, showSearchModal, showSearchOptin, dispatch, user } =
    useStoreon('isMobile', 'showSearchModal', 'showSearchOptin', 'user')

  const handleStateChange = (key, value) => {
    setSearch((prev) => ({ ...prev, [key]: value }))
  }

  const locationDropdown = useMemo(() => {
    const dropdownMenu = majorCities.map((loc) => ({
      label: loc.label,
      onClick: () => handleStateChange('l', loc.value)
    }))

    if (search.l) {
      dropdownMenu.unshift({
        label: 'Anywhere!',
        onClick: () => handleStateChange('l', '')
      })
    }

    const selectedCity = search.l
      ? majorCities.find((mc) => mc.value === search.l)?.label
      : null

    return (
      <Dropdown menu={dropdownMenu} extraText>
        <LocationWrapper>
          {selectedCity ? (
            <SelectedCityWrapper>{selectedCity}</SelectedCityWrapper>
          ) : (
            <LocationLabelWrapper>
              <span>Location</span> <Icon name="dropdown" />
            </LocationLabelWrapper>
          )}
        </LocationWrapper>
      </Dropdown>
    )
  }, [search.l])

  const maxPriceInput = useMemo(() => {
    return (
      <MaxPrice>
        <input
          type="number"
          value={search.p}
          onChange={(e) => handleStateChange('p', e.target.value)}
          placeholder="Max Price"
        />
      </MaxPrice>
    )
  }, [search.p])

  const handleSearchInput = useCallback(
    (value) => {
      const isFirstTime = isFirstTimeVisitor()

      handleStateChange('q', value)

      if (!user && isFirstTime) {
        dispatch('setShowSearchOptin', true)
      }
    },
    [search]
  )

  const handleSearch = useCallback(() => {
    const isFirstTime = isFirstTimeVisitor()

    if (!user && isFirstTime) {
      dispatch('setShowSearchOptin', true)
    } else {
      track('Search Events', {
        keyword: search.t,
        price: search.p,
        date: [search.sd, search.ed],
        location: search.l
      })

      updateQuery({
        history,
        newParams: search,
        route: reroute ? '/events' : undefined
      })

      if (isMobile) {
        setSearchModalOpen(false)
      }
    }
  }, [history, reroute, search, isMobile])

  const handleClear = useCallback(() => {
    setSearch((prev) => ({
      ...prev,
      p: '',
      sd: null,
      ed: null,
      l: ''
    }))
  }, [])

  const searchRight = useMemo(() => {
    // if (isMobile) {
    //   return (
    //     <FilterButton onClick={() => setSearchModalOpen(true)}>
    //       <Icon name="filter" />
    //     </FilterButton>
    //   )
    // }

    return (
      <EventSearchRight>
        {locationDropdown}
        <DatePicker
          startDate={search.sd}
          endDate={search.ed}
          onStartChange={(value) => handleStateChange('sd', value)}
          onEndChange={(value) => handleStateChange('ed', value)}
          dateText={'Date'}
        />
        <TagFilter
          selected={search.t.split(',')}
          onChange={(value) => value && handleStateChange('t', value.join(','))}
        />
        {/* {maxPriceInput} */}
      </EventSearchRight>
    )
  }, [isMobile, maxPriceInput, locationDropdown, search])

  // const showOptin = useMemo(() => true, [])
  // const [showOptin, setShowOptin] = useState(false)

  const optinModal = useMemo(() => {
    return (
      <Modal
        open={showSearchOptin}
        onClose={() => dispatch('setShowSearchOptin', false)}
        fullScreen={isMobile}
      >
        <SearchOptinWrapper>
          <SearchOptin onCancel={() => dispatch('setShowSearchOptin', false)} />
        </SearchOptinWrapper>
      </Modal>
    )
  }, [showSearchOptin])

  return (
    <EventSearchBarWrapper>
      {/* {!isMobile && (
        <PreTagSelector
          onSearch={handleSearch}
          // tags={search.t.split(',').filter((item) => !!item)}
          onChange={(value) => value && handleSearchInput(value)}
          searchValue={search.q}
          searchText={props.searchText}
        />
      )} */}
      <EventSearchRightWrapper>
        {searchRight}
        <SearchButton onClick={handleSearch}>
          {isMobile ? 'Go' : <Icon name="funnel" />}
        </SearchButton>
      </EventSearchRightWrapper>
      {optinModal}
    </EventSearchBarWrapper>
  )
}

export default EventSearchBar
