import React, { useState } from 'react'
import { NewsletterWrapper, NewsLetterTop, InfoSection, InfoWrapper, Button, EmailInput } from './styled'
import { Icon } from 'components'

const Newsletter = () => {
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
  }

  return (
    <NewsletterWrapper>
      <div>
        <NewsLetterTop onSubmit={handleSubmit}>
          <div>
            <h1>Arts in your inbox</h1>
            <p>Red Curtain Addict is one of the fastest-growing communities of arts enthusiasts looking to attend the best performing arts experiences around them. Join our community to be the first to know about must-see performances in your city, Behind the Curtain interviews, insiders stories, and more!</p>
            <EmailInput
              name='email'
              placeholder='Add your email'
            />
            <Button type='submit' onClick={handleSubmit} value={submitted ? 'Added!' : 'Sign up'} />
          </div>
          <div />
        </NewsLetterTop>
        <InfoSection>
          <InfoWrapper>
            <Icon name='search'/>
            <h3>Discover</h3>
            <p>Explore the arts scene in your city with ease by accessing curated lists of performances that we know you're going to love! With hundreds of options to choose from, it can be overwhelming. But not anymore! We're here to help tailor your experience so you can focus on the fun.</p>
          </InfoWrapper>
          <InfoWrapper>
            <Icon name='news'/>
            <h3>Learn</h3>
            <p>Be in the know! Access exclusive stories and insider interviews about the arts and artists performing in your city. You'll never have to go to a performance again without the insight you need to make the most out of your experience!</p>
          </InfoWrapper>
          <InfoWrapper>
            <Icon name='tickets'/>
            <h3>Go!</h3>
            <p>Who has the time to look across hundreds of websites to figure out what performance to go to? Here at Red Curtain Addict, our goal is to make going to the arts simple by listing the best performances happening across performing arts genres (theater, jazz, dance, opera, and classical music) in one place.</p>
          </InfoWrapper>
        </InfoSection>
      </div>
    </NewsletterWrapper>
  )
}

export default Newsletter
