import React from 'react'
import { NotFoundWrapper } from './styled'

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <div />
      <h1>Page not Found</h1>
      <p>Looks like you're trying to access a page that doesn't exist. Maybe you took a wrong turn, maybe something's coming soon.</p>
    </NotFoundWrapper>
  )
}

export default NotFound
