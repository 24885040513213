import React, { useState } from 'react'
import {
    CarouselWrapper,
    DotWrapper,
    CarouselDot
} from './styled'
import { CtaBanner } from 'components'
import { Carousel } from 'react-responsive-carousel'

const CtaMultiBanner = props => {
    const { spotlights } = props
    const [currentSlide, setCurrentSlide] = useState(0) 

    const updateCurrentSlide = idx => {
        if (idx !== currentSlide) {
            setCurrentSlide(idx)
        }
    }

    return (
        <CarouselWrapper>
            <Carousel
                autoPlay={true}
                interval={5000}
                infiniteLoop={true}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                selectedItem={currentSlide}
                onChange={updateCurrentSlide}
            >
                {spotlights.map(spot => (
                    <>
                        {spot.is_outside_link ? (
                            <CtaBanner
                                image={`https:///rca-s3.imgix.net/ArtistSppotlight/${spot.image}?compression=auto&height=400&width=800&fit=crop`}
                                title={spot.title}
                                description={spot.description}
                                ctaLabel={spot.button}
                                href={spot.link}
                                target='_blank'
                            />              
                        ) : (
                            <CtaBanner
                                image={`https:///rca-s3.imgix.net/ArtistSppotlight/${spot.image}?compression=auto&height=400&width=800&fit=crop`}
                                title={spot.title}
                                description={spot.description}
                                ctaLabel={spot.button}
                                link={spot.link}
                            /> 
                        )}
                    </>
                ))}
            </Carousel>
            <DotWrapper>
                {spotlights.map((spot, index) => (
                    <CarouselDot key={index} $isSelected={currentSlide === index} onClick={() => updateCurrentSlide(index)} />
                ))}
            </DotWrapper>
        </CarouselWrapper>
    )

}

export default CtaMultiBanner