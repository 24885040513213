import React from 'react'

const QuestionMarkIcon = () => {
  return (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        zoomAndPan="magnify" 
        viewBox="0 0 810 809.999993" 
        version="1.0"
    >
        <defs>
            <clipPath id="2454258048">
                <path d="M 40.5 40.5 L 769.5 40.5 L 769.5 769.5 L 40.5 769.5 Z M 40.5 40.5 " clip-rule="nonzero"/>
            </clipPath>
        </defs>
        <g clip-path="url(#2454258048)">
            <path fill="#ffffff" d="M 405 40.5 C 204.074219 40.5 40.5 204.074219 40.5 405 C 40.5 605.925781 204.074219 769.5 405 769.5 C 605.925781 769.5 769.5 605.925781 769.5 405 C 769.5 204.074219 605.925781 40.5 405 40.5 Z M 402.289062 638.761719 C 378.1875 638.761719 358.609375 619.179688 358.609375 595.082031 C 358.609375 570.984375 378.1875 551.402344 402.289062 551.402344 C 426.386719 551.402344 445.96875 570.984375 445.96875 595.082031 C 445.96875 619.179688 426.386719 638.761719 402.289062 638.761719 Z M 470.066406 433.316406 C 455.910156 441.75 446.871094 458.019531 446.871094 476.394531 C 446.871094 500.492188 427.292969 520.074219 403.191406 520.074219 C 379.09375 520.074219 359.511719 500.492188 359.511719 476.394531 C 359.511719 427.292969 384.816406 381.804688 425.785156 358.007812 C 438.132812 350.476562 451.089844 333.90625 446.871094 310.714844 C 443.859375 293.539062 429.402344 279.082031 412.226562 275.769531 C 385.71875 270.648438 366.140625 288.121094 360.417969 306.796875 C 353.789062 329.992188 329.386719 343.546875 306.191406 336.617188 C 283 329.992188 269.742188 305.589844 276.671875 282.398438 C 295.648438 217.027344 361.019531 177.261719 428.496094 189.914062 C 481.214844 199.855469 523.386719 242.03125 533.027344 295.046875 C 542.96875 350.476562 518.265625 405 470.066406 433.316406 Z M 470.066406 433.316406 " fill-opacity="1" fill-rule="nonzero"/>
        </g>
    </svg>
  )
}

export default QuestionMarkIcon
