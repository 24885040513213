import styled from 'styled-components'
import { maxDesktopWidth, breakpoints } from 'constant'

const { md } = breakpoints

export const NewsletterWrapper = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${md} {
    padding-top: 0px;
  }

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
  }
`

export const Button = styled.input`
  align-self: flex-end;
  background: linear-gradient(98.1deg, #C22026 -5.05%, #C400CB 101.49%);
  backdrop-filter: blur(16px);
  border-radius: 100px;
  color: white;
  border: none;
  font-weight: 600;
  font-size: 16px;
  padding: 16px 40px;
  font-family: 'Open Sans';
  text-align: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`

export const EmailInput = styled.input`
  background: #1E1F22;
  border-radius: 100px;
  border: none;
  padding: 16px 20px;
  width: 100%;
  margin-bottom: 16px;
  color: white;
  font-size: 16px;
`

export const NewsLetterTop = styled.form`
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  > div {
    &:first-child {
      flex: 1;
      padding-right: 40px;
      display: flex;
      flex-direction: column;

      > h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 64px;
        line-height: 64px;
        margin-bottom: 20px;
      }

      > p {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 16px;
      }

      ${md} {
        padding: 16px;

        > h1 {
          font-size: 40px;
          line-height: 48px;
        }

        > p {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    &:last-child {
      background-image: url('https://rca-s3.imgix.net/arts.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 300px;
      width: 500px;

      ${md} {
        display: none;
      }
    }
  }
`

export const InfoSection = styled.div`
  display: flex;

  ${md} {
    flex-wrap: wrap;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  > svg {
    height: 24px;
    width: 24px;
    margin-bottom: 16px;
  }

  > h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
`
