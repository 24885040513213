import { css } from 'styled-components'

export { default as colors } from './colors'
export { default as breakpoints } from './breakpoints'
export { default as terms } from './terms'
export { default as privacy } from './privacy'

export const navbarHeight = '64px'
export const headerHeight = '72px'
export const maxDesktopWidth = '1200px'
export const mobileBreakpoint = 768

export const preTags = [
  {
    category: 'Genre',
    tags: [
      { label: 'Jazz', value: '29' },
      { label: 'Opera', value: '31' },
      { label: 'Symphonic', value: '355' },
      { label: 'Theater', value: '1' },
      { label: 'Dance', value: '27' },
      { label: 'Musical', value: '504' },
      { label: 'Blues', value: '329' }
    ]
  },
  {
    category: 'Type',
    tags: [
      {
        label: 'Concert',
        value: '43',
        thumbnailUrl:
          'https://red-curtain-addict.imgix.net/preferences/Concerts.png?height=250&auto=compression'
      },
      {
        label: 'Festival',
        value: '330',
        thumbnailUrl:
          'https://red-curtain-addict.imgix.net/preferences/Festivals.png?height=250&auto=compression'
      },
      {
        label: 'Gala',
        value: '21',
        thumbnailUrl:
          'https://red-curtain-addict.imgix.net/preferences/Galas.jpg?height=250&auto=compression'
      },
      {
        label: 'Premiere',
        value: '587',
        thumbnailUrl:
          'https://red-curtain-addict.imgix.net/preferences/Premieres.png?height=250&auto=compression'
      },
      {
        label: 'Chamber',
        value: '663',
        thumbnailUrl:
          'https://red-curtain-addict.imgix.net/preferences/ChamberEnsembles.png?height=250&auto=compression'
      },
      {
        label: 'Cabaret',
        value: '551',
        thumbnailUrl:
          'https://red-curtain-addict.imgix.net/preferences/Cabarets.png?height=250&auto=compression'
      }
    ]
  },
  {
    category: 'Category',
    tags: [
      { label: 'Comedy', value: '522' },
      { label: 'Drama', value: '521' },
      { label: 'Romance', value: '719' },
      { label: 'Thriller', value: '720' },
      { label: 'Historical', value: '721' },
      { label: 'Holiday', value: '84' },
      { label: 'Family Friendly', value: '716' },
      { label: 'LGBTQ+', value: '717' }
    ]
  },
  {
    category: 'Setting',
    tags: [
      {
        label: 'Streaming',
        value: '722',
        thumbnailUrl:
          'https://red-curtain-addict.imgix.net/preferences/Streaming.jpg?height=250&auto=compression'
      },
      {
        label: 'In-Person',
        value: '723',
        thumbnailUrl:
          'https://red-curtain-addict.imgix.net/preferences/InPerson.jpg?height=250&auto=compression'
      }
    ]
  }
]

export const socialLinks = [
  { to: 'https://www.facebook.com/RedCurtainAddict', svg: 'facebook' },
  { to: 'https://www.instagram.com/redcurtainaddict', svg: 'instagram' },
  {
    to: 'https://www.youtube.com/channel/UCNi5rXYfCsHQMjE62LX3NNQ/videos',
    svg: 'youtube'
  }
]

export const menu = [{ to: '/arts-application', title: 'Become a Partner' }]

export const maxDesktopCss = css`
  width: 100%;
  max-width: ${maxDesktopWidth};
  padding: 0px 24px;
`

export const majorCities = [
  {
    label: 'San Francisco, CA',
    value: '0',
    thumbnailUrl:
      'https://red-curtain-addict.imgix.net/preferences/SanFranciscoCA.png?height=250&auto=compression'
  },
  {
    label: 'New York City, NY',
    value: '1',
    thumbnailUrl:
      'https://red-curtain-addict.imgix.net/preferences/NewYorkNY.png?height=250&auto=compression'
  },
  {
    label: 'Los Angeles, CA',
    value: '2',
    thumbnailUrl:
      'https://red-curtain-addict.imgix.net/preferences/LosAngelesCA.png?height=250&auto=compression'
  },
  {
    label: 'Chicago, IL',
    value: '3',
    thumbnailUrl:
      'https://red-curtain-addict.imgix.net/preferences/ChicagoIL.png?height=250&auto=compression'
  },
  // { label: 'Miami', value: '4' },
  {
    label: 'Washington, DC',
    value: '5',
    thumbnailUrl:
      'https://red-curtain-addict.imgix.net/preferences/WashingtonDC.png?height=250&auto=compression'
  },
  {
    label: 'London, UK',
    value: '6',
    thumbnailUrl:
      'https://red-curtain-addict.imgix.net/preferences/LondonUK.png?height=250&auto=compression'
  },
  // { label: 'Seattle', value: '7' },
  // { label: 'Detroit', value: '8' },
  // { label: 'Atlanta', value: '9' },
  // { label: 'Dallas', value: '10' },
  // { label: 'Denver', value: '11' },
  {
    label: 'Salt Lake City, UT',
    value: '12',
    thumbnailUrl:
      'https://red-curtain-addict.imgix.net/preferences/SaltLakeCityUT.png?height=250&auto=compression'
  },
  {
    label: 'Tulsa, OK',
    value: '13',
    thumbnailUrl:
      'https://red-curtain-addict.imgix.net/preferences/TulsaOK.png?height=250&auto=compression'
  }
]

export const vibes = [
  { label: 'Chill', value: '1' },
  { label: 'Funny', value: '2' },
  { label: 'Upbeat', value: '3' },
  { label: 'Playful', value: '4' },
  { label: 'Witty', value: '5' },
  { label: 'Serious', value: '6' },
  { label: 'Friendly', value: '7' },
  { label: 'Loud', value: '8' },
  { label: 'Funky', value: '9' },
  { label: 'Groovy', value: '10' },
  { label: 'Mellow', value: '11' },
  { label: 'Baller', value: '12' }
]

export const majorCitiesIdx = [
  'San Francisco',
  'New York City',
  'Los Angeles',
  'Chicago',
  'Miami',
  'Washington D.C.',
  'London',
  'Seattle',
  'Detroit',
  'Atlanta',
  'Dallas',
  'Denver',
  'Salt Lake City',
  'Tulsa'
]

export const topics = [
  { label: 'choirs', value: '604' },
  { label: 'jazz', value: '29' },
  { label: 'opera', value: '31' },
  { label: 'symphonic', value: '355' },
  { label: 'theater', value: '1' },
  { label: 'dance', value: '27' }
]
