import styled from 'styled-components'
import { colors } from 'constant'

export const LoginWrapper = styled.div`
  max-width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;

  ${({ isMobile }) => isMobile && 'height: 100vh;'}

  > p {
    margin-bottom: 24px;
  }
`

export const Header = styled.h1`
  margin-bottom: 4px;
  font-size: 40px;
  line-height: 60px;

  ${({ reason }) => reason && 'margin-bottom: 8px;'}
`

export const LoginForm = styled.form`
  width: 100%;

  > * {
    margin-bottom: 16px;
  }
`

export const Links = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > a {
    text-decoration: none;
    color: ${colors.mainText};
    font-weight: 600;
    font-size: 14px;

    > span {
      margin-left: 4px;
      text-decoration: underline;
      cursor: pointer;
    }

    &:first-child {
      margin-bottom: 8px;
    }
  }
`

export const ForgotPassword = styled.form`
  padding: 12px 24px;
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  > h1 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  > p {
    margin-bottom: 16px;
  }

  > button {
    margin-top: 16px;
  }

  > a {
    align-self: center;
    margin-top: 16px;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }

  ${({ isMobile }) => isMobile && `
    padding: 24px 12px;
    
    > h1 {
      font-size: 20px;
    }

  `}
`

export const RedirectReason = styled.div`
  margin-bottom: 16px;
  text-align: center;
  font-weight: 600;
`
