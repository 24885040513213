import React from 'react'

const PerformanceIcon = () => {
  return (
    <svg
      viewBox='0 0 512 512'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g><path d="m197.5 81.273v152.453l129.584-76.226zm30 52.454 40.415 23.773-40.415 23.773z"/><path d="m0 0v315h510v-315c-53.458 0-458.543 0-510 0zm60 285h-30v-255h30zm360 0h-330v-255h330zm60 0h-30v-255h30z"/><path d="m480 390c0-24.814-20.187-45-45-45s-45 20.186-45 45v15h-30v-15c0-24.814-20.187-45-45-45s-45 20.186-45 45v15h-30v-15c0-24.814-20.187-45-45-45s-45 20.186-45 45v15h-30v-15c0-24.814-20.187-45-45-45s-45 20.186-45 45v15h-30v105h510v-105h-30zm-420 0c0-8.272 6.729-15 15-15s15 6.728 15 15v15h-30zm60 90h-90v-45h90zm60-90c0-8.272 6.729-15 15-15s15 6.728 15 15v15h-30zm60 90h-90v-45h90zm60-90c0-8.272 6.729-15 15-15s15 6.728 15 15v15h-30zm60 90h-90v-45h90zm60-90c0-8.272 6.729-15 15-15s15 6.728 15 15v15h-30zm60 90h-90v-45h90z"/></g>
    </svg>
  )
}

export default PerformanceIcon
