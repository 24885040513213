import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'
import { Popover } from '@headlessui/react'
import { Link } from 'react-router-dom'

const { md } = breakpoints

const errorBorder = css`
  border: 1px solid ${colors.c21};
`

export const PageWrapper = styled.div`
  display: flex;
`
export const ContentWrapper = styled.div`
  width: 100%;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-top: 80px; */
`

export const Headers = styled.div`
  width: 90%;
  position: relative;
  z-index: 20;
  padding-top: 20px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  > h2 {
    font-size: 20px;
    margin-left: 12px;
  }

  > button {
    width: 140px;
    font-weight: normal;
  }
`

export const TopBackButton = styled(Link)`
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.c17};
  border: none;
  cursor: pointer;
  height: 45px;
  width: 45px;
  border-radius: 50%;

  > svg {
    height: 20px;
    width: 20px;
    color: ${colors.mainText};
  }
`

export const FormWrapper = styled.div`
  width: 100%;
  background-color: ${colors.c17};
  margin-top: 25px;
  border-radius: 16px;
  /* padding: 30px; */
  min-height: 700px;
`

export const EventTypeWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.c6};
  padding: 24px;

  > h2 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
  }
`

export const RadioGroup = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 32px;
`

export const FormSection = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.c6};
  padding: 33px 24px 24px 24px;

  > h2 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  > button {
    width: 25%;
    text-align: right;
  }
`

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > button {
    width: 25%;
    text-align: right;
  }
`

const twoCol = css`
  flex: 0 0 calc(50% - 16px);
`

const oneCol = css`
  flex: 0 0 100%;
`

const threeCol = css`
  flex: 0 0 calc(33% - 16px);
`

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;

  > div {
    ${({ $col }) =>
      $col == 1 ? oneCol : $col == 2 ? twoCol : $col == 3 ? threeCol : twoCol}
  }
`

export const TagsMultiselectContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
  border-radius: 8px;
  
  ${({$error}) => ($error ? errorBorder : null)}
  
  >div {
    > button {
      background: ${colors.c18};
    }
  }
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;

  > div {
    ${({ $col }) => ($col == 1 ? oneCol : twoCol)}
  }
`
export const UploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > button {
    width: 400px;
  }
`

export const ErrorInputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: space-between;

  > div {
    margin-bottom: 0px;

    ${({ $col }) =>
      $col == 1 ? oneCol : $col == 2 ? twoCol : $col == 3 ? threeCol : twoCol}
  }
`


export const DateWrapper = styled.div`
  border-radius: 8px;
  background: ${colors.c18};
  min-height: 56px;
  display: flex;
  align-items: center;

  ${({$error}) => ($error ? errorBorder : null)}

  > svg {
    height: 15px;
    width: 15px;
    color: ${colors.c3};
    margin-right: 20px;
  }

  > div {
    width: 100%;

    > div {
      > div {
        > svg {
          display: none;
        }
      }
    }
  }
`

export const ErrorText = styled.div`
  color: ${colors.c21};
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
`