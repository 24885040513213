import React from 'react'

const DropdownIcon = () => {
  return (
    <svg
      viewBox='0 0 240.811 240.811'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
          c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
          c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"/>
      </g>
    </svg>
  )
}

export default DropdownIcon
