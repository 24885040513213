import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FormWrapper = styled.div`
  width: 384px;
  margin-top: 100px;
`

export const TopButton = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 64px;

  > button {
    width: 130px;
    font-size: 16px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: 40px;
    font-weight: 700;
  }

  > span {
    text-align: center;
  }
`

export const PromotionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  border-top: 1px solid ${colors.c18};
  border-bottom: 1px solid ${colors.c18};
`

export const PromotionItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  &:first-child {
    margin-top: 24px;
  }
`

export const PromoIcon = styled.div`
  height: 48px;
  width: 48px;
  padding: 12px;
  border-radius: 50%;
  background-color: ${colors.c17};

  > svg {
    width: 24px;
    height: 24px;
  }
`

export const PromoLabel = styled.div`
  flex: 4;
  margin-left: 16px;
  display: flex;
  align-items: center;
`

export const CTAWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;

  > button {
    width: 180px;
  }
`
