import React from 'react'

const DrinksIcon = () => {
  return (
    <svg
      viewBox='0 0 512 512'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g><path d="m421 0c-49.55 0-90 41.067-90 91h-188.786l-86.607-86.606c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l65.394 65.393h-84.787c-6.073 0-11.547 3.663-13.865 9.276-2.317 5.614-1.021 12.071 3.284 16.355l206.582 205.603v100.956c-26.772 4.386-42.553 20.15-55.606 33.203-14.308 14.307-25.609 25.607-49.395 25.607-8.284 0-15 6.716-15 15s6.716 15 15 15h240c8.284 0 15-6.716 15-15s-6.716-15-15-15c-23.787 0-35.087-11.3-49.394-25.606-13.053-13.053-28.834-28.817-55.606-33.203v-100.978c15.804-15.804 129.951-129.952 147.339-147.34 10.362 4.026 21.469 6.127 32.66 6.127 49.922 0 91-40.431 91-90 0-49.677-41.17-91-91-91zm-21.213 121c-10.946 10.946-48.167 48.167-60 60h-71.367c-6.192-17.458-22.865-30-42.42-30-6.939 0-13.514 1.581-19.389 4.398l-34.397-34.398zm-173.811 173.812-84.212-83.812h41.817c6.192 17.458 22.865 30 42.42 30s36.228-12.542 42.42-30h41.367zm.025-113.812c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15zm-96.213-60 55.611 55.611c-.682 1.423-1.285 2.889-1.817 4.389h-71.959l-60.286-60zm150.029 361h-107.633c17.294-16.951 27.911-30 53.817-30 25.793 0 36.476 13.004 53.816 30zm141.183-331c-2.755 0-5.5-.197-8.216-.571l33.822-33.822c4.29-4.29 5.573-10.742 3.252-16.347-2.322-5.605-7.79-9.26-13.858-9.26h-75c0-33.636 26.916-61 60-61 33.065 0 61 27.935 61 61 0 33.084-27.364 60-61 60z"/></g>
    </svg>
  )
}

export default DrinksIcon
