import React, { useEffect, useState, useMemo } from 'react'
import {
  ContentWrapper,
  PageWrapper,
  Headers,
  TableWrapper,
  NoEventsContainer,
  NoEventsIcon,
  EventsTableContainer,
  EventsTable,
  Th,
  Td,
  ActionCells,
  PopoverContainer,
  PopoverButton,
  PopoverContent,
  PopoverCloser,
  PopoverActions,
  SponsoredEventContainer,
  SponsoredEventHeader,
  CheckIcon
} from './styled'
import { Button, Icon, Loader } from 'components'
import Sidebar from '../Sidebar'
import { useParams, useHistory } from 'react-router-dom'
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'
import {deleteEvent} from 'apis'
import toast from 'react-hot-toast'
import { useStoreon } from 'storeon/react'
import { venueAuthUser } from 'utils'

const GET_VENUE_ID = gql`
  query getVenueId($where: venues_bool_exp = {}) {
    venues(where: $where, limit: 1) {
      id
      featured_event_id
      owner_id
      partner_plan {
        can_sponsor_events
      }
    }
  }
`

const GET_VENUE_EVENTS = gql`
  query getVenueEvents($where: events_bool_exp = {}) {
    events(where: $where, order_by: { start_date: asc }) {
      name
      slug
      id
      is_digital
      start_date
    }
  }
`

const GET_SPONSORED_EVENT = gql`
  query getSponsoredEvent($where: events_bool_exp = {}) {
    events(where: $where, limit: 1) {
      name
      id
      is_digital
      start_date
    }
  }
`

const SET_SPONSORED_EVENT = gql`
  mutation setNewSponsoredEvent($venueId: Int!, $sponsoredId: Int! ) {
    update_venues_by_pk(pk_columns: {id: $venueId}, _set: {featured_event_id: $sponsoredId}) {
      featured_event_id
    }
  }
`

const VenueEvents = () => {
  const history = useHistory()
  const {slug} = useParams()
  const { user, userData, loading: userLoading } = useStoreon('user', 'userData', 'userLoading')
  const [loadVenue, {loading: venueLoading, data: venueData}] = useLazyQuery(GET_VENUE_ID)
  const [setNewSponsoredEvent] = useMutation(SET_SPONSORED_EVENT)
  const[loadEvents, {loading: eventLoading, data: eventData}] = useLazyQuery(GET_VENUE_EVENTS)
  const [loadSponsor, {loading: sponsoredLoading, data: sponsoredData}] = useLazyQuery(GET_SPONSORED_EVENT)

  useEffect(() => {
    loadVenue({
      variables: {
        where: {
          slug: {
            _eq: slug
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    if (venueData?.venues[0].id) {
      loadEvents({
        variables: {
          where: {
            venue_id: {
              _eq: venueData?.venues[0].id
            }
          }
        },
        fetchPolicy: 'network-only'
      })
    }

    if (venueData?.venues[0].featured_event_id) {
      loadSponsor({
        variables: {
          where: {
            venue_id: {
              _eq: venueData?.venues[0].id
            },
            id: {
              _eq: venueData?.venues[0].featured_event_id
            }
          }
        }
      })
    }
  }, [venueLoading, venueData])

  const venue = venueData?.venues[0];
  const events = eventData?.events;
  const sponsoredEvent = sponsoredData?.events[0];

  const loading = useMemo(() => {
      if (venueLoading || eventLoading) {
        return true;
      } else if (!venueData || !eventData) {
        return true;
      }

      if (!loading && !userLoading) {
        const authCheck = venueAuthUser(userData, venue?.owner_id);
    
        if (!authCheck) {
          history.push('/')
        }
      }

    return false;
  }, [venueLoading, eventLoading, venueData, eventData, sponsoredLoading, sponsoredData, userLoading]);

  const setSponsored = async (id) => {
    try {
      await setNewSponsoredEvent({
        variables: {
          venueId: venue.id,
          sponsoredId: id
        }
      })
      await loadSponsor({
        variables: {
          where: {
            venue_id: {
              _eq: venueData?.venues[0].id
            },
            id: {
              _eq: id
            }
          }
        }
      })

    } catch (e) {
      console.error('Error: ', e)
      toast.error('Failed to update sponsored event with error: ', e);
    }

  }

  const removeEvent = async (eventId) => {
    const token = await user.getIdToken()
    
    await deleteEvent({
      venueId: venue.id,
      eventId: eventId,
      token: token
    })

    await loadEvents({
      variables: {
        where: {
          venue_id: {
            _eq: venueData?.venues[0].id
          }
        }
      },
      fetchPolicy: 'network-only'
    })
  }

  const DataTable = ({ data, sponsoredId }) => {

    return (
      <EventsTable>
        <thead>
          <tr>
            <Th>Name</Th>
            <Th>Type</Th>
            <Th>Date</Th>
            <Th></Th>
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            
            <tr key={index}>
              <Td>{item.name.length > 60 ? `${item.name.substring(0, 60)}...` : item.name}</Td>
              <Td>{item.is_digital ? 'Digital' : 'Live'}</Td>
              <Td>{item.start_date}</Td>
              <Td>
                {venue?.partner_plan?.can_sponsor_events && (
                  <CheckIcon $sponsored={item.id === sponsoredId} onClick={() => setSponsored(item.id)}>
                    <Icon name="check" />
                  </CheckIcon>
                )}
              </Td>
              <ActionCells>
                <Button onClick={() => history.push(`/events/${item.slug}`)} plain>
                  Preview
                </Button>
                <PopoverContainer>
                  <PopoverButton>
                    <Icon name="moreIcon" />
                  </PopoverButton>
  
                  <PopoverContent>
                    <PopoverCloser>
                      <span onClick={() => history.push(`/venue/${slug}/dashboard/edit-event/${item.slug}`)}>
                        Edit
                      </span>
                    </PopoverCloser>
                    <PopoverCloser>
                      <span onClick={() => removeEvent(item.id)}>
                        Delete
                      </span>
                    </PopoverCloser>
                  </PopoverContent>
                </PopoverContainer>
              </ActionCells>
            </tr>
          ))}
        </tbody>
      </EventsTable>
    )
  }
  
  return (
    <PageWrapper>
      <Sidebar active={0} />

      <ContentWrapper>
        <Headers>
          <h2>Events</h2>

          <Button onClick={() => history.push(`/venue/${slug}/dashboard/create-event`)}>
            Create new
          </Button>
        </Headers>

        <TableWrapper>
          {venue?.partner_plan?.can_sponsor_events &&  (
            <React.Fragment>
              <SponsoredEventHeader>My Sponsored Event</SponsoredEventHeader>
              <SponsoredEventContainer>
                <div>{sponsoredEvent?.name}</div>
                <div>{sponsoredEvent?.is_digital ? 'Digital' : 'Live'}</div>
                <div>{sponsoredEvent?.start_date}</div>
              </SponsoredEventContainer>
            </React.Fragment>
          )}
          {loading ? (
            <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <p>Loading</p>
              <div style={{marginLeft: '10px'}}>
                <Loader/>
              </div>
            </div>
          ) : (!events.length ? (
              <NoEventsContainer>
                <NoEventsIcon>
                  <Icon name="ticket" />
                </NoEventsIcon>
                <h2>No events</h2>
                <p>
                  You don't have any events created. Create your first event to get
                  started.
                </p>

                <Button onClick={() => history.push(`/venue/${slug}/dashboard/create-event`)}>
                  Create New
                </Button>
              </NoEventsContainer>
            ) : (
              <EventsTableContainer>
                <DataTable data={events} sponsoredId={sponsoredEvent?.id}/>
              </EventsTableContainer>
            )
          )}
        </TableWrapper>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default VenueEvents
