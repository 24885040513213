import React, { useState, useEffect } from 'react'
import {
  FormWrapper,
  TopicsGridContainer,
  TopicsGridItem,
  TopicImage,
  TopicLabel,
  TopicOvelay,
  CTAWrapper,
  PageNumber,
  PageTitle,
  VibesWrapper,
  VibesPickerWrapper,
  CheckIcon
} from '../styled'
import { capitalizeFirstLetter } from 'utils'
import { preTags } from 'constant'
import { Icon, Button, MultiPick, Loader } from 'components'
import { useStoreon } from 'storeon/react'

const vibesObject = preTags.find((item) => item.category === 'Category')
const settingObject = preTags.find((item) => item.category === 'Setting')

const FourthPage = ({
  selectedVibes,
  selectedSetting,
  onSettingChange,
  onVibesUpdate,
  onBack,
  onSubmit,
  isSubmitting
}) => {
  const { user, dispatch, isMobile, loading } = useStoreon(
    'user',
    'loading',
    'isMobile'
  )
  const [hasErrors, setHasErrors] = useState(true)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 100,
  //     behavior: 'smooth'
  //   })
  // }, [])

  useEffect(() => {
    if (selectedVibes.length > 0 || selectedSetting.length > 0) {
      setHasErrors(false)
    } else {
      setHasErrors(true)
    }
  }, [selectedVibes, selectedSetting, hasErrors])

  return (
    <>
      <PageNumber>4/4</PageNumber>
      <PageTitle>
        <p>What type of "performance vibes" are you interested in?</p>
      </PageTitle>
      <FormWrapper>
        <VibesWrapper>
          <VibesPickerWrapper>
            <MultiPick
              col={2}
              options={vibesObject.tags}
              value={selectedVibes}
              onChange={(value) => onVibesUpdate(value)}
            />
          </VibesPickerWrapper>

          <PageTitle>
            <p>How do you prefer to experience the arts?</p>
          </PageTitle>

          <TopicsGridContainer>
            {settingObject.tags.map((item, idx) => {
              const { value, label, thumbnailUrl } = item

              const isSelected = selectedSetting.some(
                (i) => i === item.value || i?.value === item.value
              )

              const columns = 2
              const height = !isMobile ? 150 : 80
              const name = capitalizeFirstLetter(label)

              const imageLink = thumbnailUrl

              return (
                <TopicsGridItem
                  $column={columns}
                  $height={height}
                  key={value}
                  onClick={() => onSettingChange(item)}
                >
                  <TopicOvelay
                    $centerLabel
                    $height={height}
                    $isSelected={isSelected}
                  >
                    <TopicImage $imageUrl={imageLink}>
                      <CheckIcon>
                        {isSelected && <Icon name="check" />}
                      </CheckIcon>
                      <TopicLabel $centerLabel $isSelected={isSelected}>
                        {name}
                      </TopicLabel>
                    </TopicImage>
                  </TopicOvelay>
                </TopicsGridItem>
              )
            })}
          </TopicsGridContainer>

          <CTAWrapper $margin={!isMobile ? 32 : 69}>
            <Button
              plain
              type="button"
              onClick={() => {
                onBack()
              }}
            >
              Back
            </Button>
            <Button
              type="button"
              disabled={
                selectedVibes < 1 || selectedSetting < 1 || isSubmitting
              }
              onClick={onSubmit}
            >
              Submit
            </Button>
          </CTAWrapper>
        </VibesWrapper>
      </FormWrapper>
    </>
  )
}

export default FourthPage
