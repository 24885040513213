import React, { useState } from 'react'
import { InputWrapper, CoreInput, PasswordToggle } from './styled'
import { Label, Icon } from 'components'

const TextArea = (props) => {
  const {
    onChange,
    returnValueOnChange = true,
    value = '',
    label,
    placeholder,
    error,
    tooltip,
    onClickOverride,
    icon,
    type,
    rows,
    ...inputProps
  } = props
  const isPassword = type === 'password'
  const [showPassword, setShowPassword] = useState(false)

  return (
    <InputWrapper onClick={onClickOverride}>
      <Label icon={icon} label={label} error={error} tooltip={tooltip} />
      <CoreInput
        rows={rows}
        value={value}
        onChange={(e) => {
          if (returnValueOnChange) {
            onChange(e.target.value)
          } else {
            onChange(e)
          }
        }}
        placeholder={placeholder}
        type="text"
        {...inputProps}
      />
      {isPassword && (
        <PasswordToggle onClick={() => setShowPassword((prev) => !prev)}>
          <Icon name={showPassword ? 'invisible' : 'visible'} />
        </PasswordToggle>
      )}
    </InputWrapper>
  )
}

export default TextArea
