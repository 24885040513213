import React from 'react'

const StarIcon = () => {
  return (
    <svg
      viewBox='0 0 512 512'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <g>
          <g>
            <rect x="176" y="192" width="64" height="64"></rect>
            <rect x="272" y="192" width="64" height="64"></rect>
            <rect x="368" y="192" width="64" height="64"></rect>
            <rect x="80" y="288" width="64" height="64"></rect>
            <rect x="176" y="288" width="64" height="64"></rect>
            <rect x="272" y="288" width="64" height="64"></rect>
            <rect x="368" y="288" width="64" height="64"></rect>
            <rect x="80" y="384" width="64" height="64"></rect>
            <rect x="176" y="384" width="64" height="64"></rect>
            <rect x="272" y="384" width="64" height="64"></rect>
            <path d="M432,64V16c0-8.801-7.168-16-16-16h-64c-8.832,0-16,7.199-16,16v48H176V16c0-8.801-7.168-16-16-16H96     c-8.832,0-16,7.199-16,16v48H16v448h480V64H432z M368,32h32v64h-32V32z M112,32h32v64h-32V32z M464,480H48V160h416V480z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StarIcon
