import styled, { keyframes } from 'styled-components'
import { colors, breakpoints, maxDesktopCss } from 'constant'
import { Link } from 'react-router-dom'

const { md } = breakpoints

const enter = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const ArticleLayout = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
  min-height: 100vh;

  ${({ hide }) => hide && 'opacity: 0;'}
`

export const ArticleBody = styled.div`
  ${maxDesktopCss}
  display: flex;

  ${({ isMobile }) => isMobile && `
    flex-direction: column;
  `}
`

export const ArticleContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  ${maxDesktopCss}
  padding-bottom: 80px;
`

export const LatestArticle = styled.div`
  width: 100%;
  max-width: 530px;
  display: flex;
  flex-direction: column;
  font-size: 16px;

  > h2 {
    margin-bottom: 12px;
    font-size: 32px;
  }

  > a {
    margin-top: 32px;
    width: 183px;
    max-width: 100%;
    align-self: flex-end;
  }

  ${md} {
    > h2 {
      font-size: 24px;
    }

    > a {
      width: 100%;
    }
  }
`

export const ArticleBg = styled.div`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: blur(10px);
  transform: scale(1.4);
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0.3;
`

export const ArticleWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex: row;
  animation: ${enter} 300ms linear;
  margin-bottom: 24px;
  word-break: break-word;

  ${({ isMobile }) => isMobile && `
    padding-top: 0px;
    margin-bottom: 0px;
  `}
`

export const RelatedArticles = styled.div`
  flex: 1;
  margin-left: 64px;
  flex-shrink: 0;
  min-width: 300px;

  > h4 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  > div {
    > * {
      margin-bottom: 16px;
    }
  }

  ${({ isMobile }) => isMobile && `
    margin-left: 0px;
  `}
`

export const ContentFrame = styled.div`
  width: 700px;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  border-radius: 0px 0px 10px 10px;
 
  ${({ isMobile }) => isMobile && `
    padding: 0px 16px;
  `}
`

export const InjectedItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    max-width: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    margin: 24px 0px;
  }

  a {
    color: ${colors.mainText};
  }

  div {
    display: block;
  }

  iframe {
    display: block;
    width: 100%;
  }

  ${({ isMobile }) => isMobile && `
    p {
      font-size: 16px;
      line-height: 20px;
    }

    iframe {
      max-height: 200px;
    }
  `}
`

export const Header = styled.div`
  width: 1000px;
  max-width: 100%;
  height: 420px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: cover;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 0px 0px;

  > div {
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
    flex-direction: column;
    width: 1000px;
    max-width: 100%;
    text-align: center;

    > h1 {
      font-size: 32px;
      padding: 24px;
    }
  }
`

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${md} {
    margin-top: 45px;
  }
`

export const ModalHeader = styled.div`
  margin-top: 32px;
  margin-bottom: 8px;
  text-align: center;

  > h1 {
    font-size: 32px;
  }

  ${md} {
    font-size: 18px;
  }
`

export const Copy = styled.div`
  width: 500px;
  text-align: center;
  margin-bottom: 40px;

  > p:first-child {
    margin-bottom: 10px;
  }

  ${md} {
    width: 100%;
    font-size: 13px;
  }
`

export const CTAWrapper = styled.div`
  width: 500px;
  margin-bottom: 24px;
  display: flex;
  gap: 20px;

  > * {
    width: 50%;
  }

  ${md} {
    width: 100%;
    gap: 10px;

    > * {
      font-size: 13px;
    }
  }
`

export const LoginButton = styled(Link)`
  border: 3px solid ${colors.c25};
  background: transparent;
  color: white;
  font-weight: 600;
  border-radius: 100px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 300ms ease;
  text-decoration: none;
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
  position: relative;

  ${md} {
    font-size: 13px;
  }
`

export const Decline = styled(Link)`
  text-decoration: underline;

  ${md} {
    font-size: 13px;
  }
`

export const RcaLogo = styled.div`
  height: 89px;
  width: 117px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`
