import styled from 'styled-components'
import { maxDesktopCss, breakpoints, colors } from 'constant'
import { Link } from 'react-router-dom'

const { md } = breakpoints

export const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 80px;
`

export const ArticleContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  ${maxDesktopCss}
  padding-bottom: 80px;
`

export const LatestArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > h2 {
    margin-bottom: 12px;
    font-size: 32px;
  }

  > a {
    margin-top: 32px;
    width: 183px;
    max-width: 100%;
    align-self: flex-start;
  }

  ${md} {
    > h2 {
      font-size: 24px;
    }

    > a {
      width: 100%;
    }
  }
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${maxDesktopCss}
  margin-bottom: 24px;

  > h2 {
    display: flex;
    font-size: 32px;
    margin-bottom: 26px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    > a {
      text-decoration: underline;
      font-size: 16px;
    }
  }

  > div {
    display: flex;

    > a {
      flex: 1;

      &:first-child {
        margin-right: 16px;

        ${md} {
          margin-right: 0px;
        }
      }
    }
  }
`

export const AllCards = styled.div`
  ${maxDesktopCss}
  display: flex;
  flex-wrap: wrap;

  > a {
    width: calc(50% - 8px);
    margin-bottom: 16px;

    &:nth-child(odd) {
      margin-right: 16px;
    }
  }

  ${md} {
    > a {
      width: 100%;

      &:nth-child(odd) {
        margin-right: 0px;
      }
    }
  }
`

export const AritcleHeader = styled.div`
  ${maxDesktopCss}
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
`

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${md} {
    margin-top: 45px;
  }
`

export const Header = styled.div`
  margin-top: 32px;
  margin-bottom: 8px;
  text-align: center;

  > h1 {
    font-size: 32px;
  }

  ${md} {
    font-size: 18px;
  }
`

export const Copy = styled.div`
  width: 500px;
  text-align: center;
  margin-bottom: 40px;

  > p:first-child {
    margin-bottom: 10px;
  }

  ${md} {
    width: 100%;
    font-size: 13px;
  }
`

export const CTAWrapper = styled.div`
  width: 500px;
  margin-bottom: 24px;
  display: flex;
  gap: 20px;

  > * {
    width: 50%;
  }

  ${md} {
    width: 100%;
    gap: 10px;

    > * {
      font-size: 13px;
    }
  }
`

export const LoginButton = styled(Link)`
  border: 3px solid ${colors.c25};
  background: transparent;
  color: white;
  font-weight: 600;
  border-radius: 100px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 300ms ease;
  text-decoration: none;
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
  position: relative;

  ${md} {
    font-size: 13px;
  }
`

export const Decline = styled(Link)`
  text-decoration: underline;

  ${md} {
    font-size: 13px;
  }
`

export const RcaLogo = styled.div`
  height: 89px;
  width: 117px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`
