import {
  ModalWrapper,
  Background,
  ContentWrapper,
  MobileContentWrapper,
  CloseButton
} from './styled'
import { AnimatePresence } from 'framer-motion'
import { useStoreon } from 'storeon/react'
import React, { useMemo } from 'react'
import { Icon } from 'components'

const Modal = (props) => {
  const { open, onClose, fullScreen, children, closeButton, transparent } =
    props
  const { isMobile } = useStoreon('isMobile')

  const mobileWrapper = useMemo(() => {
    return (
      <MobileContentWrapper transparent={transparent}>
        {children}
        <CloseButton onClick={onClose}>
          <Icon name="close" />
        </CloseButton>
      </MobileContentWrapper>
    )
  }, [children, onClose])

  const desktopWrapper = useMemo(() => {
    const showCloseButton = fullScreen || closeButton
    return (
      <>
        <Background onClick={onClose} />
        <ContentWrapper fullScreen={fullScreen} transparent={transparent}>
          {children}
          {showCloseButton && (
            <CloseButton onClick={onClose}>
              <Icon name="close" />
            </CloseButton>
          )}
        </ContentWrapper>
      </>
    )
  }, [onClose, children, fullScreen])

  const content = useMemo(() => {
    if (!open) return null

    const innerContent = isMobile ? mobileWrapper : desktopWrapper

    return (
      <ModalWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'linear', duration: 0.2 }}
      >
        {innerContent}
      </ModalWrapper>
    )
  }, [open, isMobile, mobileWrapper, desktopWrapper])

  return <AnimatePresence>{content}</AnimatePresence>
}

export default Modal
