import styled, { keyframes } from 'styled-components'

const enter = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const AlertWrapper = styled.div`
  position: fixed;
  bottom: 24px;
  left: 5%;
  width: 90%;
  border-radius: 4px;
  padding: 12px 12px;
  background: ${({ color }) => color};
  z-index: 4;
  max-width: 500px;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
  animation: ${enter} 300ms linear;
  display: flex;
  align-items: center;

  > div {
    &:first-child {
      flex: 1;
      font-size: 16px;
      line-height: 20px;
      padding-right: 16px;
    }

    &:last-child {
      cursor: pointer;
    }

    > svg {
      height: 20px;
      width: 20px;
      transform: translateY(2px);
    }
  }
`
