import { createStoreon } from 'storeon'
import common from './common'
import user from './user'

const createStore = serverState => {
  const allStores = [common, user]

  let combinedState = {}
  const combinedActions = []

  allStores.forEach(item => {
    const { initialState, actions } = item

    combinedState = {
      ...combinedState,
      ...initialState,
      ...serverState
    }

    actions.forEach(item => {
      combinedActions.push(item)
    })
  })

  if (typeof window !== 'undefined' && window.__STOREON_INITIAL_STATE__) {
    combinedState = {
      ...combinedState,
      ...window.__STOREON_INITIAL_STATE__
    }
  }

  const combinedStore = store => {
    store.on('@init', () => combinedState)

    combinedActions.forEach(item => {
      const { name, cb } = item
      store.on(name, cb)
    })
  }

  return createStoreon([combinedStore])
}

export default createStore
