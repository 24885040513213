import styled from 'styled-components'
import { colors, breakpoints, maxDesktopWidth } from 'constant'

const { md, sm } = breakpoints

export const DatePickerValue = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  cursor: pointer;

  ${md} {
    font-size: 12px;
    padding: 0px;
  }

  ${sm} {
    font-size: 11px;
    padding: 0px;
  }
`

export const DatePickerLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
  border-right: 1px solid black;
  border-left: 1px solid black;

  > svg {
    height: 16px;
    width: 16px;
    color: ${colors.c3};
    margin-left: 20px;
  }

  ${md} {
    > svg {
      height: 14px;
      width: 14px;
      margin-left: 8px;
    }
  }

  ${sm} {
    > svg {
      height: 12px;
      width: 12px;
      margin-left: 8px;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;

  border-radius: 30px;

  ${md} {
    width: calc(100% / 3);
  }

  * {
    color: ${colors.mainText} !important;
  }

  .DayPickerNavigation_button {
    background: transparent;
    border: none;

    svg {
      fill: ${colors.mainText};
    }
  }

  .CalendarDay__blocked_out_of_range {
    opacity: 0.2;
  }

  .DayPicker {
    background: ${colors.cardBackground};
    box-shadow: none;
  }

  .CalendarMonthGrid {
    background: ${colors.cardBackground} !important;
  }

  .CalendarMonth {
    background: ${colors.cardBackground} !important;
  }

  .CalendarDay__default {
    background: ${colors.cardBackground} !important;
    border: none !important;

    &:hover {
      background: ${colors.buttonBackground} !important;
      border-radius: 50%;
    }
  }

  .CalendarDay__selected_start {
    ${({ isDateRange }) =>
      isDateRange &&
      `
      &:after {
        z-index: -2;
        position: absolute;
        top: 0px;
        right: 0px;
        content: '';
        height: 100%;
        width: 50%;
        background: ${colors.c18};
      }
    `}
  }

  .CalendarDay__selected_end {
    ${({ isDateRange }) =>
      isDateRange &&
      `
      &:after {
        z-index: -2;
        position: absolute;
        top: 0px;
        left: 0px;
        content: '';
        height: 100%;
        width: 50%;
        background: ${colors.c18};
      }
    `}
  }

  .CalendarDay__selected {
    position: relative;
    z-index: 1;

    &:hover {
      background: ${colors.buttonBackground};
      border-radius: 50%;
      color: white;
    }

    &:before {
      z-index: -1;
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      height: 100%;
      width: 100%;
      background: ${colors.buttonBackground};
      border-radius: 50%;
    }
  }

  .CalendarDay__selected_span {
    background: ${colors.c18} !important;

    &:hover {
      background: ${colors.buttonBackground};
      border-radius: 50%;
      color: white;
    }
  }
`

export const CalendarWrapper = styled.div`
  position: absolute;
  top: 110%;
  right: 0px;
  z-index: 2;
  background: ${colors.cardBackground};
  overflow: hidden;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);

  > button {
    height: 40px;
    width: 200px;
    background: ${colors.buttonBackground};
    border-radius: 50px;
    border: none;
    font-weight: 600;
    font-family: 'Open Sans';
    transform: translateY(-16px);
    cursor: pointer;
  }

  > span {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    transform: translateY(-4px);
  }

  ${md} {
    /* width: ${maxDesktopWidth}; */
    /* position: relative; */
    /* top: 10px; */
    width: 315px;
    left: ${({ $leftAdjust }) => $leftAdjust ? '15%' : '-90%'}
  }

  ${sm} {
    left: ${({ $leftAdjust }) => $leftAdjust ? '-165%' : '-90%'}
  }
`

export const MobileCalendarWrapper = styled.div`
  position: absolute;
  top: 110%;
  right: 0px;
  z-index: 2;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.cardBackground};

  .DayPicker {
    box-shadow: none;
  }
`

export const CalendarBottom = styled.div`
  display: flex;
  padding: 0px 24px;
  width: 100%;
  justify-content: space-between;

  > button {
    width: 48%;
    height: 40px;
    font-size: 16px;

    &:first-child {
      background: none;
      color: ${colors.c9};
    }
  }
`
