import styled from 'styled-components'
import { colors, breakpoints } from 'constant'
import { Link } from 'react-router-dom'

const { md, sm, lg } = breakpoints

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${md} {
    margin-top: 45px;
  }
`

export const LogoWrapper = styled.div``

export const Header = styled.div`
  margin-top: 32px;
  margin-bottom: 8px;
  text-align: center;

  > h1 {
    font-size: 32px;
  }

  ${md} {
    font-size: 18px;
  }
`

export const Copy = styled.div`
  width: 500px;
  text-align: center;
  margin-bottom: 40px;

  ${md} {
    width: 100%;
    font-size: 13px;
  }
`

export const CTAWrapper = styled.div`
  width: 500px;
  margin-bottom: 24px;
  display: flex;
  gap: 20px;

  > * {
    width: 50%;
  }

  ${md} {
    width: 100%;
    gap: 10px;

    > * {
      font-size: 13px;
    }
  }
`

export const LoginButton = styled(Link)`
  border: 3px solid ${colors.c25};
  background: transparent;
  color: white;
  font-weight: 600;
  border-radius: 100px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 300ms ease;
  text-decoration: none;
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
  position: relative;

  ${md} {
    font-size: 13px;
  }
`

export const Decline = styled(Link)`
  text-decoration: underline;

  ${md} {
    font-size: 13px;
  }
`

export const RcaLogo = styled.div`
  height: 89px;
  width: 117px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`
