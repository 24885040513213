import React from 'react'

const FunnelIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="30"
      height="30"
    >
      <ellipse cx="16.607" cy="301.988" fill="#40cd8b" rx="6" ry="6" />
      <path fill="#fff" d="M6 3l7 11v10l4 3v-13l7-11z" />
    </svg>
  )
}

export default FunnelIcon
