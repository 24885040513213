import React from 'react'
import { CtaBannerWrapper, MobileCtaBanner } from './styled'
import { Button } from 'components'
import { useStoreon } from 'storeon/react'

//Specify link when using this component to link to another page on red curtain addict
//Specify href to link to another site
const CtaBanner = props => {
  const { image, title, description, link, href, ctaLabel, onClick, shiftLeft, target } = props
  const { isMobile } = useStoreon('isMobile')

  if (isMobile) {
    return (
      <MobileCtaBanner shiftLeft={shiftLeft} image={image}>
        <div />
        <h2>{title}</h2>
        <h4>{description}</h4>
        {
          link &&
          <Button to={link} onClick={onClick}>{ctaLabel}</Button>
        }
        {
          href &&
          <Button href={href} target={target} onClick={onClick}>{ctaLabel}</Button>
        }
      </MobileCtaBanner>
    )
  }

  return (
    <CtaBannerWrapper shiftLeft={shiftLeft} image={image}>
      <div>
        <h2>{title}</h2>
        <h4>{description}</h4>
        {
          link &&
          <Button to={link} onClick={onClick}>{ctaLabel}</Button>
        }
        {
          href &&
          <Button href={href} target={target} onClick={onClick}>{ctaLabel}</Button>
        }
      </div>
      <div />
    </CtaBannerWrapper>
  )
}

export default CtaBanner
