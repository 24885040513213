import {
  Wrapper,
  AutocompleteWrapper,
  SearchWrapper,
  SearchContent,
  TagInputWrapper,
  AutocompleteResults,
  SectionWrapper,
  LoadWrapper,
  ResultItems,
  ResultItemWrapper,
  ResultLink
} from './styled'
import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useOnClickOutside } from 'utils'
import { MultiPick, Button, Loader, TextInput, SearchInput } from 'components'
import { preTags } from 'constant'
import { useLazyQuery, gql } from '@apollo/client'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

const GET_EVENT_RESULTS = gql`
  query getEventResults($limit: Int!, $where: events_bool_exp = {}) {
    events(limit: $limit, where: $where) {
      name
      slug
      event_tags {
        id
        tag_id
      }
    }
  }
`

const GET_VENUE_RESULTS = gql`
  query getVenueResults($limit: Int!, $where: venues_bool_exp = {}) {
    venues(limit: $limit, where: $where) {
      slug
      name
      venue_tags {
        id
        tag_id
      }
    }
  }
`

const AutocompleteTag = (props) => {
  const [showResults, setShowResults] = useState(false)
  const ref = useRef()
  const [searchTerm, setSearchTerm] = useState('')
  // const [allResults, setAllResults] = useState()

  // const [closestMatch, setClosestMatch] = useState('')
  const now = format(new Date(), 'P')
  const [getEventResults, { loading: eventLoading, data: eventData }] =
    useLazyQuery(GET_EVENT_RESULTS)
  const [getVenueResults, { loading: venueLoading, data: venueData }] =
    useLazyQuery(GET_VENUE_RESULTS)

  useOnClickOutside(ref, () => setShowResults(false))

  const { onChange, tags: tagProp = [], searchValue } = props

  useEffect(() => {
    setSearchTerm(searchValue)
  }, [searchValue])

  useEffect(() => {
    if (searchTerm) {
      getEventResults({
        variables: {
          limit: 50,
          where: {
            name: {
              _ilike: `%${searchTerm}%`
            },
            end_date: {
              _gte: now
            }
          }
        }
      })

      getVenueResults({
        variables: {
          limit: 50,
          where: {
            name: {
              _ilike: `%${searchTerm}%`
            }
          }
        }
      })
    }
  }, [searchTerm])

  // const combinedResults = useMemo(() => {
  //   let combined = []
  //   console.log('memo')
  //   if (eventData?.events) {
  //     console.log('event: ', eventData.events)
  //     combined = [...combined, ...eventData.events]
  //   }
  //   if (venueData?.venues) {
  //     combined = [...combined, ...venueData.venues]
  //   }
  //   return combined
  // }, [eventData, venueData])

  // console.log('events: ', combinedResults)

  const viewEventTrigger = () => {
    gtag('event', 'event_clicked', {
      'event_clicked': 1
    })
  }

  const handleSearch = (e) => {
    e.stopPropagation()
    const value = e.target.value

    if (value === '') {
      setShowResults(false)
    } else {
      setShowResults(true)
    }
    props.onChange(value)
    setSearchTerm(value)
  }

  // const tagLabels = preTags.flatMap((tagGroup) =>
  //   tagGroup.tags.map((tag) => tag)
  // )

  // const renderTagSection = (item, idx) => {
  //   const { category, tags: categoryTags } = item

  //   return (
  //     <SectionWrapper key={idx}>
  //       <p>What {category.toLowerCase()} of events are you interested in?</p>
  //       <MultiPick
  //         options={categoryTags.map((ct) => ({
  //           label: ct.label,
  //           value: ct.label
  //         }))}
  //         value={tagProp}
  //         onChange={onChange}
  //       />
  //     </SectionWrapper>
  //   )
  // }

  const renderResults = () => {
    if (eventLoading || venueLoading) {
      return (
        <LoadWrapper>
          <Loader />
        </LoadWrapper>
      )
    }

    return (
      <AutocompleteResults>
        {((eventData?.events?.length > 0 || venueData?.venues?.length > 0) && (
          <ResultItemWrapper>
            <ResultItems $groupHeader>Events</ResultItems>
            {eventData?.events.length > 0 ? (
              eventData?.events.map((event, idx) => {
                return (
                  <ResultItems key={idx} >
                    <ResultLink onClick={() => viewEventTrigger()} to={'/events/' + event.slug} >{event.name}</ResultLink>
                  </ResultItems>
                )
              })
            ) : (
              <ResultItems $noResult>No events from your search.</ResultItems>
            )}
            <ResultItems $groupHeader>Venues</ResultItems>
            {venueData?.venues?.length > 0 ? (
              venueData.venues.map((item, idx) => {
                return (
                  <ResultItems key={idx}>
                    <Link to={'/venue/' + item.slug} >{item.name}</Link>
                  </ResultItems>
                )
              })
            ) : (
              <ResultItems $noResult>No venues from your search.</ResultItems>
            )}
          </ResultItemWrapper>
        )) || <div>No results from your search. Try different terms.</div>}
      </AutocompleteResults>
    )
  }

  return (
    <Wrapper ref={ref}>
      <AutocompleteWrapper>
        <SearchWrapper>
          <SearchContent onClick={() => setShowResults(true)}>
            {/* <TagInputWrapper>
              {tagProp.length ? `${tagProp.length} keywords selected` : 'Select keywords'}
            </TagInputWrapper> */}
            <SearchInput
              transparent
              placeholder={props.searchText ?? "Search by Event..."}
              value={searchTerm}
              returnValueOnChange={false}
              onChange={handleSearch}
              name="search"
              onSearch={props.onSearch}
            />
          </SearchContent>
        </SearchWrapper>
        {showResults &&
          searchTerm &&
          // <AutocompleteResults>
          //   {preTags.map(renderTagSection)}
          //   <Button onClick={props.onSearch}>Search</Button>
          // </AutocompleteResults>
          renderResults()}
      </AutocompleteWrapper>
    </Wrapper>
  )
}

export default AutocompleteTag
