import styled from 'styled-components'
import { colors, breakpoints } from 'constant'
import { Link } from 'react-router-dom'

const { md } = breakpoints

export const Wrapper = styled(Link)`
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.3));
  cursor: pointer;
  text-decoration: none;
  color: ${colors.mainText};
  display: flex;
  flex-direction: column;
  background: ${colors.c1};
  border-radius: 16px;
  overflow: hidden;
  text-align: left;

  ${md} {
    filter: unset;
  }
`

export const CoverImage = styled.div`
  background-image: url(${({ image }) => image});
  height: 207px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const BottomInfo = styled.div`
  display: flex;
  flex-direction: column;

  > h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: space-between;
  flex: 1;

  > h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
  }

  .LinesEllipsis {
    font-style: normal;
    font-weight: normal;
    color: ${colors.c19};
    font-size: 12px;
    flex: 1;
    line-height: 16px;
  }
`

export const AboutWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  color: ${colors.c19};
  font-size: 12px;
  flex: 1;
  line-height: 16px;
  max-height: 74px;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(17, 17, 18)
    );
    position: absolute;
    height: 20px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const Name = styled.h2`
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: ${colors.c3};
  margin-bottom: 4px;
`

export const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  > div {
    display: flex;
    flex-direction: column;

    &:first-child {
      padding-right: 8px;
    }

    &:last-child {
      align-items: center;
    }

    /* > h2 {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: ${colors.c3};
      margin-bottom: 4px;
    } */

    > h4 {
      font-size: 12px;
      line-height: 16px;
      color: ${colors.c19};
      font-weight: normal;
    }

    > h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }

    > h5 {
      width: 48px;
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: ${colors.c19};
    }
  }
`

export const TagItem = styled.div`
  text-transform: capitalize;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% - 16px) / 3);
  color: ${colors.c3};
  border: 1px solid ${colors.c2};
  background: rgba(194, 32, 38, 0.16);
  border-radius: 50px;
  backdrop-filter: blur(16px);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  > div {
    max-width: calc(100% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const Tags = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`
