import styled from 'styled-components'
import { colors, breakpoints } from 'constant'

const { md, sm, lg } = breakpoints

export const DisableLayer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 10;
`

export const EventSearchBarWrapper = styled.div`
  height: 56px;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 12px;
  align-items: center;
  width: 100%;
  border-radius: 100px;
  padding: 0px 0px;
  position: relative;
  background: transparent;
  /* box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.6); */

  > form {
    flex: 1;
    width: 100%;
  }
`

export const FilterButton = styled.button`
  position: absolute;
  right: 54px;
  top: 15px;
  z-index: 1;
  background: none;
  border: none;
  border-left: 2px solid ${colors.c16};
  padding-left: 12px;

  > svg {
    height: 24px;
    width: 24px;
    color: white;
  }
`

export const SearchButton = styled.button`
  /* position: absolute;
  right: 18px;
  top: -4px; */
  background: ${colors.buttonBackground};
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;

  > svg {
    height: 24px;
    width: 24px;
    color: white;
  }

  ${md} {
    color: white;
    height: 32px;
    width: 32px;
  }
`

export const LocationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 0px 12px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  cursor: pointer;

  ${md} {
    padding: 0;
  }
`

export const LocationLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 100%;
  margin-right: 0;
  padding-left: 0;

  > svg {
    height: 16px;
    width: 16px;
    color: ${colors.c3};
    margin-left: 20px;
  }

  ${md} {
    font-size: 12px;
    justify-content: center;

    > svg {
      margin-left: 8px;
      height: 14px;
      width: 14px;
    }
  }

  ${sm} {
    font-size: 11px;

    > svg {
      margin-left: 8px;
      height: 12px;
      width: 12px;
    }
  }
`

export const SelectedCityWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > svg {
    height: 16px;
    width: 16px;
  }

  font-size: 14px;

  ${md} {
    font-size: 12px;
    padding-left: 10px;
  }

  ${sm} {
    font-size: 11px;
    padding-left: 10px;
  }
`

export const MaxPrice = styled.div`
  > input {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    padding: 0px 12px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    background: none;
    border: none;
    color: white;
    position: relative;

    &::placeholder {
      color: white;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &:after {
    content: '';
    height: 40px;
    width: 1px;
    background: ${colors.c16};
    position: absolute;
    left: 0px;
    top: 8px;
  }
`

export const EventSearchRightWrapper = styled.div`
  z-index: 9;
  padding: 0;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background-color: ${colors.c17}; */
  border-radius: 30px;
  flex: 1;
  background: ${colors.c26};
  box-shadow: rgba(0, 0, 0, 0.6) 2px 2px 2px 2px;

  ${sm} {
    height: 40px;
  }
`

export const EventSearchRight = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  height: 100%;
  border-radius: 30px;
  /* padding-left: 8px; */
  /* padding-right: 8px; */

  > * {
    flex: 1;
    position: relative;

    /* &:after {
      content: '';
      height: 40px;
      width: 1px;
      background: ${colors.c16};
      position: absolute;
      left: 0px;
      top: 8px;
    } */
  }
`

export const RcaLogo = styled.div`
  height: 48px;
  width: 64px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`

export const MobileFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 50vw;
  min-height: 80vh;
`

export const MobileFilterButtons = styled.div``

export const SearchOptinWrapper = styled.div``
