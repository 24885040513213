import React from 'react'

const EmailIcon = () => {
  return (
    <svg
      viewBox='0 0 32 32'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d="m30.944 7.139a3.684 3.684 0 0 0 -3.633-3.139h-22.622a3.684 3.684 0 0 0 -3.633 3.139l14.944 9.67z"/><path d="m16.543 18.84a1 1 0 0 1 -1.086 0l-14.457-9.354v14.825a3.693 3.693 0 0 0 3.689 3.689h22.622a3.693 3.693 0 0 0 3.689-3.689v-14.826z"/>
    </svg>
  )
}

export default EmailIcon
