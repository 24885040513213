import styled, { css } from 'styled-components'
import { colors, maxDesktopWidth, breakpoints } from 'constant'

const { md } = breakpoints

export const TabContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const TabHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`

const activeItem = css`
  color: ${colors.c3};
`

const inactiveItem = css`
  color: ${colors.c4};
`

export const TabItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;

  > h2 {
    font-size: 24px;
    ${({ $active }) => ($active ? activeItem : inactiveItem)}
  }
`

export const TabContent = styled.div`
  display: flex;
  justify-content: center;
`
