import React, { useState } from 'react'
import { CarouselWrapper, CarouselHeader, CarouselHeaderRight, HeaderWrapper, IconWrapper } from './styled'
import { Carousel } from 'react-responsive-carousel'
import { Icon } from 'components'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tooltip';

const CustomCarousel = props => {
  const { children, label, link, linkLabel, style, tooltip, tooltipPlace, tooltipContent, slide, setSlide } = props
  const [currentSlide, setCurrentSlide] = useState(0)

  const updateCurrentSlide = idx => {
    if (idx !== currentSlide) {
      if (slide || slide === 0) {
        setSlide(idx)
      } else {
        setCurrentSlide(idx)
      }
    }
  }

  const viewEventTrigger = () => {
    gtag('event', 'event_clicked', {
      'event_clicked': 1
    })
  }

  const onNext = () => {
    if (slide || slide === 0) {
      setSlide(prev => prev === children.length - 1 ? 0 : prev + 1)
    } else {
      setCurrentSlide(prev => prev === children.length - 1 ? 0 : prev + 1)
    }
  }

  const onPrev = () => {
    if (slide || slide === 0) {
      setSlide(prev => prev === 0 ? children.length - 1 : prev - 1)
    } else {
      setCurrentSlide(prev => prev === 0 ? children.length - 1 : prev - 1)
    }
  }

  return (
    <CarouselWrapper style={style}>
      <CarouselHeader>
        <HeaderWrapper>
          <h3>{label}</h3>
          {tooltip && (
            <IconWrapper id={'carousel-header'}>
              <Icon name={'questionMark'}/>
            </IconWrapper>
          )}
          <Tooltip
            className="carousel-tooltip"
            anchorSelect='#carousel-header'
            content={tooltipContent}
            place={tooltipPlace}
            offset={6}
            opacity={1}
          />
        </HeaderWrapper>
        <CarouselHeaderRight>
          {link && <Link to={link} onClick={viewEventTrigger}>{linkLabel}</Link>}
          <div>
            <button onClick={onPrev}>
              <Icon name='arrowLeft' />
            </button>
            <button onClick={onNext}>
              <Icon name='arrowRight' />
            </button>
          </div>
        </CarouselHeaderRight>
      </CarouselHeader>
      <Carousel
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        selectedItem={slide ?? currentSlide}
        onChange={updateCurrentSlide}
      >
        {children}
      </Carousel>
    </CarouselWrapper>
  )
}

export default CustomCarousel
