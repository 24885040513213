import React, { useEffect, useState, useMemo } from 'react'

import {
  LabelWrapper,
  MainWrapper,
  PopoverButton,
  PopoverCloser,
  PopoverContainer,
  PopoverContent
} from './styled'
import { Icon, MultiPick } from 'components'
import { preTags, topics } from 'constant'

const TagFilter = (props) => {
  const [tags, setTags] = useState([])

  const { onChange, selected } = props

  const handleClear = () => {
    onChange([])
  }

  const hasValue = useMemo(() => {
    if (
      topics
        .filter((obj) => selected.includes(obj.label))
        .map((obj) => obj.value).length
    ) {
      return true
    } else {
      return false
    }
  }, [selected])

  return (
    <MainWrapper>
      <PopoverContainer>
        <LabelWrapper>
          <span>
            Genre{' '}
            {hasValue && <React.Fragment>({selected?.length})</React.Fragment>}
          </span>

          <Icon name="dropdown" />
        </LabelWrapper>

        <PopoverContent>
          <MultiPick
            lighter
            col={1}
            options={topics}
            value={topics
              .filter((obj) => selected.includes(obj.label))
              .map((obj) => obj.value)}
            onChange={(value) => {
              onChange(
                topics
                  .filter((obj) => value.includes(obj.value))
                  .map((obj) => obj.label)
              )
              setTags(value)
            }}
          />
          <a onClick={handleClear}>Clear</a>
        </PopoverContent>
      </PopoverContainer>
    </MainWrapper>
  )
}

export default TagFilter
