import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  > p {
    width: 384px;
  }
`
export const HeroUploadWrapper = styled.div`
  margin-top: 32px;

  > p {
    text-align: center;
  }
`

export const GalleryUploadWrapper = styled.div`
  margin-top: 32px;

  > p {
    text-align: center;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;

  > button {
    width: 384px;
  }
`
