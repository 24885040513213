import React from 'react'

const MoreIcon = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="18"
        cy="12"
        r="1.5"
        transform="rotate(90 18 12)"
        fill="#fff"
      />
      <circle
        cx="12"
        cy="12"
        r="1.5"
        transform="rotate(90 12 12)"
        fill="#fff"
      />
      <circle cx="6" cy="12" r="1.5" transform="rotate(90 6 12)" fill="#fff" />
    </svg>
  )
}

export default MoreIcon
