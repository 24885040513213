import styled, { css } from 'styled-components'
import { colors } from 'constant'

export const Label = styled.label`
  position: relative;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    height: 25px;
    width: 25px;
  }
`

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

export const Checkmark = styled.span`
  height: 25px;
  width: 25px;
  background: ${colors.c18};
  border: 2px solid ${colors.c6};
  border-radius: 50%;
`

export const Text = styled.span`
  margin-left: 5px;
`
