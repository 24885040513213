import React from 'react'
import ImageUploading from 'react-images-uploading'
import {
  // ButtonWrapper,
  // HeroPlaceholder,
  MainWrapper,
  UploadButton,
  UploadedHero
} from './styled'
import { Icon, Button } from 'components'

const Uploader = props => {
  const {uploadedImages, handleUpdate} = props

  const onChange = (imageList) => {
    handleUpdate(imageList)
  }

  return (
    <ImageUploading value={uploadedImages} onChange={onChange} dataURLKey="data_url">
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps
      }) => (
        // write your building UI
        <MainWrapper>
          {uploadedImages.length ? (
            imageList.map((image, index) => (
              <>
                <UploadButton
                  onClick={() => onImageUpdate(index)}
                  {...dragProps}
                >
                  <Icon name="gallery" /> Upload Media
                </UploadButton>
                <UploadedHero $imageUrl={image['data_url']} key={index}>
                  <Button plain onClick={() => onImageRemove(index)}>
                    <Icon name="close" />
                  </Button>
                </UploadedHero>
              </>
            ))
          ) : (
            <UploadButton onClick={onImageUpload} {...dragProps}>
              <Icon name="gallery" /> Upload Media
            </UploadButton>
          )}
        </MainWrapper>
      )}
    </ImageUploading>
  )
}

export default Uploader
