import React from 'react'
import iconMap from './icons'

const Icon = props => {
  const { name } = props
  const SelectedIcon = iconMap[name]

  return <SelectedIcon {...props} />
}

export default Icon
