import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const PageWrapper = styled.div`
  display: flex;
  height: 100%;
`

export const SideBarWrapper = styled.div`
  height: 100vh;
  width: 235px;
  background-color: ${colors.c15};
`

export const ContentWrapper = styled.div`
  width: 100%;
`

export const BannerCustomContent = styled.div`
  padding-top: 70px;
  padding-left: 32px;
  padding-right: 32px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const buttonCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${colors.c10};
  border: none;
  padding: 10px 20px;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;

  > svg {
    margin-right: 12px;
    height: 20px;
    width: 24px;
  }

  /* 
  ${md} {
    > button {
      font-size: 14px;
      padding: 12px 20px;

      > svg {
        height: 18px;
        width: 18px;
        margin-right: 8px;
      }
    }
  } */
`

export const BannerLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`

export const BannerRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 384px;
`

export const BannerLeftUpper = styled.div``

export const BannerLeftLower = styled.div`
  transform: translateY(250px);
  flex-direction: column;
`

export const BannerRightUpper = styled.div`
  display: flex;
  justify-content: right;

  > button {
    ${buttonCss}
    width: 100px;
    align-self: right;
  }
`

export const a = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.buttonBackground};
  color: white;
  border-radius: 24px;
  padding: 10px 32px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
`

export const BannerRightLower = styled.div`
  display: flex;
  gap: 10px;
  transform: translateY(300px);

  > a {
    ${a}
    flex-basis: 100%;
    flex-grow: 1;
  }

  > button {
    ${buttonCss}
    flex-basis: 100%;
    flex-grow: 1;
  }
`

export const TagItem = styled.div`
  text-transform: capitalize;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.c3};
  border: 1px solid ${colors.c2};
  background: rgba(194, 32, 38, 0.08);
  border-radius: 50px;
  backdrop-filter: blur(16px);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 24px;

  ${md} {
    font-size: 12px;
    padding: 8px 14px;
  }
`

export const VibeTagItem = styled.div`
  text-transform: capitalize;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.c3};
  border: 1px solid ${colors.c2};
  background: rgba(194, 32, 38, 0.08);
  border-radius: 50px;
  backdrop-filter: blur(16px);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 24px;

  ${md} {
    font-size: 12px;
    padding: 8px 14px;
  }
`

export const TagWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  text-transform: capitalize;

  > div {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  ${md} {
    flex-wrap: wrap;

    > div {
      margin-bottom: 8px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
`

export const Details = styled.div`
  display: flex;
  transform: translateY(-110px);
  padding-right: 32px;
  padding-left: 32px;

  ${md} {
    flex-direction: column;
  }
`

export const DetailsLeft = styled.div`
  flex: 1;
  padding-right: 36px;

  > h1 {
    font-size: 24px;
  }

  > * {
    margin-bottom: 12px;
  }

  > p {
    margin-bottom: 32px;
  }

  ${md} {
    padding-right: 0px;
  }
`

export const AboutWrapper = styled.div`
  margin-bottom: 24px;
`

export const DetailsRight = styled.div`
  width: 380px;
  flex-shrink: 0;
  max-width: 100%;

  > div {
    &:first-child {
      margin-bottom: 24px;
    }
  }

  ${md} {
    width: 100%;
  }
`

export const RoundInfo = styled.div`
  border-radius: 24px;
  overflow: hidden;
  background: ${colors.c17};
`

export const UsefulInfo = styled.div`
  > * {
    &:not(:last-child) {
      border-bottom: 2px solid black;
    }
  }
`

export const InfoItem = styled.div`
  padding: 17px 24px;
  display: flex;
  align-items: center;

  > svg {
    height: 24px;
    width: 24px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  > a {
    color: white;
  }

  ${md} {
    padding: 12px 24px;
    font-size: 14px;

    > svg {
      height: 20px;
      width: 20px;
      margin-right: 14px;
    }
  }
`

export const InfoItemEnd = styled.div`
  padding: 24px;
  font-size: 24px;

  ${md} {
    padding: 12px 24px;
    font-size: 14px;
  }
`

export const SocialLink = styled.a`
  background: ${colors.c1};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;

  > svg {
    height: 22px;
    width: 22px;
    color: white;
  }
`

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;

  > a {
    margin-right: 16px;
  }
`
