import React from 'react'

const ArrowLeftIcon = () => {
  return (
    <svg
      viewBox='0 0 330 330'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path id="XMLID_28_" d="M315,150H51.213l49.393-49.394c5.858-5.857,5.858-15.355,0-21.213c-5.857-5.857-15.355-5.857-21.213,0
    l-75,75c-5.858,5.857-5.858,15.355,0,21.213l75,75C82.323,253.535,86.161,255,90,255c3.839,0,7.678-1.465,10.606-4.394
    c5.858-5.857,5.858-15.355,0-21.213L51.213,180H315c8.284,0,15-6.716,15-15S323.284,150,315,150z"/>
    </svg>
  )
}

export default ArrowLeftIcon
