import {
  Wrapper,
  AutocompleteWrapper,
  Result,
  SearchWrapper,
  SearchContent,
  TagInputWrapper,
  AutocompleteResults
} from './styled'
import React, {useState, useRef} from 'react'
import {useOnClickOutside} from 'utils'
import {Icon, Loader} from 'components'
import {useQuery, gql} from '@apollo/client'

const GET_EVENT_TAGS = gql`
  query eventTags {
    event_tags (distinct_on: tag_id) {
      id
      event_id
      tag_id
      tag {
        id
        name
      }
    }
  }
`

const AutocompleteTag = props => {
  const [showResults, setShowResults] = useState(false)
  const [tagName, setTagName] = useState('')
  const {loading: tagsLoading, data: tagsData} = useQuery(GET_EVENT_TAGS)
  const ref = useRef()

  useOnClickOutside(ref, () => setShowResults(false))

  // const tags = query.event_tags({
  //   distinct_on: 'tag_id'
  //   // where: {
  //   //   event: {
  //   //     status: {
  //   //       _eq: 1
  //   //     }
  //   //   }
  //   // }
  // })
  const tags = tagsData.event_tags;
  const {onChange, tags: tagProp = [], withValue} = props

  const mapResults = tags.filter(t => t?.tag?.name?.includes(tagName.toLowerCase())).slice(0, 9)

  const handleOnClick = item => {
    if (tagProp.some(tag => tag === item.tag.name.toLowerCase())) {
      return onChange(tagProp.filter(t => t !== item.tag.name.toLowerCase()))
    }

    if (withValue) {
      onChange([...tagProp, {
        label: item.tag.name.toLowerCase(),
        value: item.tag.id
      }])
    } else {
      onChange([...tagProp, item.tag.name.toLowerCase()])
    }

    setTagName('')
  }

  const renderResult = (item, idx) => {
    return (
      <Result
        key={idx}
        onClick={() => handleOnClick(item)}
        isActive={tagProp.some(tag => tag === item.tag.name.toLowerCase())}
      >
        <span>{item.tag.name}</span>
        <Icon
          name='close'
        />
      </Result>
    )
  }

  if (loading) {
    return (
      <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <p>Loading</p>
        <div style={{marginLeft: '10px'}}>
          <Loader/>
        </div>

      </div>
    )
  }


  return (
    <Wrapper onFocus={() => setShowResults(true)} ref={ref}>
      <AutocompleteWrapper>
        <SearchWrapper>
          <SearchContent>
            <TagInputWrapper>
              <input
                {...props}
                placeholder={tagProp.length ? `${tagProp.length} keywords selected` : 'Search keywords'}
                value={tagName || ''}
                onChange={e => setTagName(e.target.value)}
              />
            </TagInputWrapper>
          </SearchContent>
        </SearchWrapper>
        {
          showResults && (
            <AutocompleteResults>
              {mapResults.map(renderResult)}
            </AutocompleteResults>
          )
        }
      </AutocompleteWrapper>
    </Wrapper>
  )
}

export default AutocompleteTag
