import styled, { css } from 'styled-components'
import { maxDesktopWidth, colors, breakpoints } from 'constant'

const { md } = breakpoints

export const BannerWrapper = styled.div`
  height: 600px;
  width: 100%;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;

  &:before {
    content: '';
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    position: absolute;
    height: 100px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:after {
    content: '';
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    position: absolute;
    height: 80px;
    right: 0;
    top: 0;
    left: 0;
  }

  ${({ isMobile }) => isMobile && `
    min-height: 100vh;
  `}
`

export const PhotoButton = styled.button`
  display: flex;
  align-items: center;
  color: white;
  background: ${colors.c1};
  border: none;
  height: 50px;
  justify-content: center;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;
  width: 160px;
  align-self: flex-end;
  margin-top: 16px;

  > svg {
    margin-right: 8px;
    height: 18px;
    width: 18px;
  }
`

export const PriceButton = styled.a`
  display: flex;
  align-items: center;
  background: ${colors.c2};
  color: white;
  border-radius: 24px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  width: 220px;
  height: 50px;
  margin-top: 28px;
  display: flex;
  align-self: center;
  justify-content: center;
`

export const ButtonRow = styled.div`
  display: flex;
`

export const BannerContentBg = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
`

const bannerContentMobile = css`
  flex-wrap: wrap;
  padding: 16px;
  margin-bottom: 90px;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const BannerContent = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  padding: 12px 12px 40px 12px;
  justify-content: space-between;
  padding-bottom: 180px;
  
  ${({ isMobile }) => !isMobile && `
    width: ${maxDesktopWidth};
    max-width: 100%;
  `}

  ${({ isMobile }) => isMobile && bannerContentMobile}
`

export const LocationWrapper = styled.div`
  line-height: 22px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;

  > svg {
    height: 18px;
    width: 18px;
    flex-shrink: 0;
    margin-right: 4px;
  }

  ${md} {
    font-size: 16px;
    align-items: flex-start;
    margin-top: 12px;

    > svg {
      margin-top: 12px;
      height: 14px;
      width: 14px;
    }
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    font-family: 'Bebas Neue';
    font-size: 64px;
    letter-spacing: 2px;
  }

  > h4 {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 500;
  }

  > button {
    display: flex;
    align-items: center;
    color: white;
    background: ${colors.c1};
    border: none;
    height: 48px;
    justify-content: center;
    border-radius: 100px;
    font-size: 14px;
    cursor: pointer;
    width: 160px;
    align-self: center;
    margin-top: 12px;
    margin-bottom: 12px;

    > svg {
      margin-right: 8px;
      height: 18px;
      width: 18px;
    }
  }

  ${md} {
    width: 100%;

    > h1 {
      font-size: 24px;
    }
  }
`

export const MobileInfo = styled.div`
  
`

export const Right = styled.div`
  > button {
    display: flex;
    align-items: center;
    color: white;
    background: ${colors.c1};
    border: none;
    padding: 15px 24px;
    border-radius: 100px;
    font-size: 16px;
    cursor: pointer;

    > svg {
      margin-right: 12px;
      height: 24px;
      width: 24px;
    }
  }

  ${md} {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    > button {
      font-size: 14px;
      padding: 12px 20px;

      > svg {
        height: 18px;
        width: 18px;
        margin-right: 8px;
      }
    }
  }
`

export const TagItem = styled.div`
  text-transform: capitalize;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.c3};
  border: 1px solid ${colors.c2};
  background: rgba(194, 32, 38, 0.16);
  border-radius: 50px;
  backdrop-filter: blur(16px);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 24px;

  ${md} {
    font-size: 12px;
    padding: 8px 14px;
  }
`

export const TagWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  text-transform: capitalize;

  > div {
    &:not(:last-child){
      margin-right: 24px;
    }
  }

  ${md} {
    flex-wrap: wrap;

    > div {
      margin-bottom: 8px;
      
      &:not(:last-child){
        margin-right: 4px;
      }
    }
  }
`
