import styled from 'styled-components'


export const CarouselWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%
`

export const DotWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`

export const CarouselDot = styled.div`
    -webkit-transition: opacity 0.25s ease-in;
    -moz-transition: opacity 0.25s ease-in;
    -ms-transition: opacity 0.25s ease-in;
    -o-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity = 30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;

    opacity: ${({ $isSelected }) => $isSelected ? '1' : '0.3'};
`