import React from 'react'
import { Wrapper, LinkBtn, AnchorBtn } from './styled'

const Button = (props) => {
  const { onClick, to, href, target, disabled, type, loading, plain } = props

  if (href) {
    return (
      <AnchorBtn $plain={plain} href={href} target={target}>
        {props.children}
      </AnchorBtn>
    )
  }

  if (to) {
    return (
      <LinkBtn $plain={plain} to={to}>
        {props.children}
      </LinkBtn>
    )
  }

  return (
    <Wrapper
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      isLoading={loading}
      $plain={plain}
    >
      {props.children}
    </Wrapper>
  )
}

export default Button
