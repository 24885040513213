import React from 'react'

const TicketIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.5 12.25C19.4996 11.3636 19.8131 10.5056 20.385 9.82835C20.9568 9.15106 21.75 8.69817 22.624 8.55C22.7989 8.52069 22.9577 8.43038 23.0723 8.29509C23.1869 8.15981 23.2498 7.9883 23.25 7.811V5.5C23.25 5.10218 23.092 4.72064 22.8107 4.43934C22.5294 4.15804 22.1478 4 21.75 4H2.25C1.85218 4 1.47064 4.15804 1.18934 4.43934C0.908035 4.72064 0.75 5.10218 0.75 5.5V7.813C0.74996 7.99035 0.812854 8.16196 0.927488 8.29729C1.04212 8.43262 1.20106 8.52288 1.376 8.552C2.25189 8.69793 3.0476 9.14985 3.62157 9.82736C4.19555 10.5049 4.51056 11.364 4.51056 12.252C4.51056 13.14 4.19555 13.9991 3.62157 14.6766C3.0476 15.3541 2.25189 15.8061 1.376 15.952C1.20114 15.9813 1.04232 16.0716 0.927724 16.2069C0.813126 16.3422 0.75016 16.5137 0.75 16.691V19C0.75 19.3978 0.908035 19.7794 1.18934 20.0607C1.47064 20.342 1.85218 20.5 2.25 20.5H21.75C22.1478 20.5 22.5294 20.342 22.8107 20.0607C23.092 19.7794 23.25 19.3978 23.25 19V16.687C23.25 16.5096 23.1871 16.338 23.0725 16.2027C22.9579 16.0674 22.7989 15.9771 22.624 15.948C21.7504 15.7999 20.9574 15.3473 20.3856 14.6704C19.8138 13.9935 19.5001 13.1361 19.5 12.25V12.25Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.75 17.5V16"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.75 13V11.5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.75 8.5V7"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 17.5V16"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 13V11.5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 8.5V7"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default TicketIcon
