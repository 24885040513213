import styled, { css } from 'styled-components'
import { colors, maxDesktopWidth, breakpoints } from 'constant'

const { md } = breakpoints

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const TextWrapper = styled.div`
    text-align: center;
    width: 75%;
    margin-bottom: 25px;
`

export const InputWrapper = styled.div`
    width: 100%;
    margin-bottom: 25px;
`
export const AboutWrapper = styled.div`
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
`

export const TextAreaContainer = styled.div`
    margin-top: 16px;
`

export const ButtonWrapper = styled.div`
    width: 50%
`

export const CloseIcon = styled.div`
    align-self: flex-end;
    cursor: pointer;
`
