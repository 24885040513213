import React from 'react'
import {
  GalleryUploadWrapper,
  HeroUploadWrapper,
  TitleWrapper,
  ButtonContainer
} from './styled'
import Uploader from './Uploader'
import GalleryUploader from './GalleryUploader'
import { Button } from 'components'

const UploadImage = (props) => {
  const { handlePrevious } = props
  return (
    <div>
      <TitleWrapper>
        <h1>Upload Images</h1>
        <p>
          Upload images that best represent your organization and what you'd
          like to showcase.
        </p>
      </TitleWrapper>

      <HeroUploadWrapper>
        <p>Add a hero image for your profile</p>

        <Uploader />
      </HeroUploadWrapper>

      <GalleryUploadWrapper>
        <p>Add Images to your photo gallery</p>

        <GalleryUploader />
      </GalleryUploadWrapper>

      <ButtonContainer>
        <Button plain onClick={() => handlePrevious()}>
          Back
        </Button>
      </ButtonContainer>
    </div>
  )
}

export default UploadImage
