import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'
import { Link } from 'react-router-dom'

const { md } = breakpoints

export const PageWrapper = styled.div`
  display: flex;
`

export const SideBarWrapper = styled.div`
  height: 100vh;
  width: 235px;
  background-color: ${colors.c17};
`

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-top: 80px; */
`

export const Headers = styled.div`
  width: 30%;
  position: relative;
  z-index: 20;
  padding-top: 20px;
  display: flex;
  align-items: center;

  > h2 {
    font-size: 20px;
    margin-left: 15px;
  }
`

export const TopBackButton = styled(Link)`
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.c17};
  border: none;
  cursor: pointer;
  height: 45px;
  width: 45px;
  border-radius: 50%;

  > svg {
    height: 20px;
    width: 20px;
    color: ${colors.mainText};
  }
`

export const FormWrapper = styled.div`
  width: 100%;
  background-color: ${colors.c17};
  margin-top: 25px;
  border-radius: 16px;
  padding: 30px;
`

export const GeneralInfoWrapper = styled.div``

export const GeneralInfoHeader = styled.div`
  margin-bottom: 20px;
`

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  > div {
    width: calc((100% / 2) - 8px);
  }
`

export const AboutWrapper = styled.div`
  margin-top: 20px;
`

export const AboutHeader = styled.div`
  margin-top: 20px;
`

export const TextAreaContainer = styled.div`
  margin-top: 16px;
`

export const TagsHeader = styled.div`
  margin-top: 20px;
`

export const TagsContainer = styled.div`
  margin-top: 20px;
`

export const TagsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  > p {
    margin-bottom: 10px;
  }

  > div {
    width: 100%;
  }
`

export const CTAContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;

  > button {
    width: calc((100% / 4) - 5px);
  }
`
