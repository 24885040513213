import config from 'config'
import request from 'superagent'

const { rcaApi } = config

export const applicationSubmitted = async ({ token }) => {
  try {
    await request
      .post(`${rcaApi}/application-submitted`)
      .send()
      .set('Authorization', token)

    return true
  } catch (e) {
    const { message } = e

    if (message) {
      window.alert(message)
    }

    return false
  }
}
