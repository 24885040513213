import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'
import { Link } from 'react-router-dom'

const { md } = breakpoints

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BannerImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url('https://red-curtain-addict.imgix.net/partnership/audiencesClapping.JPG?compression=auto');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    height: 700px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ${md} {
    height: 100%;
  }
`

export const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  ); /* Adjust the alpha value for darkness */

  ${md} {
    height: 100%;
  }
`

export const TitleContainer = styled.div`
  position: relative;
  z-index: 1; /* Ensure the texts are above the overlay */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  height: 100%;
  padding: 20px 80px;
  box-sizing: border-box;
  color: white;

  ${md} {
    padding: unset;
    margin-top: 90px;
  }
`

export const BannerTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 10px;

  ${md} {
    font-size: 23px;
  }
`

export const BannerSubtitle = styled.p`
  width: 100%;
  font-size: 16px;
`

export const BannerGetstartedCta = styled.div`
  width: 100%;
  margin-top: 32px;

  > button {
    width: 200px;
  }

  ${md} {
    margin-bottom: 32px;
    > a {
      width: 100%;
    }
  }
`

const row = css`
  width: 80%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  z-index: 2;

  ${md} {
    width: 90%;
  }
`

export const StepIcons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transform: translateY(51px);
`

export const RequestInfoCta = styled.div`
  ${row}
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;

  > button {
    width: 300px;
  }
`

export const Section = styled.div`
  ${row}
  padding: 0 80px;

  > h1 {
    width: 100%;
    text-align: center;
  }

  ${md} {
    padding: 0;

    > h1 {
      font-size: 23px;
    }
  }
`

export const CultivationSection = styled.div`
  ${row}
  padding: 0 100px;
  gap: 50px;

  ${({ $reverse }) =>
    $reverse &&
    css`
      flex-direction: row-reverse;
    `}

  ${md} {
    padding: 0;
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
`

export const SectionHeader = styled.div`
  width: 100%;
  text-align: center;

  > h1 {
    ${md} {
      font-size: 23px;
    }
  }
`

export const CultivationImage = styled.div`
  background-image: url(${({ $imageUrl }) => $imageUrl + '?compression=auto'});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  height: 310px;
  display: flex;
  flex: 1;
  border: 2px solid ${colors.c5};

  ${md} {
    flex: unset;

    height: 200px;
    width: 300px;
  }
`

export const CultivationImageSVG = styled.img`
  border-radius: 20px;
  /* width: 500px; */
  height: 300px;
  width: 400px;
  /* display: flex;
  flex: 1; */
  border: 2px solid ${colors.c5};
`

export const CultivationCaption = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  > h1 {
    font-size: 24px;
    color: ${colors.c24};
    margin-bottom: 18px;
  }

  ${md} {
    > h1 {
      margin-bottom: 10px;
      text-align: center;
      font-size: 20px;
    }

    > p {
      text-align: center;
      font-size: 13px;
    }
  }
`

export const CheckIcon = styled.div`
  display: flex;

  > svg {
    transform: translate(-12px, 5px);
    height: 26px;
    width: 26px;
    background: ${colors.c20};
    padding: 5px;
    border-radius: 50%;
  }
`

export const Benefits = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  > h1 {
    margin-bottom: 32px;
    text-align: center;
  }

  ${md} {
    margin-top: unset;

    > h1 {
      margin-bottom: unset;
      font-size: 23px;
    }
  }
`

export const BenefitsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const BenefitsGridItem = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 10px;
  flex-direction: row;
  display: flex;
  padding: 20px;
  font-size: 18px;

  ${md} {
    flex: unset;
    max-width: unset;
    font-size: 13px;
    padding: 10px;
  }
`

export const ImagesSection = styled.div`
  ${row}

  padding: 0 80px;
  flex-direction: column;
  gap: 20px;

  > h1 {
    width: 100%;
    text-align: center;
  }

  ${md} {
    padding: 0;
    gap: 10px;
  }
`

export const ImagesRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  & > * {
    width: calc(100% / 2);
  }

  ${md} {
    gap: 5px;
    & > * {
      width: 100%;
    }
  }
`

export const SingleImage = styled.div`
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  /* width: 500px; */
  height: 300px;

  ${md} {
    border-radius: 5px;
    height: 100px;
  }
`

export const FourByFour = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  ${md} {
    height: 100px;
    gap: 5px;
  }
`

export const FourByFourItem = styled.div`
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: calc((100% / 2) - 10px);

  ${md} {
    height: 47.5px;
    border-radius: 5px;
    width: calc((100% / 2) - 2.5px);
  }
`

export const ArtAddicts = styled.div`
  width: 100%;
  > h1 {
    margin-bottom: 32px;
    text-align: center;
  }

  ${md} {
    > h1 {
      margin-bottom: unset;
      font-size: 23px;
    }
  }
`

export const ArtAddictsIcon = styled.img`
  height: 60px;
  width: 60px;
`

export const ArtAddictsLabel = styled.div`
  margin-top: 12px;
  text-align: center;
  font-size: 12pt;

  ${md} {
    font-size: 13px;
  }
`

export const ArtAddictsItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  ${md} {
    flex-direction: column;
    gap: 20px;
  }
`

export const ArtAddictsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 5);

  ${md} {
    width: 100%;
  }
`

export const FormWrapper = styled.div`
  position: relative;
  width: 100%; /* Adjust width as needed */
  height: 750px; /* Adjust height as needed */
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${md} {
    height: 972px;
  }
`

export const FormImageBackground = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('https://red-curtain-addict.imgix.net/partnership/partnership12.jpg?compression=auto');
  background-size: cover;
  filter: blur(15px); /* Adjust blur amount as needed */
`

export const FormOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(15px); /* Adjust blur amount as needed */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust overlay color and opacity as needed */
`

export const FormContent = styled.div`
  position: absolute;
  top: 0;
  width: 80%;
  height: 100%;
`

export const Form = styled.form`
  width: 100%;
`

export const FormTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 45px;
  margin-bottom: 45px;

  > h1 {
    text-align: center;
  }
  > p {
    text-align: center;
  }

  ${md} {
    margin-top: 20px;
    margin-bottom: 20px;

    > h1 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    > p {
      font-size: 13px;
    }
  }
`

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 32px;

  & > * {
    flex-grow: 0.5;
  }

  ${md} {
    flex-direction: column;
    gap: unset;

    & > * {
      flex-grow: 1;
    }
  }
`

export const AboutWrapper = styled.div`
  width: 100%;
  /* margin-top: 25px; */
  margin-bottom: 25px;
`

export const TextAreaContainer = styled.div`
  margin-top: 16px;
`

export const SubmitButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  > button {
    width: 300px;
  }
`

export const Testimonials = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > h1 {
    margin-bottom: 32px;
    text-align: center;
  }

  ${md} {
    flex-direction: column;

    > h1 {
      margin-bottom: unset;
      font-size: 23px;
    }
  }
`

export const TestimonialsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${md} {
    flex-direction: column;
  }
`

export const TestimonialsColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  ${md} {
    width: 100%;
  }
`

export const TestimonialsItem = styled.div`
  width: 48%;
  display: flex;
  padding-left: 50px;
  align-items: center;

  ${md} {
    width: 100%;
  }
`

export const TestimonialsPic = styled.div`
  transform: translateX(-54px);
  width: 85px;
  position: absolute;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-color: white;
  background-size: cover;
  background-position: center;
  box-shadow: 10px 0px 32px 13px rgba(0, 0, 0, 0.75);
`

export const Comment = styled.div`
  transform: translateX(-9px);
  padding-left: 50px;

  font-size: 15px;

  ${md} {
    font-size: 13px;
  }
`

export const TestimonialBG = styled.div`
  ${({ $gray }) =>
    $gray
      ? css`
          background: radial-gradient(
            circle,
            rgba(97, 97, 97, 1) 0%,
            rgba(34, 34, 34, 1) 100%
          );
        `
      : css`
          background: radial-gradient(
            circle,
            rgba(209, 47, 195, 1) 0%,
            #b21116 100%
          );
        `}

  background-size: cover;
  background-position: center;
  height: 100px;
  width: 500px;
  border-radius: 20px;
  display: flex;
  align-items: center;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 60vh;

  > h1 {
    margin-bottom: 8px;
  }

  > h4 {
    font-size: 1.17em;
    text-align: center;
    margin-bottom: 15px;
    max-width: 410px;
    width: 80%;
  }
`
export const CloseIcon = styled.div`
  align-self: flex-end;
  cursor: pointer;

  ${md} {
    display: none;
  }
`

export const RcaLogo = styled.div`
  margin-bottom: 15px;
  height: 77px;
  width: 136px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`

export const TextWrapper = styled.div`
  max-width: 410px;
  text-align: center;
  font-size: 0.85em;
  margin-bottom: 50px;
  width: 80%;
`

export const ButtonWrapper = styled.div`
  width: 170px;
`
export const ContentModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 580px;
  width: 70vw;

  ${md} {
    width: unset;
  }

  > h1 {
    margin-bottom: 8px;
  }

  > h4 {
    font-size: 1.17em;
    text-align: center;
    margin-bottom: 15px;
    max-width: 410px;
    width: 80%;
  }
`

export const InputModalRow = styled.div`
  width: 100%;
`

export const GridContainer = styled.div`
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 50px;

  ${md} {
    z-index: 5;
    margin-top: 30px;
    margin-bottom: 20px;
  }
`

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    height: 60px;
    width: 60px;
    z-index: 5;
  }
`

export const StepConnector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 110%;

    ${md} {
      width: 176%;
      transform: rotate(90deg);
    }
  }
`

export const StepInfo = styled.div`
  > h1 {
    text-align: center;
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 18px;
  }

  > p {
    text-align: center;
  }

  ${md} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > h1 {
      margin-top: 0px;
      margin-bottom: 10px;
      text-align: left;
    }

    > p {
      font-size: 13px;
      text-align: left;
    }
  }
`
