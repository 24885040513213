import { colors, breakpoints } from 'constant'
import { Popover } from '@headlessui/react'
import styled, { css } from 'styled-components'

const { md } = breakpoints

export const RcaLogo = styled.div`
  margin-bottom: 15px;
  height: 77px;
  width: 136px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    >h1 {
        margin-bottom: 8px;
    }

    >h4 {
        font-size: 1.17em;
        text-align: center;
        margin-bottom: 15px;
        max-width: 410px;
        width: 80%;
    }

    >a {
        text-decoration: underline;
        color: #888;
    }
`

export const TextWrapper = styled.div`
    max-width: 410px;
    text-align: center;
    font-size: 0.85em;
    margin-bottom: 50px;
    width: 80%;
`

export const ButtonWrapper = styled.div`
    width: 170px;
    margin-bottom: 40px;
`