import React from 'react'

const BackIcon = () => {
  return (
    <svg
      fill="#FFF"
      version="1.1"
      baseProfile="tiny"
      id="Layer_1"
      width="800px"
      height="800px"
      viewBox="0 0 42 42"
    >
      <path d="M31,38.32L13.391,21L31,3.68L28.279,1L8,21.01L28.279,41Z" />
    </svg>
  )
}

export default BackIcon
