import React from 'react'

const StarIcon = () => {
  return (
    <svg
      viewBox='0 0 78 78'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d="m74.89 37.64 1.6-31.12a4.77 4.77 0 0 0 -5-5l-31.13 1.58a4.76 4.76 0 0 0 -3.13 1.39l-33.32 33.32c-3.43 3.43-3.17 9.28.6 13l22.63 22.68c3.77 3.76 9.62 4 13.06.6l33.31-33.31a4.7 4.7 0 0 0 1.38-3.14zm-61.12 3.23a1 1 0 1 1 -1.35-1.35l4-4a1 1 0 0 1 1.35 1.34zm8.23-8.24a1 1 0 0 1 -1.35-1.35l14.48-14.47a1 1 0 0 1 1.35 1.35zm31.31-8a7 7 0 1 1 9.87 0 7 7 0 0 1 -9.86.05z"></path>
    </svg>
  )
}

export default StarIcon
