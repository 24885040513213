import React from 'react'

const ExternalIcon = () => {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d="m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12c.75 0 1.48-.07 2.2-.2l-.82-2.46c-.12.12-.25.22-.38.31v-1.46l-1.86-5.57c-.1-.29-.14-.59-.14-.88v2.03c-.95.04-1.88.12-2.76.27-.18-.94-.3-1.96-.35-3.06h3.11v.76c0-.71.28-1.4.81-1.93.51-.52 1.2-.81 1.94-.81.3 0 .59.05.88.14l5.51 1.84h1.81c-.02.2-.04.39-.08.58l1.93.64c.13-.72.2-1.45.2-2.2 0-6.62-5.38-12-12-12zm-1 17.77v3.88c-.86-.58-1.68-1.86-2.26-3.67.73-.11 1.48-.18 2.26-.21zm-7.4-11.18c.8.38 1.7.7 2.68.96-.21 1.07-.34 2.22-.39 3.43h-3.84c.16-1.6.72-3.09 1.55-4.39zm-1.55 6.39h3.84c.05 1.23.18 2.39.39 3.47-.98.26-1.88.58-2.68.96-.84-1.3-1.39-2.81-1.55-4.43zm5.89 8.15c-1.15-.51-2.18-1.23-3.05-2.11.57-.25 1.2-.46 1.87-.63.32 1.03.72 1.96 1.18 2.74zm-1.18-15.52c-.67-.17-1.3-.38-1.87-.63.87-.88 1.9-1.6 3.05-2.11-.46.78-.86 1.71-1.18 2.74zm4.24 5.37h-3.11c.05-1.08.17-2.09.35-3.02.88.15 1.81.23 2.76.27zm0-4.75c-.78-.03-1.53-.1-2.26-.21.58-1.81 1.4-3.09 2.26-3.67zm2-3.88c.86.58 1.68 1.86 2.26 3.67-.73.11-1.48.18-2.26.21zm0 8.63v-2.75c.95-.04 1.88-.12 2.76-.27.18.93.3 1.94.35 3.02zm3.06-8.11c1.15.51 2.18 1.23 3.05 2.11-.57.25-1.2.46-1.87.63-.32-1.03-.72-1.96-1.18-2.74zm2.05 8.11c-.05-1.21-.18-2.36-.39-3.43.98-.26 1.88-.58 2.68-.96.83 1.3 1.39 2.79 1.55 4.39z"/><path d="m16.917 24c-.005 0-.011 0-.017 0-.316-.007-.594-.212-.694-.513l-3.167-9.5c-.09-.27-.02-.567.181-.768.201-.202.498-.272.768-.181l9.5 3.167c.3.1.505.378.513.694.007.316-.185.604-.481.717l-4.263 1.64-1.64 4.263c-.112.291-.391.481-.7.481z"/>
    </svg>
  )
}

export default ExternalIcon
