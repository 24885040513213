import styled, { css } from 'styled-components'
import { colors, breakpoints } from 'constant'
const { md } = breakpoints

export const DropdownWrapper = styled.div`
  position: relative;
  height: 100%;
  cursor: pointer;

  border-radius: 30px;

  ${md} {
    width: calc(100% / 3);
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      background-color: ${colors.c15};
    `}
`

const showDropdownCss = css`
  display: flex;
  flex-direction: column;
`

export const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  right: 0px;
  background: ${colors.c1};
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px 0px;
  border-radius: 6px;
  display: none;
  z-index: 1;
  border-radius: 16px;
  padding: 20px 16px;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);

  ${({ showDropdown }) => showDropdown && showDropdownCss}

  ${md} {
    padding: 15px;
    min-width: 218px;
    right: auto;
  }
`

const borderTopCss = css`
  border: 1px solid ${colors.c18};
`

const headerCss = css`
  color: ${colors.secondaryText};
  font-size: 12px;

  &:hover {
    background: ${colors.c6};
    cursor: default;
  }
`

export const MenuItem = styled.div`
  padding: 10px 8px;
  font-size: 14px;
  color: ${colors.mainText};
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background: ${colors.c16};
  }

  ${({ borderTop }) => borderTop && borderTopCss}
  ${({ header }) => header && headerCss}

  ${md} {
    padding: 0 0 10px 0;
    font-size: 11px;
  }
`

export const MoreItem = styled.div`
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding-top: 16px;

  ${md} {
    padding-top: 5px;
  }
`
