import styled from 'styled-components'
import { colors, breakpoints } from 'constant'
import { Link } from 'react-router-dom'

const { md, sm, lg } = breakpoints

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;

  position: relative;
  z-index: 2;
`

export const Title = styled.h1`
  color: ${colors.c24};
`

export const Subtitle = styled.h4`
  font-size: 19px;
  margin-bottom: 24px;
`

export const Copy = styled.p`
  max-width: 315px;
  text-align: center;
  font-size: 18px;
`

export const CTA = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  color: white;
  margin-top: 23px;
  cursor: pointer;
`
