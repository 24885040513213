import styled from 'styled-components'

export const MobileFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SearchWrapper = styled.div`
  min-width: 50vw;
  min-height: 80vh;
`

export const RcaLogo = styled.div`
  height: 48px;
  width: 64px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`