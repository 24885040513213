import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;

  > span {
    font-weight: bold;
    margin-left: 10px;
  }
`

export const LogoPlaceholder = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${colors.c15};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.c3};
  font-weight: bold;
`

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 16px;

  > div {
    width: 384px;
  }
`

export const AboutWrapper = styled.div`
  margin-top: 32px;
`

export const TextAreaContainer = styled.div`
  margin-top: 16px;
`

export const SocialLinksWrapper = styled.div`
  margin-top: 32px;
`

export const SocialLinksInputs = styled.div`
  margin-top: 16px;
`

export const VibesWrapper = styled.div`
  margin-top: 32px;
`

export const VibeTagsContainer = styled.div`
  margin-top: 16px;
`

export const TagsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  > p {
    margin-bottom: 10px;
  }

  > div {
    width: 100%;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;

  > button {
    width: 384px;
  }
`
