import React from 'react'
import { LoaderWrapper } from './styled'

const Loader = () => {
  return (
    <LoaderWrapper>
      <div className='lds-roller'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </LoaderWrapper>
  )
}

export default Loader
