import React from 'react'

const EpisodesIcon = () => {
  return (
    <svg
      viewBox='-31 0 512 512'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='m435 512c8.285156 0 15-6.714844 15-15v-482c0-8.285156-6.714844-15-15-15h-420c-8.285156 0-15 6.714844-15 15v482c0 8.285156 6.714844 15 15 15zm-75-181h60v30h-60zm0-30v-30h60v30zm0-60v-30h60v30zm0-60v-30h60v30zm-30 180h-210v-210h210zm-240-180h-60v-30h60zm0 30v30h-60v-30zm0 60v30h-60v-30zm0 60v30h-60v-30zm0 60v30h-60v-30zm30 0h210v91h-210zm240 0h60v30h-60zm60-270h-60v-30h60zm-90 0h-210v-91h210zm-240 0h-60v-30h60zm-60 330h60v31h-60zm330 31v-31h60v31zm60-421h-60v-31h60zm-330-31v31h-60v-31zm0 0' />
      <path d='m187.921875 329.226562c4.867187 2.601563 10.785156 2.332032 15.398437-.746093l90-60c4.171876-2.78125 6.679688-7.464844 6.679688-12.480469s-2.507812-9.699219-6.679688-12.480469l-90-60c-4.601562-3.070312-10.519531-3.355469-15.398437-.746093-4.875 2.613281-7.921875 7.695312-7.921875 13.226562v120c0 5.53125 3.046875 10.613281 7.921875 13.226562zm22.078125-105.199218 47.957031 31.972656-47.957031 31.972656zm0 0' />
    </svg>
  )
}

export default EpisodesIcon
