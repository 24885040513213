import React from 'react'
import { MaxWidthContainerWrapper } from './styled'

const MaxWidthContainer = ({ children }) => {
  return (
    <MaxWidthContainerWrapper>
      <div>
        {children}
      </div>
    </MaxWidthContainerWrapper>
  )
}

export default MaxWidthContainer
