import React from 'react'

import {
  MainWrapper,
  FormWrapper,
  TitleWrapper,
  PromotionsWrapper,
  PromotionItem,
  PromoIcon,
  PromoLabel,
  CTAWrapper,
  TopButton
} from './styled'

import { Icon, Button } from 'components'

const Optin = () => {
  return (
    <MainWrapper>
      <TopButton>
        <Button plain type="button" onClick={() => console.log('Submitting')}>
          Maybe later
        </Button>
      </TopButton>
      <FormWrapper>
        <TitleWrapper>
          <h1>Don't miss a beat</h1>
          <span>Opt in to receiving updates, news, and exclusive offers.</span>
        </TitleWrapper>

        <PromotionsWrapper>
          <PromotionItem>
            <PromoIcon>
              <Icon name="confetti" />
            </PromoIcon>
            <PromoLabel>Win tickets, merch, and more.</PromoLabel>
          </PromotionItem>

          <PromotionItem>
            <PromoIcon>
              <Icon name="ticket" />
            </PromoIcon>
            <PromoLabel>
              Receive updates about arts announcements and special offers.
            </PromoLabel>
          </PromotionItem>

          <PromotionItem>
            <PromoIcon>
              <Icon name="news" />
            </PromoIcon>
            <PromoLabel>
              Relevant updates about industry news and exciting stories.
            </PromoLabel>
          </PromotionItem>
        </PromotionsWrapper>

        <CTAWrapper>
          <Button type="button" onClick={() => console.log('Submitting')}>
            Sign me up
          </Button>
        </CTAWrapper>
      </FormWrapper>
    </MainWrapper>
  )
}

export default Optin
