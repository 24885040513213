import React, { useState, useCallback } from 'react'
import { TabContainer, TabHeader, TabItem, TabContent } from './styled'
import {
  GalleryModalWrapper,
  ImageWrapper,
  Image,
  CloseButton,
  Header
} from 'components/GalleryModal/styled'
import { Modal, Icon } from 'components'
import Carousel, { Modal as ImageModal, ModalGateway } from 'react-images'
import { useStoreon } from 'storeon/react'

const MediaGallery = (props) => {
  const { show, data, handleClose } = props

  const [activeTab, setActiveTab] = useState(1)

  const photos = data.gallery

  const imageArr = photos.map((p) => ({ src: p.imageUrl }))
  const { isMobile } = useStoreon('isMobile')

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((idx) => {
    setCurrentImage(idx)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const renderImage = (item, idx) => {
    return (
      <Image key={idx} onClick={() => openLightbox(idx)} image={item.src} />
    )
  }

  const ImagesTab = () => {
    return (
      <GalleryModalWrapper>
        <ImageWrapper>{imageArr.map(renderImage)}</ImageWrapper>
        <ModalGateway>
          {viewerIsOpen ? (
            <ImageModal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={imageArr.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </ImageModal>
          ) : null}
        </ModalGateway>
      </GalleryModalWrapper>
    )
  }

  return (
    <Modal open={show} onClose={() => handleClose()} fullScreen>
      <TabContainer>
        <TabHeader>
          <TabItem $active={activeTab == 1} onClick={() => setActiveTab(1)}>
            <h2>Images</h2>
          </TabItem>
          <TabItem $active={activeTab == 2} onClick={() => setActiveTab(2)}>
            <h2>Videos</h2>
          </TabItem>
        </TabHeader>

        <TabContent>
          {activeTab === 1 && <ImagesTab />}
          {activeTab === 2 && <p>Coming soon...</p>}
        </TabContent>
      </TabContainer>
    </Modal>
  )
}

export default MediaGallery
