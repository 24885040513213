import config from 'config'
import firebase from 'firebase/app'
import 'firebase/auth'
import toast from 'react-hot-toast'

const { rcaApi } = config

export const updateProfile = async(payload) => {
    try {
        const { token } = payload
        const params = {
            id: payload.id,
            name: payload.name,
            phoneNumber: payload.phoneNumber,
            email: payload.email,
            website: payload.website,
            address: payload.address,
            city: payload.city,
            state: payload.state,
            zipCode: payload.zipCode,
            facebook: payload.facebook,
            instagram: payload.instagram,
            twitter: payload.twitter,
            youtube: payload.youtube,
            about: payload.about,
            tags: payload.tags
        }

        const res = await fetch(`${rcaApi}/venue/updateProfile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
            body: JSON.stringify(params)
        });

        return { res }
    } catch (error) {
        const { message } = error

        if (message) {
          toast.error(`Failed to update venue profile with error: ${message}`)
          console.error(`Failed to update venue profile with error: ${message}`)
        }
    
        return false
    }
}

export const setVenueCover = async(payload) => {
    try {
        const { token } = payload
        const params = {
            venueId: payload.venueId,
            mediaId: payload.mediaId
        }

        const res = await fetch(`${rcaApi}/venue/setVenueCover`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
            body: JSON.stringify(params)
        });

        return { res }
    } catch (error) {
        const { message } = error

        if (message) {
          toast.error(`Failed to set new venue cover image with error: ${message}`)
          console.error(`Failed to set new venue cover image with error: ${message}`)
        }
    
        return false
    }
}

export const uploadVenueGallery = async(payload) => {
    try {
        const { token } = payload
        const fields = {
            venueId: payload.venueId,
            media: payload.media,
        }
        const formData = new FormData();
        for (const key in fields) {
            formData.append(key, fields[key]);
        }

        const res = await fetch(`${rcaApi}/venue/uploadVenueGallery`, {
            method: 'POST',
            headers: {
                'Authorization': `${token}`
            },
            body: formData
        });

        return { res }
    } catch (error) {
        const { message } = error

        if (message) {
          toast.error(`Failed to upload venue media with error: ${message}`)
          console.error(`Failed to upload venue media with error: ${message}`)
        }
    
        return false
    }
}

export const deleteVenueGallery = async(payload) => {
    try {
        const { token } = payload
        const params = {
            venueId: payload.venueId,
            mediaName: payload.mediaName
        }

        const res = await fetch(`${rcaApi}/venue/venueDeleteGallery`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
            body: JSON.stringify(params)
        });

        return { res }
    } catch (error) {
        const { message } = error

        if (message) {
          toast.error(`Failed to delete venue media with error: ${message}`)
          console.error(`Failed to delete venue media with error: ${message}`)
        }
    
        return false
    }
}

export const createEvent = async(payload) => {
    try {
        const { token } = payload

        const fields = {
            name: payload.name,
            about: payload.about,
            artists: payload.artists,
            audience: payload.audience,
            ticket_link: payload.ticket_link,
            eventType: payload.eventType,
            min_price: payload.min_price,
            max_price: payload.max_price,
            netflix_shows: payload.netflix_shows,
            // somethingHere: payload.somethingHere,
            venue_id: payload.venue_id,
            major_city: payload.major_city,
            start_date: payload.start_date,
            end_date: payload.end_date,
            scene: payload.scene,
            tags: JSON.stringify(payload.tags),
            bgImage: payload.bgImage
        };

        const formData = new FormData();
        for (const key in fields) {
            formData.append(key, fields[key]);
        }
        for (let image of payload.gallery) {
            formData.append('gallery', image)
        }

        const res = await fetch(`${rcaApi}/venue/createEvent`, {
            method: 'POST',
            headers: {
                'Authorization': `${token}`
            },
            body: formData
        });

        return { res }
    } catch (error) {
        const { message } = error

        if (message) {
          toast.error(`Failed to create event with error: ${message}`)
          console.error(`Failed to create event with error: ${message}`)
        }
    
        return false
    }
}

export const updateEvent = async(payload) => {
    try {
        const { token } = payload
        const fields = {
            id: payload.id,
            name: payload.name,
            about: payload.about,
            artists: payload.artists,
            audience: payload.audience,
            eventType: payload.eventType,
            ticket_link: payload.ticket_link,
            min_price: payload.min_price,
            max_price: payload.max_price,
            netflix_shows: payload.netflix_shows,
            somethingHere: payload.somethingHere,
            venue_id: payload.venue_id,
            major_city: payload.major_city,
            start_date: payload.start_date,
            end_date: payload.end_date,
            scene: payload.scene,
            tags: JSON.stringify(payload.tags),
            bgImage: payload.bgImage,
            removeImages: payload.removeImages
        };

        const formData = new FormData();
        for (const key in fields) {
            formData.append(key, fields[key]);
        }

        for (let image of payload.gallery) {
            formData.append('gallery', image)
        }            


        const res = await fetch(`${rcaApi}/venue/updateEvent`, {
            method: 'POST',
            headers: {
                'Authorization': `${token}`
            },
            body: formData
        });

        return { res }

    } catch (error) {
        const { message } = error

        if (message) {
          toast.error(`Failed to update event with error: ${message}`)
          console.error(`Failed to update event with error: ${message}`)
        }
    
        return false
    }
}

export const deleteEvent = async(payload) => {
    try {
        const { token } = payload
        const params = {
            eventId: payload.eventId,
            venueId: payload.venueId
        }

        const res = await fetch(`${rcaApi}/venue/deleteEvent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
            body: JSON.stringify(params)
        });

        return { res }
    } catch (e) {
        const { message } = e

        if (message) {
            toast.error(`Failed to delete event with error: ${message}`)
            console.error(`Failed to delete event with error: ${message}`)
        }
    }
}