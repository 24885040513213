import React from 'react'
import {
  Wrapper,
  Info,
  CoverImage,
  TopInfo,
  Tags,
  TagItem,
  AboutWrapper,
  BottomInfo,
  Name
} from './styled'
import { genImagePath, formatDateP } from 'utils'
import { majorCitiesIdx } from 'constant'

const EventCard = (props) => {
  const { data } = props
  const {
    id,
    name,
    about = '',
    min_price: minPrice,
    slug,
    location,
    cover_image_id: coverImageId,
    event_images: eventImages,
    start_date: startDate,
    end_date: endDate,
    event_tags: tags,
    major_city: majorCityIdx
  } = data
  const allImages = eventImages
  const coverImage =
    allImages.find((i) => i.id === coverImageId) || allImages[0] || {}
  const imageUrl = genImagePath({
    ownerType: 1,
    ownerId: id,
    name: coverImage.name
  })
  const majorCity = majorCitiesIdx[majorCityIdx]

  const viewEventTrigger = () => {
    console.log('viewing event')
    gtag('event', 'event_clicked', {
      event_clicked: 1
    })
  }

  const renderTag = (item, idx) => {
    return (
      <TagItem key={idx}>
        <div>{item?.tag?.name}</div>
      </TagItem>
    )
  }

  let dateString

  if (startDate) {
    dateString = formatDateP(startDate)
  }

  if (endDate) {
    dateString = `${dateString} - ${formatDateP(endDate)}`
  }

  return (
    <Wrapper to={`/events/${slug}`} onClick={viewEventTrigger}>
      <CoverImage image={`${imageUrl}?height=300&auto=compression`} />
      <Info>
        <TopInfo>
          <div>
            <Name>{name}</Name>
            <h4>{location}</h4>
          </div>
          <div>
            <h3>
              {majorCity === 'London' ? '£' : '$'}
              {minPrice}
            </h3>
            <h5>Starts at</h5>
          </div>
        </TopInfo>
        <BottomInfo>
          <h5>{dateString}</h5>
          <AboutWrapper>
            <p>{about}</p>
          </AboutWrapper>
          <Tags>{tags.slice(0, 3).map(renderTag)}</Tags>
        </BottomInfo>
      </Info>
    </Wrapper>
  )
}

export default EventCard
