import styled from 'styled-components'
import { colors } from 'constant'

export const LabelWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
`

export const Error = styled.span`
  margin-left: 8px;
  color: ${colors.c2};
  font-weight: 600;
`

export const Tooltip = styled.div`
  margin-left: 6px;
  cursor: pointer;

  svg {
    height: 12px;
    width: 12px;
  }
`
