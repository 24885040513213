import React from 'react'

const ImmersiveIcon = () => {
  return (
    <svg
      viewBox='0 0 64 64'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx="23" cy="25" r="6"/><path d="m19.167 30.167c-2.274-2.274-5.983-2.2-8.164.163l-8.003 8.67v23h6v-16.18l-3.74-3.73 7-8 1.32-1.51 1.42 1.42-7 8 1 1 3 3 7.337-8.004c2.048-2.234 1.973-5.686-.17-7.829z"/><path d="m2 2v14h6l5 5v-5h27v-14zm10 10h-6v-2h6zm24 0h-21v-2h21zm0-4h-30v-2h30z"/><path d="m56 25c0 3.31-2.69 6-6 6-.94 0-1.83-.22-2.62-.6 1.56-.85 2.62-2.5 2.62-4.4h-2c0 1.48-1.09 2.72-2.51 2.95-.93-1.05-1.49-2.43-1.49-3.95 0-3.31 2.69-6 6-6s6 2.69 6 6z"/><path d="m56 31h-11.19c-1.781 0-3.469.791-4.609 2.159l-3.201 3.841-5-5-3 3 5.182 5.829c1.529 1.72 4.19 1.799 5.818.171l4-4v25h5v-16h2v16h5v-26h2v11h4v-10c0-3.314-2.686-6-6-6z"/>
    </svg>
  )
}

export default ImmersiveIcon
