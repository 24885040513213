import React, { useEffect } from 'react'
import { LayoutWrapper, TextModalWrapper } from './styled'
import LayoutHeader from './LayoutHeader'
import LayoutFooter from './LayoutFooter'
import AuthModal from './AuthModal'
import SignUpOptin from './SignUpOptin'
import Div100vh from 'react-div-100vh'
import { useStoreon } from 'storeon/react'
import { mobileBreakpoint } from 'constant'
import { useLocation, useParams } from 'react-router-dom'
import { Modal, Button, Alert } from 'components'
import firebase from 'firebase/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getPrefs } from 'apis'
import FOMOModal from './FOMOModal'

const Layout = ({ children }) => {
  const { isMobile, textModal, notification, dispatch } = useStoreon(
    'isMobile',
    'textModal',
    'notification'
  )
  const { pathname } = useLocation()
  const params = useParams()
  const [user, loading] = useAuthState(firebase.auth())

  useEffect(() => {
    (async () => {
      let userData

      if (user) {
        const token = await user.getIdToken()
        userData = await getPrefs({ token })
      }

      dispatch('setUserState', { user, loading, userData })
    })()
  }, [user, loading, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname, params])

  useEffect(() => {
    const setIsMobile = () => {
      const newIsMobile = window.innerWidth < mobileBreakpoint

      if (isMobile !== newIsMobile) {
        dispatch('setIsMobile', newIsMobile)
      }
    }

    setIsMobile()

    window.addEventListener('resize', setIsMobile)

    return () => {
      window.removeEventListener('resize', setIsMobile)
    }
  }, [dispatch, isMobile])

  const renderAlert = () => {
    if (!notification) {
      return null
    }

    const { message, color, timer } = notification

    return (
      <Alert
        onClose={() => dispatch('setNotification', null)}
        message={message}
        color={color}
        timer={timer}
      />
    )
  }

  const renderTextModal = () => {
    if (!textModal) {
      return null
    }

    return (
      <Modal open onClose={() => dispatch('setTextModal', null)}>
        <TextModalWrapper isMobile={isMobile}>
          <pre>{textModal}</pre>
          <Button onClick={() => dispatch('setTextModal', null)}>Close</Button>
        </TextModalWrapper>
      </Modal>
    )
  }

  return (
    <Div100vh>
      <LayoutWrapper>
        <LayoutHeader />
        {children}
        <LayoutFooter />
        <AuthModal />
        <SignUpOptin />
        <FOMOModal />
        {renderTextModal()}
        {renderAlert()}
      </LayoutWrapper>
    </Div100vh>
  )
}

export default Layout
