import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'
import { Link } from 'react-router-dom'

const { md } = breakpoints

export const HomepageWrapper = styled.div``

export const TopWrapper = styled.div`
  height: 750px;
  width: 100%;
  background-image: url('https://red-curtain-addict.imgix.net/static/mainbanner.jpg?bri=-40&flip=h&compression=auto&height=700');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 32px 16px 32px;

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
  }

  ${md} {
    padding: 84px 16px 24px 16px;
    height: 500px;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    max-width: ${maxDesktopWidth};
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${md} {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  > h1 {
    font-weight: bold;
    font-size: 72px;
    line-height: 72px;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;

    ${md} {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 0px;
    }
  }

  > h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    max-width: 560px;
    width: 100%;
    text-align: center;

    ${md} {
      font-size: 14px;
      line-height: 27px;
    }
  }
`

export const SecondSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
  }

  ${md} {
    padding: 16px;
  }
`

export const MobileEventSection = styled.div`
  background-image: url('https://red-curtain-addict.imgix.net/demo/homepage3.png');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  height: 652px;
  width: 100%;
  padding: 0px 0px 0px 16px;
`

export const EventsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 48px;

  > a {
    width: calc(100% / 4 - 8px);
    margin-right: 16px;

    ${md} {
      width: 100%;
    }
  }

  > a:last-child {
    margin-right: 0px;
  }
`

export const CityGuideWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 48px;

  > a {
    width: calc(100% / 4 - 8px);

    ${md} {
      width: calc(100% / 2 - 4px);
    }
  }
`

export const EventSection = styled.div`
  /* height: 652px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 32px;

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
    display: flex;
    flex-direction: column;

    > h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 30px;
    }

    > div {
      display: flex;
      justify-content: space-between;

      > a {
        width: calc(100% / 4 - 8px);
      }
    }
  }
`

export const CityGuides = styled.div`
  > h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 30px;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }
`

export const MobileSection = styled.div`
  width: 100%;
  z-index: 5;

  > h3 {
    font-weight: bold;
    font-size: 23px;
    line-height: 33px;
    margin-bottom: 30px;
  }

  > div {
    display: flex;
    overflow: scroll;
    z-index: 5;

    > a:first-child {
      margin-left: 16px;
    }

    > a:last-child {
      margin-right: 16px;
    }

    > * {
      flex-shrink: 0;
      width: ${({ itemWidth }) => itemWidth || '280px'};
      margin-right: 6px;
    }

    ${md} {
      margin: 0px -16px;
    }
  }
`

export const CityGuideLink = styled(Link)`
  width: calc((100% / 5) - 16px);
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  height: 180px;
  border-radius: 14px;
  position: relative;
  overflow: hidden;

  > div {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    background: ${colors.c17};
    display: flex;
    align-items: center;
    padding: 0px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
`

export const FourthSection = styled.div`
  padding: 80px 0px;

  ${md} {
    padding: 16px;
  }
`

export const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -60px;
  margin-bottom: 30px;
  padding: 0px 32px;

  > h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 30px;

    ${md} {
      font-size: 23px;
    }
  }

  > a {
    max-width: 250px;
    align-self: center;

    ${md} {
      max-width: 100%;
    }
  }

  ${md} {
    margin-top: 0px;
    padding: 0px;
  }
`

export const NewsArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const RenderedArticles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  ${md} {
    flex-direction: column;
  }

  > a {
    width: calc(100% / 2 - 24px);

    ${md} {
      width: 100%;
      margin-bottom: 32px;
    }
  }
`

export const SignUpSection = styled.div`
  background-image: url('https://rca-s3.imgix.net/static/joincommunity.jpg');
  background-size: cover;
  background-position: center;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${md} {
    padding: 0px 32px;
  }

  > div {
    width: 580px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    > h2 {
      font-size: 48px;
      line-height: 54px;
      text-align: center;
      margin-bottom: 20px;

      ${md} {
        font-size: 32px;
        line-height: 40px;
      }
    }

    > p {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      margin-bottom: 32px;

      ${md} {
        font-size: 20px;
        line-height: 27px;
      }
    }

    > a {
      width: 100%;
    }
  }

  &:before {
    content: '';
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.95),
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    height: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
`

export const FireworkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 30px;

    ${md} {
      font-size: 23px;
    }
  }

  > div {
    min-height: 400px;
  }

  ${md} {
    padding: 0px 0px 32px 0px;

    ${({ user }) => user && 'padding: 0px 0px 32px 0px;'}
  }
`

export const IgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 40px;

  > h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 8px;

    ${md} {
      font-size: 23px;
    }
  }

  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 48px;

    ${md} {
      font-size: 19px;
    }
  }

  > div {
    min-height: 400px;
  }

  ${md} {
    padding: 16px;
  }
`

export const Spotlight = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`

export const SomethingNewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  background-image: url(https://red-curtain-addict.imgix.net/homepage/SomethingNewFormatted.png?height=700);
  background-size: cover;
  background-position: center;
  margin-top: -50px;

  > h1 {
    margin-top: 290px;
    margin-bottom: 20px;
  }

  > div {
    text-align: center;
    margin-bottom: 20px;
  }

  > a {
    max-width: 250px;
    margin-bottom: 20px;
  }
`

export const TopPicks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const TopPicksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -48px;
  margin-bottom: 48px;

  > a {
    width: calc(100% / 5);

    ${md} {
      width: 100%;
    }
  }
`

export const ThirdSection = styled.div`
  background-image: url('https://red-curtain-addict.imgix.net/demo/homepage3.png?bri=4');
  background-size: cover;
  background-position: center;
  max-height: 1800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0px;

  ${md} {
    padding: 16px;
  }
`

export const RecWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${md} {
    padding-top: 40px;
  }
`

export const RecBackground = styled.div`
  background: url(https://red-curtain-addict.imgix.net/preferences/Theater.jpg?auto=compression&blur=150);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  max-width: 1200px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  filter: blur(10px);

  ${md} {
    min-height: 400px;
  }
`

export const BlurProtector = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
  max-width: 1200px;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;

  > h1 {
    max-width: 500px;
    margin-bottom: 30px;
    text-align: center;
  }

  > a {
    max-width: 50%;
    margin-bottom: 10px;
  }
`

export const SignInTag = styled.a`
  text-decoration: none;
  color: #c400cb;
`

export const NoFollowWrapper = styled.div`
  width: 100%;
  padding: 0px 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${md} {
    padding: 0px;
  }
`

export const NoFollowFlex = styled.div`
  width: 1200px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${md} {
    flex-direction: column;
  }
`

export const NoFollowInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;

  > h2 {
    margin-bottom: 30px;
  }

  > p {
    margin-bottom: 30px;
  }

  ${md} {
    margin-left: 0px;
    text-align: center;
  }
`

export const NoFollowImages = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 200px);
  grid-template-rows: repeat(2, 150px);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: end;
  width: 100%;
  margin-right: 50px;

  ${md} {
    margin-right: 0px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 175px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: center;
  }
`

export const NoFollowGridSquare = styled.div`
  background-image: url(${({ $image }) => $image + '&blur=80'});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${md} {
    width: 100%;
    min-height: 175px;
  }

  > img {
    width: 60px;
    height: 60px;
  }
`

export const FollowedVenuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 32px;

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  ${md} {
    padding: 0px;
    margin-bottom: 20px;
  }
`

export const VenueWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 12px;

  > div {
    display: flex;
    flex-direction: column;
    width: calc(100% / 4 - 8px);
    margin-right: 16px;
  }

  > div:last-child {
    margin-right: 0px;
  }
`

export const VenueCard = styled.div`
  width: 100%;
  height: 207px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  background-image: ${({ $isSelected, $image }) =>
    $isSelected
      ? `linear-gradient(98.1deg, rgb(192, 32, 38, 65%) -5.05%, rgb(196, 0, 203, 65%) 101.49%), url(${$image})`
      : `url(${$image})`};
`

export const ImageLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-align: center;

  ${md} {
    margin-top: 8px;
    font-size: 14px;
    font-weight: normal;
  }
`

export const VenueButtonWrapper = styled.div`
  max-width: 300px !important;
`

export const PaddingWrapper = styled.div`
  padding: 0px 32px;
  display: flex;
  justify-content: center;

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
  }

  ${md} {
    padding: 0px;
  }
`
export const VenueMobileSection = styled.div`
  width: 100%;
  padding-top: 32px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  > h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;

    ${md} {
      font-size: 23px;
    }
  }

  .carousel-tooltip {
    background: ${colors.c18};
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 400;
    letter-spacing: 0px;
    text-wrap: wrap;
    width: 325px;
    border-radius: 24px;
    border: 1px solid black;
  }

  ${md} {
    justify-content: flex-start;
  }
`

export const IconWrapper = styled.div`
  width: 15px;
  margin-left: 5px;
  margin-bottom: 10px;
`

export const VenueSelectWrapper = styled.div`
  overflow: auto;
  display: flex;

  > *:first-child {
    margin-left: 32px;

    ${md} {
      margin-left: 16px;
    }
  }

  > *:last-child {
    ${md} {
      margin-right: 16px;
    }
  }

  > * {
    flex-shrink: 0;
    width: 44vw;
    margin-right: 5px;
  }

  ${md} {
    margin: 0px -16px;
  }
`

export const SomethingNewMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 140px;
  margin-bottom: 30px;

  > a {
    max-width: 250px;
    margin-bottom: 20px;
  }
`

export const SomethingNewMobileImage = styled.div`
  background-image: url(https://red-curtain-addict.imgix.net/homepage/something-new.png?height=700);
  background-size: cover;
  background-position: center;
  min-height: 270px;
  width: 100%;
  position: absolute;
  top: -170px;
`

export const SomethingNewMobileBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 5;

  > h2 {
    margin-bottom: 20px;

    ${md} {
      font-size: 23px;
    }
  }

  > p {
    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
  }

  > a {
    max-width: 100%;
  }
`

export const VideoWrapper = styled.div`
  height: 500px;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    /* width: ${maxDesktopWidth}; */
    max-width: 100%;
  }

  ${md} {
    height: 300px;
  }

  > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const VideoContent = styled.div`
  position: absolute;
  height: 500px;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  padding: 96px 32px 16px 32px;
  width: ${maxDesktopWidth};

  ${md} {
    height: 300px;
    padding: 84px 16px 24px 16px;
  }
`

export const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  height: 500px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  ${md} {
    height: 300px;
  }
`
