import {ApolloClient, InMemoryCache, HttpLink} from '@apollo/client';
import config from 'config'
import fetch from 'cross-fetch';

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    ssrMode: true,
    link: new HttpLink({
        uri: config.gqlEndpoint,
        fetch,
    })
});

export default client;