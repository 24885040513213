import React, { useState, useEffect } from 'react'

import { TextInput, MultiPick, Button } from 'components'

import {
  MainWrapper,
  FormWrapper,
  TitleWrapper,
  InputWrapper,
  VibeHeader,
  VibeTagsContainer,
  CTAWrapper
} from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useStoreon } from 'storeon/react'
import { terms, privacy, vibes } from 'constant'

import Uploader from './Uploader'

const BasicInformation = () => {
  const [hasErrors, setHasErrors] = useState(true)

  // const [selectedVibes, setSelectedVibes] = useState([])
  const [photo, setPhoto] = useState({})
  const { user, loading, redirect, isMobile, dispatch } = useStoreon(
    'user',
    'loading',
    'redirect',
    'isMobile'
  )

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required')
    }),
    onSubmit: (values) => {
      // Handle form submission here
      // console.log({ ...values, selectedVibes: selectedVibes, photo })
    }
  })

  useEffect(() => {
    // Check if there are errors in formik
    const hasFormErrors = Object.keys(formik.errors).length > 0
    const touch = Object.keys(formik.touched).length > 0

    // Update the state variable
    console.log(hasFormErrors)

    if (touch) {
      setHasErrors(hasFormErrors)
    }
  }, [formik.errors, formik.touched])

  return (
    <MainWrapper>
      <FormWrapper>
        <TitleWrapper>
          <h1>Basic Information</h1>
          <span>Get started by telling us a little bit about you.</span>
        </TitleWrapper>

        <Uploader handleUpdate={(e) => setPhoto(e)} />

        <InputWrapper>
          <TextInput
            error={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ''
            }
            placeholder="First Name"
            value={formik.values.firstName}
            returnValueOnChange={false}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            name="firstName"
          />

          <TextInput
            error={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ''
            }
            placeholder="Last Name"
            value={formik.values.lastName}
            returnValueOnChange={false}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            name="lastName"
          />
        </InputWrapper>

        {/* <VibeHeader>
          <span>Which words best explain your vibe?</span>
        </VibeHeader> */}

        {/* <VibeTagsContainer>
          <MultiPick
            col={3}
            options={vibes}
            value={selectedVibes}
            onChange={(value) => setSelectedVibes(value)}
          />
        </VibeTagsContainer> */}

        <CTAWrapper>
          <Button
            type="button"
            disabled={hasErrors}
            onClick={() => formik.handleSubmit()}
          >
            Next
          </Button>
        </CTAWrapper>
      </FormWrapper>
    </MainWrapper>
  )
}

export default BasicInformation
