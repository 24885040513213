import React, { useState, useEffect } from 'react'
import {
  FormWrapper,
  TopicsGridContainer,
  TopicsGridItem,
  TopicImage,
  TopicLabel,
  TopicOvelay,
  CTAWrapper,
  PageNumber,
  PageTitle,
  CheckIcon
} from '../styled'
import { capitalizeFirstLetter } from 'utils'
import { majorCities } from 'constant'
import { Icon, Button } from 'components'
import { useStoreon } from 'storeon/react'

const FirstPage = ({
  selected,
  onChange,
  onNext
  // zipcode,
  // onZipcodeChange
}) => {
  const { user, dispatch, isMobile, loading } = useStoreon(
    'user',
    'loading',
    'isMobile'
  )
  const [hasErrors, setHasErrors] = useState(true)
  // const [zipcode_, setZipcode_] = useState(
  //   currentZipCode ? currentZipCode : ''
  // )

  // const handleZipcodeChange = (e) => {
  //   e.stopPropagation()
  //   const value = e.target.value
  //   onZipcodeChange(value)
  // }

  useEffect(() => {
    if (selected.length > 0) {
      setHasErrors(false)
    } else {
      setHasErrors(true)
    }
  }, [selected, hasErrors])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <PageNumber>1/4</PageNumber>
      <PageTitle>
        <p>What city do you want to see upcoming performances in?</p>
      </PageTitle>
      <FormWrapper>
        <TopicsGridContainer>
          {majorCities.map((item, idx) => {
            const { value, label, id, imageName, thumbnailUrl } = item

            const isSelected = selected.some(
              (i) => i === item.value || i?.value === item.value
            )

            const city = label.split(',')[0].trim()

            const columns = !isMobile ? 4 : 3
            const height = !isMobile ? 200 : 143
            const name = capitalizeFirstLetter(city)

            const imageLink = thumbnailUrl

            return (
              <TopicsGridItem
                $column={columns}
                $height={height}
                key={value}
                onClick={() => onChange(item)}
              >
                <TopicOvelay $height={height} $isSelected={isSelected}>
                  <TopicImage $imageUrl={imageLink}>
                    {isSelected && (
                      <CheckIcon>
                        <Icon name="check" />
                      </CheckIcon>
                    )}
                  </TopicImage>
                </TopicOvelay>
                <TopicLabel>{name}</TopicLabel>
              </TopicsGridItem>
            )
          })}
        </TopicsGridContainer>

        {/* <ZipCodeContainer>
            <span>What City do you live in?</span>

            <TextInput
              // error={
              //   formik.touched.email && formik.errors.email
              //     ? formik.errors.email
              //     : ''
              // }
              placeholder="Enter your Zip Code Here!"
              value={zipcode}
              returnValueOnChange={false}
              onChange={handleZipcodeChange}
              name="zipcode"
            />
          </ZipCodeContainer> */}

        <CTAWrapper $margin={32}>
          <Button
            type="button"
            // disabled={selectedCities.length < 1}
            // onClick={() => formik.handleSubmit()}
            onClick={() => {
              onNext()
            }}
          >
            Next
          </Button>
        </CTAWrapper>
      </FormWrapper>
    </>
  )
}

export default FirstPage
