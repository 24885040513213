import styled from 'styled-components'

export const BannerWrapper = styled.div`
  height: ${({ $height }) => ($height ? $height + 'px' : '420px')};
  width: 100%;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${({ backgroundPosition }) => backgroundPosition};
  position: relative;
  display: flex;

  &:before {
    content: '';
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    height: 100px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:after {
    content: '';
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    height: 80px;
    right: 0;
    top: 0;
    left: 0;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    min-height: 70vh;
  `}
`

export const BannerContentBg = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: rgba(0, 0, 0, 0.6);
`
