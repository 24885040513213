import React from 'react'
import { BannerWrapper, BannerContentBg } from './styled'
import { useStoreon } from 'storeon/react'

const Banner = (props) => {
  const { image, children, backgroundPosition = 'center', height } = props
  const { isMobile } = useStoreon('isMobile')

  return (
    <BannerWrapper
      $height={height}
      image={image}
      isMobile={isMobile}
      backgroundPosition={backgroundPosition}
    >
      <BannerContentBg>{children}</BannerContentBg>
    </BannerWrapper>
  )
}

export default Banner
