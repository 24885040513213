export const genPagination = data => {
  const { total, perPage, isMobile } = data
  const currentPage = +data.currentPage
  const totalPages = Math.ceil(total / perPage)
  let pages = []
  const pageSize = isMobile ? 5 : 7
  const pageLeftSize = isMobile ? 3 : 5
  const pageSubtract = 3

  if (totalPages < pageSize) {
    let pageCount = 1

    while (pageCount <= totalPages) {
      pages.push(pageCount)
      pageCount++
    }
  } else {
    if (currentPage < pageLeftSize) {
      pages = isMobile ? [1, 2, 3, '...', totalPages] : [1, 2, 3, 4, 5, '...', totalPages]
    } else if (currentPage > totalPages - pageSubtract) {
      pages = [1, '...']

      let pageCount = totalPages - pageSubtract

      while (pageCount <= totalPages) {
        pages.push(pageCount)
        pageCount++
      }
    } else {
      pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages]
    }
  }

  return {
    pages,
    showPrevious: currentPage !== 1,
    showNext: currentPage !== totalPages
  }
}
