import styled from 'styled-components'
import { colors } from 'constant'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.c15};
  padding: 12px 16px;
  border-radius: 10px;
  color: ${colors.mainText};
  position: relative;
`

export const CoreInput = styled.input`
  background: none;
  border: none;
  padding: 0px 6px;
  color: ${colors.mainText};
  margin-top: 6px;
  font-size: 16px;
`

export const PasswordToggle = styled.div`
  > svg {
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 12px;
    right: 16px;
    opacity: 0.8;
  }
`
