import React from 'react'

import { Label, Input, Checkmark, Text } from './styled'

import { Icon } from 'components'

const RadioButton = ({ label, checked, onChange, value }) => {
  return (
    <Label>
      <Input type="radio" value={value} checked={checked} onChange={onChange} />
      {checked ? <Icon name="radioChecked" /> : <Checkmark />}
      <Text>{label}</Text>
    </Label>
  )
}

export default RadioButton
