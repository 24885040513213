import React from 'react'
import { MultiPickWrapper, Option } from './styled'
import { Icon } from 'components'
import { toggleArr } from 'utils'

const MultiPick = (props) => {
  const { options, value, onChange, removeOnly, col = 2, lighter } = props

  const handleOnClick = (item) => {
    if (removeOnly) {
      return onChange(value.filter((t) => t.value !== item.value))
    }

    onChange(toggleArr(value, item.value))
  }

  const renderOption = (item, idx) => {
    return (
      <Option
        $col={col}
        $lighter={lighter}
        key={idx}
        onClick={() => handleOnClick(item)}
        isActive={value.some(
          (i) => i === item.value || i?.value === item.value
        )}
      >
        <span>{item.label}</span>
        <Icon name="close" />
      </Option>
    )
  }

  return <MultiPickWrapper>{options.map(renderOption)}</MultiPickWrapper>
}

export default MultiPick
