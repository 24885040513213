import styled, { css } from 'styled-components'
import { colors } from 'constant'

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const withError = css`
  border-color: ${colors.error};
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${colors.c18};
  padding: 18px 16px;
  border-radius: 8px;
  color: ${colors.mainText};
  position: relative;
  border: 1px solid ${colors.c18};
  transition: border-color 300ms ease;
  ${({ $withError }) => $withError && withError};

  > input {
    background-color: ${colors.c18} !important;
    -webkit-text-fill-color: ${colors.mainText};
    transition: background-color 5000s ease-in-out 0s;
  }

  &:hover {
    border-color: ${colors.c4};
  }
`

export const CoreInput = styled.input`
  background: none;
  border: none;
  padding: 0px 6px;
  color: ${colors.mainText};
  font-size: 16px;
`

export const PasswordToggle = styled.div`
  > svg {
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 18px;
    right: 16px;
    opacity: 0.8;
  }
`

export const ErrorMessage = styled.div`
  color: ${colors.error};
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
`
