import React from 'react'
import {
  TablePaginationWrapper,
  TablePaginationItem,
  TablePaginationEnds,
  TablePaginationCenter
} from './styled'
import { useHistory } from 'react-router-dom'
import { genPagination, getQuery, updateQuery } from 'utils'
import { Icon } from 'components'
import { useStoreon } from 'storeon/react'

const Pagination = ({ metadata, onChange }) => {
  const history = useHistory()
  const { page = 1 } = getQuery(history)
  const { isMobile } = useStoreon('isMobile')

  const handlePaginationItemClick = (value) => {
    if (value === '...') {
      return null
    }

    window.scrollTo(0, 0)

    updateQuery({
      history,
      newParams: { page: value }
    })

    if (onChange) {
      onChange()
    }
  }

  const renderPaginationItem = (item, idx) => {
    return (
      <TablePaginationItem
        key={idx}
        onClick={() => handlePaginationItemClick(item)}
        active={item === +page}
      >
        {item}
      </TablePaginationItem>
    )
  }

  const { pages, showPrevious, showNext } = genPagination({
    ...metadata,
    currentPage: page,
    isMobile
  })

  return (
    <TablePaginationWrapper>
      <TablePaginationEnds
        disabled={!showPrevious}
        onClick={() => handlePaginationItemClick(+page - 1)}
      >
        <Icon name="arrowLeft" />
        <span>
          Prev<span>ious</span>
        </span>
      </TablePaginationEnds>
      <TablePaginationCenter>
        {pages.map(renderPaginationItem)}
      </TablePaginationCenter>
      <TablePaginationEnds
        disabled={!showNext}
        onClick={() => handlePaginationItemClick(+page + 1)}
      >
        <span>Next</span>
        <Icon name="arrowRight" />
      </TablePaginationEnds>
    </TablePaginationWrapper>
  )
}

export default Pagination
