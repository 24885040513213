import React from 'react'
import { LabelWrapper, Tooltip, Error } from './styled'
import { Icon } from 'components'

const Label = props => {
  const { label, error, tooltip } = props

  return (
    <LabelWrapper>
      <span>{label}</span>
      {tooltip && (
        <Tooltip data-tip={tooltip} data-effect='solid'>
          <Icon name='info' />
        </Tooltip>
      )}
      {error && <Error>*{error}</Error>}
    </LabelWrapper>
  )
}

export default Label
