export default {
  initialState: {
    isMobile: true,
    showAuth: false,
    textModal: null,
    notification: null,
    articles: null,
    article: null,
    relatedArticles: null,
    showSearchModal: false,
    showSearchOptin: false,
    showSignupModal: true,
    showSignupOptin: false,
    showFOMOModal: false,
    returnURL: '/'
  },
  actions: [
    {
      name: 'setIsMobile',
      cb: (_, value) => {
        return { isMobile: value }
      }
    },
    {
      name: 'setShowAuth',
      cb: (_, value) => {
        return { showAuth: value }
      }
    },
    {
      name: 'setTextModal',
      cb: (_, value) => {
        return { textModal: value }
      }
    },
    {
      name: 'setNotification',
      cb: (_, value) => {
        return { notification: value }
      }
    },
    {
      name: 'setArticles',
      cb: (_, value) => {
        return { articles: value }
      }
    },
    {
      name: 'setRelatedArticles',
      cb: (_, value) => {
        return { relatedArticles: value }
      }
    },
    {
      name: 'setArticle',
      cb: (_, value) => {
        return { article: value }
      }
    },
    {
      name: 'setShowSearchModal',
      cb: (_, value) => {
        return { showSearchModal: value }
      }
    },
    {
      name: 'setShowSearchOptin',
      cb: (_, value) => {
        return { showSearchOptin: value }
      }
    },
    {
      name: 'setShowSignupModal',
      cb: (_, value) => {
        return { showSignupModal: value }
      }
    },
    {
      name: 'setShowSignupOptin',
      cb: (_, value) => {
        return { showSignupOptin: value }
      }
    },
    {
      name: 'setShowFOMOModal',
      cb: (_, value) => {
        return { showFOMOModal: value }
      }
    },
    {
      name: 'setReturnURL',
      cb: (_, value) => {
        return { returnURL: value }
      }
    }
  ]
}
