import styled, { css } from 'styled-components'
import { colors, breakpoints } from 'constant'

const { lg, md } = breakpoints

export const Wrapper = styled.form`
  width: 500px;
  ${({ open }) => open && 'z-index: 2;'}
`

export const AutocompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 9;
`
export const MobileAutocompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
`

export const AutocompleteResults = styled.div`
  width: 100%;
  position: absolute;
  top: calc(100% + 8px);
  left: 0px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 16px;
  overflow: hidden;
  background: ${colors.c17};
  padding: 16px 17px;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
`

const activeCss = css`
  background: ${colors.buttonBackground};

  > svg {
    transform: rotate(0deg);
  }
`

export const Result = styled.div`
  padding: 8px 13px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  color: ${colors.mainText};
  width: calc((100% / 3) - 4px);
  margin: 4px 2px;
  display: flex;
  align-items: center;
  background: ${colors.c18};
  border-radius: 50px;
  word-break: break-word;
  font-weight: 600;

  ${lg} {
    width: calc((100% / 2) - 4px);
  }

  ${md} {
    width: calc((100% / 2) - 4px);
  }

  > span {
    flex: 1;
  }

  > svg {
    height: 10px;
    width: 10px;
    flex-shrink: 0;
    transform: rotate(45deg);
    transition: 300ms ease;
  }

  &:hover {
    background: ${colors.buttonBackground};
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    background: transparent;
    border-bottom: 1px solid ${colors.c12};
    font-size: 14px;
  `}

  ${({ isActive }) => isActive && activeCss}
`

export const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background: transparent;
  border-radius: 100px;
  overflow: hidden;
  padding: 0px 16px;
  align-items: center;

  &:focus-within {
    svg {
      color: ${colors.mainText};
    }

    label {
      color: ${colors.mainText};
    }
  }
`

export const SearchContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const TagInputWrapper = styled.div`
  height: 56px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  input {
    flex: 1;
    width: 100%;
    border: none;
    letter-spacing: 0px;
    color: ${colors.mainText};
    background: transparent !important;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;

    &::placeholder {
      color: ${colors.secondaryText};
    }
  }
`
