import React from 'react'

const ConfettiIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_626_12)">
        <path
          d="M9.6752 14.3256C10.6598 15.3102 11.7996 16.0588 12.8439 16.4069C13.8881 16.755 14.7514 16.674 15.2437 16.1817C15.7359 15.6895 15.817 14.8262 15.4689 13.7819C15.1208 12.7376 14.3721 11.5978 13.3875 10.6133C12.4029 9.62872 11.2631 8.88003 10.2189 8.53194C9.17457 8.18384 8.31133 8.26484 7.81905 8.75713C7.32676 9.24941 7.24576 10.1126 7.59386 11.1569C7.94195 12.2012 8.69064 13.341 9.6752 14.3256V14.3256Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.40047 9.7002L0.888469 21.1212C0.759642 21.3998 0.719415 21.7113 0.773218 22.0136C0.827022 22.3158 0.972266 22.5942 1.18934 22.8113C1.40642 23.0284 1.68487 23.1736 1.98711 23.2274C2.28935 23.2813 2.60082 23.241 2.87947 23.1122L14.3005 16.6052"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.7734 7.7002C15.8084 7.73503 15.8361 7.77641 15.855 7.82197C15.8739 7.86753 15.8836 7.91637 15.8836 7.9657C15.8836 8.01502 15.8739 8.06386 15.855 8.10942C15.8361 8.15498 15.8084 8.19636 15.7734 8.2312"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.2432 7.7C15.3135 7.62978 15.4088 7.59033 15.5082 7.59033C15.6075 7.59033 15.7029 7.62978 15.7732 7.7"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.2431 8.2268C15.1731 8.15619 15.1338 8.06076 15.1338 7.9613C15.1338 7.86184 15.1731 7.76641 15.2431 7.6958"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.7732 8.22705C15.7029 8.29728 15.6075 8.33672 15.5082 8.33672C15.4088 8.33672 15.3135 8.29728 15.2432 8.22705"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.0166 3.4541C20.0515 3.48885 20.0792 3.53015 20.0981 3.57562C20.117 3.6211 20.1267 3.66986 20.1267 3.7191C20.1267 3.76835 20.117 3.8171 20.0981 3.86258C20.0792 3.90806 20.0515 3.94935 20.0166 3.9841"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.4863 3.45384C19.5211 3.41894 19.5624 3.39126 19.6078 3.37237C19.6533 3.35347 19.7021 3.34375 19.7513 3.34375C19.8006 3.34375 19.8493 3.35347 19.8948 3.37237C19.9403 3.39126 19.9816 3.41894 20.0163 3.45384"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.4861 3.9841C19.4512 3.94935 19.4235 3.90806 19.4046 3.86258C19.3857 3.8171 19.376 3.76835 19.376 3.7191C19.376 3.66986 19.3857 3.6211 19.4046 3.57562C19.4235 3.53015 19.4512 3.48885 19.4861 3.4541"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.0163 3.98389C19.946 4.05411 19.8507 4.09356 19.7513 4.09356C19.652 4.09356 19.5566 4.05411 19.4863 3.98389"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.0166 14.0601C20.0515 14.0949 20.0792 14.1363 20.0981 14.1818C20.117 14.2274 20.1268 14.2762 20.1268 14.3256C20.1268 14.3749 20.117 14.4237 20.0981 14.4693C20.0792 14.5148 20.0515 14.5562 20.0166 14.5911"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.4863 14.0599C19.5566 13.9896 19.652 13.9502 19.7513 13.9502C19.8507 13.9502 19.946 13.9896 20.0163 14.0599"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.4861 14.5911C19.4512 14.5562 19.4235 14.5148 19.4046 14.4693C19.3857 14.4237 19.376 14.3749 19.376 14.3256C19.376 14.2762 19.3857 14.2274 19.4046 14.1818C19.4235 14.1363 19.4512 14.0949 19.4861 14.0601"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.0163 14.5908C19.946 14.661 19.8507 14.7005 19.7513 14.7005C19.652 14.7005 19.5566 14.661 19.4863 14.5908"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.34961 4.51416C8.41965 4.58477 8.45895 4.6802 8.45895 4.77966C8.45895 4.87912 8.41965 4.97455 8.34961 5.04516"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.81934 4.51397C7.88965 4.44374 7.98496 4.4043 8.08434 4.4043C8.18371 4.4043 8.27902 4.44374 8.34934 4.51397"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.81915 5.04516C7.78423 5.01033 7.75652 4.96894 7.73762 4.92339C7.71872 4.87783 7.70898 4.82899 7.70898 4.77966C7.70898 4.73033 7.71872 4.68149 7.73762 4.63593C7.75652 4.59038 7.78423 4.54899 7.81915 4.51416"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.34934 5.04492C8.27902 5.11515 8.18371 5.15459 8.08434 5.15459C7.98496 5.15459 7.88965 5.11515 7.81934 5.04492"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.8572 0.75C13.0683 2.63992 12.8875 4.55306 12.3262 6.37"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.5691 2.12793L16.0391 4.77893"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.2509 11.1431C21.3609 10.932 19.4478 11.1128 17.6309 11.6741"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.8727 7.43115L19.2217 7.96115"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_626_12">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ConfettiIcon
