import styled from 'styled-components'
import { colors } from 'constant'
import { Link } from 'react-router-dom'

export const Wrapper = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${colors.mainText};
  display: flex;
  flex-direction: column;
  background: ${colors.c1};
  border-radius: 16px;
  overflow: hidden;
  text-align: left;
`

export const CoverImage = styled.div`
  background-image: url(${({ image }) => image});
  height: 31vw;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const BottomInfo = styled.div`
  display: flex;
  flex-direction: column;

  > h5 {
    font-weight: 600;
    font-size: 11px;
    line-height: 19px;
    margin-bottom: 9px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: space-between;
  flex: 1;

  > h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
  }

  .LinesEllipsis {
    font-style: normal;
    font-weight: normal;
    color: ${colors.c19};
    font-size: 12px;
    flex: 1;
    line-height: 16px;
  }
`

export const Name = styled.h2`
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: ${colors.c3};
  margin-bottom: 4px;
`

export const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  > div {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    align-items: flex-start;

    /* > h2 {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      color: ${colors.c3};
      margin-bottom: 4px;
    } */

    > h4 {
      font-size: 10px;
      line-height: 16px;
      color: ${colors.c19};
      font-weight: normal;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const TagItem = styled.div`
  text-transform: capitalize;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% - 4px) / 2);
  color: ${colors.c3};
  border: 1px solid ${colors.c2};
  background: rgba(194, 32, 38, 0.16);
  border-radius: 50px;
  backdrop-filter: blur(16px);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  > div {
    max-width: calc(100% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const Tags = styled.div`
  display: flex;
  justify-content: space-between;
`
