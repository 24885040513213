import styled from 'styled-components'
import { maxDesktopCss, breakpoints } from 'constant'

const { md } = breakpoints

export const EpisodeItem = styled.a`
  background-image: url("${({ image }) => image}");
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 12px 16px;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    position: absolute;
    height: 400px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  > div {
    z-index: 1;
  }

  ${md} {
    width: 220px;
  }
`

export const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 80px;
`

export const ArticleContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  ${maxDesktopCss}
  padding-bottom: 80px;
`

export const LatestArticle = styled.div`
  width: 100%;
  max-width: 530px;
  display: flex;
  flex-direction: column;

  > h2 {
    margin-bottom: 12px;
    font-size: 32px;
  }

  > a {
    margin-top: 32px;
    width: 183px;
    max-width: 100%;
  }

  ${md} {
    > h2 {
      font-size: 24px;
    }

    > a {
      width: 100%;
    }
  }
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${maxDesktopCss}
  margin-bottom: 24px;

  > h2 {
    display: flex;
    font-size: 32px;
    margin-bottom: 26px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  > div {
    display: flex;
  }
`

export const AllCards = styled.div`
  ${maxDesktopCss}
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const AritcleHeader = styled.div`
  ${maxDesktopCss}
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
`

export const EpisodesWrapper = styled.div`
  display: flex;
  overflow: auto;

  > div {
    display: flex;
  }
`
