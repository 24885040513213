import React, { useState } from 'react'
import { Modal } from 'components'
import { MainWrapper, FormWrapper, RCALogoWrapper } from './styled'
import { RcaLogo } from '../../../Layout/LayoutHeader/styled'
import BasicInfo from './BasicInfo'
import UploadImage from './UploadImage'

const VenueSignup = (props) => {
  const [page, setPage] = useState(1)

  return (
    <MainWrapper>
      <FormWrapper>
        {page === 1 && <BasicInfo handleNext={() => setPage(2)} />}
        {page === 2 && <UploadImage handlePrevious={() => setPage(1)} />}
      </FormWrapper>
    </MainWrapper>
  )
}

export default VenueSignup
