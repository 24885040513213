import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'
import { Popover } from '@headlessui/react'
import Button from '../../../../components/Button'

const { md } = breakpoints

export const PageWrapper = styled.div`
  display: flex;
`
export const ContentWrapper = styled.div`
  width: 100%;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-top: 80px; */
`

export const Headers = styled.div`
  width: 90%;
  position: relative;
  z-index: 20;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h2 {
    font-size: 20px;
  }

  > button {
    width: 140px;
    font-weight: normal;
  }
`

export const SponsoredEventContainer = styled.div`
  border: 4px solid ${colors.c24};
  border-radius: 30px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
`

export const SponsoredEventHeader = styled.h2`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
`

export const CheckIcon = styled.div`
  display: flex;
  width: 100%;
  > svg {
    height: 26px;
    width: 26px;
    background: ${colors.c20};
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;

    ${({ $sponsored }) =>
      !$sponsored &&
      css`
        opacity: 0.3;
      `}
  }
`

export const TableWrapper = styled.div`
  width: 100%;
  background-color: ${colors.c17};
  margin-top: 25px;
  border-radius: 16px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NoEventsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h2 {
    margin-top: 8px;
  }

  > p {
    margin-top: 5px;
    text-align: center;
    width: 300px;
  }

  > button {
    margin-top: 12px;
    width: 140px;
    font-weight: normal;
  }
`

export const NoEventsIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EventsTableContainer = styled.div`
  width: 100%;
`

export const EventsTable = styled.table`
  padding-top: 30px;
  width: 100%;
`

export const Th = styled.th`
  border-bottom: 2px solid ${colors.c16};
  text-align: left;
  padding-bottom: 20px;
  font-size: 18px;

  &:first-child {
    padding-left: 25px;
  }

  &:last-child {
    width: 180px;
  }
`

const td = css`
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 2px solid ${colors.c16};
`

export const Td = styled.td`
  &:first-child {
    padding-left: 25px;
  }

  ${td}
`

export const ActionCells = styled.td`
  ${td}

  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > button {
    &:first-child {
      font-weight: normal;
      width: 100px;
    }

    &:last-child {
      padding: unset;
      width: 50px;
      height: 50px;
      font-weight: bold;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > svg {
      margin-right: 0px;
    }
  }
`

export const PopoverContainer = styled(Popover)`
  position: relative;
`

export const PopoverButton = styled(Popover.Button)`
  background: none;
  border: none;
  cursor: pointer;
  background-color: ${colors.c18};
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: 24px;
    width: 24px;
    color: ${colors.c3};
  }
`

export const PopoverContent = styled(Popover.Panel)`
  position: absolute;
  z-index: 2;
  top: calc(100% + 8px);
  background: ${colors.c18};
  border-radius: 16px;
  right: 0px;
  width: 200px;
  max-width: calc(100vw - 36px);
  padding: 10px;
  display: flex;
  flex-direction: column;
`

export const PopoverTop = styled.div`
  padding: 20px 20px 24px 20px;
  border-bottom: 1px solid #101010;

  > div {
    &:first-child {
    }

    &:last-child {
    }
  }
`

export const PopoverCloser = styled(Popover.Button)`
  background-color: transparent;
  border: none;
  padding: unset;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 12px;
    color: ${colors.c4};
    justify-content: left;
    height: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    padding-left: 10px;

    &:hover {
      background: ${colors.c16};
      color: ${colors.c3};
    }
  }
`
