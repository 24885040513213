import styled, { css } from 'styled-components'
import { colors, maxDesktopWidth, breakpoints } from 'constant'
import { Link } from 'react-router-dom'

const { md } = breakpoints

export const MainWrapper = styled.div`
  /* width: calc(100% / 3 - 12px); */
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ImageWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  ${md} {
    padding: 5px;
    height: 150px;
  }
`

export const MainImage = styled.div`
  height: 100%;
  border-radius: 16px;
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 4px;
  /* filter: blur(4px); */
`

export const ContentWrapper = styled.div`
  position: absolute;
  top: 7px;
`

export const IconWrapper = styled.div`
  display: flex;
  padding-left: 5px;
  padding-top: 5px;
`

export const IconButton = styled.button`
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  padding: unset;
  background: ${colors.c12};
  cursor: pointer;

  &:hover {
    background: ${colors.c23};
  }

  > svg {
    height: 30px;
    width: 30px;
  }
`

export const ImageLabel = styled.div`
  /* margin-top: 10px; */
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 8px;

  ${md} {
    font-size: 14px;
    font-weight: normal;
  }
`
