import styled, { css } from 'styled-components'
import { colors, breakpoints } from 'constant'
import { Link } from 'react-router-dom'

// https://static.vecteezy.com/system/resources/previews/002/323/622/non_2x/404-error-page-isolated-flat-icon-free-vector.jpg

const { md, sm } = breakpoints

export const MainWrapper = styled.div`
  ${md} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  > h1 {
    font-size: 52px;
    margin-top: 28px;
  }

  > p {
    margin-top: 8px;
    font-size: 20px;
  }

  ${md} {
    > h1 {
      font-size: 23px;
    }

    > p {
      font-size: 13px;
    }
  }
`

export const HeaderImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 90px;
  > img {
    width: 400px;
  }

  ${md} {
    > img {
      width: 200px;
    }
  }
`

export const SearchWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;

  > div {
    width: 90%;
  }

  ${md} {
    margin-top: 20px;
  }
`

export const AfterSearch = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  > p {
    text-align: center;
    width: 500px;
    font-size: 20px;
  }

  ${md} {
    margin-top: 20px;

    > p {
      width: 250px;
      font-size: 13px;
    }
  }
`

export const PageLink = styled(Link)`
  color: #f570df;
  text-decoration: underline;
`

export const SearchTips = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div {
    width: 500px;

    > ul {
      font-size: 20px;
      line-height: 1.5;
    }

    > p {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 24px;
    }
  }

  ${md} {
    > div {
      width: 90%;

      > h1 {
        font-size: 23px;
      }

      > ul {
        font-size: 14px;
        line-height: 1.5;
      }

      > p {
        font-size: 13px;
      }
    }
  }
`

export const FilterHeader = styled.h1`
  text-align: center;

  ${md} {
    font-size: 23px;
  }
`

export const TopicsGridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;

  ${md} {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`

const selectedCss = css`
  background-color: ${colors.c14};
  /* background-color: transparent; */
`

const unSelectedCss = css`
  background-color: transparent;
`

const threeColumnGrid = css`
  width: calc(33% - 20px);
`

const fourColumnGrid = css`
  width: calc(25% - 20px);
`

export const TopicsGridItem = styled.div`
  cursor: pointer;
  /* flex: 1 0 calc(33% - 20px); */
  /* width: calc(33% - 20px); */
  height: ${({ $height }) => $height + 'px'};
  border-radius: 16px;
  background-color: ${colors.c17};

  ${({ $column }) =>
    $column === 3
      ? threeColumnGrid
      : $column === 4
      ? fourColumnGrid
      : threeColumnGrid}

  ${md} {
    width: calc(33% - 10px);
  }
`

const centerLabel = css`
  height: ${({ $height }) => $height + 'px'};
  border-radius: 16px;
`

const bottomLabel = css`
  height: ${({ $height }) => $height - 50 + 'px'};
`

export const TopicOvelay = styled.div`
  position: relative;
  width: 100%;
  background-color: ${colors.c15};
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  /* &:hover {
    &::before {
      background-color: ${colors.c14};
    }
  } */

  ${({ $centerLabel }) => ($centerLabel ? centerLabel : bottomLabel)}

  &::before {
    ${TopicsGridItem}:hover & {
      background-color: ${colors.c14};
    }
    transition: background-color 0.3s ease-in-out;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${({ $isSelected }) => ($isSelected ? selectedCss : unSelectedCss)}

    ${({ $centerLabel }) =>
      $centerLabel ? `background-color: ${colors.c22}` : ''}
  }

  ${md} {
    height: 115px;
  }
`

export const TopicImage = styled.div`
  height: 100%;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
`

export const CheckIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  > svg {
    transform: translate(-10px, 10px);
    height: 26px;
    width: 26px;
    background: ${colors.c20};
    padding: 5px;
    border-radius: 50%;
  }
`

const centerLabelCss = css`
  transform: translate(0px, 50px);
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`

const selectedCenterLabelCss = css`
  transform: translate(0px, 24px);
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`

export const TopicLabel = styled.div`
  /* padding-left: 20px; */
  padding-top: 12px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;

  ${({ $centerLabel }) => ($centerLabel ? centerLabelCss : '')}
  ${({ $centerLabel, $isSelected }) =>
    $centerLabel && $isSelected ? selectedCenterLabelCss : ''}

  ${md} {
    font-size: 12px;
    padding-top: 5px;
  }
`

export const CarouselWrapper = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 32px;

  ${md} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const SearchCTAWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  > button {
    width: 200px;
    font-size: 22px;
  }
`
