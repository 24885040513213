import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MainWrapper,
  HeaderWrapper,
  HeaderImageWrapper,
  SearchWrapper,
  AfterSearch,
  PageLink,
  SearchTips,
  FilterHeader,
  TopicsGridContainer,
  TopicOvelay,
  TopicImage,
  TopicsGridItem,
  TopicLabel,
  CheckIcon,
  CarouselWrapper,
  SearchCTAWrapper
} from './styled'
import { getQuery, updateQuery } from 'utils'
import { EventSearchBar, Carousel, Icon, Button, SearchModal } from 'components'
import { preTags, majorCities, topics } from 'constant'
import { useStoreon } from 'storeon/react'
import { toggleArr } from 'utils'

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const tagOptions = preTags.flatMap(({ tags }) =>
  tags.map(({ label, value }) => ({ label, value }))
)

const NoResult = () => {
  const history = useHistory()
  const { search = '' } = getQuery(history)

  const { user, dispatch, isMobile, showSearchModal } = useStoreon(
    'user',
    'loading',
    'isMobile',
    'showSearchModal'
  )

  const [selectedCity, setSelectedCity] = useState('')
  const [selectedTopics, setSelectedTopics] = useState([])

  const handleCityUpdate = (item) => {
    setSelectedCity(item.value)
  }

  const handleTopicsUpdate = (item) => {
    setSelectedTopics(toggleArr(selectedTopics, item.value))
  }

  const handleSearch = () => {
    const selectedLabels = topics
      .filter((topic) => selectedTopics.includes(topic.value)) // Filter topics based on selected values
      .map((topic) => topic.label)

    // console.log(selectedLabels)

    const searchObj = {
      s: '',
      p: '',
      sd: null,
      ed: null,
      q: '',
      l: selectedCity,
      t: selectedLabels.join(',')
    }

    updateQuery({
      history,
      newParams: searchObj,
      replace: true,
      route: '/events'
    })

    // console.log(searchObj)
  }

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return <SearchModal />
    }
  }, [showSearchModal])

  const renderCity = (item, idx) => {
    const { value, label, thumbnailUrl } = item

    const isSelected = selectedCity === item.value

    const city = label.split(',')[0].trim()

    const columns = !isMobile ? 4 : 3
    const height = !isMobile ? 200 : 143
    const name = capitalizeFirstLetter(city)

    const imageLink = thumbnailUrl

    return (
      <TopicsGridItem
        $column={columns}
        $height={height}
        key={idx}
        onClick={() =>
          handleCityUpdate(item.value === selectedCity ? '' : item)
        }
      >
        <TopicOvelay $height={height} $isSelected={isSelected}>
          <TopicImage $imageUrl={imageLink}>
            {isSelected && (
              <CheckIcon>
                <Icon name="check" />
              </CheckIcon>
            )}
          </TopicImage>
        </TopicOvelay>
        <TopicLabel>{name}</TopicLabel>
      </TopicsGridItem>
    )
  }

  const renderTopic = (item, idx) => {
    const { value, label } = item

    const isSelected = selectedTopics.some(
      (i) => i === item.value || i?.value === item.value
    )

    const columns = !isMobile ? 4 : 3
    const height = !isMobile ? 200 : 143
    const name = capitalizeFirstLetter(label)

    const imageLink = `https://red-curtain-addict.imgix.net/preferences/${name}.jpg`

    return (
      <TopicsGridItem
        $column={columns}
        $height={height}
        key={idx}
        onClick={() => handleTopicsUpdate(item)}
      >
        <TopicOvelay $height={height} $isSelected={isSelected}>
          <TopicImage $imageUrl={imageLink}>
            {isSelected && (
              <CheckIcon>
                <Icon name="check" />
              </CheckIcon>
            )}
          </TopicImage>
        </TopicOvelay>
        <TopicLabel>{name}</TopicLabel>
      </TopicsGridItem>
    )
  }

  return (
    <MainWrapper>
      <HeaderWrapper>
        <HeaderImageWrapper>
          <img src="https://red-curtain-addict.imgix.net/404page/PageDoesntExist.svg" />
        </HeaderImageWrapper>

        <h1>The page doesn't exist!</h1>
        <p>Sorry we couldn't find any results matching "{search}"</p>
      </HeaderWrapper>

      <SearchWrapper>
        <EventSearchBar reroute />
      </SearchWrapper>

      <AfterSearch>
        <p>
          Try searching again, or you can return to our{' '}
          <PageLink to={'/'}>home page</PageLink> or{' '}
          <PageLink to={'/'}>contact us</PageLink> <br /> if you can't find what
          you're looking for.
        </p>
      </AfterSearch>

      <SearchTips>
        <div>
          <h1>Search Tips</h1>
          <ul>
            <li>Check you spelling and try again</li>
            <li>
              Try a similar but different search time, like Bach instead of I'm
              bringing sexy Bach...
            </li>
            <li>
              Keep your search term simple as our search function works best
              with shorter descriptions
            </li>
          </ul>
          <p>Or</p>
        </div>
      </SearchTips>

      <FilterHeader>Try filtering by city and genre below</FilterHeader>

      {isMobile ? (
        <React.Fragment>
          <CarouselWrapper>
            <Carousel label={'Sort by City of interest'}>
              <TopicsGridContainer>
                {majorCities.slice(0, 3).map(renderCity)}
              </TopicsGridContainer>
              <TopicsGridContainer>
                {majorCities.slice(3, 6).map(renderCity)}
              </TopicsGridContainer>
              <TopicsGridContainer>
                {majorCities.slice(6, 9).map(renderCity)}
              </TopicsGridContainer>
            </Carousel>
          </CarouselWrapper>

          <CarouselWrapper>
            <Carousel label={'Sort by Genre of interest'}>
              <TopicsGridContainer>
                {topics.slice(0, 3).map(renderTopic)}
              </TopicsGridContainer>
              <TopicsGridContainer>
                {topics.slice(3, 6).map(renderTopic)}
              </TopicsGridContainer>
            </Carousel>
          </CarouselWrapper>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CarouselWrapper>
            <Carousel label={'Sort by City of interest'}>
              <TopicsGridContainer>
                {majorCities.slice(0, 4).map(renderCity)}
              </TopicsGridContainer>
              <TopicsGridContainer>
                {majorCities.slice(4, 8).map(renderCity)}
              </TopicsGridContainer>
            </Carousel>
          </CarouselWrapper>

          <CarouselWrapper>
            <Carousel label={'Sort by Genre of interest'}>
              <TopicsGridContainer>
                {topics.slice(0, 4).map(renderTopic)}
              </TopicsGridContainer>
              <TopicsGridContainer>
                {topics.slice(4, 8).map(renderTopic)}
              </TopicsGridContainer>
            </Carousel>
          </CarouselWrapper>
        </React.Fragment>
      )}

      <SearchCTAWrapper>
        <Button onClick={() => handleSearch()}>Search</Button>
      </SearchCTAWrapper>

      {searchModal}
    </MainWrapper>
  )
}

export default NoResult
