import React, { useEffect, useState } from 'react'

import { MainWrapper, TitleWrapper, GridWrapper } from './styled'

import { toggleArr } from 'utils'
import { preTags } from 'constant'
import { Loader } from 'components'
import { useHistory } from 'react-router-dom'
import { getPrefs, updatePrefs } from 'apis'
import { useStoreon } from 'storeon/react'
import toast from 'react-hot-toast'

import FirstPage from './FirstPage'
import SecondPage from './SecondPage'
import ThirdPage from './ThirdPage'
import FourthPage from './FourthPage'

export const topics = [
  { label: 'jazz', value: '29' },
  { label: 'opera', value: '31' },
  { label: 'symphonic', value: '355' },
  { label: 'theater', value: '1' },
  { label: 'dance', value: '27' },
  { label: 'choirs', value: '604' }
]

const Preferences = () => {
  const categoryObject = preTags.find((item) => item.category === 'Type')
  const vibesObject = preTags.find((item) => item.category === 'Category')
  const settingObject = preTags.find((item) => item.category === 'Setting')

  const [selectedFavorites, setSelectedFavorites] = useState([])
  const [selectedCities, setSelectedCities] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedVibes, setSelectedVibes] = useState([])
  const [selectedSetting, setSelectedSetting] = useState([])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isSubmitting, setIsSubmitting] = useState(false)
  // const [zipcode, setZipcode] = useState('')

  const { user, dispatch, isMobile, loading } = useStoreon(
    'user',
    'loading',
    'isMobile'
  )

  const history = useHistory()

  useEffect(() => {
    const fetch = async () => {
      if (!loading) {
        if (!user) {
          console.log('logged out')
          history.push('/signup')
        }
      }

      if (user && user.getIdToken) {
        const token = await user.getIdToken()
        const data = await getPrefs({ token })
        const {
          cities: c,
          tags: t,
          firstName: fn = '',
          lastName: ln = ''
        } = data

        setFirstName(fn)
        setLastName(ln)

        const filteredFavorites = topics.filter((tag) =>
          t.some((item) => item.value === Number(tag.value))
        )

        const filteredCategories = categoryObject.tags.filter((tag) =>
          t.some((item) => item.value === Number(tag.value))
        )

        const filteredVibes = vibesObject.tags.filter((tag) =>
          t.some((item) => item.value === Number(tag.value))
        )

        const filteredSettings = settingObject.tags.filter((tag) =>
          t.some((item) => item.value === Number(tag.value))
        )

        setSelectedCities(c.map((i) => `${i}`))
        setSelectedFavorites(filteredFavorites.map((item) => item.value))
        setSelectedCategory(filteredCategories.map((item) => item.value))
        setSelectedVibes(filteredVibes.map((item) => item.value))
        setSelectedSetting(filteredSettings.map((item) => item.value))
      } else {
        // history.push('/')
      }
    }

    fetch()
  }, [user, loading])

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const token = await user.getIdToken()

    const mergedTags = [
      ...selectedFavorites,
      ...selectedCategory,
      ...selectedVibes,
      ...selectedSetting
    ]

    const submitData = {
      token,
      firstName,
      lastName,
      tags: mergedTags,
      cities: selectedCities
      // zipcode: zipcode ?? ''
    }

    const prefRequest = await updatePrefs(submitData)
    const userData = await getPrefs({ token })

    if (prefRequest.statusCode === 200) {
      dispatch('setUserState', { userData })
      history.push('/')
      dispatch('setNotification', {
        message: 'Preferences saved!',
        timer: 8000
      })
      setIsSubmitting(false)
    } else {
      toast.error(
        'Failed to update preferences with error: ',
        prefRequest.statusText
      )
      setIsSubmitting(false)
    }
  }

  const handleCityUpdate = (item) => {
    setSelectedCities(toggleArr(selectedCities, item.value))
  }

  // const handleZipcodeChange = (value) => {
  //   setZipcode(value)
  // }

  const handleFavoritesUpdate = (item) => {
    setSelectedFavorites(toggleArr(selectedFavorites, item.value))
  }

  const handleCategoryUpdate = (item) => {
    setSelectedCategory(toggleArr(selectedCategory, item.value))
  }

  const handleVibesUpdate = (item) => {
    setSelectedVibes(item)
  }

  const handleSettingUpdate = (item) => {
    setSelectedSetting(toggleArr(selectedSetting, item.value))
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <FirstPage
            selected={selectedCities}
            onChange={handleCityUpdate}
            onNext={() => handlePageChange(2)}
          />
        )
      case 2:
        return (
          <SecondPage
            selected={selectedFavorites}
            onChange={handleFavoritesUpdate}
            onBack={() => handlePageChange(1)}
            onNext={() => handlePageChange(3)}
          />
        )
      case 3:
        return (
          <ThirdPage
            selected={selectedCategory}
            onChange={handleCategoryUpdate}
            onBack={() => handlePageChange(2)}
            onNext={() => handlePageChange(4)}
          />
        )
      case 4:
        return (
          <FourthPage
            selectedVibes={selectedVibes}
            selectedSetting={selectedSetting}
            onSettingChange={handleSettingUpdate}
            onVibesUpdate={handleVibesUpdate}
            onBack={() => handlePageChange(3)}
            onSubmit={() => handleSubmit()}
            isSubmitting={isSubmitting}
          />
        )
      default:
        return null
    }
  }

  if (loading) {
    return (
      <div
        style={{
          height: '100vh',
          margin: 'auto',
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Loading</p>
        <div style={{ marginLeft: '10px' }}>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <MainWrapper>
      <TitleWrapper>
        <h1>Preferences</h1>
        <span>
          Get the best recommendations by telling us
          <br /> a little bit about you
        </span>
      </TitleWrapper>
      <GridWrapper>{renderPage()}</GridWrapper>
    </MainWrapper>
  )
}

export default Preferences
