
import React, { useState } from 'react'
import { Wrapper, ChangePasswordWrapper, InputWrapper, Error } from './styled'
import { Button, Input } from 'components'
import { useHistory } from 'react-router-dom'
import { getQuery } from 'utils'
import { changePassword } from 'apis'

const NotFound = () => {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const { token, email } = getQuery(history)

  const onSubmit = async e => {
    e.preventDefault()

    if (password !== passwordConfirm) {
      return setError('Passwords do not match')
    }

    try {
      await changePassword({
        email,
        token,
        password
      })
    } catch (e) {
      setError('Token has expired, please try request another reset.')
    }

    setSuccess(true)
  }

  if (success) {
    return (
      <Wrapper card width={600}>
        <ChangePasswordWrapper>
          <div />
          <h1>Password Change</h1>
          <p>Password change successful. Please login with your new password.</p>
          <Button to='/login'>Login</Button>
        </ChangePasswordWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper card width={600}>
      <ChangePasswordWrapper onSubmit={onSubmit}>
        <div />
        <h1>Password Change</h1>
        {error && <Error>{error}</Error>}
        <InputWrapper>
          <Input
            label='New Password'
            type='password'
            value={password}
            onChange={value => {
              setError('')
              setPassword(value)
            }}
          />
          <Input
            label='Confirm New Password'
            type='password'
            value={passwordConfirm}
            onChange={value => {
              setError('')
              setPasswordConfirm(value)
            }}
          />
        </InputWrapper>
        <Button type='submit' disabled={!password || !passwordConfirm}>Reset Password</Button>
      </ChangePasswordWrapper>
    </Wrapper>
  )
}

export default NotFound
