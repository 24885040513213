import React from 'react'
import {
  Wrapper,
  Info,
  CoverImage,
  TopInfo,
  Tags,
  TagItem,
  BottomInfo,
  Name
} from './styled'
import { genImagePath, formatDateP } from 'utils'

const MobileEventCard = (props) => {
  const { data } = props
  const {
    id,
    name,
    slug,
    location,
    cover_image_id: coverImageId,
    event_images: eventImages,
    start_date: startDate,
    end_date: endDate,
    event_tags: tags
  } = data
  const allImages = eventImages
  const coverImage =
    allImages.find((i) => i.id === coverImageId) || allImages[0] || {}
  const imageUrl = genImagePath({
    ownerType: 1,
    ownerId: id,
    name: coverImage.name
  })

  const renderTag = (item, idx) => {
    return (
      <TagItem key={idx}>
        <div>{item?.tag?.name}</div>
      </TagItem>
    )
  }

  let dateString

  if (startDate) {
    dateString = formatDateP(startDate)
  }

  if (endDate) {
    dateString = `${dateString} - ${formatDateP(endDate)}`
  }

  return (
    <Wrapper to={`/events/${slug}`}>
      <CoverImage image={`${imageUrl}?height=300&auto=compression`} />
      <Info>
        <TopInfo>
          <div>
            <Name>{name}</Name>
            <h4>{location}</h4>
          </div>
        </TopInfo>
        <BottomInfo>
          <h5>{dateString}</h5>
          <Tags>{tags.slice(0, 2).map(renderTag)}</Tags>
        </BottomInfo>
      </Info>
    </Wrapper>
  )
}

export default MobileEventCard
