import React from 'react'
import ImageUploading from 'react-images-uploading'
import {
  ProfilePhotoPlaceholder,
  ProfilePhotoWrapper,
  MainWrapper,
  UploadedPhoto
} from './styled'
import { Icon } from 'components'

const Uploader = ({ handleUpdate }) => {
  const [images, setImages] = React.useState([])

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    handleUpdate(imageList[0])
    setImages(imageList)
  }

  return (
    <ImageUploading
      acceptType={['jpg', 'png']}
      value={images}
      onChange={onChange}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps
      }) => (
        // write your building UI
        <MainWrapper>
          {images.length ? (
            imageList.map((image, index) => (
              <ProfilePhotoWrapper key={index}>
                <UploadedPhoto
                  $imageUrl={image['data_url']}
                  onClick={() => onImageUpdate(index)}
                  {...dragProps}
                />
                <span onClick={() => onImageUpdate(index)} {...dragProps}>
                  Change Image
                </span>
              </ProfilePhotoWrapper>
            ))
          ) : (
            <ProfilePhotoWrapper>
              <ProfilePhotoPlaceholder onClick={onImageUpload} {...dragProps}>
                <Icon name={'profile'} />
              </ProfilePhotoPlaceholder>
              <span onClick={onImageUpload} {...dragProps}>
                Add Profile Image
              </span>
            </ProfilePhotoWrapper>
          )}
        </MainWrapper>
      )}
    </ImageUploading>
  )
}

export default Uploader
