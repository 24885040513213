import React, { useState, useRef, useMemo } from 'react'
import { AboutWrapper, Row, Info, RowImage, MobileImage, ContactUs, FormInput, FormButton } from './styled'
import { MaxWidthContainer, Button, SearchModal } from 'components'
import ReactPlayer from 'react-player'
import { useStoreon } from 'storeon/react'

const About = () => {
  const { isMobile, showSearchModal } = useStoreon('isMobile')
  const [submitted, setSubmitted] = useState(false)
  const formRef = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
  }

  const onTouch = () => {
    formRef.current.scrollIntoView()
  }

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return (
        <SearchModal />
      )
    }
    
    return null
  }, [showSearchModal])

  return (
    <AboutWrapper>
      <MaxWidthContainer>
        <Row>
          <Info player>
            <h2>About Us</h2>
            {isMobile && <ReactPlayer url='https://www.youtube.com/watch?v=1wxQ_Uri8js' />}
            <p>Red Curtain Addict is a one-stop arts recommendation platform for new and diverse audiences to discover, learn, and enjoy performing arts experiences locally, online, and when they travel. <br/><br/> Not only can performances be searched for by multiple genres, this new digital gateway to the arts also helps fans discover performances based on personal interests.</p>
            <Button onClick={onTouch}>Get in touch</Button>
          </Info>
          {!isMobile && <ReactPlayer url='https://www.youtube.com/watch?v=1wxQ_Uri8js' />}
        </Row>
        <Row flip>
          <Info>
            <h2>Our Vibe</h2>
            { isMobile && <MobileImage style={{ backgroundPosition: 'center' }} src={'https://rca-s3.imgix.net/static/vibe.jpg'} /> }
            <p>Think of us as that go-to friend that has their finger on the pulse of all things arts that won’t steer you wrong. Red Curtain Addict was built to help you find the arts for YOU and make sense of all the shows that are happening near you. <br/><br/>We’re here to break down everything and help you decide on what to see next so that you can get going and have that fun arts experience that you’re looking for! </p>
          </Info>
          { !isMobile && <RowImage style={{ backgroundPosition: 'center' }} src={'https://rca-s3.imgix.net/static/vibe.jpg'} /> }
        </Row>
        <Row>
          <Info>
            <h2>Our Community</h2>
            { isMobile && <MobileImage src={'https://rca-s3.imgix.net/static/c3.jpg'} /> }
            <p>We are a community of people that love going to the next best arts experience and we want you to come with us! No matter if you know everything about the arts or you don’t know anything, we welcome you because we are all inclusive! <br/><br/>Red Curtain Addict is built upon diversity, inclusion, and equality and we are committed to continue fostering a united community that is fun, eclectic and genuine.</p>
          </Info>
          { !isMobile && <RowImage src={'https://rca-s3.imgix.net/static/c3.jpg'} /> }
        </Row>
        <Row flip>
          <Info>
            <h2>Meet the Founders</h2>
            { isMobile && <MobileImage src={'https://rca-s3.imgix.net/static/kp.jpeg'} /> }
            <p>Kari and Parker founded Red Curtain Addict to make the arts more accessible and welcoming to music and theater lovers, and also to future fans who are interested in attending events but may not know where to begin.<br/><br/>By lifting the curtain on art forms like jazz, theater, dance, and classical music, it’s their goal to bring the joy, beauty, and fun of the arts to as many people as possible!</p>
          </Info>
          { !isMobile && <RowImage src={'https://rca-s3.imgix.net/static/kp.jpeg'} /> }
        </Row>
        <Row >
          <Info>
            <h2>Kari Lincks Coomans</h2>
            { isMobile && <MobileImage src={'https://rca-s3.imgix.net/static/kari.jpg'} /> }
            <p>Kari’s passion for the arts comes from her music performance background and music business experience. She believes that anyone and everyone should be able to easily find and go to performing arts experiences because once they do, they will want to go back again and again!<br/><br/>“I want to help people find performances that they will love across genres with no boundaries,” says Kari. “I also want to give audiences the opportunity to learn about the music and the stories of the amazing people that are on stage, because at the end of the day, people connect with people.”</p>
          </Info>
          { !isMobile && <RowImage src={'https://rca-s3.imgix.net/static/kari.jpg'} /> }
        </Row>
        <Row flip>
          <Info>
            <h2>Parker Coomans</h2>
            { isMobile && <MobileImage src={'https://rca-s3.imgix.net/static/parker.jpg'} /> }
            <p>As an arts enthusiast with a background in business development, Parker believes it will take a third party company like Red Curtain Addict to help bridge the gap between the arts and the world. <br/><br/>“My goal is to keep the arts relevant for the next generation by creating tools for audiences to experience and go to the arts in their city or when they travel,” says Parker. “We live in a connected world that is moving faster than ever and we don’t want the arts to get left behind.”</p>
          </Info>
          { !isMobile && <RowImage src={'https://rca-s3.imgix.net/static/parker.jpg'} /> }
        </Row>
        <ContactUs ref={formRef} onSubmit={handleSubmit}>
          <h2>Let’s work together -- get in touch!</h2>
          <h4>Request more information below.</h4>
          <div>
            <FormInput
              name='name'
              placeholder={'Name (first & last)'}
            />
            <FormInput
              name='company'
              placeholder={'Company'}
            />
          </div>
          <div>
            <FormInput
              name='email'
              placeholder={'Contact email'}
            />
            <FormInput
              name='phone'
              placeholder={'Phone number'}
            />
          </div>
          <FormButton type='submit' onClick={handleSubmit} value={submitted ? 'Submitted!' : 'Get in touch'} />
        </ContactUs>
      </MaxWidthContainer>
      {searchModal}
    </AboutWrapper>
  )
}

export default About
