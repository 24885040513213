import styled from 'styled-components'
import { maxDesktopWidth } from 'constant'

export const MaxWidthContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 100%;
    max-width: ${maxDesktopWidth};
  }
`
