import {
  Wrapper,
  MobileCalendarWrapper,
  CalendarWrapper,
  CalendarBottom,
  DatePickerValue,
  DatePickerLabel
} from './styled'
import React, { useState, useRef } from 'react'
import { DayPickerRangeController } from 'react-dates'
import { Button, Icon } from 'components'
import { useOnClickOutside, datesToString } from 'utils'
import { useStoreon } from 'storeon/react'
import moment from 'moment'
import 'react-dates/initialize'

const DatePicker = (props) => {
  const {
    startDate: unixStartDate,
    endDate: unixEndDate,
    onStartChange,
    onEndChange,
    calendar,
    onSearchPassed,
    onSearch,
    leftAdjust,
    dateText,
    onDateClear
  } = props
  const [showDatePicker, setShowDatePicker] = useState(false)
  const yesterday = useRef(moment().subtract(1, 'days'))
  const [focused, setFocused] = useState('startDate')
  const { isMobile } = useStoreon('isMobile')
  const ref = useRef()

  const momentStartDate = unixStartDate ? moment.unix(unixStartDate) : null
  const momentEndDate = unixEndDate ? moment.unix(unixEndDate) : null

  useOnClickOutside(ref, () => setShowDatePicker(false))

  const onClear = () => {
    onStartChange(null)
    onEndChange(null)

    if (onDateClear) {
      onDateClear()
    }
  }

  const handleDateChange = ({ startDate, endDate }) => {
    if (unixStartDate && unixEndDate) {
      onEndChange(null)
      return onStartChange(startDate.unix())
    }

    if (startDate) {
      onStartChange(startDate.unix())
    }

    if (endDate) {
      onEndChange(endDate.unix())
    }
  }

  const handleFocusChange = (focusedInput) => {
    setFocused(focusedInput || 'startDate')
  }

  const isOutsideRange = (date) => {
    return yesterday.current.diff(date) > 0
  }

  const handleSearch= () => {
    if (onSearchPassed) {
      onSearch();
    }

    setShowDatePicker(false);
  }

  //FORCE THIS PART TO FALSE FOR THE MEAN TIME, FOR LATER USE IF NEEDED
  if (false) {
    return (
      <Wrapper
        onFocus={() => setShowDatePicker(true)}
        ref={ref}
        isDateRange={momentStartDate && momentEndDate}
      >
        <DatePickerValue tabIndex={-1}>
          {datesToString({
            startDate: momentStartDate,
            endDate: momentEndDate
          }) || (
            <DatePickerLabel>
              Date <Icon name="dropdown" />
            </DatePickerLabel>
          )}
        </DatePickerValue>
        {showDatePicker && (
          <MobileCalendarWrapper>
            <DayPickerRangeController
              startDate={momentStartDate}
              endDate={momentEndDate}
              minimumNights={0}
              onDatesChange={handleDateChange}
              focusedInput={focused}
              hideKeyboardShortcutsPanel
              isOutsideRange={isOutsideRange}
              onFocusChange={handleFocusChange}
              transitionDuration={0}
            />
            <CalendarBottom>
              <Button onClick={() => setShowDatePicker(false)}>Close</Button>
              <Button onClick={() => setShowDatePicker(false)}>
                Choose Dates
              </Button>
            </CalendarBottom>
          </MobileCalendarWrapper>
        )}
      </Wrapper>
    )
  }

  return (
    <Wrapper
      // onFocus={() => setShowDatePicker(true)}
      ref={ref}
      isDateRange={momentStartDate && momentEndDate}
      onClick={() => setShowDatePicker(!showDatePicker)}
    >
      <DatePickerValue tabIndex={-1}>
        <DatePickerLabel>
          {datesToString({
            startDate: momentStartDate,
            endDate: momentEndDate
          }) || (
            <React.Fragment>
              <span>{dateText}</span> <Icon name={calendar ? "calendar" : "dropdown"} />
            </React.Fragment>
          )}
        </DatePickerLabel>
      </DatePickerValue>
      {showDatePicker && (
        <CalendarWrapper $leftAdjust={leftAdjust}>
          <DayPickerRangeController
            startDate={momentStartDate}
            endDate={momentEndDate}
            minimumNights={0}
            onDatesChange={handleDateChange}
            focusedInput={focused}
            hideKeyboardShortcutsPanel
            isOutsideRange={isOutsideRange}
            onFocusChange={handleFocusChange}
            transitionDuration={0}
          />
          <button onClick={handleSearch}>Select</button>
          <span onClick={onClear}>Clear</span>
        </CalendarWrapper>
      )}
    </Wrapper>
  )
}

export default DatePicker
