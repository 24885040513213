import styled from 'styled-components'
import { colors } from 'constant'

export const RegisterWrapper = styled.div`
  max-width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;

  ${({ isMobile }) => isMobile && 'height: 100vh;'}

  > p {
    margin-bottom: 24px;
  }
`

export const Header = styled.h1`
  margin-bottom: 4px;
  font-size: 40px;
  line-height: 60px;
`

export const RegisterForm = styled.form`
  width: 100%;

  > * {
    margin-bottom: 16px;
  }
`

export const Links = styled.div`
  margin-top: 12px;

  > a {
    text-decoration: none;
    color: ${colors.mainText};
    font-weight: 600;
    font-size: 14px;

    > span {
      cursor: pointer;
      margin-left: 4px;
      text-decoration: underline;
    }
  }
`

export const Terms = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 18px;
  align-items: center;

  > div {
    &:last-child {
      padding-left: 16px;
      flex: 1;
    }

    > a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`
