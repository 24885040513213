import React from 'react'

const RadioChecked = () => {
  return (
    <svg
      viewBox="0 0 36 36"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#C22026" />
          <stop offset="100%" stopColor="#C400CB" />
        </linearGradient>
      </defs>
      <path
        d="M18,11a7,7,0,1,1-7,7,7,7,0,0,1,7-7"
        class="clr-i-outline clr-i-outline-path-1"
        fill="url(#gradient)" // Apply the linear gradient here
      ></path>
      <path
        d="M18,34A16,16,0,1,1,34,18,16,16,0,0,1,18,34ZM18,4A14,14,0,1,0,32,18,14,14,0,0,0,18,4Z"
        class="clr-i-outline clr-i-outline-path-2"
        fill="url(#gradient)" // Apply the linear gradient here
      ></path>
      <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
    </svg>
  )
}

export default RadioChecked
