import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const MainWrapper = styled.div`
  margin-top: 12px;
`

export const HeroPlaceholder = styled.div`
  background-color: transparent;
  border: 2px solid ${colors.c9};
  width: 792px;
  height: 301px;
  border-radius: 16px;
`

export const UploadedPhoto = styled.div`
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProfilePhotoPlaceholder = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${colors.c15};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.c3};
  cursor: pointer;

  > svg {
    width: 24px;
    height: 24px;
  }
`
export const ProfilePhotoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;

  > span {
    margin-left: 15px;
    cursor: pointer;
  }

  :hover {
    text-decoration: underline;
  }
`
