import styled, { css } from 'styled-components'
import { colors, maxDesktopWidth, breakpoints } from 'constant'

const { md } = breakpoints

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${colors.c5};
  background: ${colors.c6};
`

export const EventsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 48px;

  > a {
    width: calc(100% / 4 - 8px);
    margin-right: 16px;

    ${md} {
      width: 100%;
    }
  }

  > a:last-child {
    margin-right: 0px;
  }
`

export const EventSection = styled.div`
  background-image: url('https://red-curtain-addict.imgix.net/demo/homepage3.png');
  background-size: cover;
  background-position: center;
  height: 652px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 32px;

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
    display: flex;
    flex-direction: column;

    > h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 30px;
    }

    > div {
      display: flex;
      justify-content: space-between;

      > a {
        width: calc(100% / 4 - 8px);
      }
    }
  }
`

export const RoundInfo = styled.div`
  border-radius: 24px;
  overflow: hidden;
  background: black;
  /* border: 2px solid ${colors.c18}; */
`

export const Content = styled.div`
  max-width: 100%;
  width: ${maxDesktopWidth};
  padding: 32px;

  ${md} {
    padding: 16px;
  }
`

export const AboutWrapper = styled.div`
  margin-bottom: 24px;
`

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background-color: ${colors.c17};

  > div {
    > h2 {
      font-size: 18px;
      margin-bottom: 2px;
    }
  }

  > a {
    display: flex;
    align-items: center;
    background: ${colors.buttonBackground};
    color: white;
    border-radius: 24px;
    padding: 10px 32px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
`

export const MobileDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const Details = styled.div`
  display: flex;
  transform: translateY(-110px);

  ${md} {
    flex-direction: column;
  }
`

export const DetailsLeft = styled.div`
  flex: 1;
  padding-right: 36px;

  > h1 {
    font-size: 24px;
  }

  > * {
    margin-bottom: 12px;
  }

  > p {
    margin-bottom: 32px;
  }

  ${md} {
    padding-right: 0px;
  }
`

export const MediaGalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    font-size: 24px;
    margin-bottom: 8px;
  }
`

export const MediaGalleryImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  cursor: pointer;
`

export const MediaGalleryMainImage = styled.img`
  display: block;
  width: unset;
  max-height: 400px;
  object-fit: contain;
  border-radius: 20px;
  transition: transform 0.3s ease-in-out;

  ${md} {
    width: 100%;
  }
`

export const MediaGalleryImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  transition: opacity 0.3s ease-in-out;
  ${MediaGalleryImageContainer}:hover & {
    opacity: 1;
  }
`

export const DetailsMobile = styled.div`
  display: flex;
  flex-direction: column;
`

export const DetailsRight = styled.div`
  width: 380px;
  flex-shrink: 0;
  max-width: 100%;

  > div {
    &:first-child {
      margin-bottom: 24px;
    }
  }

  ${md} {
    width: 100%;
  }
`

export const About = styled.p`
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 32px;
`

export const UsefulInfo = styled.div`
  background-color: ${colors.c17};
  > * {
    &:not(:last-child) {
      border-bottom: 2px solid ${colors.c6};
    }
  }
`

export const InfoItem = styled.div`
  padding: 17px 24px;
  display: flex;
  align-items: center;

  > svg {
    height: 24px;
    width: 24px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  > a {
    color: white;
  }

  ${md} {
    padding: 12px 24px;
    font-size: 14px;

    > svg {
      height: 20px;
      width: 20px;
      margin-right: 14px;
    }
  }
`

export const InfoItemEnd = styled.div`
  padding: 24px;
  font-size: 24px;

  ${md} {
    padding: 12px 24px;
    font-size: 14px;
  }
`

export const SocialLink = styled.a`
  background: ${colors.c1};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;

  > svg {
    height: 22px;
    width: 22px;
    color: white;
  }
`

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;

  > a {
    margin-right: 16px;
  }
`

export const RcaLogo = styled.div`
  height: 48px;
  width: 64px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`

export const MobileFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
