import styled from 'styled-components'

export const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > h1 {
    margin-bottom: 16px;
  }

  > p {
    text-align: center;
    margin-bottom: 16px;
    line-height: 20px;
  }

  > div {
    &:first-child {
      height: 120px;
      width: 120px;
      background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`
