import React, { useEffect, useState } from 'react'

import {
  MainWrapper,
  FormWrapper,
  TitleWrapper,
  TopicsGridContainer,
  TopicsGridItem,
  TopicImage,
  TopicLabel,
  TopicOvelay,
  CTAWrapper,
  GridWrapper,
  PageNumber,
  PageTitle,
  ZipCodeContainer,
  VibesWrapper,
  VibesPickerWrapper,
  CheckIcon
} from './styled'

import { toggleArr, capitalizeFirstLetter } from 'utils'
import { preTags, majorCities } from 'constant'
import { Icon, Button, TextInput, MultiPick } from 'components'
import { useHistory } from 'react-router-dom'
import { getPrefs, updatePrefs } from 'apis'
import { useStoreon } from 'storeon/react'
import toast from 'react-hot-toast'

const Preferences = () => {
  const topics = [
    { label: 'jazz', value: '29' },
    { label: 'opera', value: '31' },
    { label: 'symphonic', value: '355' },
    { label: 'theater', value: '1' },
    { label: 'dance', value: '27' },
    { label: 'choirs', value: '604' }
  ]

  const categoryObject = preTags.find((item) => item.category === 'Type')
  const vibesObject = preTags.find((item) => item.category === 'Category')
  const settingObject = preTags.find((item) => item.category === 'Setting')

  const [selectedFavorites, setSelectedFavorites] = useState([])
  const [selectedCities, setSelectedCities] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedVibes, setSelectedVibes] = useState([])
  const [selectedSetting, setSelectedSetting] = useState([])
  // const [zipcode, setZipcode] = useState('')

  const { user, dispatch, isMobile } = useStoreon('user', 'loading', 'isMobile')

  const [currentPage, setCurrentPage] = useState(1)

  const history = useHistory()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const handleSubmit = async () => {
    const token = await user.getIdToken()

    const mergedTags = [
      ...selectedFavorites,
      ...selectedCategory,
      ...selectedVibes,
      ...selectedSetting
    ]

    const submitData = {
      token,
      firstName: '',
      lastName: '',
      tags: mergedTags,
      cities: selectedCities
      // zipcode: zipcode ?? ''
    }
    const prefRequest = await updatePrefs(submitData)

    const userData = await getPrefs({ token })

    if (prefRequest.statusCode === 200) {
      dispatch('setUserState', { userData })
      history.push('/signup/preferences/thankyou')
      dispatch('setNotification', {
        message: 'Preferences saved!',
        timer: 8000
      })
    } else {
      toast.error(
        'Failed to update preferences with error: ',
        prefRequest.statusText
      )
    }
  }

  const handleCityUpdate = (item) => {
    setSelectedCities(toggleArr(selectedCities, item.value))
  }

  // const handleZipcodeChange = (value) => {
  //   setZipcode(value)
  // }

  const handleFavoritesUpdate = (item) => {
    setSelectedFavorites(toggleArr(selectedFavorites, item.value))
  }

  const handleCategoryUpdate = (item) => {
    setSelectedCategory(toggleArr(selectedCategory, item.value))
  }

  const handleVibesUpdate = (item) => {
    setSelectedVibes(item)
  }

  const handleSettingUpdate = (item) => {
    setSelectedSetting(toggleArr(selectedSetting, item.value))
  }

  const renderFirstPage = ({
    selected,
    onChange,
    onNext
    // zipcode,
    // onZipcodeChange
  }) => {
    const [hasErrors, setHasErrors] = useState(true)
    // const [zipcode_, setZipcode_] = useState(
    //   currentZipCode ? currentZipCode : ''
    // )

    // const handleZipcodeChange = (e) => {
    //   e.stopPropagation()
    //   const value = e.target.value
    //   onZipcodeChange(value)
    // }

    useEffect(() => {
      if (selected.length > 0) {
        setHasErrors(false)
      } else {
        setHasErrors(true)
      }
    }, [selected, hasErrors])

    return (
      <>
        <PageNumber>1/4</PageNumber>
        <PageTitle>
          <p>What city do you want to see upcoming performances in?</p>
        </PageTitle>
        <FormWrapper>
          <TopicsGridContainer>
            {majorCities.map((item, idx) => {
              const { value, label, id, imageName, thumbnailUrl } = item

              const isSelected = selected.some(
                (i) => i === item.value || i?.value === item.value
              )

              const city = label.split(',')[0].trim()

              const columns = !isMobile ? 4 : 3
              const height = !isMobile ? 200 : 143
              const name = capitalizeFirstLetter(city)

              const imageLink = thumbnailUrl

              return (
                <TopicsGridItem
                  $column={columns}
                  $height={height}
                  key={value}
                  onClick={() => onChange(item)}
                >
                  <TopicOvelay $height={height} $isSelected={isSelected}>
                    <TopicImage $imageUrl={imageLink}>
                      {isSelected && (
                        <CheckIcon>
                          <Icon name="check" />
                        </CheckIcon>
                      )}
                    </TopicImage>
                  </TopicOvelay>
                  <TopicLabel>{name}</TopicLabel>
                </TopicsGridItem>
              )
            })}
          </TopicsGridContainer>

          {/* <ZipCodeContainer>
            <span>What City do you live in?</span>

            <TextInput
              // error={
              //   formik.touched.email && formik.errors.email
              //     ? formik.errors.email
              //     : ''
              // }
              placeholder="Enter your Zip Code Here!"
              value={zipcode}
              returnValueOnChange={false}
              onChange={handleZipcodeChange}
              name="zipcode"
            />
          </ZipCodeContainer> */}

          <CTAWrapper $margin={32}>
            <Button
              type="button"
              disabled={selectedCities.length < 1}
              // onClick={() => formik.handleSubmit()}
              onClick={() => {
                onNext()
                scrollToTop()
              }}
            >
              Next
            </Button>
          </CTAWrapper>
        </FormWrapper>
      </>
    )
  }

  const renderSecondPage = ({ selected, onChange, onNext, onBack }) => {
    const [hasErrors, setHasErrors] = useState(true)

    useEffect(() => {
      if (selected.length > 0) {
        setHasErrors(false)
      } else {
        setHasErrors(true)
      }
    }, [selected, hasErrors])
    return (
      <>
        <PageNumber>2/4</PageNumber>
        <PageTitle>
          <p>Select your favorite performing arts genres!</p>
        </PageTitle>
        <FormWrapper>
          <TopicsGridContainer>
            {topics.map((item, idx) => {
              const { value, label } = item

              const isSelected = selected.some(
                (i) => i === item.value || i?.value === item.value
              )

              const columns = 3
              const height = !isMobile ? 230 : 143
              const name = capitalizeFirstLetter(label)

              const imageLink = `https://red-curtain-addict.imgix.net/preferences/${name}.jpg`

              return (
                <TopicsGridItem
                  $column={columns}
                  $height={height}
                  key={value}
                  onClick={() => onChange(item)}
                >
                  <TopicOvelay $height={height} $isSelected={isSelected}>
                    <TopicImage $imageUrl={imageLink}>
                      {isSelected && (
                        <CheckIcon>
                          <Icon name="check" />
                        </CheckIcon>
                      )}
                    </TopicImage>
                  </TopicOvelay>
                  <TopicLabel>{name}</TopicLabel>
                </TopicsGridItem>
              )
            })}
          </TopicsGridContainer>

          <CTAWrapper $margin={32}>
            <Button
              plain
              type="button"
              onClick={() => {
                onBack()
                scrollToTop()
              }}
            >
              Back
            </Button>
            <Button
              type="button"
              disabled={selectedFavorites.length < 1}
              onClick={() => {
                onNext()
                scrollToTop()
              }}
            >
              Next
            </Button>
          </CTAWrapper>
        </FormWrapper>
      </>
    )
  }

  const renderThirdPage = ({ selected, onChange, onNext, onBack }) => {
    const [hasErrors, setHasErrors] = useState(true)

    useEffect(() => {
      if (selected.length > 0) {
        setHasErrors(false)
      } else {
        setHasErrors(true)
      }
    }, [selected, hasErrors])
    return (
      <>
        <PageNumber>3/4</PageNumber>
        <PageTitle>
          <p>Which performance category sparks your fancy?</p>
        </PageTitle>
        <FormWrapper>
          <TopicsGridContainer>
            {categoryObject.tags.map((item, idx) => {
              const { value, label, thumbnailUrl } = item

              const isSelected = selected.some(
                (i) => i === item.value || i?.value === item.value
              )

              const columns = 3
              const height = !isMobile ? 230 : 143
              const name = capitalizeFirstLetter(label)

              // const imageLink = `https://red-curtain-addict.imgix.net/preferences/${name}.jpg`

              const imageLink = thumbnailUrl

              return (
                <TopicsGridItem
                  $column={columns}
                  $height={height}
                  key={value}
                  onClick={() => onChange(item)}
                >
                  <TopicOvelay $height={height} $isSelected={isSelected}>
                    <TopicImage $imageUrl={imageLink}>
                      {isSelected && (
                        <CheckIcon>
                          <Icon name="check" />
                        </CheckIcon>
                      )}
                    </TopicImage>
                  </TopicOvelay>
                  <TopicLabel>{name}</TopicLabel>
                </TopicsGridItem>
              )
            })}
          </TopicsGridContainer>

          <CTAWrapper $margin={32}>
            <Button
              plain
              type="button"
              onClick={() => {
                onBack()
                scrollToTop()
              }}
            >
              Back
            </Button>
            <Button
              type="button"
              disabled={selectedCategory.length < 1}
              onClick={() => {
                onNext()
                scrollToTop()
              }}
            >
              Next
            </Button>
          </CTAWrapper>
        </FormWrapper>
      </>
    )
  }

  const renderFourthPage = ({
    selected,
    selectedSetting,
    onSettingChange,
    onChange,
    onBack
  }) => {
    const [hasErrors, setHasErrors] = useState(true)

    useEffect(() => {
      if (selected.length > 0) {
        setHasErrors(false)
      } else {
        setHasErrors(true)
      }
    }, [selected, hasErrors])
    return (
      <>
        <PageNumber>4/4</PageNumber>
        <PageTitle>
          <p>What type of "performance vibes" are you interested in?</p>
        </PageTitle>
        <FormWrapper>
          <VibesWrapper>
            <VibesPickerWrapper>
              <MultiPick
                col={2}
                options={vibesObject.tags}
                value={selected}
                onChange={(value) => onChange(value)}
              />
            </VibesPickerWrapper>

            <PageTitle>
              <p>How do you prefer to experience the arts?</p>
            </PageTitle>

            <TopicsGridContainer>
              {settingObject.tags.map((item, idx) => {
                const { value, label, thumbnailUrl } = item

                const isSelected = selectedSetting.some(
                  (i) => i === item.value || i?.value === item.value
                )

                const columns = 2
                const height = !isMobile ? 150 : 80
                const name = capitalizeFirstLetter(label)

                const imageLink = thumbnailUrl

                return (
                  <TopicsGridItem
                    $column={columns}
                    $height={height}
                    key={value}
                    onClick={() => onSettingChange(item)}
                  >
                    <TopicOvelay
                      $centerLabel
                      $height={height}
                      $isSelected={isSelected}
                    >
                      <TopicImage $imageUrl={imageLink}>
                        <CheckIcon>
                          {isSelected && <Icon name="check" />}
                        </CheckIcon>
                        <TopicLabel $centerLabel $isSelected={isSelected}>
                          {name}
                        </TopicLabel>
                      </TopicImage>
                    </TopicOvelay>
                  </TopicsGridItem>
                )
              })}
            </TopicsGridContainer>

            <CTAWrapper $margin={!isMobile ? 32 : 69}>
              <Button
                plain
                type="button"
                onClick={() => {
                  onBack()
                  scrollToTop()
                }}
              >
                Back
              </Button>
              <Button
                type="button"
                disabled={selectedVibes < 1 || selectedSetting < 1}
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </CTAWrapper>
          </VibesWrapper>
        </FormWrapper>
      </>
    )
  }

  return (
    <MainWrapper>
      <TitleWrapper>
        <h1>Preferences</h1>
        <span>
          Get the best recommendations by telling us
          <br /> a little bit about you
        </span>
      </TitleWrapper>
      <GridWrapper>
        {currentPage === 1
          ? renderFirstPage({
              // zipcode: zipcode,
              // onZipcodeChange: handleZipcodeChange,
              selected: selectedCities,
              onChange: (value) => handleCityUpdate(value),
              onNext: () => setCurrentPage(2)
            })
          : currentPage === 2
          ? renderSecondPage({
              selected: selectedFavorites,
              onChange: (value) => handleFavoritesUpdate(value),
              onBack: () => setCurrentPage(1),
              onNext: () => setCurrentPage(3)
            })
          : currentPage === 3
          ? renderThirdPage({
              selected: selectedCategory,
              onChange: (value) => handleCategoryUpdate(value),
              onBack: () => setCurrentPage(2),
              onNext: () => setCurrentPage(4)
            })
          : currentPage === 4
          ? renderFourthPage({
              selected: selectedVibes,
              selectedSetting: selectedSetting,
              onSettingChange: (value) => handleSettingUpdate(value),
              onChange: (value) => handleVibesUpdate(value),
              onBack: () => setCurrentPage(3)
            })
          : {}}
      </GridWrapper>
    </MainWrapper>
  )
}

export default Preferences
