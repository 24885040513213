import styled, { css } from 'styled-components'
import { colors, breakpoints } from 'constant'

const { md } = breakpoints

export const TablePaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;

  ${md} {
    padding: 0px 8px;
  }
`

const activePaginationItem = css`
  background: ${colors.buttonBackground};
  border-radius: 50%;
`

export const TablePaginationItem = styled.button`
  height: 40px;
  width: 40px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  color: ${colors.secondaryText};
  border-top: 2px solid transparent;

  ${({ active }) => active && activePaginationItem}

  ${md} {
    height: 30px;
    width: 30px;
  }
`

export const TablePaginationCenter = styled.div`
  display: flex;
`

export const TablePaginationEnds = styled.button`
  font-weight: 600;
  color: ${colors.secondaryText};
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  > * {
    &:first-child {
      margin-right: 16px;
    }
  }

  > svg {
    height: 20px;
  }

  &:disabled {
    opacity: 0;
  }

  ${md} {
    > svg {
      display: none;
    }

    > span {
      > span {
        display: none;
      }
    }

    > * {
      &:first-child {
        margin-right: 0px;
      }
    }
  }
`
