import styled from 'styled-components'
import { colors, breakpoints } from 'constant'
import { Link } from 'react-router-dom'

const { md } = breakpoints

export const PickWrapper = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

// export const CoverImage = styled.div`
//     background-image: url(${({ image }) => image + '?height=160'});
//     height: 160px;
//     width: 33.33%;
//     position: relative;
//     top: 48px;
//     float: right;
//     background-repeat: no-repeat;
//     background-position: center;
//     min-width: 130px;
//     box-shadow: -10px 0px 10px black;

//     right: ${({ $isImageTen }) => !$isImageTen ? `-35px` : `0px`};

//     ${md} {
//         background-image: url(${({ image }) => image + '?height=205'});
//         right: ${({ $isImageTen }) => !$isImageTen ? `-38px` : `0px`};
//         top: 0px;
//         box-shadow: unset;
//         min-width: 125px;
//         height: 205px;
//     }
// `;

export const CoverImage = styled.div`
  background-image: url(${({ image }) => image + '?height=160'});
  height: 160px;
  width: 33.33%;
  position: relative;
  top: 48px;
  float: right;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 130px;
  box-shadow: -10px 0px 10px black;

  right: ${({ $isImageTen }) => (!$isImageTen ? `-35px` : `0px`)};

  ${md} {
    background-image: url(${({ image }) => image + '?height=195'});
    right: ${({ $isImageTen }) => (!$isImageTen ? `-34px` : `35px`)};
    top: 2px;
    box-shadow: unset;
    min-width: 125px;
    height: 195px;
  }
`

// export const NumberImage = styled.div`
//     background-image: url(${({image}) => image + '?height=250'});
//     width: 100%;
//     height: 250px;
//     position: relative;
//     background-repeat: no-repeat;
//     background-position: center;

//     left: ${({ $isImageTen }) => !$isImageTen ? `-45px` : `0px`};

//     ${md} {
//         background-image: url(${({image}) => image +'?height=200'});
//         left: ${({ $isImageTen }) => !$isImageTen ? `-38px` : `0px`};
//     }
// `;

export const NumberImage = styled.div`
  background-image: url(${({ image }) => image + '?height=250'});
  width: 100%;
  height: 250px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;

  left: ${({ $isImageTen }) => (!$isImageTen ? `-45px` : `0px`)};

  ${md} {
    background-image: url(${({ image }) => image + '?height=225'});
    left: ${({ $isImageTen }) => (!$isImageTen ? `-34px` : `0px`)};
    min-width: ${({ $isImageTen }) => (!$isImageTen ? `100%` : `225px`)};
  }
`

export const ImageWrapper = styled.div`
  height: 230px;
  width: 100%;
`

export const NameWrapper = styled.div`
  text-align: center;

  ${md} {
    margin-top: -15px;
    margin-right: 10px;
    > h4 {
      font-size: 13px;
    }

    > p {
      font-size: 13px;
    }
  }
`
