import React, { useState, useEffect, useMemo } from 'react'
import { Button, Icon } from 'components'
import Sidebar from '../Sidebar'

import {
  PageWrapper,
  ContentWrapper,
  Headers,
  TableWrapper,
  TabContainer,
  TabHeader,
  TabItem,
  TabContent,
  GalleryGridContainer,
  GalleryGridItem,
  GridItemActions,
  PrimaryLabel,
  PopoverContainer,
  PopoverButton,
  PopoverContent,
  PopoverCloser
} from './styled'
import { Loader } from 'components'
import { gql, useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { uploadVenueGallery, deleteVenueGallery, setVenueCover } from 'apis'
import { useStoreon } from 'storeon/react'
import { venueAuthUser } from 'utils'
import { useHistory } from 'react-router-dom'

const GET_VENUE = gql`
  query getVenue($where: venues_bool_exp = {}) {
    venues(where: $where, limit: 1) {
      name
      id
      bg_image_id
      owner_id
      venue_gallery {
        name
        id
      }
    }
  }
`

const Gallery = () => {
  const [activeTab, setActiveTab] = useState(1)
  const {slug} = useParams()
  const history =useHistory()
  const [venueImages, setVenueImages] = useState()
  const [venueVideos, setVenueVideos] = useState()
  const { user, userData, loading: userLoading } = useStoreon('user', 'userData', 'userLoading')
  const [getVenue, {loading: venueLoading, data: venueData}] = useLazyQuery(GET_VENUE, {
    variables: {
      where: {
        slug: {
          _eq: slug
        }        
      }
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    getVenue({
      variables: {
        where: {
          slug: {
            _eq: slug
          }
        }
      }
    })
  }, [])

  const venue = venueData?.venues[0]

  const loading = useMemo(() => {
    if (venueLoading) {
      return true
    } else if (!venueData) {
      return true
    }

    if (!loading && !userLoading) {
      const authCheck = venueAuthUser(userData, venue?.owner_id);
  
      if (!authCheck) {
        history.push('/')
      }
    }

    return false
  }, [venueLoading, venueData, userLoading])

  useEffect(() => {
    if (venue) {
      let images = []
      let videos = []

      for (let media of venue.venue_gallery) {
        if (media.is_video) {
          videos.push(media)
        } else {
          images.push(media)
        }
      }

      setVenueImages(images)
      setVenueVideos(videos)
    }
  }, [venue])

  const setCoverImage = async (venueId, mediaId) => {
    const token = await user.getIdToken()

    await setVenueCover({
      venueId: venueId,
      mediaId: mediaId,
      token: token
    })

    await getVenue({
      variables: {
        where: {
          slug: {
            _eq: slug
          }
        }
      }
    })
  }

  const handleImageUpload = async(e) => {
    const file = e.target.files[0];
    const token = await user.getIdToken()

    await uploadVenueGallery({
      venueId: venue.id,
      media: file,
      token: token
    });

    await getVenue({
      variables: {
        where: {
          slug: {
            _eq: slug
          }
        }
      }
    })
  }

  const handleDeleteGallery = async (mediaName) => {
    const token = await user.getIdToken()

    await deleteVenueGallery({ 
      venueId: venue.id, 
      mediaName: mediaName,
      token: token
    })

    await getVenue({
      variables: {
        where: {
          slug: {
            _eq: slug
          }
        }
      }
    })
  }
  
  const renderImagesGalleryGrid = (item, idx) => {
    const isPrimary = item.id === venue.bg_image_id;

    return (
      <GalleryGridItem key={idx} $imageUrl={`https://rca-s3.imgix.net/4/${venue.id}/${item.name}?height=250&auto=compression`}>
        <GridItemActions>
          {isPrimary && <PrimaryLabel>Cover</PrimaryLabel>}
          <PopoverContainer>
            <PopoverButton>
              <Icon name="moreIcon" />
            </PopoverButton>

            <PopoverContent>
              <PopoverCloser>
                <span onClick={() => setCoverImage(venue.id, item.id)}>
                  Set cover
                </span>
              </PopoverCloser>
              <PopoverCloser>
                <span onClick={() => handleDeleteGallery(item.name)}>
                  Delete
                </span>
              </PopoverCloser>
            </PopoverContent>
          </PopoverContainer>

        </GridItemActions>
      </GalleryGridItem>
    )
  }

  return (
    <PageWrapper>
      <Sidebar active={2} />
      <ContentWrapper>
        <Headers>
          <h2>Gallery</h2>
          <Button>
            <input type="file" style={{"display": "none"}} onChange={(e) => handleImageUpload(e)} />
            <span onClick={() => document.querySelector('input[type="file"]').click()}>Upload</span>
          </Button>
        </Headers>

        {/* {loading ? (
          <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p>Loading</p>
            <div style={{marginLeft: '10px'}}>
              <Loader/>
            </div>
          </div>
        ) : (
          <> */}
            <TableWrapper>
              <TabContainer>
                <TabHeader>
                  <TabItem $active={activeTab == 1} onClick={() => setActiveTab(1)}>
                    <h2>Images</h2>
                  </TabItem>
                  <TabItem $active={activeTab == 2} onClick={() => setActiveTab(2)}>
                    <h2>Videos</h2>
                  </TabItem>
                </TabHeader>

                <TabContent>
                  {activeTab === 1 && (
                    <GalleryGridContainer>
                      {venueImages?.map(renderImagesGalleryGrid)}
                    </GalleryGridContainer>
                  )}
                  {activeTab === 2 && (
                    <GalleryGridContainer>
                      {venueVideos?.map(renderImagesGalleryGrid)}
                    </GalleryGridContainer>
                  )}
                </TabContent>
              </TabContainer>
            </TableWrapper>
          {/* </>
        )} */}

      </ContentWrapper>
    </PageWrapper>
  )
}

export default Gallery
