import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormWrapper = styled.div`
  width: 384px;
  margin-top: 115px;

  ${md} {
    width: 90%;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: 40px;
    font-weight: 700;
  }

  > span {
  }
`

export const SocialButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  > span {
    margin-bottom: 16px;
  }
`

export const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 18px;

  > button {
    width: 116px;
  }
`

export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`
export const DividerText = styled.div``

export const Divider = styled.div`
  border-bottom: 2px solid ${colors.c17};
  width: 176px;
  height: 9px;
`
export const InputWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`
export const CTAWrapper = styled.div`
  margin-top: 12px;
`

export const Terms = styled.div`
  display: flex;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  justify-content: center;

  > div {
    text-align: center;

    &:last-child {
      flex: 1;
    }

    > a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

export const Links = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > a {
    text-decoration: none;
    color: ${colors.mainText};
    font-weight: 600;
    font-size: 14px;

    > span {
      margin-left: 4px;
      text-decoration: underline;
      cursor: pointer;
    }

    &:first-child {
      margin-bottom: 8px;
    }
  }
`

export const ForgotPassword = styled.form`
  padding: 12px 24px;
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  > h1 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  > p {
    margin-bottom: 16px;
  }

  > button {
    margin-top: 16px;
  }

  > a {
    align-self: center;
    margin-top: 16px;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }

  ${({ isMobile }) => isMobile && `
    padding: 24px 12px;
    
    > h1 {
      font-size: 20px;
    }

  `}
`
