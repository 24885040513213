export default {
  sm: '@media (max-width: 576px)',
  md: '@media (max-width: 768px)',
  lmd: '@media (max-width: 1000px)',
  lg: '@media (max-width: 1100px)',
  xl: '@media (max-width: 1200px)',
  xlg: '@media (max-width: 1400px)',
  xxl: '@media (max-width: 1600px)',
  xxxl: '@media (max-width: 1800px)'
}
