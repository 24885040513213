import React, { useState, useRef, useEffect, useMemo } from 'react'
import { DropdownWrapper, DropdownMenu, MoreItem, MenuItem } from './styled'
import { Link } from 'react-router-dom'

const Dropdown = (props) => {
  const { children, menu, content, extraText } = props
  const [showDropdown, setShowDropdown] = useState(false)
  const node = useRef()

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return
    }
    setShowDropdown(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const renderMenuItem = (item, idx) => {
    const { label, to, borderTop, header, onClick } = item

    const menuContent = (
      <MenuItem
        key={idx}
        header={header}
        borderTop={borderTop}
        onClick={() => {
          if (onClick) {
            onClick()
            setShowDropdown(false)
          }

          setShowDropdown(false)
        }}
      >
        {label}
      </MenuItem>
    )

    if (to) {
      return (
        <Link to={to} style={{ textDecoration: 'none' }}>
          {menuContent}
        </Link>
      )
    }

    return menuContent
  }

  const dropdownMenu = useMemo(() => {
    if (content) {
      return <DropdownMenu showDropdown={showDropdown}>{content}</DropdownMenu>
    }

    return (
      <DropdownMenu showDropdown={showDropdown}>
        {menu.map(renderMenuItem)}
        {extraText && <MoreItem>More Cities Coming Online Soon</MoreItem>}
      </DropdownMenu>
    )
  }, [menu, showDropdown, content, extraText])

  return (
    <DropdownWrapper ref={node}>
      <div
        onClick={() => setShowDropdown((prev) => !prev)}
        style={{ height: '100%' }}
      >
        {children}
      </div>
      {dropdownMenu}
    </DropdownWrapper>
  )
}

export default Dropdown
