import styled from 'styled-components'
import { maxDesktopWidth, colors, breakpoints } from 'constant'

const { md } = breakpoints

export const LayoutFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 32px 48px 32px;
  max-width: 100%;
  
  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      width: 100%;

      ${md} {
        flex-direction: column;
      }
    }

    > p {
      margin-top: 32px;
    }
  }

  ${md} {
    padding: 16px;
  }
`

export const RcaLogo = styled.div`
  height: 48px;
  width: 64px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`

export const FooterLeft = styled.div`
  flex: 1;

  ${md} {
    margin-bottom: 24px;
  }
`

export const FooterMiddle = styled.div`
  display: flex;
  flex: 2;

  > div {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 100px;

      ${md} {
        margin-right: 64px;
      }
    }

    > a {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 24px;
    }
  }
`

export const FooterRight = styled.div`
  max-width: 382px;
  flex: 2;

  > div {
    &:first-child {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 24px;
    }

    &:last-child {
      display: flex;
      
      > a {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
`

export const SocialLink = styled.a`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.c17};
  border-radius: 50%;

  > svg {
    height: 20px;
    width: 20px;
    color: white;
  }
`
