import React, { useState, useEffect, useMemo } from 'react'
import {
  AboutHeader,
  AboutWrapper,
  ContentWrapper,
  FormWrapper,
  GeneralInfoHeader,
  GeneralInfoWrapper,
  Headers,
  InputRow,
  PageWrapper,
  SideBarWrapper,
  TopBackButton,
  TextAreaContainer,
  TagsSectionWrapper,
  TagsContainer,
  CTAContainer
} from './styled'
import { Icon, TextInput, TextArea, MultiPick, Loader } from 'components'
import { Button } from 'components'
import { preTags } from 'constant'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Sidebar from '../../Sidebar'
import { useQuery, gql } from '@apollo/client'
import { updateProfile } from 'apis'
import {useParams, useHistory} from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import { venueAuthUser } from 'utils'

const GET_VENUE_DETAILS = gql`
  query getVenueDetails($where: venues_bool_exp = {}) {
    venues(where: $where, limit: 1) {
      id
      name
      address
      city
      state
      zip_code
      phone
      website
      email
      about
      facebook
      instagram
      twitter
      youtube
      owner_id
      venue_tags {
        tag_id
      }
    }
  }
`

const EditProfile = () => {
  // const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/
  const { slug } = useParams()
  const history = useHistory()
  const { user, userData, loading: userLoading} = useStoreon('user', 'userData', 'loading')
  const [tags, setTags] = useState([])
  const {loading: venueLoading, data: venueData} = useQuery(GET_VENUE_DETAILS, {
    variables: {
      where: {
        slug: {
          _eq: slug
        }
      }
    }
  })

  const formik = useFormik({
    initialValues: {
      orgName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      email: '',
      phoneNumber: '',
      website: '',
      about: '',
      facebook: '',
      instagram: '',
      youtube: '',
      twitter: ''
    },
    validationSchema: Yup.object({
      orgName: Yup.string().required('Organization name is required'),
      phoneNumber: Yup.string().required('Phone number is required'),
      email: Yup.string().email().required('Email is required'),
      website: Yup.string().url('Invalid URL format'),
      address: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zipCode: Yup.string().required('Zip Code is required'),
      about: Yup.string().required('Biography is required')
    }),
    onSubmit: async (values) => {
      // Handle form submission here
      const token = await user.getIdToken()

      await updateProfile({
        id: venue.id,
        name: values.orgName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        website: values.website,
        address: values.address,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        facebook: values.facebook,
        instagram: values.instagram,
        twitter: values.twitter,
        youtube: values.youtube,
        about: values.about,
        tags: tags,
        token: token
      })

      setTimeout(() => {
        history.push(`/venue/${slug}/dashboard/profile`)
      }, 300);
    }
  })

  const venue = venueData?.venues[0]

  const loading = useMemo(() => {
    if (venueLoading) {
      return true
    } else if (!venueData) {
      return true
    }

    if (!loading && !userLoading) {
      const authCheck = venueAuthUser(userData, venue?.owner_id);
  
      if (!authCheck) {
        history.push('/')
      }
    }

    return false
  }, [venueLoading, venueData, userLoading])

  useEffect(() => {
    if (venue) {
      formik.setFieldValue('orgName', venue.name)
      formik.setFieldValue('phoneNumber', venue.phone)
      formik.setFieldValue('email', venue.email)
      formik.setFieldValue('website', venue.website)
      formik.setFieldValue('address', venue.address)
      formik.setFieldValue('city', venue.city)
      formik.setFieldValue('state', venue.state)
      formik.setFieldValue('zipCode', venue.zip_code)
      formik.setFieldValue('facebook', venue.facebook)
      formik.setFieldValue('instagram', venue.instagram)
      formik.setFieldValue('twitter', venue.twitter)
      formik.setFieldValue('youtube', venue.youtube)
      formik.setFieldValue('about', venue.about)

      if (venue.venue_tags) {
        const tagOptions = preTags.flatMap(({ tags }) =>
          tags.map(({ label, value }) => ({ label, value }))
        )
        const selectedTags = tagOptions.filter(tag => venue.venue_tags.some(venueTag => venueTag.tag_id.toString() === tag.value));
        const selectedValues = selectedTags.map(tag => tag.value)

        setTags(selectedValues)
      }
    }
  }, [venue])

  const renderTagSection = (item, idx) => {
    const { category, tags: categoryTags } = item

    return (
      <TagsSectionWrapper key={idx}>
        <p>What {category.toLowerCase()} of events are you interested in?</p>
        <MultiPick
          lighter
          col={4}
          options={categoryTags}
          value={tags}
          onChange={(value) => setTags(value)}
        />
      </TagsSectionWrapper>
    )
  }

  return (
    <PageWrapper>
      <Sidebar active={1} />
      <ContentWrapper>
        <Headers>
          <TopBackButton to={`/venue/${slug}/dashboard/profile`}>
            <Icon name="backIcon" />
          </TopBackButton>
          <h2>Edit Profile</h2>
        </Headers>

        <FormWrapper>
          {loading ? (
            <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <p>Loading</p>
              <div style={{marginLeft: '10px'}}>
                <Loader/>
              </div>
            </div>
          ) : (
            <>
              <GeneralInfoWrapper>
                <GeneralInfoHeader>General Information</GeneralInfoHeader>

                <InputRow>
                  <TextInput
                    error={
                      formik.touched.orgName && formik.errors.orgName
                        ? formik.errors.orgName
                        : ''
                    }
                    placeholder="Name of Organization"
                    value={formik.values.orgName}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="orgName"
                  />
                  <TextInput
                    error={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? formik.errors.phoneNumber
                        : ''
                    }
                    placeholder="Phone Number"
                    value={formik.values.phoneNumber}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="phoneNumber"
                  />
                </InputRow>

                <InputRow>
                  <TextInput
                    error={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : ''
                    }
                    placeholder="Email"
                    value={formik.values.email}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="email"
                  />
                  <TextInput
                    error={
                      formik.touched.website && formik.errors.website
                        ? formik.errors.website
                        : ''
                    }
                    placeholder="Website"
                    value={formik.values.website}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="website"
                  />
                </InputRow>
              </GeneralInfoWrapper>

              <GeneralInfoWrapper>
                <GeneralInfoHeader>Address of your organization</GeneralInfoHeader>

                <InputRow>
                  <TextInput
                    error={
                      formik.touched.address && formik.errors.address
                        ? formik.errors.address
                        : ''
                    }
                    placeholder="Street Address"
                    value={formik.values.address}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="address"
                  />
                  <TextInput
                    error={
                      formik.touched.city && formik.errors.city
                        ? formik.errors.city
                        : ''
                    }
                    placeholder="City"
                    value={formik.values.city}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="city"
                  />
                </InputRow>

                <InputRow>
                  <TextInput
                    error={
                      formik.touched.state && formik.errors.state
                        ? formik.errors.state
                        : ''
                    }
                    placeholder="State Code"
                    value={formik.values.state}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="state"
                  />
                  <TextInput
                    error={
                      formik.touched.zipCode && formik.errors.zipCode
                        ? formik.errors.zipCode
                        : ''
                    }
                    placeholder="Zip Code"
                    value={formik.values.zipCode}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="zipCode"
                  />
                </InputRow>
              </GeneralInfoWrapper>

              <GeneralInfoWrapper>
                <GeneralInfoHeader>Your social media links</GeneralInfoHeader>

                <InputRow>
                  <TextInput
                    error={
                      formik.touched.facebook && formik.errors.facebook
                        ? formik.errors.facebook
                        : ''
                    }
                    placeholder="Facebook"
                    value={formik.values.facebook}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="facebook"
                  />
                  <TextInput
                    error={
                      formik.touched.instagram && formik.errors.instagram
                        ? formik.errors.instagram
                        : ''
                    }
                    placeholder="Instagram"
                    value={formik.values.instagram}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="instagram"
                  />
                </InputRow>

                <InputRow>
                  <TextInput
                    error={
                      formik.touched.twitter && formik.errors.twitter
                        ? formik.errors.twitter
                        : ''
                    }
                    placeholder="Twitter"
                    value={formik.values.twitter}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="twitter"
                  />
                  <TextInput
                    error={
                      formik.touched.youtube && formik.errors.youtube
                        ? formik.errors.youtube
                        : ''
                    }
                    placeholder="Youtube"
                    value={formik.values.youtube}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="youtube"
                  />
                </InputRow>
              </GeneralInfoWrapper>

              <AboutWrapper>
                <AboutHeader>About your organization</AboutHeader>
                <TextAreaContainer>
                  <TextArea
                    rows={7}
                    placeholder="Write a bio about your organization"
                    error={
                      formik.touched.about && formik.errors.about
                        ? formik.errors.about
                        : ''
                    }
                    value={formik.values.about}
                    returnValueOnChange={false}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    name="about"
                  />
                </TextAreaContainer>
              </AboutWrapper>

              <TagsContainer>
                {preTags
                  .filter((item) => item.category !== 'Setting')
                  .map(renderTagSection)}
              </TagsContainer>

              <CTAContainer>
                <Button plain onClick={() => history.push(`/venue/${slug}/dashboard/profile`)}>
                  Cancel
                </Button>

                <Button type={'submit'} onClick={formik.handleSubmit}>Save</Button>
              </CTAContainer>
            </>
          )}
        </FormWrapper>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default EditProfile
