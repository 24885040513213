import React from 'react'

import {
  SideBarItem,
  SideBarItems,
  SideBarLabel,
  SideBarWrapper
} from './styled'
import { useParams, useHistory } from 'react-router-dom'
import { Icon } from 'components'


const Sidebar = ({ active }) => {
  const { slug } = useParams()
  const routes = [
    {
      label: 'Events',
      icon: 'ticket',
      url: `/${slug}/dashboard/events`
    },
    {
      label: 'Profile',
      icon: 'layoutIcon',
      url: `/${slug}/dashboard/profile`
    },
    {
      label: 'Gallery',
      icon: 'galleryOutline',
      url: `/${slug}/dashboard/gallery`
    }
  ]

  
  const renderLinks = (item, idx) => {
    return (
      <SideBarItem key={idx} $active={active === idx}>
        <SideBarLabel to={'/venue' + item.url}>
          <Icon name={item.icon} />
          {item.label}
        </SideBarLabel>
      </SideBarItem>
    )
  }
  return (
    <SideBarWrapper>
      <SideBarItems>{routes.map(renderLinks)}</SideBarItems>
    </SideBarWrapper>
  )
}

export default Sidebar
