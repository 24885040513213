import React, { useState, useEffect } from 'react'
import {
  FormWrapper,
  TopicsGridContainer,
  TopicsGridItem,
  TopicImage,
  TopicLabel,
  TopicOvelay,
  CTAWrapper,
  PageNumber,
  PageTitle,
  CheckIcon
} from '../styled'
import { capitalizeFirstLetter } from 'utils'
import { Icon, Button } from 'components'
import { useStoreon } from 'storeon/react'
import { topics } from '../index'

const SecondPage = ({ selected, onChange, onNext, onBack }) => {
  const { user, dispatch, isMobile, loading } = useStoreon(
    'user',
    'loading',
    'isMobile'
  )
  const [hasErrors, setHasErrors] = useState(true)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    if (window.innerHeight < 670)
      window.scrollTo({
        top: 100,
        behavior: 'smooth'
      })
  }, [])

  useEffect(() => {
    if (selected.length > 0) {
      setHasErrors(false)
    } else {
      setHasErrors(true)
    }
  }, [selected, hasErrors])
  return (
    <>
      <PageNumber>2/4</PageNumber>
      <PageTitle>
        <p>Select your favorite performing arts genres!</p>
      </PageTitle>
      <FormWrapper>
        <TopicsGridContainer>
          {topics.map((item, idx) => {
            const { value, label } = item

            const isSelected = selected.some(
              (i) => i === item.value || i?.value === item.value
            )

            const columns = 3
            const height = !isMobile ? 230 : 143
            const name = capitalizeFirstLetter(label)

            const imageLink = `https://red-curtain-addict.imgix.net/preferences/${name}.jpg`

            return (
              <TopicsGridItem
                $column={columns}
                $height={height}
                key={value}
                onClick={() => onChange(item)}
              >
                <TopicOvelay $height={height} $isSelected={isSelected}>
                  <TopicImage $imageUrl={imageLink}>
                    {isSelected && (
                      <CheckIcon>
                        <Icon name="check" />
                      </CheckIcon>
                    )}
                  </TopicImage>
                </TopicOvelay>
                <TopicLabel>{name}</TopicLabel>
              </TopicsGridItem>
            )
          })}
        </TopicsGridContainer>

        <CTAWrapper $margin={32}>
          <Button
            plain
            type="button"
            onClick={() => {
              onBack()
            }}
          >
            Back
          </Button>
          <Button
            type="button"
            // disabled={selectedFavorites.length < 1}
            onClick={() => {
              onNext()
            }}
          >
            Next
          </Button>
        </CTAWrapper>
      </FormWrapper>
    </>
  )
}

export default SecondPage
