import React, { useEffect, useState } from 'react'
import { AuthModalWrapper } from './styled'
import { Modal } from 'components'
import { useStoreon } from 'storeon/react'
import Login from './Login'
import Register from './Register'

const AuthModal = () => {
  const { showAuth, dispatch, user } = useStoreon('showAuth', 'user')
  const [isSignUp, setIsSignUp] = useState(true)

  useEffect(() => {
    if (showAuth === 'login') {
      setIsSignUp(false)
    }

    if (user) {
      dispatch('setShowAuth', false)
    }
  }, [user, dispatch, showAuth])

  const handleOnClose = () => {
    dispatch('setShowAuth', false)
  }

  return (
    <Modal
      open={showAuth}
      onClose={handleOnClose}
      fullScreen
    >
      <AuthModalWrapper>
        {isSignUp ? <Register handleGoToLogin={() => setIsSignUp(false)}/> : <Login handleGoToSignup={() => setIsSignUp(true)}/>}
      </AuthModalWrapper>
    </Modal>
  )
}

export default AuthModal
