import episodes from './EpisodesIcon'
import profile from './ProfileIcon'
import tickets from './TicketsIcon'
import violin from './ViolinIcon'
import ballet from './BalletIcon'
import theater from './TheaterIcon'
import menu from './MenuIcon'
import facebook from './FacebookIcon'
import instagram from './InstagramIcon'
import twitter from './TwitterIcon'
import youtube from './YoutubeIcon'
import star from './StarIcon'
import drinks from './DrinksIcon'
import musical from './MusicalIcon'
import laugh from './LaughIcon'
import search from './SearchIcon'
import place from './PlaceIcon'
import calendar from './CalendarIcon'
import price from './PriceIcon'
import tag from './TagIcon'
import closeCircle from './CloseCircleIcon'
import close from './CloseIcon'
import home from './HomeIcon'
import invisible from './InvisibleIcon'
import visible from './VisibleIcon'
import check from './CheckIcon'
import immersive from './ImmersiveIcon'
import gallery from './GalleryIcon'
import external from './ExternalIcon'
import phone from './PhoneIcon'
import email from './EmailIcon'
import clock from './ClockIcon'
import dropdown from './DropdownIcon'
import audience from './AudienceIcon'
import performance from './PerformanceIcon'
import filter from './FilterIcon'
import news from './NewsIcon'
import arrowLeft from './ArrowLeftIcon'
import arrowRight from './ArrowRightIcon'
import circleCheck from './circleCheckIcon'
import follow from './FollowIcon'
import unfollow from './UnfollowIcon'
import venue from './VenueIcon'
import confetti from './ConfettiIcon'
import ticket from './TicketIcon'
import starOutline from './StarOutlineIcon'
import megaPhone from './MegaPhoneIcon'
import gear from './GearIcon'
import exit from './ExitIcon'
import funnel from './FunnelIcon'
import backIcon from './BackIcon'
import galleryOutline from './GalleryOutline'
import layoutIcon from './LayoutIcon'
import moreIcon from './MoreIcon'
import radioChecked from './RadioChecked'
import heartIcon from './HeartIcon'
import heartIconFilled from './HeartIconFilled'
import questionMark from './QuestionMark'
import loadingIcon from './Loading'
import heartIconLarge from './HeartIconLarge'

const iconMap = {
  episodes,
  profile,
  tickets,
  violin,
  ballet,
  theater,
  menu,
  facebook,
  instagram,
  twitter,
  youtube,
  star,
  drinks,
  musical,
  laugh,
  search,
  place,
  calendar,
  price,
  tag,
  closeCircle,
  close,
  home,
  invisible,
  visible,
  check,
  immersive,
  gallery,
  external,
  phone,
  email,
  clock,
  dropdown,
  filter,
  audience,
  news,
  arrowLeft,
  arrowRight,
  performance,
  circleCheck,
  follow,
  venue,
  unfollow,
  confetti,
  ticket,
  starOutline,
  megaPhone,
  gear,
  exit,
  funnel,
  backIcon,
  galleryOutline,
  layoutIcon,
  moreIcon,
  radioChecked,
  heartIcon,
  heartIconFilled,
  questionMark,
  loadingIcon,
  heartIconLarge
}

export default iconMap
