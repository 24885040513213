import React, { useMemo, useEffect, useState } from 'react'
import { Modal, SearchOptin, SearchBox } from 'components'
import { MobileFilters, RcaLogo, SearchWrapper } from './styled'
import { useStoreon } from 'storeon/react'
import { isFirstTimeVisitor } from 'utils'

const SearchModal = (props) => {
  // const {setOptin, optin} = props;
  const [optin, setOptin] = useState(true)
  const { dispatch, user, showSearchModal } = useStoreon(
    'isMobile',
    'user',
    'userData',
    'showSearchModal'
  )

  const handleCancel = () => {
    setOptin(false)
  }

  return (
    <Modal
      open={showSearchModal}
      onClose={() => dispatch('setShowSearchModal', false)}
      closeButton
    >
      <MobileFilters>
        {isFirstTimeVisitor() && optin && !user ? (
          <SearchOptin onCancel={handleCancel} />
        ) : (
          <SearchWrapper>
            <RcaLogo />
            <SearchBox searchText={props.searchText} />
          </SearchWrapper>
        )}
      </MobileFilters>
    </Modal>
  )
}

export default SearchModal
