import React from 'react'

import {
  MainWrapper,
  LogoWrapper,
  Header,
  Copy,
  CTAWrapper,
  RcaLogo,
  LoginButton,
  Decline
} from './styled'

import { Button } from 'components'
import { useHistory } from 'react-router-dom'

const SearchOptin = ({ onCancel }) => {
  const history = useHistory()

  const handleCancel = () => {
    onCancel()
  }

  return (
    <MainWrapper>
      <RcaLogo />
      <Header>
        <h1>Customize Your Experience!</h1>
      </Header>

      <Copy>
        Sign up or Log in so we can recommend upcoming arts experiences curated
        to your personal interests.
      </Copy>

      <CTAWrapper>
        <Button to={'/signup'}>Let's Get Started!</Button>
        <LoginButton to={'/login'}>Login</LoginButton>
      </CTAWrapper>

      <Decline onClick={handleCancel}>Thanks, but maybe later</Decline>
    </MainWrapper>
  )
}

export default SearchOptin
