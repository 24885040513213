import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  FormWrapper,
  InputWrapper,
  ButtonWrapper,
  TextWrapper,
  AboutWrapper,
  TextAreaContainer,
  CloseIcon
} from './styled'
import { Button, TextInput, TextArea, Modal } from 'components'
import { useMutation, gql } from '@apollo/client'
import { sendClaimVenueEmail } from 'apis'

const ADD_ROW_TO_CLAIM_VENUE_FORM = gql`
  mutation addRowToClaimVenueForm(
    $orgName: String!
    $contactName: String!
    $address: String!
    $email: String!
    $phone: String!
    $website: String!
    $about: String!
  ) {
    insert_claim_venue_form(
      objects: {
        org_name: $orgName
        contact_name: $contactName
        address: $address
        email: $email
        phone_number: $phone
        website: $website
        about: $about
      }
    ) {
      affected_rows
    }
  }
`
import { useStoreon } from 'storeon/react'

const ClaimVenue = (props) => {
  const { open, onClose } = props
  const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/
  const [addRowToClaimVenueForm] = useMutation(ADD_ROW_TO_CLAIM_VENUE_FORM)
  const { isMobile } = useStoreon('isMobile')

  const sendEmail = async (data) => {
    try {
      await sendClaimVenueEmail(data)
    } catch (e) {
      console.error('Failed to send email with error: ', e)
    }
  }

  const formik = useFormik({
    initialValues: {
      orgName: '',
      email: '',
      address: '',
      phoneNumber: '',
      website: '',
      about: '',
      contactName: ''
    },
    validationSchema: Yup.object({
      orgName: Yup.string().required('Name is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      address: Yup.string().required('Address is required'),
      phoneNumber: Yup.string().required('Phone number is required'),
      website: Yup.string().required('Website is required'),
      about: Yup.string().required(
        'Description of your organization is required'
      ),
      contactName: Yup.string().required(
        `Name of organization's contact is required`
      )
    }),
    onSubmit: (values) => {
      addRowToClaimVenueForm({
        variables: {
          orgName: values.orgName,
          address: values.address,
          email: values.email,
          phone: values.phoneNumber,
          website: values.website,
          about: values.about,
          contactName: values.contactName
        }
      })

      sendEmail({
        orgName: values.orgName,
        address: values.address,
        email: values.email,
        phone: values.phoneNumber,
        website: values.website,
        about: values.about,
        toEmail: 'Info@redcurtainaddict.com'
      })
      onClose()
    }
  })

  return (
    <Modal onClose={onClose} open={open} closeButton>
      <form onSubmit={formik.handleSubmit}>
        <FormWrapper>
          <h3>Basic Informaition</h3>
          <TextWrapper>
            Let's get started by adding the necessary information about your
            organization.
          </TextWrapper>

          <InputWrapper>
            <TextInput
              error={
                formik.touched.orgName && formik.errors.orgName
                  ? formik.errors.orgName
                  : ''
              }
              placeholder="Organization Name"
              value={formik.values.orgName}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="orgName"
            />
          </InputWrapper>

          <InputWrapper>
            <TextInput
              error={
                formik.touched.contactName && formik.errors.contactName
                  ? formik.errors.contactName
                  : ''
              }
              placeholder="Contact Name"
              value={formik.values.contactName}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="contactName"
            />
          </InputWrapper>

          <InputWrapper>
            <TextInput
              error={
                formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : ''
              }
              placeholder="Address"
              value={formik.values.address}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="address"
            />
          </InputWrapper>

          <InputWrapper>
            <TextInput
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ''
              }
              placeholder="Contact Email"
              value={formik.values.email}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="email"
            />
          </InputWrapper>

          <InputWrapper>
            <TextInput
              error={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? formik.errors.phoneNumber
                  : ''
              }
              placeholder="Phone Number"
              value={formik.values.phoneNumber}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="phoneNumber"
            />
          </InputWrapper>

          <InputWrapper>
            <TextInput
              error={
                formik.touched.website && formik.errors.website
                  ? formik.errors.website
                  : ''
              }
              placeholder="Website"
              value={formik.values.website}
              returnValueOnChange={false}
              onChange={(e) => formik.handleChange(e)}
              name="website"
            />
          </InputWrapper>

          <AboutWrapper>
            <p style={{ marginBottom: '10px' }}>About your organization</p>
            <TextAreaContainer>
              <TextArea
                rows={7}
                placeholder="Write a bio about your organization"
                error={
                  formik.touched.about && formik.errors.about
                    ? formik.errors.about
                    : ''
                }
                value={formik.values.about}
                returnValueOnChange={false}
                onChange={(e) => formik.handleChange(e)}
                name="about"
              />
            </TextAreaContainer>
          </AboutWrapper>

          <ButtonWrapper>
            <Button type="submit">Submit</Button>
          </ButtonWrapper>
        </FormWrapper>
      </form>
    </Modal>
  )
}

export default ClaimVenue
