import React from 'react'

const MegaPhoneIcon = () => {
  return (
// <?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
    <svg fill="currentColor" viewBox="0 0 1050 1050" xmlns="http://www.w3.org/2000/svg">
        <path d="M191.969 659.238l740.956 208.241c45.721 12.846 91.076-21.519 91.076-69.012V245.794c0-54.316-51.909-93.594-104.186-78.835L191.944 372.528c-10.885 3.074-17.217 14.39-14.143 25.275s14.39 17.217 25.275 14.143l727.869-205.568c26.138-7.379 52.095 12.262 52.095 39.417v552.673c0 20.354-19.441 35.084-39.035 29.579L203.05 619.807c-10.889-3.06-22.197 3.286-25.257 14.175s3.286 22.197 14.175 25.257z"/><path d="M199.778 372.753L45.994 353.707c-24.43-3.031-45.996 16.032-45.996 40.651v236.308c0 29.197 24.489 50.856 53.469 47.3l146.298-18.024c11.226-1.383 19.205-11.605 17.822-22.83s-11.605-19.205-22.83-17.822L48.468 637.312c-4.557.559-7.511-2.053-7.511-6.646V394.358l153.786 19.045c11.225 1.39 21.452-6.582 22.842-17.807s-6.582-21.452-17.807-22.842z"/><path d="M177.03 392.238v247.286c0 11.311 9.169 20.48 20.48 20.48s20.48-9.169 20.48-20.48V392.238c0-11.311-9.169-20.48-20.48-20.48s-20.48 9.169-20.48 20.48zM522.717 731.2l-13.435 50.135c-4.393 16.388-21.237 26.114-37.633 21.726l-154.413-41.378c-16.394-4.393-26.121-21.24-21.728-37.624l13.435-50.137c2.928-10.925-3.556-22.155-14.481-25.083s-22.155 3.556-25.083 14.481l-13.435 50.135c-10.251 38.236 12.445 77.544 50.689 87.793l154.42 41.38c38.248 10.236 77.542-12.454 87.792-50.687l13.435-50.137c2.928-10.925-3.556-22.155-14.481-25.083s-22.155 3.556-25.083 14.481z"/>
    </svg>
  )
}

export default MegaPhoneIcon
