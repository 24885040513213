import styled from 'styled-components'
import { breakpoints } from 'constant'

const { md } = breakpoints

export const AboutWrapper = styled.div`
  padding-top: 120px;

  ${md} {
    padding-top: 60px;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 80px;

  ${md} {
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

  > * {
    flex: 1;
    padding: 40px;


    ${md} {
      width: 100%;
      padding: 12px;
      max-width: 100% !important;
    }
  }

  iframe {
    ${md} {
      height: 300px;
    }
  }

  ${({ flip }) => flip && 'flex-direction: row-reverse;'} 
`

export const Community = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  > h2 {
    font-size: 42px;
    margin-bottom: 16px;
  }

  > p {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  ${md} {
    padding: 12px;
    margin-bottom: 20px;

    > h2 {
      font-size: 26px;
    }
  }

`

export const CommunityImage = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 600px;
  max-width: 100%;
  height: 400px;
  margin-bottom: 28px;
`

export const MobileImage = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center bottom;
  border-radius: 10px;
  width: 100%;
  height: 200px;
  margin-bottom: 16px;
`

export const RowImage = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center bottom;
  border-radius: 10px;
  width: 100%;
  height: 320px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > h2 {
    font-size: 42px;
    margin-bottom: 8px;
  }

  > p {
    font-size: 16px;
    line-height: 24px;
  }

  > button {
    margin-top: 24px;
  }

  ${md} {
    > h2 {
      font-size: 26px;
    }

    ${({ player }) => player && `
      > div {
        max-width: 100%;
        height: 320px !important;
      }
    `}
  }
`

export const ContactUs = styled.form`
  display: flex;
  flex-direction: column;

  > h2 {
    font-size: 44px;
    line-height: 52px;
    margin-bottom: 8px;
  }

  > h4 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 26px;
    font-weight: 500;
  }

  > div {
    display: flex;
    
    > input {
      &:first-child {
        margin-right: 12px;
      }
    }
  }

  ${md} {
    padding: 12px;

    > div {
      flex-direction: column;

      > input {
        &:first-child {
          margin-right: 0px;
        }
      }
    }

    > h2 {
       font-size: 22px;
       line-height: 26px;
    }
  }
`

export const FormInput = styled.input`
  background: #1E1F22;
  border-radius: 100px;
  border: none;
  padding: 16px 20px;
  width: 100%;
  margin-bottom: 16px;
  color: white;
  font-size: 16px;
`

export const FormButton = styled.input`
  align-self: flex-end;
  background: linear-gradient(98.1deg, #C22026 -5.05%, #C400CB 101.49%);
  backdrop-filter: blur(16px);
  border-radius: 100px;
  color: white;
  border: none;
  font-weight: 600;
  font-size: 16px;
  padding: 16px 40px;
  font-family: 'Open Sans';
  text-align: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`
