import styled from 'styled-components'
import { colors } from 'constant'

export const LayoutWrapper = styled.div`
  position: relative;
`

export const TextModalWrapper = styled.div`
  width: 600px;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  > button {
    margin-top: 16px;
  }

  > pre {
    background: ${colors.backgroundSecondary};
    padding: 12px;
    height: 300px;
    width: 100%;
    overflow: auto;
    white-space: pre-wrap; 
    word-wrap: break-word; 
    border-radius: 4px; 
  }

  ${({ isMobile }) => isMobile && 'padding-top: 24px;'}
`
