import React from 'react'
import ImageUploading from 'react-images-uploading'
import {
  GridContainer,
  BlankGridItem,
  MainWrapper,
  UploadButton,
  ButtonWrapper,
  GalleryGridItem,
  BlankGridContainer,
  AddMoreButon,
  AddMoreGrid,
  Editbutton
} from './styled'
import { Icon } from 'components'

const GalleryUploader = () => {
  const [images, setImages] = React.useState([])
  const maxNumber = 69
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList)
  }

  const renderBlankGrid = () => {
    const divs = []

    for (let i = 1; i <= 4; i++) {
      divs.push(<BlankGridItem key={i}></BlankGridItem>)
    }

    return divs
  }

  const renderGalleryGrid = ({ onUpdate }) => {
    const gallery = []

    images.map((image, index) => {
      gallery.push(
        <GalleryGridItem key={index} $imageUrl={image['data_url']}>
          <Editbutton onClick={() => onUpdate(index)}>Edit</Editbutton>
        </GalleryGridItem>
      )
    })

    return gallery
  }

  return (
    <ImageUploading
      multiple
      value={images}
      maxNumber={maxNumber}
      onChange={onChange}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps
      }) => (
        // write your building UI
        <MainWrapper>
          {images.length ? (
            <>
              <GridContainer>
                {renderGalleryGrid({ onUpdate: onImageUpdate })}
                <AddMoreGrid>
                  <AddMoreButon onClick={onImageUpload} {...dragProps}>
                    <Icon name="gallery" /> Add More
                  </AddMoreButon>
                </AddMoreGrid>
              </GridContainer>
            </>
          ) : (
            <>
              <ButtonWrapper>
                <UploadButton onClick={onImageUpload} {...dragProps}>
                  <Icon name="gallery" /> Upload
                </UploadButton>
              </ButtonWrapper>
              <BlankGridContainer>{renderBlankGrid()}</BlankGridContainer>
            </>
          )}
        </MainWrapper>
      )}
    </ImageUploading>
  )
}

export default GalleryUploader
