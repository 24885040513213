import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'
import { Popover } from '@headlessui/react'

const { md } = breakpoints

export const PageWrapper = styled.div`
  display: flex;
`
export const ContentWrapper = styled.div`
  width: 100%;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-top: 80px; */
`

export const Headers = styled.div`
  width: 90%;
  position: relative;
  z-index: 20;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h2 {
    font-size: 20px;
  }

  > button {
    width: 140px;
    font-weight: normal;
  }
`

export const TableWrapper = styled.div`
  width: 100%;
  /* background-color: ${colors.c17}; */
  margin-top: 12px;
  border-radius: 16px;
  min-height: 700px;
  display: flex;
`

export const TabContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const TabHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`

const activeItem = css`
  color: ${colors.c3};
`

const inactiveItem = css`
  color: ${colors.c4};
`

export const TabItem = styled.div`
  cursor: pointer;

  > h2 {
    font-size: 24px;
    ${({ $active }) => ($active ? activeItem : inactiveItem)}
  }

  &:last-child {
    margin-left: 24px;
  }
`

export const TabContent = styled.div`
  display: flex;
  justify-content: center;
`

export const GalleryGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`

export const GalleryGridItem = styled.div`
  display: flex;
  /* width: calc((100% / 4) - 4px); */
  /* justify-content: center;
  align-items: center; */
  border: 2px solid ${colors.c14};
  height: 180px;
  width: 180px;
  background-image: url('${({ $imageUrl }) => $imageUrl}');
  border-radius: 16px;
  background-size: cover;
  background-position: center;

  &:hover {
    border: 2px solid ${colors.c4};
  }
`

export const GridItemActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 5px 5px;

  > button {
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    background-color: ${colors.c23};

    > svg {
      height: 20px;
      width: 20px;
      color: ${colors.c3};
      margin-right: unset;
    }
  }
`

export const PrimaryLabel = styled.span`
  border-radius: 100px;
  background-color: ${colors.c23};
  color: white;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
  font-size: 12px;
`

export const PopoverContainer = styled(Popover)`
  position: relative;
  margin-left: 5px;
`

export const PopoverButton = styled(Popover.Button)`
  background: rgba(0, 0, 0, 0.8);
  border: none;
  cursor: pointer;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: 24px;
    width: 24px;
    color: ${colors.c3};
  }
`

export const PopoverContent = styled(Popover.Panel)`
  position: absolute;
  z-index: 2;
  background: ${colors.c18};
  border-radius: 16px;
  right: 0px;
  width: 115px;
  max-width: calc(100vw - 36px);
  padding: 10px;
  display: flex;
  flex-direction: column;
`

export const PopoverTop = styled.div`
  padding: 20px 20px 24px 20px;
  border-bottom: 1px solid #101010;

  > div {
    &:first-child {
    }

    &:last-child {
    }
  }
`

export const PopoverCloser = styled(Popover.Button)`
  background-color: transparent;
  border: none;
  padding: unset;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 12px;
    color: ${colors.c4};
    justify-content: left;
    height: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    padding-left: 10px;

    &:hover {
      background: ${colors.c16};
      color: ${colors.c3};
    }
  }
`