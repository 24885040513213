import React from 'react'
import styled, { css } from 'styled-components'
import { maxDesktopWidth, breakpoints, colors } from 'constant'

const { md } = breakpoints

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 64px;
`

export const FormWrapper = styled.div`
  width: 792px;
  margin-left: 64px;
  margin-right: 64px;

  ${md} {
    width: auto;
    margin-left: unset;
    margin-right: unset;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > h1 {
    font-size: 32px;
    font-weight: 700;
  }

  > span {
  }
`

export const GridWrapper = styled.div`
  background-color: ${colors.c15};
  margin-top: 24px;
  border-radius: 16px;
  padding-bottom: 32px;

  ${md} {
    width: 85%
  }
`

export const TopicsGridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 32px;
  justify-content: center;

  ${md} {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`

export const PageNumber = styled.div`
  text-align: right;
  margin-right: 32px;
  margin-top: 20px;
`

export const PageTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  align-items: center;

  > h1 {
    font-size: 40px;
    font-weight: 700;

    ${md} {
      text-align: center
    }
  }

  > p {
    text-align: center;
    font-size: 20px;
    width: 80%;
  }
`

const selectedCss = css`
  background-color: ${colors.c14};
  /* background-color: transparent; */
`

const unSelectedCss = css`
  background-color: transparent;
`

const threeColumnGrid = css`
  width: calc(33% - 20px);
`

const fourColumnGrid = css`
  width: calc(25% - 20px);
`

export const TopicsGridItem = styled.div`
  cursor: pointer;
  /* flex: 1 0 calc(33% - 20px); */
  /* width: calc(33% - 20px); */
  height: ${({ $height }) => $height + 'px'};
  border-radius: 16px;
  background-color: ${colors.c17};

  ${({ $column }) =>
    $column === 3
      ? threeColumnGrid
      : $column === 4
      ? fourColumnGrid
      : threeColumnGrid}

  ${md} {
    width: calc(33% - 10px);
  }
`

const centerLabel = css`
  height: ${({ $height }) => $height + 'px'};
  border-radius: 16px;
`

const bottomLabel = css`
  height: ${({ $height }) => $height - 50 + 'px'};
`

export const TopicOvelay = styled.div`
  position: relative;
  width: 100%;
  background-color: ${colors.c15};
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  /* &:hover {
    &::before {
      background-color: ${colors.c14};
    }
  } */

  ${({ $centerLabel }) => ($centerLabel ? centerLabel : bottomLabel)}

  &::before {
    ${TopicsGridItem}:hover & {
      background-color: ${colors.c14};
    }
    transition: background-color 0.3s ease-in-out;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${({ $isSelected }) => ($isSelected ? selectedCss : unSelectedCss)}

    ${({ $centerLabel }) =>
      $centerLabel ? `background-color: ${colors.c22}` : ''}
  }

  ${md} {
    height: 115px; 
  }
`

export const TopicImage = styled.div`
  height: 100%;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
`

export const CheckIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  > svg {
    transform: translate(-10px, 10px);
    height: 26px;
    width: 26px;
    background: ${colors.c20};
    padding: 5px;
    border-radius: 50%;
  }
`

const centerLabelCss = css`
  transform: translate(0px, 50px);
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`

const selectedCenterLabelCss = css`
  transform: translate(0px, 24px);
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`

export const TopicLabel = styled.div`
  /* padding-left: 20px; */
  padding-top: 12px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;

  ${({ $centerLabel }) => ($centerLabel ? centerLabelCss : '')}
  ${({ $centerLabel, $isSelected }) =>
    $centerLabel && $isSelected ? selectedCenterLabelCss : ''}

  ${md} {
    font-size: 12px;
    padding-top: 5px;
  }
`

export const ZipCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;
  align-items: center;

  > span {
    text-align: center;
  }

  > div {
    margin-top: 16px;
    width: 300px;
  }
`

export const CTAWrapper = styled.div`
  margin-top: ${({ $margin }) => $margin + 'px'};;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  gap: 20px;

  justify-content: ${(props) =>
    React.Children.count(props.children) === 1 ? 'flex-end' : 'space-between'};

  > button {
    width: 180px;
  }

  ${md} {
    justify-content: center;

    > button {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`

export const VibesWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const VibesPickerWrapper = styled.div`
  width: 600px;

  ${md} {
    width: 90%
  }
`
