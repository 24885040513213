import styled, { css } from 'styled-components'
import { breakpoints, colors } from 'constant'

const { md } = breakpoints

export const MultiPickWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${md} {
    align-items: center;
  }
`

const activeCss = css`
  background: ${colors.buttonBackground};

  > svg {
    transform: rotate(0deg);
  }
`

export const Option = styled.div`
  padding: 8px 13px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  color: ${colors.mainText};
  width: calc((100% / ${({ $col }) => $col}) - 8px);
  margin: 6px 4px;
  display: flex;
  align-items: center;
  background: ${({ $lighter }) => ($lighter ? colors.c18 : colors.c17)};
  border-radius: 50px;
  word-break: break-word;
  font-weight: 600;

  > span {
    flex: 1;
  }

  > svg {
    height: 10px;
    width: 10px;
    flex-shrink: 0;
    transform: rotate(45deg);
    transition: 300ms ease;
  }

  &:hover {
    background: ${colors.buttonBackground};
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    background: transparent;
    border-bottom: 1px solid ${colors.c12};
    font-size: 14px;
  `}

  ${({ isActive }) => isActive && activeCss}
`
