import React, { useState, useCallback } from 'react'
import { GalleryModalWrapper, ImageWrapper, Image, CloseButton, Header } from './styled'
import { Modal, Icon } from 'components'
import Carousel, { Modal as ImageModal, ModalGateway } from 'react-images'
import { useStoreon } from 'storeon/react'

const GalleryModal = props => {
  const { open, photos, onClose } = props
  const imageArr = photos.map(p => ({ src: p.imageUrl }))
  const { isMobile } = useStoreon('isMobile')

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((idx) => {
    setCurrentImage(idx)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const renderImage = (item, idx) => {
    return (
      <Image
        key={idx}
        onClick={() => openLightbox(idx)}
        image={item.src}
      />
    )
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <GalleryModalWrapper>
        <Header>
          <h1>Image Gallery</h1>
          {
            !isMobile &&
            <CloseButton onClick={onClose}>
              <Icon name='closeCircle' />
            </CloseButton>
          }
        </Header>
        <ImageWrapper>
          {imageArr.map(renderImage)}
        </ImageWrapper>
        <ModalGateway>
          {viewerIsOpen ? (
            <ImageModal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={imageArr.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </ImageModal>
          ) : null}
        </ModalGateway>
      </GalleryModalWrapper>
    </Modal>
  )
}

export default GalleryModal
