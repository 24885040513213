import React from 'react'
import { SelectWrapper, ListItem } from './styled'
import { Listbox } from '@headlessui/react'

const Select = props => {
  const { value, label, onChange, options, placeholder } = props

  const valueObj = options.find(o => o.value === value) || {}

  const renderOptions = (item, idx) => {
    return (
      <Listbox.Option
        key={idx}
        value={item}
      >
        {({ selected, active }) => (
          <ListItem
            selected={selected}
            active={active}
          >
            {item.label}
          </ListItem>
        )}
      </Listbox.Option>
    )
  }

  return (
    <SelectWrapper>
      <label>{label}</label>
      <Listbox value={valueObj} onChange={onChange}>
        <Listbox.Button>{valueObj.label || placeholder}</Listbox.Button>
        <Listbox.Options>
          {options.map(renderOptions)}
        </Listbox.Options>
      </Listbox>
    </SelectWrapper>
  )
}

export default Select
