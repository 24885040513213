import React from 'react'
import Select from 'react-select'
// import makeAnimated from 'react-select/animated'

import { colors } from 'constant'

const MultiSelect = ({ options, onChange, placeholder, defaultValue = [] }) => {
  const handleChange = (selected) => {
    onChange(selected)
  }

  return (
    <Select
      placeholder={placeholder || 'Select...'}
      onChange={handleChange}
      closeMenuOnSelect={false}
      defaultValue={defaultValue}
      isMulti
      options={options}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: colors.c18,
          borderColor: state.isFocused ? colors.c4 : colors.c18,
          boxShadow: state.menuIsOpen && 'none',
          borderRadius: '8px',
          ':hover': {
            borderColor: colors.c4
          }
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          margin: '10px'
        }),
        container: (baseStyles, state) => ({
          ...baseStyles,
          boxSizing: 'content-box'
        }),
        indicatorSeparator: () => ({
          display: 'none'
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: colors.c18
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isFocused ? colors.c16 : '',
          ':hover': {
            backgroundColor: colors.c16
          }
          //   backgroundColor: state.isSelected && colors.c16
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          background: colors.c20,
          borderRadius: '100px',
          height: '34px',
          alignItems: 'center',
          paddingLeft: '10px',
          paddingRight: '5px'
        }),
        multiValueLabel: (baseStyles) => ({
          ...baseStyles,
          color: colors.c3
        }),
        multiValueRemove: (baseStyles) => ({
          ...baseStyles,
          ':hover': {
            backgroundColor: 'transparent',
            cursor: 'pointer',
            color: colors.c7
          }
        })
      }}
    />
  )
}

export default MultiSelect
