import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import {
  MainWrapper,
  Header,
  Copy,
  CTAWrapper,
  RcaLogo,
  LoginButton,
  Decline
} from './styled'

import { Modal } from 'components'
import { useStoreon } from 'storeon/react'

import { Button } from 'components'

const SignUpOptin = () => {
  const { showSignupOptin, isMobile, dispatch } = useStoreon('showSignupOptin', 'isMobile')
  const location = useLocation()

  const handleOnClose = () => {
    dispatch('setShowSignupOptin', false)
  }

  useEffect(() => {
    dispatch('setShowSignupOptin', false)
  }, [location])

  // const handleSignup = () => {
  //   handleOnClose()

  //   setTimeout(() => {
  //     history.push('/signup')
  //   }, 200)
  // }

  // const handleLogin = () => {
  //   history.push('/login')
  // }

  return (
    <Modal open={showSignupOptin} onClose={handleOnClose} fullScreen={isMobile}>
      <MainWrapper>
        <RcaLogo />
        <Header>
          <h1>Don't miss a beat!</h1>
        </Header>

        <Copy>Sign up or Login to unlock this feature and more!</Copy>

        <CTAWrapper>
          <Button to={'/signup'}> Sign Up!</Button>
          <LoginButton to={'/login'}>Login</LoginButton>
        </CTAWrapper>

        <Decline onClick={handleOnClose}>Thanks, but maybe later</Decline>
      </MainWrapper>
    </Modal>
  )
}

export default SignUpOptin
