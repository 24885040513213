import React from 'react'
import ImageUploading from 'react-images-uploading'
import {
  GridContainer,
  BlankGridItem,
  MainWrapper,
  UploadButton,
  GalleryGridItem
} from './styled'
import { Icon, Button } from 'components'

const GalleryUploader = props => {
  const {uploadedImages, handleUpdate} = props
  const maxNumber = 69

  const onChange = (imageList) => {
    handleUpdate(imageList)
  }

  const renderBlankGrid = () => {
    const divs = []

    for (let i = 1; i <= 4; i++) {
      divs.push(<BlankGridItem key={i}></BlankGridItem>)
    }

    return divs
  }

  const renderGalleryGrid = ({ onImageRemove }) => {
    const gallery = []

    uploadedImages.map((image, index) => {
      gallery.push(
        <GalleryGridItem key={index} $imageUrl={image['data_url']}>
          <Button plain onClick={() => onImageRemove(index)}>
            <Icon name="close" />
          </Button>
        </GalleryGridItem>
      )
    })

    return gallery
  }

  return (
    <ImageUploading
      multiple
      value={uploadedImages}
      maxNumber={maxNumber}
      onChange={onChange}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps
      }) => (
        // write your building UI
        <MainWrapper>
          {uploadedImages.length ? (
            <>
              <UploadButton onClick={onImageUpload} {...dragProps}>
                <Icon name="gallery" /> Add more
              </UploadButton>
              <GridContainer>
                {renderGalleryGrid({ onImageRemove })}
              </GridContainer>
            </>
          ) : (
            <UploadButton onClick={onImageUpload} {...dragProps}>
              <Icon name="gallery" /> Upload Media
            </UploadButton>
          )}
        </MainWrapper>
      )}
    </ImageUploading>
  )
}

export default GalleryUploader
